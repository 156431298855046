<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SearchTextField from '@/components/Base/SearchTextField/index.vue'

const { t } = useI18n()

const model = defineModel<string>({ required: true })
</script>

<template>
  <div class="search-patient-name">
    <SearchTextField
      v-model="model"
      :label="t('features.hospitalizationOrders.List.search.patientName')"
    />
  </div>
</template>

<style lang="scss" scoped>
.search-patient-name {
  @media (0px <= width < 1280px) {
    min-width: calc(50% - 4px);
  }
  @media (1280px <= width) {
    min-width: 240px;
  }
}
</style>
