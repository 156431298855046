<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { SearchHospitalizationOrderStatusCode } from '../type'

import SimpleSelect from '@/components/Base/SimpleSelect/index.vue'

const { t } = useI18n()

const model = defineModel<SearchHospitalizationOrderStatusCode>({ required: true })

const items: { title: string; value: SearchHospitalizationOrderStatusCode }[] = [
  {
    title: t('attributes.all'),
    value: 'all'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.preHospital'),
    value: 'pre_hospital'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.hospital'),
    value: 'hospital'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.discharged'),
    value: 'discharged'
  }
]
</script>

<template>
  <div class="search-hospitalization-order-status">
    <SimpleSelect
      v-model="model"
      :label="t('features.patient.List.search.hospitalizationOrderStatus')"
      :items="items"
      item-title="title"
      item-value="value"
      hide-details
    />
  </div>
</template>

<style lang="scss" scoped>
.search-hospitalization-order-status {
  min-width: 120px;
  max-width: 120px;
}
</style>
