<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed } from 'vue'

import BaseButton from '../Button/index.vue'
import BaseChip from '../Chip/index.vue'

type Props = {
  /**
   * タイトル
   */
  title: string
  /**
   * チップのラベル
   */
  label: string
  /**
   * チップの色
   */
  chipColor: string
  /**
   * チップの表示・非表示
   */
  showChip: boolean
  /**
   * ボタンカラー
   */
  outlined: boolean
  /**
   * ボタンの中身
   */
  firstBtnText: string
  secondBtnText: string
  /**
   * ボタンの配置方向
   */
  layout: 'single' | 'split-h' | 'split-v'

  firstBtnDisabled?: boolean
  secondBtnDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: 'タイトル',
  label: 'チップ',
  chipColor: 'grey',
  showChip: true,
  outlined: false,
  firstBtnText: 'ボタン',
  secondBtnText: 'ボタン',
  layout: 'single'
})

const emit = defineEmits<{
  (e: 'firstClick'): void
  (e: 'secondClick'): void
}>()

const firstClick = () => {
  emit('firstClick')
}

const secondClick = () => {
  emit('secondClick')
}

const titleStyle = getTypography('TITLE_M')
const bodyStyle = getTypography('BODY_M')

const btnColor = computed(() => {
  return props.outlined ? 'primitive-white-DEFAULT-value' : 'primary'
})

const variant = props.outlined ? 'outlined' : 'elevated'

const btnClass =
  props.layout === 'split-h' ? ['status-card-btn-split-h', 'status-card-btn'] : 'status-card-btn'
</script>

<template>
  <v-sheet class="status-card" width="308px" height="200px" elevation="2">
    <div class="status-card-title">
      <div :style="titleStyle">{{ title }}</div>
      <BaseChip v-if="showChip" :label="label" :color="chipColor" elevation="2" />
      <div v-if="!showChip">
        {{ '-' }}
      </div>
    </div>
    <div
      :style="bodyStyle"
      :class="layout == 'split-v' ? 'status-card-body-v' : 'status-card-body-h'"
    >
      <slot name="body"></slot>
    </div>
    <div :class="layout == 'split-v' ? 'status-card-action-v' : 'status-card-action-h'">
      <base-button
        :class="btnClass"
        :size="'small'"
        :color="btnColor"
        :content="firstBtnText"
        @click="firstClick"
        :token-type="'LABEL_M'"
        :disabled="props.firstBtnDisabled"
        :variant="variant"
      />
      <base-button
        v-if="layout !== 'single'"
        :class="btnClass"
        :size="'small'"
        :color="btnColor"
        :content="secondBtnText"
        @click="secondClick"
        :token-type="'LABEL_M'"
        :disabled="props.secondBtnDisabled"
        :variant="variant"
      />
    </div>
  </v-sheet>
</template>
<style lang="scss" scoped>
.status-card-title {
  display: flex;
  justify-content: space-between;
  height: 24px;
  margin-bottom: 13px;
}

.status-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 5px;
}

.status-card-body-v {
  max-height: 42px;
  white-space: pre-line;
}

.status-card-body-h {
  max-height: 95px;
  white-space: pre-line;
}

.status-card-action-v {
  display: flex;
  flex-direction: column;
  height: 76px;
  justify-content: space-between;
}

.status-card-action-h {
  display: flex;
  justify-content: space-between;
}

.status-card-btn {
  width: 100%;
  height: 36px;
}

.status-card-btn:disabled {
  width: 100%;
  height: 36px;
  color: #a3a9ad !important;
  background-color: #e8e9eb !important;
}

.status-card-btn-split-h {
  width: 133px;
}

.status-card-btn-split-h:disabled {
  width: 133px;
  color: #a3a9ad !important;
  background-color: #e8e9eb !important;
}
</style>
