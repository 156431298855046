<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiDocumentSetContentItem } from '@/api/generate/apiSchema'

import { SimpleScrollTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/SimpleScrollTable/types'
import useContentTypeFormatter from '@/composables/useContentTypeFormatter'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'

const props = defineProps<{
  contentList: MiDocumentSetContentItem[]
  isLoading: boolean
}>()

const { t } = useI18n()
const contentList = ref(props.contentList)
const { getFormattedDateTime } = useDateFormatter()
const { transContentType } = useContentTypeFormatter()

const headers: Header[] = [
  {
    title: t('features.documentSets.detail.header.contentName'),
    key: 'contentName',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.documentSets.detail.header.fileType'),
    key: 'fileType',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.documentSets.detail.header.updatedAt'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed(() => {
  return contentList.value.map((content: MiDocumentSetContentItem) => {
    return {
      id: content.id,
      contentName: content.title,
      fileType: transContentType(content.contentType),
      updatedAt: getFormattedDateTime(new Date(content.updatedAt))
    }
  })
})

const simpleScrollTable = ref<InstanceType<typeof SimpleScrollTable>>()

const resetScroll = () => {
  if (simpleScrollTable.value == undefined) {
    return
  }
  simpleScrollTable.value.resetScroll()
}

defineExpose({
  resetScroll
})
</script>

<template>
  <SimpleScrollTable
    class="document-set-detail-table"
    ref="simpleScrollTable"
    :headers="headers"
    :items="items"
    @itemClick="(item: Item) => router.push(`/contents/${item.id}`)"
    :loading="isLoading"
    :no-data-text="t('attributes.noDataText')"
  />
</template>

<style lang="scss" scoped>
.document-set-detail-table {
  max-height: 446px;
}
</style>
