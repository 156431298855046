<script setup lang="ts">
import SendDocumentSets from '@features/SendDocumentSets/index.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { DisplayPatientInfo } from '@/features/SendDocumentSets/type'

import { hospitalizationOrderApiClient, medicalInstitutionsApiClient } from '@/api/apiClient'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const displayNameStore = useDisplayNameStore()

const isLoading = ref<boolean>(true)
const patientId = ref<string>('')
const route = useRoute()
const hospitalizationOrderId =
  typeof route.params.hospitalizationOrderId == 'string' ? route.params.hospitalizationOrderId : ''

displayNameStore.updateDisplayName({
  breadcrumbs: [
    {
      title: t('pages.hospitalizationOrders.list'),
      href: '/hospitalization-orders',
      disable: false
    },
    {
      title: t('pages.hospitalizationOrders.detail'),
      href: `/hospitalization-orders/${hospitalizationOrderId}`,
      disable: false
    },
    {
      title: t('pages.hospitalizationOrders.sendDocumentSet'),
      disable: true
    }
  ]
})

onMounted(async () => {
  await fetchHospitalizationOrder()
  await fetchPatient()
})

const fetchHospitalizationOrder = async () => {
  try {
    isLoading.value = true
    const response =
      await hospitalizationOrderApiClient().api.hospitalizationOrderByMedicalInstitution(
        medicalInstitutionsStore.currentMedicalInstitution().id,
        hospitalizationOrderId,
        { optimId: '' }
      )
    patientId.value = response.data.patientId
    isLoading.value = false
  } catch (error: unknown) {
    throw Error('fetch hospitalizationOrder')
  }
}

const patientInfo = ref<DisplayPatientInfo>()

const fetchPatient = async () => {
  try {
    const response = await medicalInstitutionsApiClient().api.patientByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      patientId.value,
      { optimId: '' }
    )

    patientInfo.value = {
      patientNo: response.data.patientNo,
      familyName: response.data.familyName,
      givenName: response.data.givenName,
      phoneticFamilyName: response.data.phoneticFamilyName,
      phoneticGivenName: response.data.phoneticGivenName,
      gender: response.data.gender,
      birthDate: response.data.birthDate
    }
  } catch (error: unknown) {
    throw Error('fetch hospitalizationOrder')
  }
}
</script>
<template>
  <v-container class="container">
    <SendDocumentSets
      v-if="patientInfo != undefined"
      v-model:patientInfo="patientInfo"
      v-model:isLoading="isLoading"
      :hospitalizationOrderId="hospitalizationOrderId"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
