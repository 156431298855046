import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { DisplayNameType } from '@/components/Layout/AppBar/types'

export const useDisplayNameStore = defineStore('displayName', () => {
  // 画面情報
  const displayName = ref<DisplayNameType>({
    breadcrumbs: []
  })
  const updateDisplayName = (newDisplayName: Partial<DisplayNameType>) => {
    displayName.value = { ...displayName.value, ...newDisplayName }
  }
  return {
    displayName,
    updateDisplayName
  }
})
