import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t } = useI18n()

  // 生年月日を表示フォーマットに変更
  const getFormattedBirthDate = (birthDate: Date | unknown): string => {
    if (typeof birthDate !== 'string') {
      return ''
    }

    const date = new Date(birthDate)

    const fullAge = getCalcFullAge(date)

    return (
      format(date, 'yyyy/MM/dd') + t('attributes.formatTemplate.fullAge', { resource: fullAge })
    )
  }

  // 満年齢算出
  const getCalcFullAge = (birthDate: Date): number => {
    const now = new Date()
    let age = now.getFullYear() - birthDate.getFullYear()
    const month = now.getMonth() - birthDate.getMonth()

    if (month < 0 || (month === 0 && now.getDate() < birthDate.getDate())) {
      // 誕生月の日より前の場合は1歳減らす
      age--
    }

    return age
  }

  return {
    getFormattedBirthDate,
    getCalcFullAge
  }
}
