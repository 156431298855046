<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, onMounted, reactive, ref, watch, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseButton from '@/components/Base/Button/index.vue'
import Dialog from '@/components/Base/Dialog/index.vue'
import useDialog from '@/composables/useDialog'
import {
  FormOptionType,
  type AvailableDestination,
  type AvailableInputSet,
  type FormContentInput,
  type FormOption,
  type InputSetOption
} from '@/features/FormContentForm/type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * セクションインデックス番号
   */
  sectionIndex: number
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォーム親オブジェクト
   */
  form: FormContentInput
  /**
   * フォームオプション
   */
  option: InputSetOption
}>()
const formOption = reactive(props.option)
const form: Ref<FormContentInput> = computed(() => ({ ...props.form }))
const inputSetSettings = ref<AvailableInputSet[]>([])
const selectedFormOptionIds = ref<string[]>([...props.option.optionIds])

// 選択可能候補一覧
const availableDestinationList: Ref<AvailableDestination[]> = computed(() => {
  if (form.value) {
    // セクション内からインプットセットを除くフォームオプションのIDとインデックスを取得
    return (
      form.value.contentJson[props.sectionIndex].options
        // フォームオプションのIDとインデックスを抽出
        .map((fo: FormOption, foIndex: number) => ({ id: fo.id, index: foIndex, type: fo.type }))
        // 順番が自分より前の要素を抽出。インプットセットと選択肢を除外。
        .filter(
          (ad: AvailableDestination & { type: string }) =>
            ad.index < props.optionIndex &&
            ad.type !== FormOptionType.InputSet &&
            ad.type !== FormOptionType.Choice
        )
        // 抽出結果を型整形
        .map((ad: AvailableDestination & { type: string }) => ({ id: ad.id, index: ad.index }))
    )
  }
  // 利用可能候補一覧が存在しないとき
  return []
})

// 初期化
onMounted(() => {
  if (inputSetSettings.value.length === 0) {
    // 入力セット設定値に利用可能候補一覧をコピー
    inputSetSettings.value.splice(
      0,
      inputSetSettings.value.length,
      ...availableDestinationList.value.map((e) => ({ ...e, isChecked: false }))
    )
    // コピーの場合は設定値を画面に反映
    selectedFormOptionIds.value.forEach((formOptionId) => {
      inputSetSettings.value.forEach((i) => {
        if (formOptionId === i.id) {
          i.isChecked = true
        }
      })
    })
  }
})

/**
 * フォームオプションIDから回答番号を逆引き
 * @param string フォームオプションのid
 * @return number 回答番号（インデックス+1）
 **/
const getAvailableQuestionNumber = (formOptionId: string): number | null => {
  const destination = availableDestinationList.value.find(
    (ava: AvailableDestination) => ava.id === formOptionId
  )
  return destination ? destination.index + 1 : null
}

const checkedInputSetIds = computed<string[]>(() => {
  const result: string[] = []
  inputSetSettings.value.forEach((inputSet: AvailableInputSet) => {
    if (inputSet.isChecked === true) {
      result.push(inputSet.id)
    }
  })
  return result
})

// 登録された入力セットの論理名を取得
const getOptionName = (formOptionId: string) => {
  // ダイアログで設定されたidに紐づく回答番号を取得
  return (
    t('features.FormContents.new.input.questionIndexPrefix') +
    getAvailableQuestionNumber(formOptionId)
  )
}

// 入力セットの保存
const saveDialog = () => {
  isOpenDialog.value = !isOpenDialog.value
  // formオブジェクトに反映
  formOption.optionIds.splice(0, formOption.optionIds.length, ...checkedInputSetIds.value)
}

// 登録状態を引継ぎながらフォームオプションオブジェクトの更新を反映
const refreshInputSetIds = () => {
  // 比較用マップオブジェクト
  const availableMap = new Map(
    availableDestinationList.value.map((e: AvailableDestination) => [e.id, e])
  )

  // 入力セット設定値にあるが利用可能候補一覧にない要素を削除
  inputSetSettings.value = inputSetSettings.value.filter((e: AvailableInputSet) =>
    availableMap.has(e.id)
  )
  // 順番入れ替えと要素コピーを反映
  availableDestinationList.value.forEach((ad: AvailableDestination) => {
    const existsInputSet: AvailableInputSet | undefined = inputSetSettings.value.find(
      (i: AvailableInputSet) => i.id === ad.id
    )
    if (!existsInputSet) {
      // 利用候補一覧にあるが入力セット設定値にない場合は追加
      inputSetSettings.value.push({ ...ad, isChecked: false })
    } else if (ad.index !== existsInputSet.index) {
      // 入力セット設定値に存在するがインデックスが異なる場合は最新値で更新
      existsInputSet.index = ad.index
    }
  })

  // インデックス番号でソート
  inputSetSettings.value.sort((a, b) => a.index - b.index)

  // 入力セット設定値を更新
  selectedFormOptionIds.value.splice(
    0,
    selectedFormOptionIds.value.length,
    ...checkedInputSetIds.value
  )
  formOption.optionIds.splice(0, formOption.optionIds.length, ...checkedInputSetIds.value)
}

// フォームオプションの順番入れ替え時に登録先の妥当性をチェック
watch(
  () => props.form.contentJson[props.sectionIndex].options,
  () => {
    refreshInputSetIds()
  },
  { deep: true }
)

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}

// ダイアログの開閉制御
const isOpenDialog = ref<boolean>(false)
let currentInputSetIds: AvailableInputSet[]

const openDialog = () => {
  isOpenDialog.value = true
  currentInputSetIds = JSON.parse(JSON.stringify(inputSetSettings.value))
}
const closeDialog = () => {
  isOpenDialog.value = false
  inputSetSettings.value = currentInputSetIds
}

// ローディング管理用
const { isLoadingState } = useDialog()

// スタイル用
const labelStyle = computed(() => getTypography('LABEL_M'))
</script>

<template>
  <div class="input-set-form-option">
    <div class="content">
      <div>
        <!-- 項目名 -->
        <v-text-field
          v-model="formOption.name"
          class="required-field"
          :placeholder="t('features.FormContents.new.placeholder.fieldName')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
          :rules="[required(formOption.name, t('features.FormContents.new.placeholder.fieldName'))]"
        ></v-text-field>
      </div>

      <div class="input-set no-under-gutters">
        <!-- 選択した回答 -->
        <v-text-field
          v-for="formOptionId in selectedFormOptionIds"
          :key="formOptionId"
          :value="getOptionName(formOptionId)"
          prepend-icon="radio_button_unchecked"
          :disabled="true"
          hide-details
        ></v-text-field>
      </div>

      <div>
        <!-- 質問を編集するボタン -->
        <v-btn
          class="option-btn text-primary"
          :style="labelStyle"
          label="option"
          valiant="plain"
          rounded="lg"
          flat
          @click="openDialog"
        >
          <template v-slot:prepend>
            <v-icon icon="add" size="20"> </v-icon>
          </template>

          {{ t('features.FormContents.new.inputSet.editQuestion') }}</v-btn
        >
      </div>
    </div>

    <!-- 質問を編集するダイアログ -->
    <Dialog
      :value="isOpenDialog"
      :title="t('features.FormContents.new.inputSet.editInputField')"
      titleColor="primary"
      :cancelBtnText="t('attributes.cancel')"
      cancel-btn-default="right"
      confirmBtnVisible
      @input="closeDialog"
    >
      <template #contents>
        <v-container>
          <v-row class="my-0 mt-1 py-0">
            <v-col cols="10" class="my-0 py-0 row no-under-gutters">
              <!-- 回答番号の一覧 -->
              <v-checkbox
                v-for="inputSet in inputSetSettings"
                :key="inputSet.index"
                v-model="inputSet.isChecked"
                :label="getOptionName(inputSet.id)"
                :ripple="true"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- 保存するボタン -->
      <template #actions-right>
        <BaseButton
          color="primary"
          :content="t('attributes.save')"
          :loading="isLoadingState.value"
          variant="flat"
          min-width="7rem"
          @click="saveDialog"
        />
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.no-under-gutters .v-input__details) {
  display: none;
}
:deep(.no-under-gutters .v-field__overlay) {
  display: none;
}
:deep(.no-under-gutters .v-field--disabled) {
  opacity: 1;
}
.background-transparent {
  background-color: transparent;
}
:deep(.required-field :before) {
  border-width: 0 0 3px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.option-btn {
  height: 24px;
  min-width: auto !important;
  padding: 0 4px;
}
</style>
