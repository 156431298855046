<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import BasePropertyField from '@components/Base/PropertyField/index.vue'
import { computed, ref } from 'vue'
import { toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MedicalWorkerDetailInput } from './type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()
const { CONSTANTS } = useConstants()

type Props = {
  medicalWorker: MedicalWorkerDetailInput
  medicalWorkerId: string
}

const props = defineProps<Props>()
const medicalWorkerId = ref(props.medicalWorkerId)

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 編集画面のパス
const editPath = `/medical-workers/${medicalWorkerId.value}/edit`

const translatedRole = computed(() => {
  switch (props.medicalWorker.role) {
    case CONSTANTS.ROLE.GENERAL:
      return t('attributes.role.general')
    case CONSTANTS.ROLE.READ:
      return t('attributes.role.read')
    case CONSTANTS.ROLE.ADMIN:
      return t('attributes.role.admin')
    default:
      throw Error(`unknown permission${props.medicalWorker.role}`)
  }
})

// 医療機関ユーザー削除
const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    // 医療機関ユーザー削除処理実行
    await medicalInstitutionsApiClient().api.destroyMedicalWorkerByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      medicalWorkerId.value,
      { optimId: '' }
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.medicalWorkers.list') })
    )
    done()
    transitionList()
  } catch (error: unknown) {
    failure()
    if (axiosErrorGuard.isBadRequest(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.medicalWorkers.list') })
      )
      return
    }

    throw error
  }
}

// 編集画面へ遷移
const edit = () => {
  // 医療機関ユーザー編集画面へ遷移
  router.push(editPath)
}

// 医療機関ユーザー一覧画面へ遷移
const transitionList = () => {
  router.push('/medical-workers')
}

// モーダル開閉管理
const isOpenDeleteDialog = toRef<boolean>(false)

// 削除確認ダイアログを開く
const openDeleteConfirmDialog = () => {
  isOpenDeleteDialog.value = true
}
</script>

<template>
  <div class="medical-worker-detail">
    <div class="medical-worker-detail-header">
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('features.medicalWorker.MedicalWorkerDetail.button.edit')"
        icon="mode_edit_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="edit"
      >
      </BaseButton>
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('features.medicalWorker.MedicalWorkerDetail.button.delete')"
        icon="delete_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="openDeleteConfirmDialog"
      >
      </BaseButton>
    </div>

    <div class="medical-worker-detail-body rounded-lg">
      <div class="row">
        <BasePropertyField
          :key-content="t('features.medicalWorker.MedicalWorkerDetail.input.email')"
          :value-content="medicalWorker.email"
          class="medical-worker-detail-field--email property-margin-right"
        />
        <BasePropertyField
          :key-content="t('features.medicalWorker.MedicalWorkerDetail.input.role')"
          :value-content="translatedRole"
          class="medical-worker-detail-field--role"
        />
      </div>
    </div>

    <!-- 従業員削除確認ダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenDeleteDialog"
      :title="t('attributes.deleteDialog.title', { resource: t('pages.medicalWorkers.list') })"
      :content="t('attributes.deleteDialog.message', { resource: t('pages.medicalWorkers.list') })"
      :cancelBtnText="t('attributes.cancel')"
      :confirmBtnText="t('attributes.delete')"
      :confirmBtnColor="'object-caution'"
      :size="'medium'"
      :confirmBtnVisible="true"
      @input="isOpenDeleteDialog = $event"
      @confirm="confirmDestroy"
    />
  </div>
</template>

<style lang="scss" scoped>
.medical-worker-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 616px;
  min-width: 520px;
  padding: 16px;
}
.medical-worker-detail-header {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-bottom: 8px;
}
.row {
  display: flex;
  width: 100%;
}

.property-margin-right {
  margin-right: 2px;
}

.medical-worker-detail-field {
  &--email {
    width: 60%;
  }
  &--role {
    width: 40%;
  }
}
</style>
