<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import type { NavSubMenu } from './types'

const listItemHeight = 48

type Props = {
  /**
   * アイコン
   */
  icon: string
  /**
   * メニューリスト
   */
  menuList: NavSubMenu[]
}
const props = defineProps<Props>()
const { icon, menuList } = toRefs(props)

const route = useRoute()

const isActive = computed<boolean>(() => {
  // ページpathのうち2階層目までを取得する
  const splitPath = route.path.split('/')
  const secondPath = splitPath.slice(0, 3).join('/')

  return menuList.value.some((item: NavSubMenu) => secondPath === item.to)
})

const cardHeight = computed<number>(() => {
  return listItemHeight * menuList.value.length
})
</script>

<template>
  <v-menu
    location="right"
    open-on-hover
    class="app-navigation-mini-menu"
    content-class="app-navigation-mini-menu__contents"
  >
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template #activator="{ props }">
      <v-list-item
        :active="isActive"
        active-class="app-navigation-mini-menu__list--active"
        class="app-navigation-mini-menu__trigger-button"
        :ripple="false"
        v-bind="props"
        :prepend-icon="icon"
      />
    </template>
    <v-card elevation="24" class="app-navigation-mini-menu__list menu-list" :height="cardHeight">
      <v-list class="pt-0">
        <v-list-item
          v-for="item in menuList"
          :key="item.name"
          active-class="app-navigation-mini-menu__list--active"
          :to="item.to"
          height="48"
        >
          <a
            v-if="item.isAnchorLink"
            class="anchor"
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-list-item-title class="w-full">
              <div class="d-flex align-center">
                <span>{{ item.name }}</span>
                <v-icon class="ml-1">open_in_new</v-icon>
              </div>
            </v-list-item-title>
          </a>
          <v-list-item-title v-else class="menu-list__item-title">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style scoped lang="scss">
.app-navigation-mini-menu {
  &__list {
    width: 224px !important;

    &--active {
      color: rgb(var(--v-theme-primary));
      background: rgba(1, 142, 238, 0.1);
    }

    :deep() {
      .v-list-item--active::before,
      .v-list-item--active:hover::before,
      .v-list-item:focus::before {
        opacity: 0;
      }
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &__contents {
    margin-left: -1.5px;
    overflow-y: hidden;
  }
}

.app-navigation-mini-menu .menu-list {
  &__item-title {
    line-height: 24px;
  }
}

.anchor {
  color: #2f2f2f;
  text-decoration: none;
}
</style>
