<script setup lang="ts">
import { toRef, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { PatientRegistrationInput } from './type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiCreatePatientRequest } from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import PatientRegistrationForm from '@/features/PatientRegistration/PatientRegistrationForm/index.vue'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const isLoading = ref<boolean>(false)

const form = reactive<PatientRegistrationInput>({
  patientNo: '',
  familyName: '',
  givenName: '',
  phoneticFamilyName: '',
  phoneticGivenName: '',
  gender: undefined,
  birthDate: ''
})

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 患者ユーザー登録
const create = async () => {
  isLoading.value = true
  if (form.gender === undefined) {
    isLoading.value = false
    toggleValidationDialog()
    return
  }

  // リクエスト情報を作成
  const data: MiCreatePatientRequest = {
    patientNo: form.patientNo,
    familyName: form.familyName,
    givenName: form.givenName,
    phoneticFamilyName: form.phoneticFamilyName,
    phoneticGivenName: form.phoneticGivenName,
    gender: form.gender,
    birthDate: form.birthDate
  }

  try {
    const response = await medicalInstitutionsApiClient().api.createPatientByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      { optimId: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('pages.patients.list') })
    )
    router.push(`/patients/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// 患者一覧画面へ遷移
const transitionList = () => {
  router.push('/patients')
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- 入力フォーム -->
  <PatientRegistrationForm
    v-model:patientNo="form.patientNo"
    v-model:familyName="form.familyName"
    v-model:givenName="form.givenName"
    v-model:phoneticFamilyName="form.phoneticFamilyName"
    v-model:phoneticGivenName="form.phoneticGivenName"
    v-model:gender="form.gender"
    v-model:birthDate="form.birthDate"
    :disabled="isLoading"
    @submit="create()"
    @cancel="transitionList()"
  />

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog()"
  />
</template>

<style></style>
