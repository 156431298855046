<script setup lang="ts">
import DocumentSetEditForm from '@features/DocumentSetEdit/DocumentSetEditForm/index.vue'
import { ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DocumentSetType } from './DocumentSetEditForm/type'

import { documentManagementApiClient } from '@/api/apiClient'
import { type MiFormDocumentSetRequest, type MiDocumentSetResponse } from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const axiosErrorGuard = useAxiosErrorGuard()
const snackbarStore = useSnackbarStore()
// ログイン情報
const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id

type Props = {
  documentSet: MiDocumentSetResponse
  documentSetId: string
}

// コンテンツ情報取得
const isLoading = defineModel<boolean>('isLoading', { required: true })
const props = defineProps<Props>()
const documentSetId = ref(props.documentSetId)
const documentSetForm = ref<DocumentSetType>({
  title: props.documentSet.title,
  description: props.documentSet.description,
  contents: props.documentSet.contents
})

// 更新処理
const updateDocumentSet = async () => {
  isLoading.value = true
  try {
    const requestData: MiFormDocumentSetRequest = {
      title: documentSetForm.value.title,
      description: documentSetForm.value.description,
      contentIds: documentSetForm.value.contents.map(({ id }) => id)
    }
    await documentManagementApiClient().api.updateDocumentSetByMedicalInstitution(
      medicalInstitutionId,
      documentSetId.value,
      { optimId: '' },
      requestData
    )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', { resource: t('features.documentSet.title') })
    )
    transitionDocumentSetDetail()
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      openValidationDialog()
      return
    }
    throw error
  }
}

const transitionDocumentSetDetail = () => {
  router.push(`/document-sets/${documentSetId.value}`)
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

const closeValidationDialog = () => {
  isOpenValidationDialog.value = false
}

const openValidationDialog = () => {
  isOpenValidationDialog.value = true
}
</script>

<template>
  <DocumentSetEditForm
    v-model:editUrlContentForm="documentSetForm"
    :isLoading="isLoading"
    @submit="async () => await updateDocumentSet()"
    @cancel="transitionDocumentSetDetail"
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="closeValidationDialog"
  />
</template>

<style></style>
