<script setup lang="ts">
import { DragAndDropTable } from '@components/Table/index'
import DocumentSetSelectList from '@features/SendDocumentSets/SendDocumentSetsForm/DocumentSetSelectList/index.vue'
import PatientInfoDisplay from '@features/SendDocumentSets/SendDocumentSetsForm/PatientInfoDisplay/index.vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DisplayPatientInfo } from '../type'
import type { Header, Item } from '@/components/Table/DragAndDropTable/types'

import { type MiDocumentSetItem } from '@/api/generate/apiSchema'
import Button from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import useDateFormatter from '@/composables/useDateFormatter'

defineProps<{
  isLoading: boolean
  patientInfo: DisplayPatientInfo
  hospitalizationOrderId: string
}>()

const { t } = useI18n()
const { getFormattedDateTime } = useDateFormatter()

const form = defineModel<MiDocumentSetItem[]>('documentSetsSendForm', {
  required: true
})

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

const submit = () => {
  emit('submit')
}

const cancel = () => {
  emit('cancel')
}

const headers: Header[] = [
  {
    title: t('features.SendDocumentSets.Form.Table.Header.title'),
    key: 'title',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.SendDocumentSets.Form.Table.Header.contentSize'),
    key: 'contentSize',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.SendDocumentSets.Form.Table.Header.onUpdated'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed<Item[]>({
  get: () => {
    return form.value.map((documentSet: MiDocumentSetItem) => {
      return {
        id: documentSet.id,
        title: documentSet.title,
        contentSize: documentSet.contentSize,
        updatedAt: getFormattedDateTime(new Date(documentSet.updatedAt))
      }
    })
  },
  set: (items: Item[]) => {
    form.value = items.map((item) => {
      return form.value.find((documentSet) => {
        return documentSet.id == item.id
      }) as MiDocumentSetItem
    })
  }
})

const isOpenDocumentSetSelectDialog = ref<boolean>(false)
const toggleDocumentSetSelectDialog = () => {
  isOpenDocumentSetSelectDialog.value = !isOpenDocumentSetSelectDialog.value
}
</script>

<template>
  <v-form>
    <div class="document-set-send">
      <PatientInfoDisplay :patientInfo="patientInfo" />
      <div>
        <Label
          forHtml="content_table"
          :labelText="t('features.SendDocumentSets.Form.LabelText.sendDocumentSets')"
          :isRequired="true"
          direction="left"
        />
        <DragAndDropTable
          :headers="headers"
          v-model="items"
          :isLoading="isLoading"
          :noDataText="t('features.SendDocumentSets.Form.Table.noContentData')"
        />
        <p class="validation-register-text">{{ t('validations.maxRegister', { max: 10 }) }}</p>
      </div>
      <div>
        <Button
          type="icon-link"
          color="primary"
          icon="add"
          :disabled="false"
          :loading="false"
          :content="t('features.SendDocumentSets.Form.addDocumentSets')"
          @click="toggleDocumentSetSelectDialog"
        />
      </div>
    </div>
    <div class="form-buttons">
      <Button
        type="button"
        color="primitive-white-DEFAULT-value"
        :disabled="false"
        :loading="false"
        :content="t('attributes.cancel')"
        elevated="text"
        min-width="8rem"
        @click="cancel"
        variant="outlined"
      />
      <Button
        type="button"
        color="primary"
        :disabled="false"
        :loading="false"
        :content="t('attributes.send')"
        min-width="8rem"
        @click="submit"
      />
    </div>
    <DocumentSetSelectList
      v-model:registeredDocumentSets="form"
      :hospitalizationOrderId="hospitalizationOrderId"
      :isOpenDocumentSetSelectDialog="isOpenDocumentSetSelectDialog"
      @toggleDocumentSetSelectDialog="toggleDocumentSetSelectDialog"
    />
  </v-form>
</template>

<style>
.validation-register-text {
  color: var(--object-object-hint, #a3a9ad);
  font-size: 14px;
  padding: 4px;
}

.document-set-send {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--surface-surface-primary, #ffffff);
  padding: 16px;
  min-width: 520px;
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
