<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiPatientItem } from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'
import type { Item } from '@/components/Table/ScrollSortTable/types'

import ScrollSortTable from '@/components/Table/ScrollSortTable/index.vue'
import { type Header } from '@/components/Table/ScrollSortTable/types'
import { usePatientsStore } from '@/stores/patients'

const emit = defineEmits<{
  (e: 'itemClick', item: Item): void
  (e: 'requestNextItem', item: MiPatientItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const patientsStore = usePatientsStore()

const headers: Header[] = [
  {
    title: t('features.hospitalizationOrders.registration.patient.number'),
    key: 'patientNo',
    width: '180px',
    minWidth: '180px',
    maxWidth: '180px'
  },
  {
    title: t('features.hospitalizationOrders.List.header.patientName'),
    key: 'name',
    width: 'auto',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    title: t('features.hospitalizationOrders.registration.patient.gender'),
    key: 'gender',
    width: '64px',
    minWidth: '64px'
  },
  {
    title: t('features.hospitalizationOrders.registration.patient.birthDate'),
    key: 'birthDate',
    width: '103px',
    minWidth: '103px'
  }
]

const items = computed(() => {
  const patientList = patientsStore.getPatientList()
  return patientList.value.map((content: MiPatientItem) => {
    return {
      id: content.id,
      patientNo: content.patientNo,
      name: `${content.familyName} ${content.givenName}`,
      gender: t(`attributes.gender.${content.gender}`),
      birthDate: content.birthDate.replace(/-/g, '/')
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }
  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const itemClick = (item: Item) => {
  emit('itemClick', item)
}

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}
</script>
<template>
  <ScrollSortTable
    class="patient-select-list-table"
    :headers="headers"
    :items="items"
    :noDataText="t('attributes.noDataText')"
    @itemClick="itemClick"
    @requestNextItem="$emit('requestNextItem', $event as MiPatientItem)"
    @update:sortBy="sortBy"
  />
</template>
<style lang="scss" scoped>
.patient-select-list-table {
  min-width: 505px;
  max-height: 400px;
}
</style>
