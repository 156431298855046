<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { VuetifyColorTokenType } from '@/common/VuetifyTheme/types'

import BaseDialog from '@/components/Base/Dialog/index.vue'
import useDialog from '@/composables/useDialog'

const { t } = useI18n()

type Props = {
  value: boolean
  isOverSelect: boolean
  title: string
  confirmBtnVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  title: '',
  isOverSelect: false
})

type Emits = {
  (e: 'confirm'): void
  (e: 'input', value: boolean): void
}

const emit = defineEmits<Emits>()

const { isLoadingState, startLoading, stopLoading } = useDialog()

let btnColor: VuetifyColorTokenType
watch(props, () => {
  btnColor = props.isOverSelect ? 'surface-disable' : 'primary'
})

// submit処理
const onConfirm = () => {
  startLoading()

  emit('confirm')
  stopLoading()
  onClose(false)
}

const onClose = (value: boolean) => {
  emit('input', value)
}
</script>

<template>
  <BaseDialog
    :value="props.value"
    :title="props.title"
    :confirm-btn-text="t('attributes.select')"
    :confirmBtnVisible="true"
    cancel-btn-default="right"
    :cancel-btn-text="t('attributes.cancel')"
    size="large"
    minHeight="633px"
    persistent
    @input="onClose"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>

    <template #actions-right>
      <BaseButton
        type="button"
        :color="btnColor"
        :disabled="props.isOverSelect"
        :loading="isLoadingState.value"
        :content="t('attributes.select')"
        min-width="8rem"
        @click="onConfirm"
      />
    </template>
    <template #contents>
      <slot name="content" />
    </template>
  </BaseDialog>
</template>

<style></style>
