<script setup lang="ts">
import { DragAndDropTable } from '@components/Table/index'
import ContentSelectList from '@features/DocumentSetEdit/DocumentSetEditForm/ContentSelectList/index.vue'
import { type DocumentSetType } from '@features/DocumentSetEdit/DocumentSetEditForm/type'
import { computed, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiDocumentSetContentItem } from '@/api/generate/apiSchema'
import type { Header, Item } from '@/components/Table/DragAndDropTable/types'

import Button from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import useContentTypeFormatter from '@/composables/useContentTypeFormatter'
import useDateFormatter from '@/composables/useDateFormatter'

const { t } = useI18n()
const { getFormattedDateTime } = useDateFormatter()
const { transContentType } = useContentTypeFormatter()
defineProps<{
  isLoading: boolean
}>()

const form = defineModel<DocumentSetType>('editUrlContentForm', { required: true })

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

const submit = () => {
  emit('submit')
}

const cancel = () => {
  emit('cancel')
}

const headers: Header[] = [
  {
    title: t('features.documentSet.Form.tableHeader.content'),
    key: 'title',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.documentSet.Form.tableHeader.contentType'),
    key: 'contentType',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.documentSet.Form.tableHeader.onUpdated'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed<Item[]>({
  get: () => {
    return form.value.contents.map((content: MiDocumentSetContentItem) => {
      return {
        id: content.id,
        title: content.title,
        contentType: transContentType(content.contentType),
        updatedAt: getFormattedDateTime(new Date(content.updatedAt))
      }
    })
  },
  set: (items: Item[]) => {
    form.value.contents = items.map((item) => {
      return form.value.contents.find((content) => {
        return content.id == item.id
      }) as MiDocumentSetContentItem
    })
  }
})

const isOpenContentSelectDialog = toRef<boolean>(false)
const toggleContentSelectDialog = () => {
  isOpenContentSelectDialog.value = !isOpenContentSelectDialog.value
}
</script>

<template>
  <v-form>
    <div class="document-set-edit rounded-lg">
      <div>
        <Label
          forHtml="content_title"
          :labelText="t('features.documentSet.Form.input.title')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="content_title"
          class="mt-1"
          v-model="form.title"
          counter="30"
          :hint="t('validations.maxLength', { max: 30 })"
          :placeholder="t('features.documentSet.Form.placeholder.title')"
          persistent-hint
          :rules="[]"
        ></v-text-field>
      </div>
      <div>
        <Label
          forHtml="content_description"
          :labelText="t('features.documentSet.Form.input.description')"
        ></Label>
        <v-textarea
          id="content_description"
          class="mt-1"
          :placeholder="t('features.documentSet.Form.placeholder.description')"
          :hint="t('validations.maxLength', { max: 1000 })"
          persistent-hint
          v-model="form.description"
          counter="1000"
        ></v-textarea>
      </div>
      <div>
        <Label
          forHtml="content_table"
          :labelText="t('features.documentSet.Form.input.addContent')"
          :isRequired="true"
          direction="left"
        ></Label>
        <DragAndDropTable
          class="mt-1"
          :headers="headers"
          v-model="items"
          :isLoading="isLoading"
          :noDataText="t('features.documentSet.Form.noContentData')"
        />
      </div>
      <div>
        <Button
          type="icon-link"
          color="primary"
          icon="add"
          :disabled="false"
          :loading="false"
          :content="t('features.documentSet.Form.placeholder.addContent')"
          @click="toggleContentSelectDialog"
        />
        <p class="validation-register-text">{{ t('validations.maxRegister', { max: 20 }) }}</p>
      </div>
    </div>
    <div class="form-buttons">
      <Button
        type="button"
        color="primitive-white-DEFAULT-value"
        :disabled="false"
        :loading="false"
        :content="t('attributes.cancel')"
        elevated="text"
        min-width="8rem"
        @click="cancel"
        variant="outlined"
      ></Button>
      <Button
        type="button"
        color="primary"
        :disabled="false"
        :loading="false"
        :content="t('attributes.save')"
        min-width="8rem"
        @click="submit"
      ></Button>
    </div>
    <ContentSelectList
      v-model:is-open-content-select-dialog="isOpenContentSelectDialog"
      v-model:registered-contents="form.contents"
      @toggle-content-select-dialog="toggleContentSelectDialog"
    />
  </v-form>
</template>

<style scoped>
.validation-register-text {
  color: var(--object-object-hint, #a3a9ad);
  font-size: 14px;
  padding: 4px;
}

.document-set-edit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
