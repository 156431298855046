<script setup lang="ts">
import DocumentSetEdit from '@features/DocumentSetEdit/index.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { MiDocumentSetResponse } from '@/api/generate/apiSchema'

import { documentManagementApiClient } from '@/api/apiClient'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

// コンテンツ情報取得
const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
const optimId = ''
const query = {
  optimId: optimId
}

const documentSet = ref<MiDocumentSetResponse>()
const isLoading = ref<boolean>(true)

const route = useRoute()
const documentSetId =
  typeof route.params.documentSetId == 'string' ? route.params.documentSetId : ''

onMounted(async () => {
  await fetchDocumentSet()
})

const fetchDocumentSet = async () => {
  try {
    const response = await documentManagementApiClient().api.documentSetByMedicalInstitution(
      medicalInstitutionId,
      documentSetId,
      query
    )

    documentSet.value = {
      id: response.data.id,
      title: response.data.title,
      description: response.data.description,
      createAt: response.data.createAt,
      updatedAt: response.data.updatedAt,
      contents: response.data.contents
    }
    isLoading.value = true
    displayNameStore.updateDisplayName({
      breadcrumbs: [
        {
          title: t('pages.documentSets.documentSets'),
          href: '/document-sets',
          disable: false
        },
        {
          title: documentSet.value.title,
          href: `/document-sets/${documentSetId}`,
          disable: false
        },
        {
          title: t('pages.documentSets.edit'),
          disable: true
        }
      ]
    })
  } catch (error: unknown) {
    throw Error('fetch documentSet')
  }
}
</script>
<template>
  <v-container class="container">
    <DocumentSetEdit
      v-if="documentSet != undefined"
      :documentSetId="documentSetId"
      :documentSet="documentSet"
      v-model:isLoading="isLoading"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
