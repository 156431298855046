/**
 * フォームオプションの種別
 */
export enum FormOptionType {
  'Text' = 'text',
  'Choice' = 'choice',
  'CheckBox' = 'check_box',
  'Quantitative' = 'quantitative',
  'Date' = 'date',
  'InputSet' = 'input_set'
}

/**
 * セクション種別
 */
export enum SectionType {
  'Section' = 'section'
}

/**
 * フォームオプションの種別名称
 */
export const formOptionTypeName: { [key in FormOptionType | SectionType]: { name: string } } = {
  [SectionType.Section]: {
    name: 'features.FormContents.typeName.section'
  },
  [FormOptionType.Text]: {
    name: 'features.FormContents.typeName.text'
  },
  [FormOptionType.Choice]: {
    name: 'features.FormContents.typeName.choices'
  },
  [FormOptionType.CheckBox]: {
    name: 'features.FormContents.typeName.checkBox'
  },
  [FormOptionType.Quantitative]: {
    name: 'features.FormContents.typeName.quantitative'
  },
  [FormOptionType.Date]: {
    name: 'features.FormContents.typeName.date'
  },
  [FormOptionType.InputSet]: {
    name: 'features.FormContents.typeName.inputSet'
  }
}

/**
 * フォームオプション基底情報
 */
type FormOptionBase<T extends FormOptionType> = {
  id: string
  type: T
}

/**
 * テキスト
 */
export type TextOption = FormOptionBase<FormOptionType.Text> & {
  name: string
  description: string
  isRequired: boolean
  isMultiLine: boolean
}

/**
 * 選択肢
 */
export type ChoiceOption = FormOptionBase<FormOptionType.Choice> & {
  name: string
  description: string
  isRequired: boolean
  choices: Choice[]
}

/**
 * ブランチ型の合成型
 */
export type BranchType = BranchNext | BranchLast | BranchNextOption | BranchDefault
export type BranchTypeValue = 'next' | 'last' | 'option' | 'default'

/**
 * 選択肢子要素 - 選択肢の分岐
 */
export type Choice = {
  // 選択肢入力フィールド
  name: string
  // 次へ | 末尾へ | 設問に分岐する場合
  branch: BranchType
}

/**
 * 選択肢子要素 - 次へ
 */
export type BranchNext = {
  type: 'next'
}

/**
 * 選択肢子要素 - 末尾へ
 */
export type BranchLast = {
  type: 'last'
}

/**
 * 選択肢子要素 - 個別設定
 */
export type BranchNextOption = {
  type: 'option'
  // 分岐先設問のid
  id: string
}

/**
 * 選択肢子要素 - 初期値型（フロント側でのみ使用すうｒ。API送信時にはnextに変更する）
 */
export type BranchDefault = {
  type: 'default'
}

/**
 * チェックボックス
 */
export type CheckBoxOption = FormOptionBase<FormOptionType.CheckBox> & {
  name: string
  description: string
  isRequired: boolean
  selects: string[]
}

/**
 * 数値
 */
export type QuantitativeOption = FormOptionBase<FormOptionType.Quantitative> & {
  name: string
  description: string
  isRequired: boolean
  unit: string
}

/**
 * 日付
 */
export type DateOption = FormOptionBase<FormOptionType.Date> & {
  name: string
  description: string
  isRequired: boolean
}

/**
 * 入力セット
 */
export type InputSetOption = FormOptionBase<FormOptionType.InputSet> & {
  name: string
  optionIds: string[]
}

/**
 * フォームオプション
 * < text:テキスト | choice:選択肢 | check_box:チェックボックス | quantitative:数値 | date:日付 | input_set:入力セット >
 */
export type FormOption =
  | TextOption
  | ChoiceOption
  | CheckBoxOption
  | QuantitativeOption
  | DateOption
  | InputSetOption

/**
 * セクションコンテナ
 */
export type SectionContainer = {
  id: string
  name: string
  options: FormOption[]
}

/**
 * フォーム新規作成-回答フォーム
 */
export type FormContentInput = {
  /**
   * タイトル
   */
  title: string
  /**
   * 内容説明
   */
  description: string
  /**
   * セクション
   */
  contentJson: SectionContainer[]
  /**
   * タグ
   */
  tag?: string
}

/**
 * フォームオプションメニュータイプ
 */
export interface MenuButton {
  /**
   * フォームオプションの種別
   */
  type: FormOptionType | SectionType
  /**
   * ボタン名
   */
  name: string
  /**
   * ボタンアイコン（mdi）
   */
  icon: string
}

/**
 * フォームオプションメニュー
 */
export const menuButtons: MenuButton[] = [
  {
    type: SectionType.Section,
    name: 'features.FormContents.typeName.section',
    icon: 'label_outline'
  },
  {
    type: FormOptionType.Text,
    name: 'features.FormContents.typeName.text',
    icon: 'title'
  },
  {
    type: FormOptionType.Choice,
    name: 'features.FormContents.typeName.choices',
    icon: 'radio_button_checked'
  },
  {
    type: FormOptionType.CheckBox,
    name: 'features.FormContents.typeName.checkBox',
    icon: 'check_box'
  },
  {
    type: FormOptionType.Quantitative,
    name: 'features.FormContents.typeName.quantitative',
    icon: 'pin'
  },
  {
    type: FormOptionType.Date,
    name: 'features.FormContents.typeName.date',
    icon: 'event'
  },
  {
    type: FormOptionType.InputSet,
    name: 'features.FormContents.typeName.inputSet',
    icon: 'format_list_bulleted'
  }
]

export type AvailableDestination = {
  id: string
  index: number
}

export type AvailableInputSet = {
  id: string
  index: number
  isChecked: boolean
}
