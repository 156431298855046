<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiBindPatientRequest, MiRepresentativeRequest } from '@/api/generate/apiSchema'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import BaseDialog from '@/components/Base/Dialog/index.vue'
import Label from '@/components/Base/Label/index.vue'
import OnlyTextContentDialog from '@/components/Dialog/OnlyTextContentDialog/index.vue'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

type Props = {
  /**
   * ダイアログの開閉
   */
  value: boolean
  /**
   * 認証ID
   */
  bindingToken: string
  /**
   * 入院オーダーID
   */
  hospitalizationId: string
  /**
   * 患者ID
   */
  patientId: string
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  bindingToken: '',
  hospitalizationId: '',
  patientId: ''
})

type Emits = {
  (e: 'close'): void
  (e: 'bind'): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()

const familyName = ref('')
const givenName = ref('')
const relationshipType = ref('')
const isUser = ref('')

const relationshipSelectItems = [
  { value: 'wife', title: t('attributes.relationshipType.wife') },
  { value: 'husband', title: t('attributes.relationshipType.husband') },
  { value: 'biological_mother', title: t('attributes.relationshipType.biological_mother') },
  { value: 'biological_father', title: t('attributes.relationshipType.biological_father') },
  { value: 'mother_in_law', title: t('attributes.relationshipType.mother_in_law') },
  { value: 'father_in_law', title: t('attributes.relationshipType.father_in_law') },
  { value: 'eldest_son', title: t('attributes.relationshipType.eldest_son') },
  { value: 'eldest_daughter', title: t('attributes.relationshipType.eldest_daughter') },
  { value: 'second_son', title: t('attributes.relationshipType.second_son') },
  { value: 'second_daughter', title: t('attributes.relationshipType.second_daughter') },
  { value: 'third_son', title: t('attributes.relationshipType.third_son') },
  { value: 'third_daughter', title: t('attributes.relationshipType.third_daughter') },
  { value: 'fourth_son', title: t('attributes.relationshipType.fourth_son') },
  { value: 'fourth_daughter', title: t('attributes.relationshipType.fourth_daughter') },
  { value: 'fifth_son', title: t('attributes.relationshipType.fifth_son') },
  { value: 'fifth_daughter', title: t('attributes.relationshipType.fifth_daughter') },
  { value: 'older_brother', title: t('attributes.relationshipType.older_brother') },
  { value: 'older_sister', title: t('attributes.relationshipType.older_sister') },
  { value: 'younger_brother', title: t('attributes.relationshipType.younger_brother') },
  { value: 'younger_sister', title: t('attributes.relationshipType.younger_sister') },
  { value: 'grand_father', title: t('attributes.relationshipType.grand_father') },
  { value: 'grand_mother', title: t('attributes.relationshipType.grand_mother') },
  { value: 'grand_son', title: t('attributes.relationshipType.grand_son') },
  { value: 'grand_daughter', title: t('attributes.relationshipType.grand_daughter') },
  { value: 'uncle', title: t('attributes.relationshipType.uncle') },
  { value: 'aunt', title: t('attributes.relationshipType.aunt') },
  { value: 'nephew', title: t('attributes.relationshipType.nephew') },
  { value: 'niece', title: t('attributes.relationshipType.niece') },
  { value: 'other', title: t('attributes.relationshipType.other') }
]

const onClose = () => {
  emit('close')
  isUser.value = ''
  givenName.value = ''
  familyName.value = ''
  relationshipType.value = ''
}

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id

const onConfirm = async () => {
  if (isUser.value === 'patient') {
    await patientUserRegistration()
  } else {
    await representativeUserRegistration()
  }

  emit('bind')
}

const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()
const isOpenValidationDialog = ref(false)

const patientUserRegistration = async () => {
  try {
    const data: MiBindPatientRequest = {
      userMedicalInstitutionBindingToken: props.bindingToken.replace(/\s|-/g, '')
    }
    // アプリユーザ（本人）を登録
    await medicalInstitutionsApiClient().api.bindPatientByMedicalInstitution(
      medicalInstitutionId,
      props.patientId,
      { optimId: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('attributes.appUsers.user') })
    )
  } catch (error: unknown) {
    if (
      axiosErrorGuard.isBadRequest(error) ||
      axiosErrorGuard.isConflict(error) ||
      axiosErrorGuard.isNotFound(error)
    ) {
      openValidationDialog()
      return
    }
    throw error
  }
}

const representativeUserRegistration = async () => {
  try {
    const data: MiRepresentativeRequest = {
      userMedicalInstitutionBindingToken: props.bindingToken.replace(/\s|-/g, ''),
      familyName: familyName.value,
      givenName: givenName.value,
      relationshipType: relationshipType.value
    }
    // アプリユーザ（代理人）を登録
    await medicalInstitutionsApiClient().api.bindRepresentativeByMedicalInstitution(
      medicalInstitutionId,
      props.hospitalizationId,
      { optimId: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('attributes.appUsers.user') })
    )
  } catch (error: unknown) {
    if (
      axiosErrorGuard.isBadRequest(error) ||
      axiosErrorGuard.isConflict(error) ||
      axiosErrorGuard.isNotFound(error)
    ) {
      openValidationDialog()
      return
    }
    throw error
  }
}

const formatBindingToken = () => {
  const bindingToken = ref(props.bindingToken)
  if (!props.bindingToken.includes('-')) {
    const bindingTokenPiece = props.bindingToken.match(/.{1,4}/g)
    if (bindingTokenPiece == null) {
      bindingToken.value = ''
    } else {
      bindingToken.value = bindingTokenPiece.slice(0, 4).join('-')
      if (bindingTokenPiece.length > 4) {
        bindingToken.value += bindingTokenPiece.slice(4).join('')
      }
    }
  }
  return bindingToken.value
}

const closeValidationDialog = () => {
  isOpenValidationDialog.value = false
}

const openValidationDialog = () => {
  isOpenValidationDialog.value = true
}
</script>
<template>
  <div>
    <BaseDialog
      :value="props.value"
      :title="t('features.Card.appUsage.button.agentModal.title')"
      :confirm-btn-text="t('attributes.register')"
      :confirmBtnVisible="true"
      :cancel-btn-text="t('attributes.cancel')"
      :cancelBtnDefault="'right'"
      :persistent="true"
      @input="onClose"
      @confirm="onConfirm"
    >
      <template #contents>
        <v-form>
          <div class="py-5">
            <label>{{
              t('features.Card.appUsage.button.agentModal.body', { resource: formatBindingToken() })
            }}</label>
          </div>
          <v-radio-group v-model="isUser">
            <v-radio :label="t('attributes.appUsers.patient')" value="patient" />
            <v-radio :label="t('attributes.appUsers.representative')" value="representative" />
          </v-radio-group>
          <div v-if="isUser === 'representative'">
            <Label
              :labelText="t('features.medicalWorker.MedicalWorkerRegistrationForm.input.name')"
              :isRequired="true"
              direction="left"
            ></Label>
            <div class="representative-name">
              <v-text-field
                id="new-medical-worker-family-name"
                class="py-2"
                v-model="familyName"
                :hint="t('validations.maxLength', { max: 100 })"
                :placeholder="t('features.patient.PatientEditForm.placeholder.familyName')"
                persistent-hint
                :rules="[]"
              ></v-text-field>
              <v-text-field
                class="py-2"
                v-model="givenName"
                :hint="t('validations.maxLength', { max: 100 })"
                :placeholder="t('features.patient.PatientEditForm.placeholder.givenName')"
                persistent-hint
                :rules="[]"
              ></v-text-field>
            </div>
            <div class="select-relationshipType">
              <Label
                :labelText="t('features.Card.appUsage.button.agentModal.relationship')"
                :isRequired="true"
              ></Label>
              <v-select
                class="py-2"
                :placeholder="t('features.Card.appUsage.button.agentModal.relationship')"
                persistent-placeholder
                :modelValue="
                  relationshipSelectItems.some((item) => item.value === relationshipType)
                    ? relationshipType
                    : null
                "
                @update:modelValue="relationshipType = $event"
                :items="relationshipSelectItems"
              ></v-select>
            </div>
          </div>
        </v-form>
      </template>
    </BaseDialog>

    <!-- バリデーションエラーダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenValidationDialog"
      :content="t('validations.error')"
      :cancelBtnText="t('attributes.close')"
      :size="'small'"
      :confirmBtnVisible="false"
      @input="closeValidationDialog"
    />
  </div>
</template>
<style lang="scss" scoped>
.representative-name {
  display: flex;
  gap: 16px;
}

.select-relationshipType {
  max-width: calc(50% - 8px);
}
</style>
