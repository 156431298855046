<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { SearchUseAppStatusCode } from '../type'

import SimpleSelect from '@/components/Base/SimpleSelect/index.vue'

const { t } = useI18n()

const model = defineModel<string>()

const items: { title: string; value: SearchUseAppStatusCode }[] = [
  {
    title: t('attributes.all'),
    value: 'all'
  },
  {
    title: t('attributes.useAppStatus.notUsed'),
    value: 'not_used'
  },
  {
    title: t('attributes.useAppStatus.used'),
    value: 'used'
  }
]
</script>

<template>
  <div style="min-width: 120px">
    <SimpleSelect
      v-model="model"
      :label="t('features.hospitalizationOrders.List.search.useAppStatus')"
      :items="items"
      hide-details
    />
  </div>
</template>
