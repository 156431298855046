import { vuetifyThemeColors } from '@optim-design-system/src'

import type { VuetifyColorTokenType } from './types'

export const VuetifyColorToken: Record<VuetifyColorTokenType, string> = {
  ...vuetifyThemeColors,
  anchor: '#0C67ED',
  primary: '#014386',
  'primary-light': '#C3E1FE',
  'primary-dark': '#00001C',
  secondary: '#0C67ED',
  'secondary-light': '#CEE1FB',
  'secondary-dark': '#041F46',
  accent: '#014386',
  error: '#D81B60',
  'error-light': '#F7D1DF',
  'error-dark': '#40081D',
  warning: '#EF6C08',
  'warning-light': '#FCE2CC',
  'warning-dark': '#472001',
  success: '#43A047',
  'success-light': '#D9ECDA',
  'success-dark': '#143015',
  info: '#1397A7',
  'info-light': '#CCEAED',
  'info-dark': '#042D32',
  'not-working': '#546E7A',
  'not-working-light': '#DDE2E4',
  'not-working-dark': '#192124',
  'object-caution': '#EF5350',
  'surface-disable': '#E8E9EB'
}
