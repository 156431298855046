<script setup lang="ts">
import LogoArea from '@features/AppTools/LogoArea/index.vue'
import { computed, onMounted, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

import NavigationMenu from './NavigationMenu.vue'

import type { NavMenu } from './types'

import { getToken } from '@/components/Typography/token'

type Props = {
  /**
   * ナビゲーションメニュー
   */
  navMenus: NavMenu[]
  /**
   * ナビゲーションの表示
   */
  modelValue: boolean

  /**
   * ナビゲーションの開閉
   */
  drawer: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  rail: false
})

const { modelValue, drawer } = toRefs(props)
const route = useRoute()

type Emits = {
  (e: 'click'): void
}

const emit = defineEmits<Emits>()

const onClick = () => {
  emit('click')
}

const rail = computed(() => props.drawer)

const { mobile } = useDisplay()

onMounted(() => {
  if (mobile.value) {
    emit('click')
  }
})

const isActive = (path: string): boolean => {
  if (path == '/hospitalization-orders') {
    return route.path.endsWith('/') || route.path.startsWith(path)
  } else {
    return route.path.startsWith(path)
  }
}

const largeLabelTokenStyle = getToken('LABEL_L')
</script>

<template>
  <v-navigation-drawer
    :modelValue="modelValue"
    class="app-navigation-drawer sidebar-text-color"
    flat
    permanent
    :rail="rail"
    :temporary="mobile"
    :scrim="false"
    rail-width="48"
  >
    <v-list-item class="pa-0 my-0 mx-0 mt-2 mb-4">
      <template #prepend>
        <slot name="nav-icon">
          <v-app-bar-nav-icon
            class="app-bar__nav-icon"
            aria-label="ナビゲーションを開く"
            :tile="true"
            elevation="0"
            :class="{ 'app-bar__nav-icon--active': drawer }"
            append-icon="menu"
            @click="onClick"
          />
        </slot>
      </template>
      <template #default>
        <div class="ma-0 ml-2 pa-0" height="48px">
          <LogoArea />
        </div>
      </template>
    </v-list-item>
    <v-list
      v-for="(item, index) in navMenus"
      :key="`${item.name}-${index}`"
      class="app-navigation-drawer__list py-0"
    >
      <!-- 子要素がある場合 -->
      <template v-if="item.subMenus !== undefined">
        <!-- rail状態か -->
        <v-list-group v-if="!rail" :value="item.name">
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #activator="{ props }">
            <v-list-item v-bind="props" :title="item.name" :prepend-icon="item.icon" />
          </template>
          <v-list-item
            v-for="(menu, i) in item.subMenus"
            :key="`${menu.name}-${i}`"
            :to="menu.isAnchorLink ? undefined : menu.to"
            active-class="app-navigation-drawer__list--active"
          >
            <a
              v-if="menu.isAnchorLink"
              class="anchor"
              :href="menu.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-list-item-title>
                <div class="d-flex align-center">
                  <span>
                    {{ menu.name }}
                  </span>
                  <v-icon class="ml-1">open_in_new</v-icon>
                </div>
              </v-list-item-title>
            </a>
            <v-list-item-title v-else>
              {{ menu.name }}
              <v-icon v-if="menu.icon">
                {{ menu.icon }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <!-- railにした場合のホバーカード含めたメニュー要素 -->
        <navigation-menu v-else :icon="item.icon" :menu-list="item.subMenus" />
      </template>
      <!-- 子要素がない -->
      <template v-else>
        <v-list-item
          :active="isActive(item.to || '')"
          :to="item.to"
          active-class="app-navigation-drawer__list--active"
          :prepend-icon="item.icon"
          :title="item.name"
        >
          <template v-slot:prepend>
            <span class="material-symbols-outlined icon">{{ item.icon }}</span>
          </template>
          <div v-show="isActive(item.to || '')" class="active-bar" />
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
// railの左側のpaddingを調整
:deep(.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line) {
  padding-inline-start: 8px;
}

.app-navigation-drawer {
  &__list {
    &--active {
      color: white;
      background: rgba(var(--v-theme-theme-dark-surface-brand-primary-selected-hover-value));
      font-variation-settings: 'FILL' 1;

      :deep(.v-list-item__overlay) {
        opacity: 0;
      }

      :deep(.v-list-item-title) {
        font-size: v-bind('largeLabelTokenStyle.fontSize');
        line-height: v-bind('largeLabelTokenStyle.lineHeight');
        font-weight: v-bind('largeLabelTokenStyle.fontWeight');
      }
    }

    .v-list-item--active::before,
    .v-list-item--active:hover::before,
    :deep(.v-list-item:focus::before) {
      opacity: 0;
    }

    :deep(.v-list-item__prepend) {
      padding-left: 7px;
      padding-right: 7px;
    }

    :deep(.v-icon ~ .v-list-item__spacer) {
      width: 10px;
    }
  }
  background: rgba(var(--v-theme-primary));
}

// vuetifyのv-list-item-iconのデフォルトマージン設定を上書き
.list-item-right {
  margin-right: 14px !important;
}

.anchor {
  color: #2f2f2f;
  text-decoration: none;
}

.sidebar-text-color {
  color: white !important;
}

.active-bar {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 8px;
  height: 100%;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value)) 0% 0% no-repeat;
}

.icon {
  font-size: 20px !important;
}
</style>
