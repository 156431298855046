<script setup lang="ts">
import ContentSelectDialog from '@features/DocumentSetRegistration/DocumentSetRegistrationForm/ContentSelectList/ContentSelectDialog/index.vue'
import ContentSelectListSearch from '@features/DocumentSetRegistration/DocumentSetRegistrationForm/ContentSelectList/ContentSelectListSearch/index.vue'
import SelectContentsListTable from '@features/DocumentSetRegistration/DocumentSetRegistrationForm/ContentSelectList/ContentSelectListTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MiDocumentSetContentItem } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'
import { useContentsStore } from '@/stores/contents'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const selectContentsStore = useContentsStore()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const { t } = useI18n()

// ダイアログの状態
defineProps<{
  isOpenContentSelectDialog: boolean
}>()

const emit = defineEmits<{
  (e: 'toggleContentSelectDialog'): void
}>()

const toggleContentSelectDialog = () => {
  emit('toggleContentSelectDialog')
}

// 登録済みコンテンツと選択中のコンテンツの状態を管理
const registeredContents = defineModel<MiDocumentSetContentItem[]>('registeredContents', {
  required: true
})
const selectContents = ref<string[]>([])
const changRegisteredContents = () => {
  const contentList = selectContentsStore.getContentList()

  // 新規に追加する要素を抽出
  const newItemsToAdd = contentList.value.filter(
    (content) =>
      selectContents.value.includes(content.id) &&
      !registeredContents.value.some((registered) => registered.id === content.id)
  )

  // registeredContentsから削除する要素のidを抽出
  const itemsToRemoveIds = registeredContents.value
    .filter((registered) => !selectContents.value.includes(registered.id))
    .map((item) => item.id)

  // 新しい要素を追加
  registeredContents.value = [
    ...registeredContents.value.filter((item) => !itemsToRemoveIds.includes(item.id)),
    ...newItemsToAdd
  ]
}

const selectLimit = 20
const isOverSelect = ref<boolean>(false)

const isLoading = ref(true)
const searchTitle = ref<string>('')
const searchTag = ref<string>('')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
const selectContentsListTable = ref<InstanceType<typeof SelectContentsListTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (selectContentsListTable.value == undefined) {
    return
  }

  selectContentsListTable.value.resetScroll()
}

watch([searchTitle, searchTag, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  selectContentsStore
    .fetchContentList(
      medicalInstitutionId,
      searchTitle.value,
      searchTag.value,
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch content list')
      }
    })
})

watch(selectContents, () => {
  selectContents.value.length > selectLimit
    ? (isOverSelect.value = true)
    : (isOverSelect.value = false)
})

onMounted(async () => {
  const response = await selectContentsStore.fetchContentList(
    medicalInstitutionId,
    searchTitle.value,
    searchTag.value,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch content list')
  }

  // 登録済みコンテンツのidを追加する
  selectContents.value = registeredContents.value.map((content) => content.id)
  selectContents.value.length > selectLimit
    ? (isOverSelect.value = true)
    : (isOverSelect.value = false)
})

onBeforeMount(() => {
  selectContentsStore.clearContentList()
})

const requestNextItem = (item: MiDocumentSetContentItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextContentList(item)
}

const fetchNextContentList = async (item: MiDocumentSetContentItem) => {
  const response = await selectContentsStore.fetchNextContentList(
    medicalInstitutionId,
    item.id,
    searchTitle.value,
    searchTag.value,
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}
</script>

<template>
  <ContentSelectDialog
    :value="isOpenContentSelectDialog"
    :title="t('features.contentSet.List.dialog.title')"
    :isOverSelect="isOverSelect"
    @input="toggleContentSelectDialog"
    @confirm="changRegisteredContents"
  >
    <template v-slot:content>
      <div class="container">
        <div class="head">
          <ContentSelectListSearch
            v-model:search-title="searchTitle"
            v-model:search-tag="searchTag"
          />
        </div>
        <SelectContentsListTable
          v-model:select-items="selectContents"
          ref="selectContentsListTable"
          :isLoading="isLoading"
          @requestNextItem="
            () => {
              const contentList = selectContentsStore.getContentList()
              requestNextItem(contentList.value.slice(-1)[0])
            }
          "
          @update:sort-by="sortBy"
        />
      </div>
    </template>
  </ContentSelectDialog>
</template>

<style lang="scss" scoped>
.container {
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.head {
  width: 240px;
}
</style>
