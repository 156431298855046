<script setup lang="ts">
import DocumentSetList from '@features/DocumentSetList/index.vue'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()

onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.documentSets.documentSets'),
        disable: true
      }
    ]
  })
})
</script>

<template>
  <v-container class="container">
    <DocumentSetList />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 64px);
  padding: 0px 16px 16px 16px;
}
</style>
