<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import PatientListSearch from '@features/PatientList/PatientListSearch/index.vue'
import PatientListTable from '@features/PatientList/PatientListTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { SearchHospitalizationOrderStatusCode } from './type'

import { type MiPatientItem } from '@/api/generate/apiSchema'
import { HospitalizationOrderStatusCode, type Order } from '@/api/types'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { usePatientsStore } from '@/stores/patients'

const patientsStore = usePatientsStore()
const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const isLoading = ref(true)
const searchPatientNo = ref<string>('')
const searchName = ref<string>('')
const searchHospitalizationOrderStatus = ref<SearchHospitalizationOrderStatusCode>('all')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id

const patientListTable = ref<InstanceType<typeof PatientListTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (patientListTable.value == undefined) {
    return
  }

  patientListTable.value.resetScroll()
}

const getSearchHospitalizationOrderStatus = (): HospitalizationOrderStatusCode | undefined => {
  if (searchHospitalizationOrderStatus.value == 'all') {
    return undefined
  } else {
    return searchHospitalizationOrderStatus.value
  }
}

watch([searchPatientNo, searchName, searchHospitalizationOrderStatus, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  patientsStore
    .fetchPatientList(
      medicalInstitutionId,
      searchPatientNo.value,
      searchName.value,
      getSearchHospitalizationOrderStatus(),
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch patient list')
      }
    })
})

onMounted(async () => {
  const response = await patientsStore.fetchPatientList(
    medicalInstitutionId,
    searchPatientNo.value,
    searchName.value,
    getSearchHospitalizationOrderStatus(),
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch patient list')
  }
})

onBeforeMount(() => {
  patientsStore.clearPatientList()
})

const requestNextItem = (item: MiPatientItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextPatientList(item)
}

const fetchNextPatientList = async (item: MiPatientItem) => {
  const response = await patientsStore.fetchNextPatientList(
    medicalInstitutionId,
    item.id,
    searchPatientNo.value,
    searchName.value,
    getSearchHospitalizationOrderStatus(),
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch patient list')
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}

const transitionNewPatient = () => {
  router.push('/patients/new')
}
</script>

<template>
  <div class="patient-list">
    <div class="patient-list-header">
      <PatientListSearch
        class="patient-list-header--search"
        v-model:name="searchName"
        v-model:patientNo="searchPatientNo"
        v-model:hospitalizationOrderStatus="searchHospitalizationOrderStatus"
      />

      <BaseButton
        class="new-button"
        v-if="medicalInstitutionsStore.isGeneral()"
        type="icon-link"
        color="primary"
        :loading="false"
        :content="t('attributes.newCreate')"
        elevated="text"
        min-height="48px"
        icon="add"
        @click="transitionNewPatient()"
      ></BaseButton>
    </div>
    <div class="patient-list-body">
      <PatientListTable
        ref="patientListTable"
        :isLoading="isLoading"
        @requestNextItem="requestNextItem"
        @update:sortBy="sortBy"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.patient-list {
  min-width: 520px;
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 660px;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
}

.patient-list-header {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 8px;
}

.patient-list-header > * {
  flex: 1 1;
}

.patient-list-body {
  @media (0px <= width < 768px) {
    height: calc(100% - 118px);
  }
  @media (768px <= width) {
    height: calc(100% - 62px);
  }
}

.new-button {
  @media (0px <= width < 768px) {
    min-width: 100%;
  }
  @media (768px <= width) {
    min-width: 122.25px;
    max-width: 122.25px;
  }
}
</style>
