<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import SentDocumentSetContentList from '@/features/SentDocumentSetContentList/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()

const route = useRoute()
const hospitalizationOrderId =
  typeof route.params.hospitalizationOrderId == 'string' ? route.params.hospitalizationOrderId : ''

onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.hospitalizationOrders.list'),
        href: '/hospitalization-orders',
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.detail'),
        href: `/hospitalization-orders/${hospitalizationOrderId}`,
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.sentDocumentSets'),
        disable: true
      }
    ]
  })
})
</script>

<template>
  <v-container class="container">
    <SentDocumentSetContentList
      v-if="hospitalizationOrderId != undefined"
      :hospitalization-order-id="hospitalizationOrderId"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
