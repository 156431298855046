<script setup lang="ts">
import { useSnackbarStore } from '@/stores/snackbar'

const snackbarStore = useSnackbarStore()
</script>

<template>
  <v-snackbar
    :modelValue="snackbarStore.isActive"
    :color="snackbarStore.color"
    class="base-snackbar"
  >
    {{ snackbarStore.message }}
  </v-snackbar>
</template>

<style lang="scss" scoped>
.base-snackbar {
  margin-bottom: 20px;
}
</style>
