import {
  DocumentSetStatusCode,
  SentDocumentSetContentStatusCode,
  HospitalizationOrderStatusCode,
  UseAppStatusCode
} from '@/api/types'
import { type TypedItem } from '@/components/Table/ScrollSortTable/types'

// Chipを使う際の文字と色の変換を行う関数を返す
export default function () {
  const getAppStatus = (statusCode: UseAppStatusCode | string): TypedItem | undefined => {
    switch (statusCode) {
      case 'used':
        return {
          type: 'chip',
          label: 'attributes.useAppStatus.used',
          color: 'primary'
        }
      case 'not_used':
        return {
          type: 'chip',
          label: 'attributes.useAppStatus.notUsed',
          color: 'error'
        }
    }
  }

  const getHospitalizationOrderStatus = (
    statusCode: HospitalizationOrderStatusCode | string
  ): TypedItem | undefined => {
    switch (statusCode) {
      case 'pre_hospital':
        return {
          type: 'chip',
          label: 'attributes.hospitalizationOrderStatus.preHospital',
          color: 'error'
        }
      case 'hospital':
        return {
          type: 'chip',
          label: 'attributes.hospitalizationOrderStatus.hospital',
          color: 'primary'
        }
      case 'discharged':
        return {
          type: 'chip',
          label: 'attributes.hospitalizationOrderStatus.discharged',
          color: 'grey'
        }
    }
  }

  const getDocumentSetStatus = (
    statusCode: DocumentSetStatusCode | string
  ): TypedItem | undefined => {
    switch (statusCode) {
      case 'not_sent':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.notSent',
          color: 'yellow'
        }
      case 'not_submitted':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.notSubmitted',
          color: 'error'
        }
      case 'partial_submitted':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.partialSubmitted',
          color: 'info-primary'
        }
      case 'submitted':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.submitted',
          color: 'primary'
        }
      case 'confirmed':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.confirmed',
          color: 'grey'
        }
      case 'no_document_submit':
        return {
          type: '',
          label: '',
          color: ''
        }
    }
  }

  const getSentDocumentSetContentStatus = (
    statusCode: SentDocumentSetContentStatusCode
  ): TypedItem => {
    switch (statusCode) {
      case 'not_submitted':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.notSubmitted',
          color: 'error'
        }
      case 'submitted':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.submitted',
          color: 'primary'
        }
      case 'confirmed':
        return {
          type: 'chip',
          label: 'attributes.documentSetStatus.confirmed',
          color: 'grey'
        }
    }
  }
  return {
    getSentDocumentSetContentStatus,
    getAppStatus,
    getHospitalizationOrderStatus,
    getDocumentSetStatus
  }
}
