<script setup lang="ts">
import Text from '@components/Base/BodyText/index.vue'
import BaseButton from '@components/Base/Button/index.vue'
import Label from '@components/Base/Label/index.vue'
import { format } from 'date-fns'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import PatientIntelligence from './PatientIntelligence/index.vue'
import { type SentFormContentUnionType } from './type'

import type { PatientDetailInput } from '../PatientDetail/type'
import type {
  MiHospitalizationOrderConfirmationFormContentResponse,
  MiHospitalizationOrderDocumentSetContentResponse,
  MiHospitalizationOrderDocumentSetItem,
  MiHospitalizationOrderResponse,
  MiHospitalizationOrderSubmissionFormContentResponse
} from '@/api/generate/apiSchema'
import type { SectionContainer } from '@/features/CommonForm/type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import { DocumentSetStatusCode } from '@/api/types'
import { OnlyTextContentDialog } from '@/components/Dialog'
import Typography from '@/components/Typography/Typography.vue'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import useDialog from '@/composables/useDialog'
import SectionResult from '@/features/CommonForm/SectionResult.vue'
import SentDocumentSetContentEditDialog from '@/features/SentDocumentSetContentDetail/SentDocumentSetContentEditDialog/index.vue'
import { useHospitalizationOrderConfirmationStore } from '@/stores/hospitalizationOrderConfirmation'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const hospitalizationOrderConfirmationStore = useHospitalizationOrderConfirmationStore()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const { startLoading, stopLoading } = useDialog()
const axiosErrorGuard = useAxiosErrorGuard()

// 初期化
const props = withDefaults(
  defineProps<{
    hospitalizationOrderDetail: MiHospitalizationOrderResponse
    patientDetail: PatientDetailInput
    medicalInstitutionId: string
    hospitalizationOrderDocumentSetContentId: string
    hospitalizationOrderDocumentSetItem: MiHospitalizationOrderDocumentSetItem
  }>(),
  {}
)

// emitを定義
const emit = defineEmits<{
  (e: 'onReload'): void
}>()

// 送付済入院オーダー書類セットコンテンツ
const submissionFormContent = ref<MiHospitalizationOrderSubmissionFormContentResponse>()

// 送付済入院オーダー書類セットコンテンツ（編集APIで送信した場合は確定フラグfalseで登録される）
const confirmFormContent = ref<MiHospitalizationOrderConfirmationFormContentResponse>()

// 入院オーダー書類セットコンテンツ
const documentSetContent = ref<MiHospitalizationOrderDocumentSetContentResponse>()

// 入院オーダー書類セットコンテンツを取得
const fetchDocumentSetContent = async () => {
  const response = await hospitalizationOrderApiClient({ allowNotFound: true })
    .api.hospitalizationOrderDocumentSetContentByMedicalInstitution(
      props.medicalInstitutionId,
      props.hospitalizationOrderDetail.id,
      props.hospitalizationOrderDocumentSetItem.hospitalizationOrderDocumentSetId,
      props.hospitalizationOrderDocumentSetItem.hospitalizationOrderDocumentSetContentId,
      { optimId: '' }
    )
    .catch((error) => {
      if (axiosErrorGuard.isNotFound(error)) {
        return undefined
      }
      throw error
    })

  documentSetContent.value = response?.data
}

// 提出済フォームコンテンツを取得
const fetchSubmissionFormContent = async (): Promise<void> => {
  const submissionResponse = await hospitalizationOrderApiClient({ allowNotFound: true })
    .api.hospitalizationOrderSubmissionFormContentByMedicalInstitution(
      props.medicalInstitutionId,
      props.hospitalizationOrderDetail.id,
      props.hospitalizationOrderDocumentSetContentId,
      { optimId: '' }
    )
    .catch((error) => {
      if (axiosErrorGuard.isNotFound(error)) {
        return undefined
      }
      throw error
    })
  submissionFormContent.value = submissionResponse?.data
}

// 確定済フォームコンテンツの取得
const fetchConfirmFormContent = async (): Promise<void> => {
  const confirmResponse = await hospitalizationOrderApiClient({ allowNotFound: true })
    .api.hospitalizationOrderConfirmationFormContentByMedicalInstitution(
      props.medicalInstitutionId,
      props.hospitalizationOrderDetail.id,
      props.hospitalizationOrderDocumentSetContentId,
      { optimId: '' }
    )
    .catch((error) => {
      if (axiosErrorGuard.isNotFound(error)) {
        return undefined
      }
      throw error
    })
  confirmFormContent.value = confirmResponse?.data
}

// 確定済がないなら提出済を取得。提出済もない場合は未提出とみなす
const choseFormContent = computed<SentFormContentUnionType | null>(() => {
  if (confirmFormContent.value) {
    return confirmFormContent.value
  }
  if (submissionFormContent.value) {
    return submissionFormContent.value
  }
  return null
})

// フォームコンテンツの取得
const formContentContentJson = computed<SectionContainer[]>(() => {
  // 未提出（or 途中保存）の場合に非表示にする
  if (documentStatus.value === DocumentSetStatusCode[1]) {
    return []
  }

  const formContent = choseFormContent.value
  if (formContent !== null) {
    return JSON.parse(formContent.contentJson)
  }
  return []
})

// 書類提出状況
const documentStatus = computed<DocumentSetStatusCode>(() => {
  if (confirmFormContent.value) {
    if (confirmFormContent.value.confirmed) {
      // 確定済の場合
      return DocumentSetStatusCode[4]
    } else {
      // 編集で登録された場合
      return DocumentSetStatusCode[3]
    }
  }

  if (submissionFormContent.value) {
    if (submissionFormContent.value.submitted) {
      // 提出済みの場合
      return DocumentSetStatusCode[3]
    } else {
      // 途中保存の場合
      return DocumentSetStatusCode[1]
    }
  }

  return DocumentSetStatusCode[1]
})

// 更新日時の取得
const updateDateTime = computed<string>(() => {
  const updatedAt = props.hospitalizationOrderDocumentSetItem.updatedAt
  const date = new Date(updatedAt)
  return format(date, 'yyyy/MM/dd HH:mm')
})

// 権限チェック
const permittedChangeAction = computed<boolean>(() => {
  // 一般・管理者以上の権限の場合のみ許可
  const permittedOfRole = medicalInstitutionsStore.isGeneral()

  // 提出ステータスが提出済みの場合のみ許可
  const permittedOfDocumentStatus = documentStatus.value === DocumentSetStatusCode[3]

  // 上記を全て満たす場合のみ許可
  return permittedOfRole && permittedOfDocumentStatus
})

// 確定・編集ボタン非活性のスタイル
const disableStyle = computed(() => {
  return permittedChangeAction.value
    ? {}
    : {
        opacity: 0.2,
        color: 'inherit !important',
        backgroundColor: 'inherit !important'
      }
})

// 編集ダイアログ開閉フラグ
const isOpenEditDialog = ref<boolean>(false)
// 提出済フォーム回答内容編集ダイアログの開閉制御
const closeEditDialog = () => (isOpenEditDialog.value = false)
// 編集セクションナンバー
const editSectionNumber = ref<number | undefined>()

// 提出済フォーム回答内容編集ダイアログを開く
const openEditDialog = (sectionIndex: number) => {
  // 権限チェック
  if (permittedChangeAction.value === false) {
    return
  }
  editSectionNumber.value = sectionIndex
  isOpenEditDialog.value = true
}

// 提出済フォーム回答内容編集ダイアログ保存後にコンテンツ再読み込み
const reload = async () => {
  startLoading()
  confirmFormContent.value = undefined
  submissionFormContent.value = undefined
  await fetchConfirmFormContent()
  await fetchSubmissionFormContent()
  closeEditDialog()
  stopLoading()
}

// 確定用確認モーダル開閉制御
const isOpenDialog = ref<boolean>(false)

// 確認ダイアログを開く
const openConfirmDialog = () => {
  isOpenDialog.value = true
}

// モーダル確定押下時
const onConfirmation = async () => {
  // 権限チェック
  if (permittedChangeAction.value === false) {
    return
  }

  startLoading()
  try {
    const confirmationResponse =
      await hospitalizationOrderConfirmationStore.confirmHospitalizationOrderConfirmationFormContent(
        props.medicalInstitutionId,
        props.hospitalizationOrderDetail.id,
        props.hospitalizationOrderDocumentSetContentId
      )
    if (!confirmationResponse.success) {
      await openErrorDialog()
    }
    // 成功した場合は画面をリロードする
    emit('onReload')
  } finally {
    closeConfirmDialog()
  }
}

// 確定確認用モーダルを閉じる
const closeConfirmDialog = () => {
  isOpenDialog.value = false
  stopLoading()
}

// エラーダイアログ開閉制御
const isOpenErrorDialog = ref<boolean>(false)

// エラーダイアログのメッセージ
const errorMessage = ref<string>()

// エラーモーダル
const openErrorDialog = async () => {
  startLoading()
  errorMessage.value = t('errors.hospitalization.documentSetContentConfirmError')
  isOpenErrorDialog.value = true
}

// エラーモーダルを閉じる
const closeErrorDialog = () => {
  stopLoading()
  isOpenDialog.value = false
  isOpenErrorDialog.value = false
}

// 初期化
onMounted(async () => {
  await fetchConfirmFormContent()
  await Promise.all([fetchDocumentSetContent(), fetchSubmissionFormContent()])
})
</script>

<template>
  <!-- 右上アクションボタン群 -->
  <div v-if="medicalInstitutionsStore.isGeneral()" class="hospitalization-order-detail-header">
    <BaseButton
      class="confirm-button-color"
      :style="[disableStyle]"
      type="icon-link"
      variant="outlined"
      :content="t('attributes.confirm')"
      icon="check_circle"
      tokenType="LABEL_M"
      size="small"
      :disabled="!permittedChangeAction"
      @click="openConfirmDialog"
    />
  </div>

  <!-- 患者基本情報 -->
  <div class="hospitalization-order-detail rounded-lg">
    <PatientIntelligence
      class="patient-intelligence"
      :patient-detail="patientDetail"
      :representatives="hospitalizationOrderDetail.representatives"
      :document-status-code="documentStatus"
      :update-date="updateDateTime"
    />
  </div>

  <!-- コンテンツタイトルと説明文-->
  <div v-if="documentSetContent !== undefined">
    <div class="rounded-lg form-content-detail-body">
      <div class="row">
        <Label for-html="content_title" :labelText="t('features.contentSet.Detail.input.title')" />
        <Text class="mt-1" :text="documentSetContent.title" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.description')"
        />
        <Text class="form-content-description" :text="documentSetContent.description" :typographyType="'p'" />
      </div>
    </div>
  </div>

  <!-- 書類未提出の場合の初期表示 -->
  <div class="not-submitted-message-container" v-if="documentStatus === DocumentSetStatusCode[1]">
    <Typography>{{ t('features.SendDocumentSets.send.notSubmittedMessage') }}</Typography>
  </div>
  <div v-else>
    <!-- 患者が入力した回答フォーム -->
    <div v-if="formContentContentJson !== undefined && formContentContentJson.length">
      <div class="form-content-container">
        <div
          class="section-group"
          v-for="(section, sectionIndex) in formContentContentJson"
          :key="section.id"
        >
          <div class="section-container">
            <SectionResult :section="section" :section-index="sectionIndex">
              <template #section-button>
                <div v-if="medicalInstitutionsStore.isGeneral()">
                  <BaseButton
                    type="icon-link"
                    :style="[disableStyle]"
                    variant="outlined"
                    :content="t('attributes.edit')"
                    icon="mode_edit_outline"
                    color="primitive-white-DEFAULT-value"
                    tokenType="LABEL_M"
                    size="small"
                    :disabled="!permittedChangeAction"
                    @click="openEditDialog(sectionIndex)"
                  />
                </div>
              </template>
            </SectionResult>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 編集ダイアログ セクションごとに開く-->
  <div v-if="editSectionNumber !== undefined">
    <SentDocumentSetContentEditDialog
      :value="isOpenEditDialog"
      :fetchedContentJson="formContentContentJson"
      :sectionNumber="editSectionNumber"
      :medicalInstitutionId="medicalInstitutionId"
      :hospitalizationOrderId="hospitalizationOrderDetail.id"
      :hospitalizationOrderDocumentSetContentId="hospitalizationOrderDocumentSetContentId"
      :isPermittedChangeAction="permittedChangeAction"
      @close="closeEditDialog"
      @confirm="reload"
    ></SentDocumentSetContentEditDialog>
  </div>

  <OnlyTextContentDialog
    :value="isOpenDialog"
    :title="t('features.SendDocumentSets.applyDialog.title')"
    :content="t('features.SendDocumentSets.applyDialog.confirmFormContentMessage')"
    :cancelBtnText="t('attributes.cancel')"
    :confirmBtnText="t('attributes.confirm')"
    :size="'medium'"
    :confirmBtnVisible="true"
    @confirm="onConfirmation"
    @input="closeConfirmDialog"
  />

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenErrorDialog"
    :content="errorMessage"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="closeErrorDialog"
  />
</template>

<style lang="scss" scoped>
.hospitalization-order-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
}

.hospitalization-order-detail-header {
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.not-submitted-message-container {
  margin: 40px 0;
}

.row {
  word-wrap: break-word;
}

.patient-intelligence {
  flex-grow: 3;
  :deep(.property-field) {
    .value {
      min-width: 260px;
    }
  }
}

.form-content-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  padding: 20px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1019607843);
}

.form-content-container {
  margin: 16px 0;
}

.form-content-description {
  white-space: pre-wrap;
  margin-top: 4px;
}

// セクションの集合
.section-group {
  margin: 0 0 16px 0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #00000033;
  box-shadow: 0px 1px 3px 1px #0000001a;
  background-color: white;
}
// セクションごとの箱
.section-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 16px;
}

.confirm-button-color {
  color: white !important;
  background-color: rgb(var(--v-theme-primary)) !important;
}
</style>
