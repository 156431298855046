<script setup lang="ts">
import type { Item } from './types'

type Props = {
  modelValue: string | undefined
  items: Item[]
  label: string
  clearable?: boolean
  hideDetails?: boolean
}

withDefaults(defineProps<Props>(), {
  clearable: false,
  hideDetails: false
})

type Emits = {
  (e: 'update:modelValue', value: string): void
}

const emit = defineEmits<Emits>()
</script>

<template>
  <v-select
    class="simple-select"
    :label="label"
    :clearable="clearable"
    :items="items"
    variant="outlined"
    rounded
    :model-value="modelValue"
    :hide-details="hideDetails"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>

<style lang="scss" scoped>
.simple-select {
  --v-input-control-height: 48px !important;
  --v-input-padding-top: 12px !important;
  .v-field {
    --v-field-padding-bottom: 12px !important;
  }
}
</style>
