<script setup lang="ts">
import { onMounted, reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MedicalWorkerRegistrationType } from './type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiCreateMedicalWorkerRequest } from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import { useConstants } from '@/composables/useConstants'
import MedicalWorkerRegistrationForm from '@/features/MedicalWorkerRegistration/MedicalWorkerRegistrationForm/index.vue'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { CONSTANTS } = useConstants()
const { t } = useI18n()

const isLoading = ref<boolean>(false)

onMounted(() => {
  isLoading.value = false
})

const defaultRole = CONSTANTS.ROLE.GENERAL

const form = reactive<MedicalWorkerRegistrationType>({
  familyName: '',
  givenName: '',
  phoneticFamilyName: '',
  phoneticGivenName: '',
  role: defaultRole,
  email: ''
})

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 医療機関ユーザー登録
const create = async () => {
  isLoading.value = true
  try {
    // リクエスト情報を作成
    const data: MiCreateMedicalWorkerRequest = {
      familyName: form.familyName,
      givenName: form.givenName,
      phoneticFamilyName: form.phoneticFamilyName,
      phoneticGivenName: form.phoneticGivenName,
      email: form.email,
      roleUrn: form.role
    }
    const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
    const query = {
      optimId: '',
      optimIdToken: ''
    }
    const response =
      await medicalInstitutionsApiClient().api.createMedicalWorkerByMedicalInstitution(
        medicalInstitutionId,
        query,
        data
      )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('pages.medicalWorkers.list') })
    )
    router.push(`/medical-workers/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// 医療期間ユーザー一覧画面へ遷移
const transitionList = () => {
  router.push('/medical-workers')
}

// 登録
const submit = async () => {
  // 医療機関ユーザーを登録
  await create()
}
// キャンセル
const cancel = () => {
  // 医療期間ユーザー一覧画面へ遷移
  transitionList()
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)
// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- 入力フォーム -->
  <MedicalWorkerRegistrationForm
    v-model:familyName="form.familyName"
    v-model:givenName="form.givenName"
    v-model:phoneticFamilyName="form.phoneticFamilyName"
    v-model:phoneticGivenName="form.phoneticGivenName"
    v-model:email="form.email"
    v-model:role="form.role"
    :disabled="isLoading"
    @submit="submit"
    @cancel="cancel"
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style></style>
