<script setup lang="ts">
import Button from '@components/Base/Button/index.vue'
import ContentListSearch from '@features/ContentList/ContentListSearch/index.vue'
import ContentListTable from '@features/ContentList/ContentListTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiContentItem } from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'

import { getToken } from '@/components/Typography/token'
import router from '@/router'
import { useContentsStore } from '@/stores/contents'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const contentsStore = useContentsStore()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

const isLoading = ref(true)
const searchTitle = ref<string>('')
const searchTag = ref<string>('')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const contentListTable = ref<InstanceType<typeof ContentListTable>>()
const resetScroll = () => {
  if (contentListTable.value == undefined) {
    return
  }

  contentListTable.value.resetScroll()
}

watch([searchTitle, searchTag, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  contentsStore
    .fetchContentList(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      searchTitle.value,
      searchTag.value,
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch content list')
      }
    })
})

onMounted(async () => {
  const response = await contentsStore.fetchContentList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    searchTitle.value,
    searchTag.value,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch content list')
  }
})

onBeforeMount(() => {
  contentsStore.clearContentList()
})

const requestNextItem = (item: MiContentItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextContentList(item)
}

const fetchNextContentList = async (item: MiContentItem) => {
  const response = await contentsStore.fetchNextContentList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    item.id,
    searchTitle.value,
    searchTag.value,
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}

const contentTypes = ['form', 'url']

const isOpenNewContentTypeDropDown = ref<boolean>(false)
const toggleNewContentTypeDropDown = () => {
  isOpenNewContentTypeDropDown.value = !isOpenNewContentTypeDropDown.value
}

const tokenStyleBodyL = getToken('BODY_L')
const tokenStyleLabelL = getToken('LABEL_L')
</script>

<template>
  <div class="content-list">
    <div class="tabs">
      <div class="tab not-active-tab">
        <p :style="tokenStyleBodyL" @click="router.push('/document-sets')">
          {{ t('pages.documentSets.documentSets') }}
        </p>
      </div>
      <div class="tab active-tab">
        <p :style="tokenStyleLabelL">{{ t('pages.documentSets.contents') }}</p>
      </div>
    </div>
    <div class="content-list-content">
      <div class="content-list-header">
        <div>
          <ContentListSearch v-model:search-title="searchTitle" v-model:search-tag="searchTag" />
        </div>
        <div>
          <div class="new-content-dropdown">
            <Button
              v-if="medicalInstitutionsStore.isAdmin()"
              class="new-button"
              type="icon-link"
              :content="t('attributes.newCreate')"
              icon="add"
              color="primary"
              :loading="false"
              elevated="text"
              min-height="48px"
              @click="toggleNewContentTypeDropDown"
            />
            <div v-if="isOpenNewContentTypeDropDown" class="content-type-options">
              <v-btn
                variant="text"
                class="content-type-option"
                :key="index"
                :style="tokenStyleBodyL"
                v-for="(contentType, index) in contentTypes"
                @click="router.push(`/contents/new?type=${contentType}`)"
              >
                {{ $t(`features.contentSet.${contentType}`) }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="content-list-body">
        <ContentListTable
          ref="contentListTable"
          :isLoading="isLoading"
          @requestNextItem="
            () => {
              const contentList = contentsStore.getContentList()
              requestNextItem(contentList.value.slice(-1)[0])
            }
          "
          @update:sort-by="sortBy"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-list {
  min-width: 520px;
  height: 100%;
}

.content-list-header {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: start;
  gap: 8px;
}

.content-list-header > * {
  @media (0px <= width < 768px) {
    flex: 1 1;
  }
}

.content-list-content {
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 558px;
  height: calc(100% - 43.6px);
  border-radius: 0 8px 8px 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-button {
  @media (0px <= width < 730px) {
    min-width: 100%;
  }
  @media (730px <= width) {
    min-width: 122.25px;
    max-width: 122.25px;
  }
}

.content-list-body {
  @media (0px <= width < 730px) {
    height: calc(100% - 118px);
  }
  @media (730px <= width) {
    height: calc(100% - 62px);
  }
}

.tabs {
  display: flex;
  margin-left: 0.3px;
  text-align: center;
}
.tab {
  padding: 9px 16px;
  background-color: white;
}
.not-active-tab {
  cursor: pointer;
}
.active-tab {
  border-bottom: 2px solid #004386;
}
.active-tab > p {
  color: #004386;
}

.content-type-options {
  position: absolute;
  width: 122px;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.content-type-option {
  padding: 12px 8px !important;
  width: 100%;
  height: 100%;
  text-align: start;
  justify-content: left;
}
</style>
