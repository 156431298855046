<script setup lang="ts">
import SearchTag from './SearchTag.vue'
import SearchTitle from './SearchTitle.vue'

const searchTitle = defineModel<string>('searchTitle', { required: true })
const searchTag = defineModel<string>('searchTag', { required: true })
</script>

<template>
  <div class="hospitalization-order-list-search">
    <SearchTitle v-model:search-title="searchTitle" />
    <SearchTag v-model:searchTag="searchTag" />
  </div>
</template>

<style lang="scss" scoped>
.hospitalization-order-list-search {
  display: flex;
  gap: 8px;
}
</style>
