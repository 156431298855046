<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SearchTextField from '@/components/Base/SearchTextField/index.vue'

const { t } = useI18n()

const model = defineModel()
</script>

<template>
  <div class="search-patient-no">
    <SearchTextField v-model="model" :label="t('features.patient.List.search.patientNo')" />
  </div>
</template>

<style lang="scss" scoped>
.search-patient-no {
  min-width: 140px;
  max-width: 180px;
}
</style>
