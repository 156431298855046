<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import MedicalWorkerDetail from '@/features/MedicalWorkerDetail/index.vue'
import { type MedicalWorkerDetailInput } from '@/features/MedicalWorkerDetail/type'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const medicalWorker = ref<MedicalWorkerDetailInput | undefined>()

// パスからmedicalWorkerIdを抽出
const route = useRoute()
const medicalWorkerId =
  typeof route.params.medicalWorkerId == 'string' ? route.params.medicalWorkerId : ''

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const displayNameStore = useDisplayNameStore()

onMounted(async () => {
  await fetchMedicalWorker()
})

// 医療機関ユーザー取得
const fetchMedicalWorker = async () => {
  const response = await medicalInstitutionsApiClient().api.medicalWorkerByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    medicalWorkerId,
    { optimId: '' }
  )
  medicalWorker.value = {
    familyName: response.data.familyName,
    givenName: response.data.givenName,
    phoneticFamilyName: response.data.phoneticFamilyName,
    phoneticGivenName: response.data.phoneticGivenName,
    email: response.data.email,
    role: response.data.roleUrn
  }

  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.medicalWorkers.list'),
        href: '/medical-workers',
        disable: false
      },
      {
        title: `${medicalWorker.value.familyName}` + ' ' + `${medicalWorker.value.givenName}`,
        disable: true
      }
    ]
  })
}
</script>
<template>
  <v-container class="container">
    <MedicalWorkerDetail
      v-if="medicalWorker != undefined"
      :medicalWorker="medicalWorker"
      :medicalWorkerId="medicalWorkerId"
    ></MedicalWorkerDetail>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
