<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed } from 'vue'

type Props = {
  /**
   * チップのラベル
   */
  label: string
  /**
   * チップの色
   */
  color?: string
  /**
   * アウトライン表示かどうか
   */
  outlined?: boolean
  /**
   * 影
   */
  elevation?: string | number
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  color: 'error',
  outlined: false
})

// class を動的に作成
const classes = computed(() => {
  // アウトラインを付与
  return [`base-status-label--${props.color}`]
})

const tokenStyle = getTypography('LABEL_S')
</script>

<template>
  <v-chip
    :class="`${classes}`"
    style="padding: 3px 12px; height: 100%"
    :color="color"
    :ripple="false"
    :outlined="outlined"
    :elevation="elevation"
    label
  >
    <div class="chip-text" :style="tokenStyle">
      {{ label }}
    </div>
  </v-chip>
</template>

<style lang="scss" scoped>
.base-status-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  &--success {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-success)) !important;
  }

  &--info {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-info)) !important;
  }

  &--info-primary {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: $primitive-light-blue-40;
  }

  &--not-working {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-not-working)) !important;
  }

  &--not-working-light {
    color: rgb(var(--v-theme-primitive-black-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-not-working-light)) !important;
  }

  &--warning {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-warning)) !important;
  }

  &--error {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: $primitive-red-40;
  }

  &--primary {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-primary)) !important;
  }

  &--secondary {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: rgb(var(--v-theme-secondary)) !important;
  }
  &--yellow {
    .chip-text {
      color: #192833 !important;
    }
    background-color: $primitive-yellow-40 !important;
  }
  &--grey {
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
    background-color: #a3a9ad;
  }
  &--primitive {
    color: inherit !important;
    background-color: transparent;
    :deep(.v-chip__underlay) {
      background-color: transparent;
    }
  }
}
</style>
