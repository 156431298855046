<script setup lang="ts">
import SearchHospitalizationOrderStatus from './SearchHospitalizationOrderStatus.vue'
import SearchName from './SearchName.vue'
import SearchPatientNo from './SearchPatientNo.vue'

const patientNo = defineModel<string>('patientNo', { required: true })
const name = defineModel<string>('name', { required: true })
const hospitalizationOrderStatus = defineModel<string>('hospitalizationOrderStatus', {
  required: true
})
</script>

<template>
  <div class="patient-list-search">
    <SearchPatientNo v-model="patientNo" />
    <SearchName v-model="name" />
    <SearchHospitalizationOrderStatus v-model="hospitalizationOrderStatus" />
  </div>
</template>

<style lang="scss" scoped>
.patient-list-search {
  display: flex;
  gap: 8px;
}

.patient-list-search > * {
  flex: 1 1;
}
</style>
