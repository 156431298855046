<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import Error from '@/features/Error/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()

onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: []
  })
})
</script>

<template>
  <v-container class="container">
    <Error :title="t('pages.notFound.title')" :description="t('pages.notFound.description')" />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
  height: 100%;
}
</style>
