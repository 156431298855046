// ルーティング毎のページ名などを記載する

export const pages = {
  hospitalizationOrders: {
    list: '入院オーダー',
    new: '新規登録',
    sentDocumentSets: '書類提出',
    sendDocumentSet: '書類送付',
    edit: '編集',
    sendDocumentSets: '書類提出',
    detail: 'オーダー詳細',
    submissionDetail: '{resource}の詳細',
    submissionEditing: '{resource}の回答編集'
  },
  patients: {
    list: '患者',
    new: '新規登録',
    edit: '編集',
    detail: '詳細'
  },
  documentSets: {
    documentSets: '書類セット',
    contents: 'コンテンツ',
    url: 'URL',
    form: '回答フォーム',
    new: {
      list: '新規登録',
      url: '新規作成 URL',
      form: '新規作成 回答フォーム'
    },
    update: {
      url: '編集 URL',
      form: '編集 回答フォーム'
    },
    edit: '編集',
    ofEdit: '{resource}の編集'
  },
  medicalWorkers: {
    list: '従業員',
    new: '新規登録',
    edit: '編集'
  },
  serverError: {
    title: 'サーバーエラーが発生しました。',
    description:
      'サーバーエラーが発生したためサービスをご利用いただけません。お手数をおかけしますが、しばらくしてから再度アクセスしてください。'
  },
  forbidden: {
    title: '権限がありません。',
    description: 'アクセス権限がないため機能をご利用いただけません。'
  },
  notFound: {
    title: 'ページが存在しません。',
    description: ''
  }
}
