<script setup lang="ts">
import { useMedicalWorkersStore } from '@stores/medicalWorkers'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MiMedicalWorkerItem } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/ScrollSortTable/types'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'

defineProps<{
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiMedicalWorkerItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const medicalWorkersStore = useMedicalWorkersStore()
const { CONSTANTS } = useConstants()

const headers: Header[] = [
  {
    title: t('features.medicalWorker.List.header.name'),
    key: 'name',
    width: 'auto',
    minWidth: '204px',
    maxWidth: '204px'
  },
  {
    title: t('features.medicalWorker.List.header.email'),
    key: 'email',
    width: '220px',
    minWidth: '220px',
    maxWidth: '220px'
  },
  {
    title: t('features.medicalWorker.List.header.role'),
    key: 'roleUrn',
    width: '64px',
    minWidth: '64px'
  }
]

const translatedRole = (roleUrl: string) => {
  switch (roleUrl) {
    case CONSTANTS.ROLE.GENERAL:
      return t('attributes.role.general')
    case CONSTANTS.ROLE.READ:
      return t('attributes.role.read')
    case CONSTANTS.ROLE.ADMIN:
      return t('attributes.role.admin')
    default:
      throw Error(`unknown permission${roleUrl}`)
  }
}

const items = computed(() => {
  const medicalWorkerList = medicalWorkersStore.getMedicalWorkerList()
  return medicalWorkerList.value.map((medicalWorker: MiMedicalWorkerItem) => {
    return {
      id: medicalWorker.id,
      name: medicalWorker.familyName + ' ' + medicalWorker.givenName,
      email: medicalWorker.email,
      roleUrn: translatedRole(medicalWorker.roleUrn)
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}

const transitionDetailUser = (item: Item) => {
  const detailPath = `/medical-workers/${item.id}` // Assuming route path for detail
  router.push(detailPath)
}
</script>

<template>
  <ScrollSortTable
    class="medical-worker-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    @itemClick="transitionDetailUser"
    :noDataText="t('attributes.noDataText')"
    @requestNextItem="$emit('requestNextItem', $event as MiMedicalWorkerItem)"
    @update:sortBy="sortBy"
  />
</template>

<style scoped>
.medical-worker-list-table {
  max-height: 100%;
}
</style>
