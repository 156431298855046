<script setup lang="ts">
import SendDocumentSetForm from '@features/SendDocumentSets/SendDocumentSetsForm/index.vue'
import { ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DisplayPatientInfo } from './type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import {
  type MiAddHospitalizationOrderDocumentSetsRequest,
  type MiDocumentSetItem
} from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()
const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

type Props = {
  hospitalizationOrderId: string
}

const props = defineProps<Props>()

const isLoading = defineModel<boolean>('isLoading', { required: true })
const patientInfo = defineModel<DisplayPatientInfo>('patientInfo', { required: true })

const sendDocumentSetsForm = ref<MiDocumentSetItem[]>([])

// 書類セット登録
const create = async () => {
  isLoading.value = true
  const data: MiAddHospitalizationOrderDocumentSetsRequest = {
    documentSetIds: sendDocumentSetsForm.value.map(
      (documentSet: MiDocumentSetItem) => documentSet.id
    )
  }

  try {
    await hospitalizationOrderApiClient().api.addHospitalizationOrderDocumentSetsByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.hospitalizationOrderId,
      { optimId: '' },
      data
    )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('features.SendDocumentSets.sendDocumentSets') })
    )
    router.push(`/hospitalization-orders/${props.hospitalizationOrderId}/contents`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }
  }
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <SendDocumentSetForm
    v-if="patientInfo != undefined"
    :patientInfo="patientInfo"
    v-model:documentSetsSendForm="sendDocumentSetsForm"
    :isLoading="isLoading"
    :hospitalizationOrderId="hospitalizationOrderId"
    @submit="create"
    @cancel="() => router.push(`/hospitalization-orders/${hospitalizationOrderId}`)"
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style></style>
