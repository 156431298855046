import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'

/**
 * 医療機関情報
 */
export const useMedicalInstitutionsStore = defineStore('medicalInstitutions', () => {
  type MedicalInstitution = {
    id: string
    roleUrn: string
    name: string
    familyName: string
    givenName: string
    email: string
  }
  type MedicalInstitutionList = MedicalInstitution[]

  // 医療機関一覧
  const medicalInstitutionList = ref<MedicalInstitutionList>([])
  // 現在操作中の医療機関
  const currentMedicalInstitutionId = ref<string | undefined>()
  const loading = ref<boolean>(true)

  /**
   * 医療機関情報の取得を行う
   * アプリを開いた際にに一度実行される
   */
  const fetchMedicalInstitutionList = async (noHandling: boolean = false) => {
    if (fetchMedicalInstitution() != undefined) {
      return
    }

    loading.value = true
    try {
      let response = await medicalInstitutionsApiClient(
        { allowNotFound: true },
        noHandling
      ).api.medicalInstitutionsByMedicalWorker({
        optimId: ''
      })

      // 0件の場合初期ユーザーの作成を試す
      if (response.data.list.length <= 0) {
        // 医療機関の作成
        await medicalInstitutionsApiClient(
          { allowNotFound: true },
          noHandling
        ).api.createMedicalWorkerByMedicalWorker({
          optimIdToken: ''
        })

        // 医療機関再度取得
        response = await medicalInstitutionsApiClient(
          { allowNotFound: true },
          noHandling
        ).api.medicalInstitutionsByMedicalWorker({
          optimId: ''
        })

        // 作成に失敗している場合、500エラーとする
        if (response.data.list.length <= 0) {
          Sentry.captureMessage('not found medical-institutions')
          loading.value = false
          router.push('/server-error')
        }
      }

      currentMedicalInstitutionId.value = response.data.list[0].id
      medicalInstitutionList.value = response.data.list
    } finally {
      loading.value = false
    }
  }

  const fetchMedicalInstitution = (): MedicalInstitution | undefined => {
    const medicalInstitution = medicalInstitutionList.value.find(
      (medicalInstitution) => medicalInstitution.id === currentMedicalInstitutionId.value
    )

    return medicalInstitution
  }

  /**
   * 医療機関を取得
   */
  const currentMedicalInstitution = (): MedicalInstitution => {
    const medicalInstitution = fetchMedicalInstitution()
    if (medicalInstitution == undefined) {
      throw Error('undefined currentMedicalInstitution')
    }
    return medicalInstitution
  }

  // API処理中の場合はtrue、そうでない場合はfalse
  const isLoading = (): boolean => {
    return loading.value
  }

  // loadingが終わった前提でのログインチェック
  const isLogged = (): boolean => {
    return fetchMedicalInstitution() !== undefined
  }

  // 管理者の権限
  const isAdmin = (): boolean => {
    return useConstants().CONSTANTS.ROLE.ADMIN == currentMedicalInstitution().roleUrn
  }

  // 一般の権限以上を持つ場合
  const isGeneral = (): boolean => {
    return [useConstants().CONSTANTS.ROLE.ADMIN, useConstants().CONSTANTS.ROLE.GENERAL].includes(
      currentMedicalInstitution().roleUrn
    )
  }

  // 閲覧の権限以上を持つ場合
  const isRead = (): boolean => {
    return [
      useConstants().CONSTANTS.ROLE.ADMIN,
      useConstants().CONSTANTS.ROLE.GENERAL,
      useConstants().CONSTANTS.ROLE.READ
    ].includes(currentMedicalInstitution().roleUrn)
  }

  const currentUserName = (): string => {
    return `${currentMedicalInstitution().familyName} ${currentMedicalInstitution().givenName}`
  }

  return {
    fetchMedicalInstitutionList,
    currentMedicalInstitution,
    isLoading,
    isLogged,
    isAdmin,
    isGeneral,
    isRead,
    currentUserName
  }
})
