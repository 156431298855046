<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SearchTextField from '@/components/Base/SearchTextField/index.vue'

const { t } = useI18n()

const model = defineModel()
</script>

<template>
  <div class="search-name">
    <SearchTextField v-model="model" :label="t('features.patient.List.search.name')" />
  </div>
</template>

<style lang="scss" scoped>
.search-name {
  min-width: 170px;
  max-width: 240px;
}
</style>
