<script setup lang="ts">
import { reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { tidyContentJson } from '../FormContentForm/TemplateFormFunctions'

import type { MiContentRequest } from '@/api/generate/apiSchema'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import FormContentForm from '@/features/FormContentForm/index.vue'
import { type FormContentInput } from '@/features/FormContentForm/type'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

const form = reactive<FormContentInput>({
  title: '',
  description: '',
  contentJson: [],
  tag: undefined
})

// フォーム新規登録
const create = async () => {
  isLoading.value = true
  // リクエスト情報を作成
  const data: MiContentRequest = {
    title: form.title,
    description: form.description,
    formContentRevision: tidyContentJson(form.contentJson),
    tag: form.tag
  }
  const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
  const query = {
    optimId: ''
  }

  try {
    const response = await medicalInstitutionsApiClient().api.createContentByMedicalInstitution(
      medicalInstitutionId,
      query,
      data
    )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('pages.documentSets.contents') })
    )
    router.push(`/contents/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// コンテンツ一覧画面へ遷移
const transitionList = () => {
  router.push('/contents')
}

const isLoading = ref<boolean>(false)

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- フォーム新規作成画面 -->
  <FormContentForm :form="form" />

  <div class="form-buttons">
    <BaseButton
      type="button"
      :disabled="isLoading"
      :loading="isLoading"
      :content="t('attributes.cancel')"
      variant="outlined"
      min-width="8rem"
      @click="transitionList"
    />
    <BaseButton
      type="button"
      color="primary"
      :disabled="isLoading"
      :loading="isLoading"
      :content="t('attributes.register')"
      min-width="8rem"
      @click="create"
    />
  </div>

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style lang="scss" scoped>
.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
