import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'

import type { FetchApiResponse } from './types'
import type { MiUpdateHospitalizationOrderConfirmationFormContentRequest } from '@/api/generate/apiSchema'

import { hospitalizationOrderApiClient } from '@/api/apiClient'

export const useHospitalizationOrderConfirmationStore = defineStore(
  'hospitalizationOrderConfirmation',
  () => {
    // 入院オーダー書類セットのフォームコンテンツの確定
    const confirmHospitalizationOrderConfirmationFormContent = async (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string
    ): Promise<FetchApiResponse<string>> => {
      try {
        await hospitalizationOrderApiClient().api.confirmHospitalizationOrderConfirmationFormContentByMedicalInstitution(
          medicalInstitutionId,
          hospitalizationOrderId,
          hospitalizationOrderDocumentSetContentId,
          { optimId: '' }
        )

        return {
          success: true,
          content: hospitalizationOrderDocumentSetContentId
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return {
            success: false,
            status: axiosError.response?.status
          }
        }
        throw error
      }
    }

    // 入院オーダー書類セットコンテンツの更新
    const updateHospitalizationOrderSubmissionFormContent = async (
      medicalInstitutionId: string,
      hospitalizationOrderId: string,
      hospitalizationOrderDocumentSetContentId: string,
      data: MiUpdateHospitalizationOrderConfirmationFormContentRequest
    ): Promise<FetchApiResponse<string>> => {
      try {
        await hospitalizationOrderApiClient().api.updateHospitalizationOrderConfirmationFormContentByMedicalInstitution(
          medicalInstitutionId,
          hospitalizationOrderId,
          hospitalizationOrderDocumentSetContentId,
          { optimId: '' },
          data
        )
        return {
          success: true,
          content: hospitalizationOrderDocumentSetContentId
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError
          return {
            success: false,
            status: axiosError.response?.status
          }
        }
        throw error
      }
    }

    return {
      confirmHospitalizationOrderConfirmationFormContent,
      updateHospitalizationOrderSubmissionFormContent
    }
  }
)
