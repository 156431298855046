<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { documentManagementApiClient } from '@/api/apiClient'
import DocumentSetDetail from '@/features/DocumentSetDetail/index.vue'
import { type DocumentSetDetailInput } from '@/features/DocumentSetDetail/type'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const isLoading = ref(true)

const documentSet = ref<DocumentSetDetailInput | undefined>()

const route = useRoute()
const documentSetId =
  typeof route.params.documentSetId == 'string' ? route.params.documentSetId : ''

const medicalInstitutionsStore = useMedicalInstitutionsStore()

onMounted(async () => {
  await fetchDocumentDetail()
})

const fetchDocumentDetail = async () => {
  const detail = await documentManagementApiClient().api.documentSetByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    documentSetId,
    { optimId: '' }
  )

  documentSet.value = {
    title: detail.data.title,
    description: detail.data.description,
    contentList: detail.data.contents
  }

  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.documentSets.documentSets'),
        href: '/document-sets',
        disable: false
      },
      {
        title: `${documentSet.value.title}`,
        disable: true
      }
    ]
  })
  isLoading.value = false
}

const displayNameStore = useDisplayNameStore()
</script>

<template>
  <v-container class="container">
    <DocumentSetDetail
      v-if="documentSet != undefined"
      :documentSet="documentSet"
      :documentSetId="documentSetId"
    >
    </DocumentSetDetail>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
