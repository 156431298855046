<script setup lang="ts">
import SearchDocumentSetTitle from './SearchDocumentSetTitle.vue'

const searchDocumentSetTitle = defineModel<string>('searchDocumentSetTitle', { required: true })
</script>

<template>
  <div>
    <SearchDocumentSetTitle v-model="searchDocumentSetTitle" />
  </div>
</template>

<style lang="scss" scoped></style>
