<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Item } from './type'

import Card from '@/components/Base/Card/index.vue'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

type Props = {
  /**
   * ボタンの色分岐
   */
  disabled?: boolean
  /**
   * アプリの利用状況
   */
  isUseAppStatus: boolean
  /**
   * 入院オーダーId
   */
  hospitalizationOrderId: string
  /**
   * 書類提出状態ステータス
   */
  documentStatus: Item
  /**
   * ローディング
   */
  loading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  documentSetStatus: undefined,
  documentStatus: undefined,
  disabled: false,
  isUseAppStatus: false,
  hospitalizationOrderId: undefined,
  loading: true
})

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const hospitalizationOrderId = ref<string>(props.hospitalizationOrderId)
const documentStatus = ref<Item>(props.documentStatus)

const title = t('features.Card.documentSubmit.title')
const outlined = false

const isDisabled = computed(() => {
  if (!props.isUseAppStatus || !medicalInstitutionsStore.isGeneral()) {
    if (
      documentStatus.value.documentSetStatus.label ===
      t('features.Card.documentSubmit.label.confirmed')
    ) {
      return false
    }
    return true
  }
  return false
})

// 書類提出状態ステータスに応じてbodyテキストを変更する
const body = computed(() => {
  // アプリの利用状況が無の場合
  if (!props.isUseAppStatus) {
    return t('features.Card.documentSubmit.body.notUsed')
  }

  switch (documentStatus.value.documentSetStatus.label) {
    case t('features.Card.documentSubmit.label.notSend'):
      return t('features.Card.documentSubmit.body.notSend')
    case t('features.Card.documentSubmit.label.notSubmit'):
      return t('features.Card.documentSubmit.body.notSubmit')
    case t('features.Card.documentSubmit.label.submitted'):
      return t('features.Card.documentSubmit.body.submitted')
    case t('features.Card.documentSubmit.label.partial_submitted'):
      return t('features.Card.documentSubmit.body.submitted')
    case t('features.Card.documentSubmit.label.confirmed'):
      return t('features.Card.documentSubmit.body.confirmed')
    default:
      return t('features.Card.documentSubmit.body.noDocumentSubmit')
  }
})

// ステータスの有無に応じてチップの表示を切り替える
const showChip = computed(() => {
  if (documentStatus.value.documentSetStatus.label === '') {
    return false
  } else {
    return true
  }
})

const cardLayout = computed(() => {
  // 「未送付」または「確定済」の場合にボタンを一つにする
  if (
    documentStatus.value.documentSetStatus.label ===
      t('features.Card.documentSubmit.label.notSend') ||
    documentStatus.value.documentSetStatus.label ===
      t('features.Card.documentSubmit.label.confirmed')
  ) {
    return 'single'
  } else {
    return 'split-v'
  }
})

// 書類提出状態ステータスに応じたfirstButtonのテキスト内容
const firstBtnText = computed(() => {
  if (
    documentStatus.value.documentSetStatus.label ===
    t('features.Card.documentSubmit.label.confirmed')
  ) {
    return t('features.Card.documentSubmit.btn.sentDocumentCheck')
  } else {
    return t('features.Card.documentSubmit.btn.documentSent')
  }
})

// 書類提出状況に応じたfirstButtonの処理
const onFirstClick = () => {
  if (
    documentStatus.value.documentSetStatus.label ===
    t('features.Card.documentSubmit.label.confirmed')
  ) {
    documentCheck()
  } else {
    documentSent()
  }
}

const documentSent = () => {
  // 書類セットの送付画面へ遷移
  router.push({
    path: `/hospitalization-orders/${hospitalizationOrderId.value}/document-sets/send`
  })
}

const documentCheck = () => {
  // 送付済みのコンテンツ一覧画面へ遷移
  router.push({ path: `/hospitalization-orders/${hospitalizationOrderId.value}/contents` })
}
</script>
<template>
  <div v-if="!loading">
    <Card
      :title="title"
      :label="documentStatus.documentSetStatus.label"
      :chipColor="documentStatus.documentSetStatus.color"
      :showChip="showChip"
      :outlined="outlined"
      :firstBtnText="firstBtnText"
      :secondBtnText="t('features.Card.documentSubmit.btn.sentDocumentCheck')"
      :layout="cardLayout"
      :onFirstClick="onFirstClick"
      :onSecondClick="documentCheck"
      :firstBtnDisabled="isDisabled"
    >
      <template #body>
        <p>{{ body }}</p>
      </template>
    </Card>
  </div>
</template>
<style lang="scss" scoped></style>
