// Features配下をディレクトリ毎に区切って指定する
export const features = {
  medicalWorker: {
    List: {
      header: {
        name: '従業員名',
        email: 'メールアドレス',
        role: '権限'
      },
      search: {
        userName: '従業員名で検索'
      }
    },
    MedicalWorkerRegistrationForm: {
      input: {
        name: '名前',
        phoneticName: '名前（カタカナ）',
        email: 'メールアドレス',
        role: '権限'
      },
      placeholder: {
        familyName: '姓',
        givenName: '名',
        phoneticFamilyName: 'セイ',
        phoneticGivenName: 'メイ',
        email: 'メールアドレス',
        role: '権限'
      }
    },
    MedicalWorkerDetail: {
      input: {
        email: 'アドレス',
        role: '権限'
      },
      button: {
        edit: '編集',
        delete: '削除'
      },
      alert: {
        title: '従業員削除'
      }
    }
  },
  patient: {
    List: {
      header: {
        patientNo: '患者番号',
        name: '患者名',
        gender: '性別',
        birthDate: '生年月日',
        hospitalizationOrderStatus: 'ステータス'
      },
      search: {
        patientNo: '患者番号で検索',
        name: '患者名で検索',
        hospitalizationOrderStatus: 'ステータス'
      }
    },
    PatientRegistrationForm: {
      input: {
        patientNo: '患者番号',
        name: '名前',
        phoneticName: '名前（カタカナ）',
        gender: '性別',
        birthDate: '生年月日'
      },
      placeholder: {
        patientNo: '患者番号',
        familyName: '姓',
        givenName: '名',
        phoneticFamilyName: 'セイ',
        phoneticGivenName: 'メイ',
        gender: '選択してください'
      }
    },
    PatientDetail: {
      item: {
        patientNo: '患者番号',
        name: '名前',
        phoneticName: '名前（カタカナ）',
        gender: '性別',
        birthDate: '生年月日'
      }
    },
    PatientEditForm: {
      input: {
        patientNo: '患者番号',
        name: '名前',
        phoneticName: '名前（カタカナ）',
        gender: '性別',
        birthDate: '生年月日'
      },
      placeholder: {
        patientNo: '患者番号',
        familyName: '姓',
        givenName: '名',
        phoneticFamilyName: 'セイ',
        phoneticGivenName: 'メイ',
        gender: '選択してください'
      }
    }
  },
  hospitalizationOrders: {
    List: {
      header: {
        patientNo: '患者番号',
        patientName: '患者名',
        gender: '性別',
        hospitalizationDate: '入院予定日',
        useAppStatus: 'アプリ利用',
        hospitalizationOrderStatus: '入院状況',
        documentSetStatus: '書類提出状況'
      },
      search: {
        patientNo: '患者番号で検索',
        patientName: '患者名で検索',
        useAppStatus: 'アプリ利用',
        hospitalizationOrderStatus: '入院状況',
        documentSetStatus: '書類提出状況'
      }
    },
    registration: {
      hospitalizationDate: '入院予定日',
      dischargeDate: '退院予定日',
      department: '診療科',
      ward: '病棟',
      room: '病室',
      patient: {
        information: '患者情報',
        number: '患者番号',
        name: '名前',
        birthDate: '生年月日',
        gender: '性別'
      },
      button: {
        select: '選択'
      },
      search: {
        patient: '患者を選択',
        patientNo: '患者番号で検索',
        patientName: '患者名で検索'
      }
    },
    Edit: {
      HospitalizationOrderStatus: '入院状況',
      hospitalizationOrder: '入院オーダー',
      hospitalizationDate: '入院予定日',
      dischargeDate: '退院予定日',
      department: '診療科',
      ward: '病棟',
      room: '病室',
      patient: {
        information: '患者情報',
        number: '患者番号',
        name: '名前',
        birthDate: '生年月日',
        gender: '性別'
      },
      button: {
        select: '選択'
      },
      search: {
        patient: '患者を選択',
        patientNo: '患者番号で検索',
        patientName: '患者名で検索'
      }
    }
  },
  sentDocumentSetList: {
    header: {
      documentSetName: '書類セット名',
      contentName: 'コンテンツ名',
      contentType: 'ファイル形式',
      sendStatus: '書類提出状況',
      updatedAt: '更新日時'
    }
  },
  documentSet: {
    title: '書類セット',
    Form: {
      input: {
        title: 'タイトル',
        description: '内容説明',
        addContent: '登録するコンテンツ',
        sendDocumentSets: '送付する書類セット'
      },
      placeholder: {
        title: 'タイトル',
        description: '説明文',
        addContent: 'コンテンツ選択'
      },
      hint: {
        addContent: '最大20件まで登録できます'
      },
      tableHeader: {
        title: '書類セット名',
        content: 'コンテンツ名',
        contentSize: '書類点数',
        contentType: 'ファイル形式',
        onUpdated: '更新日時'
      },
      noContentData: '表示するコンテンツはありません'
    }
  },
  contentSet: {
    url: 'URL',
    form: '回答フォーム',
    RegistrationForm: {
      input: {
        title: 'タイトル',
        description: '内容説明',
        url: 'URL'
      },
      placeholder: {
        title: 'タイトル',
        description: '説明文',
        url: 'https://'
      }
    },
    Detail: {
      input: {
        title: 'タイトル',
        description: '内容説明',
        url: 'URL',
        tag: '検索タグ',
        ward: '検索ワード'
      },
      alert: {
        title: 'URLコンテンツ削除',
        formTitle: '回答フォーム削除',
        content: 'URLコンテンツを削除します。\n※この動作は取り消せません。',
        formContent: '回答フォームを削除します。\n※この動作は取り消せません。',
        deleted: '削除',
        cancel: 'キャンセル'
      }
    },
    List: {
      header: {
        title: 'コンテンツ名',
        contentType: 'ファイル形式',
        updatedAt: '更新日時'
      },
      search: {
        title: 'タイトル',
        tag: '検索タグ'
      },
      dialog: {
        title: 'コンテンツを選択'
      }
    }
  },
  FormContents: {
    typeName: {
      section: 'セクション',
      text: 'テキスト',
      choices: '選択肢',
      checkBox: 'チェックボックス',
      quantitative: '数値',
      date: '日付',
      inputSet: '入力セット'
    },
    new: {
      input: {
        title: 'タイトル',
        subTitle: 'サブタイトル',
        contentDescription: '内容説明',
        questionIndexPrefix: '回答',
        questions: '回答内容',
        upload: 'アップロード',
        required: '回答必須',
        question: '質問',
        sectionIndexPrefix: 'セクション',
        copySectionInitValue: '（コピー）',
        searchTag: '検索タグ'
      },
      placeholder: {
        title: 'タイトル',
        fieldName: '項目名',
        description: '説明',
        contentDescription: '内容説明',
        choice: '選択肢'
      },
      section: {
        section: 'セクション',
        placeholder: 'セクション名'
      },
      textForm: {
        pleaseAnswer: '回答を入力してください',
        multiLineAnswer: '複数行入力',
        required: '回答必須'
      },
      choices: {
        choice: '選択肢',
        addBranch: '分岐を追加する',
        addChoice: '選択肢を追加する',
        addBranchCaption: 'ラジオボタン選択時、選択した質問以降のフォームを表示します。',
        toNext: '次へ',
        toLast: 'フォームの末尾',
        settingBranch: '分岐設定'
      },
      checkBox: {
        choice: '選択肢',
        addChoice: '選択肢を追加する'
      },
      quantitative: {
        unit: '単位'
      },
      dateForm: {},
      inputSet: {
        editQuestion: '質問を編集する',
        editInputField: '入力セット編集'
      }
    },
    result: {
      noAnswerMessage: '[回答なし]'
    }
  },
  Card: {
    appUsage: {
      title: 'アプリ利用状況',
      body: {
        notUsed: '患者アプリとの紐づけがありません。',
        used: '紐付しているユーザー名'
      },
      button: {
        QrCodeScanner: 'QR読取',
        idInput: '認証ID入力',
        idModal: {
          body: '患者アプリに表示される認証IDを入力してください。',
          fieldTitle: '認証ID'
        },
        agentModal: {
          title: '認証ID確認',
          body: '認証ID({resource})が本人様のものか、代理人のものかを選択してください。代理人の場合は、お名前と続柄をご入力ください。',
          relationship: '続柄'
        }
      }
    },
    documentSubmit: {
      title: '書類提出',
      label: {
        notSend: '未送付',
        notSubmit: '未提出',
        partial_submitted: '一部提出',
        submitted: '提出済',
        confirmed: '確定済'
      },
      body: {
        notUsed: '患者アプリの紐づけを行ってください。',
        notSend: '患者へ入院書類を送付してください。',
        notSubmit: '現在、書類提出待ちです。',
        submitted: '提出書類の入力内容を確認し、確定してください。',
        confirmed: '入力内容を確定済みです。',
        noDocumentSubmit: '患者が提出する書類はありません。'
      },
      btn: {
        documentSent: '書類を送付',
        StatusCheck: '入力状況を確認',
        documentCheck: '入力内容を確認',
        sentDocumentCheck: '送付書類を確認'
      }
    }
  },
  documentSets: {
    search: {
      title: '検索'
    },
    header: {
      title: '書類セット名',
      contentSize: '書類点数',
      updatedAt: '更新日時'
    },
    detail: {
      title: 'タイトル',
      description: '内容説明',
      header: {
        contentName: 'コンテンツ名',
        fileType: 'ファイル形式',
        updatedAt: '更新日時'
      },
      alert: {
        title: '書類セット削除',
        content: '書類セットを削除します。\n※この動作は取り消せません。'
      }
    },
    dialog: {
      title: '書類セットを選択'
    }
  },
  SendDocumentSets: {
    PatientDisplay: {
      patientNo: '患者番号',
      name: '名前',
      gender: '性別',
      birthDate: '生年月日'
    },
    Form: {
      Table: {
        Header: {
          title: '書類セット名',
          contentSize: '書類点数',
          onUpdated: '更新日時'
        },
        noContentData: '表示する書類セットはありません'
      },
      DocumentSetSelectList: {
        Dialog: {
          title: '書類セットを選択'
        },
        Table: {
          Header: {
            title: '書類セット名',
            contentSize: '書類点数',
            onUpdated: '更新日時'
          }
        }
      },
      addDocumentSets: '書類セットを追加',
      LabelText: {
        sendDocumentSets: '送付する書類セット'
      }
    },
    send: {
      notSubmittedMessage: '※現在、書類は提出されておりません。'
    },
    sendDocumentSets: '書類送付',
    applyDialog: {
      title: 'コンテンツ確定',
      confirmFormContentMessage: '入力された回答を確定します。\n※確定後の回答修正はできません。'
    }
  },
  DetailHospitalizationOrder: {
    patientDetail: {
      no: '患者番号',
      name: '名前',
      birthDate: '生年月日',
      gender: '性別',
      bloodType: '血液型',
      representativeName: '代理人',
      documentStatus: '書類提出状況',
      updateDate: '更新日時'
    },
    hospitalization: {
      hospitalizationDetail: '入院詳細',
      hospitalizationStatus: '入院状況',
      hospitalizationDate: '入院予定日',
      dischargeDate: '退院予定日',
      hospitalizationDepartment: '診療科',
      hospitalizationWard: '病棟',
      hospitalizationRoom: '病室'
    },
    scanQrCode: '患者アプリのQRコードを読み取ってください。'
  },
  hospitalizationDocument: {
    FormContentSubmission: {
      start: '回答をはじめる',
      goToNext: '次の質問へ',
      goToConfirm: '確認画面へ',
      stepBack: '前の質問へ',
      interruptForm: '回答を中断',
      interruptFormTitle: '回答を中断しますか？',
      interruptFormDescription:
        '入力情報は保存されます。次回続きから回答を再開することができます。',
      interruptFormCancelText: '入力を続ける',
      interruptFormOkText: '中断する',
      pleaseEnterNumber: '数字を入力してください',
      pleaseEnterText: 'テキストを入力してください',
      pleaseEnterDate: '日付を選んでください',
      pleaseSelect: '当てはまるものを全て選択してください',
      pleaseChoseOne: '選択肢から一つ選んでください',
      addInputSetButtonCaption: 'を追加',
      nonSelectAnswer: '選択なし',
      completeAnswer: '回答完了',
      fixAnswer: '回答修正',
      goBack: '前の質問へ',
      goNext: '次の質問へ'
    }
  }
}
