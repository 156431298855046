<script setup lang="ts">
import { tidyContentJson } from '@features/FormContentForm/TemplateFormFunctions'
import { reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import FormContentForm from '@/features/FormContentForm/index.vue'
import { type FormContentInput } from '@/features/FormContentForm/type'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

type Props = {
  contentInput: FormContentInput
  contentId: string
}

const props = defineProps<Props>()

const formContentInput = reactive<FormContentInput>({
  ...props.contentInput
})

// フォーム更新
const update = async () => {
  isLoading.value = true
  const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
  try {
    const response = await medicalInstitutionsApiClient().api.updateContentByMedicalInstitution(
      medicalInstitutionId,
      props.contentId,
      { optimId: '' },
      {
        title: formContentInput.title,
        description: formContentInput.description,
        formContentRevision: tidyContentJson(formContentInput.contentJson),
        tag: formContentInput.tag
      }
    )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', { resource: t('pages.documentSets.contents') })
    )
    router.push(`/contents/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// コンテンツ一覧画面へ遷移
const transitionDetail = () => {
  router.push(`/contents/${props.contentId}`)
}

const isLoading = ref<boolean>(false)

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- フォーム更新画面 -->
  <FormContentForm :form="formContentInput" />

  <div class="form-buttons">
    <BaseButton
      type="button"
      :disabled="isLoading"
      :loading="isLoading"
      :content="t('attributes.cancel')"
      variant="outlined"
      min-width="8rem"
      @click="transitionDetail"
    />
    <BaseButton
      type="button"
      color="primary"
      :disabled="isLoading"
      :loading="isLoading"
      :content="t('attributes.save')"
      min-width="8rem"
      @click="update"
    />
  </div>

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style lang="scss" scoped>
.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
