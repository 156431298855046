<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import HospitalizationOrderRegistration from '@/features/HospitalizationOrderRegistration/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()

onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.hospitalizationOrders.list'),
        href: '/hospitalization-orders',
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.new'),
        disable: true
      }
    ]
  })
})
</script>
<template>
  <v-container class="container">
    <!-- 入力フォーム -->
    <HospitalizationOrderRegistration />
  </v-container>
</template>
<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
