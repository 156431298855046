import AsyncLock from 'async-lock'
import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiPatientListResponse } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'

const limit = 50

interface SearchConditions {
  medicalInstitutionId?: string
  patientNo?: string
  name?: string
  hospitalizationOrderStatus?: string
  orderBy?: string
  order?: string
}

export const usePatientsStore = defineStore('patients', () => {
  const lock = new AsyncLock({ timeout: 2000 })
  const searchConditions = ref<SearchConditions>({})
  const currentProcess = ref<string | undefined>()
  const searchedLastPatientId = ref<string | undefined>()
  const patientList = ref<MiPatientListResponse['list']>([])
  const getPatientList = () => patientList

  /**
   * 検索結果を保存する
   * 結果は排他制御し、全て同一の呼び出しで更新されることを保証する
   */
  const setSearchResult = async (
    medicalInstitutionId: string,
    _searchedLastPatientId: string | undefined,

    _patientList: MiPatientListResponse['list'],
    patientNo: string | undefined,
    name: string | undefined,
    hospitalizationOrderStatus: string | undefined,
    orderBy: string | undefined,
    order: string | undefined
  ) => {
    await lock.acquire('setSearchResult', () => {
      searchedLastPatientId.value = _searchedLastPatientId
      patientList.value = _patientList
      searchConditions.value = {
        medicalInstitutionId,
        patientNo,
        name,
        hospitalizationOrderStatus,
        orderBy,
        order
      }
    })
  }

  const fetchPatientList = async (
    medicalInstitutionId: string,
    patientNo?: string,
    name?: string,
    hospitalizationOrderStatus?: string,
    orderBy?: string,
    order?: string
  ): Promise<FetchApiResponse<MiPatientListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    try {
      const response = await medicalInstitutionsApiClient().api.patientsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          patientNo,
          name,
          hospitalizationOrderStatus,
          orderBy,
          order,
          limit
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        undefined,
        response.data.list,
        patientNo,
        name,
        hospitalizationOrderStatus,
        orderBy,
        order
      )

      return {
        success: true,
        content: patientList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const fetchNextPatientList = async (
    medicalInstitutionId: string,
    lastPatientId: string,
    patientNo?: string,
    name?: string,
    hospitalizationOrderStatus?: string,
    orderBy?: string,
    order?: Order
  ): Promise<FetchApiResponse<MiPatientListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    // 重複した検索
    if (searchedLastPatientId.value == lastPatientId) {
      return {
        success: false,
        status: 400
      }
    }
    // 検索キーが一致するか確認
    if (
      searchConditions.value.medicalInstitutionId != medicalInstitutionId ||
      searchConditions.value.orderBy != orderBy ||
      searchConditions.value.order != order
    ) {
      // 一致しない場合は、追加検索ではなく初期化を行う
      return fetchPatientList(
        medicalInstitutionId,
        patientNo,
        name,
        hospitalizationOrderStatus,
        orderBy,
        order
      )
    }

    // 取得処理
    try {
      const response = await medicalInstitutionsApiClient().api.patientsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          patientNo,
          name,
          hospitalizationOrderStatus,
          lastPatientId,
          orderBy,
          order,
          limit
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        lastPatientId,
        [...patientList.value, ...response.data.list],
        patientNo,
        name,
        hospitalizationOrderStatus,
        orderBy,
        order
      )

      return {
        success: true,
        content: patientList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const clearPatientList = () => {
    patientList.value = []
    searchedLastPatientId.value = undefined
    searchConditions.value = {}
    currentProcess.value = undefined
  }

  return {
    getPatientList,
    fetchPatientList,
    fetchNextPatientList,
    clearPatientList
  }
})
