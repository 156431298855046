import { createRouter, createWebHistory } from 'vue-router'

import { useConstants } from '@/composables/useConstants'
import AppVersion from '@/pages/AppVersion.vue'
import ContentList from '@/pages/ContentList.vue'
import DetailContent from '@/pages/DetailContent.vue'
import DocumentSetDetail from '@/pages/DetailDocumentSet.vue'
import DetailHospitalizationOrder from '@/pages/DetailHospitalizationOrder.vue'
import MedicalWorkerDetail from '@/pages/DetailMedicalWorker.vue'
import PatientDetail from '@/pages/DetailPatient.vue'
import DocumentSetsList from '@/pages/DocumentSetsList.vue'
import EditContent from '@/pages/EditContent.vue'
import EditDocumentSet from '@/pages/EditDocumentSet.vue'
import EditHospitalization from '@/pages/EditHospitalization.vue'
import MedicalWorkerEdit from '@/pages/EditMedicalWorker.vue'
import PatientEdit from '@/pages/EditPatient.vue'
import Forbidden from '@/pages/error/Forbidden.vue'
import NotFound from '@/pages/error/NotFound.vue'
import ServerError from '@/pages/error/ServerError.vue'
import HospitalizationOrderList from '@/pages/HospitalizationOrderList.vue'
import MedicalWorkerList from '@/pages/MedicalWorkerList.vue'
import NewContent from '@/pages/NewContent.vue'
import NewDocumentSet from '@/pages/NewDocumentSet.vue'
import NewHospitalization from '@/pages/NewHospitalization.vue'
import MedicalWorkerRegistration from '@/pages/NewMedicalWorker.vue'
import PatientRegistration from '@/pages/NewPatient.vue'
import PatientList from '@/pages/PatientList.vue'
import SendDocumentSets from '@/pages/SendDocumentSets.vue'
import SentDocumentSetContentDetail from '@/pages/SentDocumentSetContentDetail.vue'
import SentDocumentContentSetList from '@/pages/SentDocumentSetContentList.vue'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const role = useConstants().CONSTANTS.ROLE

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // 遷移時にスクロールトップに戻す
    return { top: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HospitalizationOrderList,
      meta: { [role.READ]: true }
    },
    {
      path: '/hospitalization-orders',
      name: 'hospitalization-orders',
      component: HospitalizationOrderList,
      meta: { [role.READ]: true }
    },
    {
      // 患者書類選択・送付画面
      path: '/hospitalization-orders/:hospitalizationOrderId/document-sets/send',
      name: 'DGHI-005-05',
      component: SendDocumentSets,
      meta: { [role.READ]: true }
    },
    // 送付済みの書類セット一覧確認画面
    {
      path: '/hospitalization-orders/:hospitalizationOrderId/contents',
      name: 'DGHI-005-06',
      component: SentDocumentContentSetList,
      meta: { [role.READ]: true }
    },
    // 提出済みのフォーム解答内容の詳細画面
    {
      path: '/hospitalization-orders/:hospitalizationOrderId/contents/:hospitalizationOrderDocumentSetContentId',
      name: 'DGHI-005-07',
      component: SentDocumentSetContentDetail,
      meta: { [role.READ]: true }
    },
    {
      // 患者の一覧画面
      path: '/patients',
      name: 'DGHI-004-01',
      component: PatientList,
      meta: { [role.READ]: true }
    },
    {
      // 患者の新規登録画面
      path: '/patients/new',
      name: 'DGHI-004-02',
      component: PatientRegistration,
      meta: { [role.GENERAL]: true }
    },
    {
      // 患者の詳細画面
      path: '/patients/:patientId',
      name: 'DGHI-004-03',
      component: PatientDetail,
      meta: { [role.READ]: true }
    },
    {
      // 患者の編集画面
      path: '/patients/:patientId/edit',
      name: 'DGHI-004-04',
      component: PatientEdit,
      meta: { [role.GENERAL]: true }
    },
    {
      // 書類セット一覧画面
      path: '/document-sets',
      name: 'document-sets',
      component: DocumentSetsList,
      meta: { [role.READ]: true }
    },
    {
      path: '/document-sets/:documentSetId',
      name: 'detail-document-sets',
      component: DocumentSetDetail,
      meta: { [role.READ]: true }
    },
    {
      path: '/document-sets/new',
      name: 'new-document-sets',
      component: NewDocumentSet,
      meta: { [role.ADMIN]: true }
    },
    {
      // 書類セット編集画面
      path: '/document-sets/:documentSetId/edit',
      name: 'edit-document-set',
      component: EditDocumentSet,
      meta: { [role.ADMIN]: true }
    },
    {
      // 医療機関ユーザー一覧画面
      path: '/medical-workers',
      name: 'medical-workers',
      component: MedicalWorkerList,
      meta: { [role.READ]: true }
    },
    {
      // 医療機関ユーザー詳細画面
      path: '/medical-workers/:medicalWorkerId',
      name: 'detail-medical-workers',
      component: MedicalWorkerDetail,
      meta: { [role.READ]: true }
    },
    {
      // 医療機関ユーザー登録画面
      path: '/medical-workers/new',
      name: 'new-medical-workers',
      component: MedicalWorkerRegistration,
      meta: { [role.ADMIN]: true }
    },
    {
      // 医療機関ユーザー編集画面
      path: '/medical-workers/:medicalWorkerId/edit',
      name: 'edit-medical-workers',
      component: MedicalWorkerEdit,
      meta: { [role.ADMIN]: true }
    },
    {
      // コンテンツ一覧画面
      path: '/contents',
      name: 'contents',
      component: ContentList,
      meta: { [role.READ]: true }
    },
    {
      //コンテンツ詳細画面
      path: '/contents/:contentId',
      name: 'content-detail',
      component: DetailContent,
      meta: { [role.READ]: true }
    },
    {
      // コンテンツ新規作成画面
      path: '/contents/new',
      name: 'new-form-contents',
      component: NewContent,
      meta: { [role.ADMIN]: true }
    },
    {
      // コンテンツ編集画面
      path: '/contents/:contentId/edit',
      name: 'contents-edit',
      component: EditContent,
      meta: { [role.ADMIN]: true }
    },
    {
      path: '/server_error',
      name: '',
      component: ServerError,
      meta: { notAuth: true }
    },
    {
      path: '/forbidden',
      name: '',
      component: Forbidden,
      meta: { notAuth: true }
    },
    {
      path: '/not_found',
      name: '',
      component: NotFound,
      meta: { notAuth: true }
    },
    {
      path: '/:catchAll(.*)',
      component: NotFound,
      meta: { notAuth: true },
      beforeEnter: () => {
        router.push('/not_found')
      }
    },
    {
      // 入院オーダー新規登録画面
      path: '/hospitalization-orders/new',
      name: '',
      component: NewHospitalization,
      meta: { [role.GENERAL]: true }
    },
    {
      // 入院オーダー詳細画面
      path: '/hospitalization-orders/:hospitalizationOrderId',
      name: 'DetailHospitalizationOrder',
      component: DetailHospitalizationOrder,
      meta: { [role.READ]: true }
    },
    {
      //入院オーダー編集画面
      path: '/hospitalization-orders/:hospitalizationOrderId/edit',
      name: 'DGHI-005-04',
      component: EditHospitalization,
      meta: { [role.GENERAL]: true }
    },
    {
      // アプリのバージョン確認画面（検証用）
      path: '/version',
      name: 'version',
      component: AppVersion,
      meta: { notAuth: true }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const notAuth = to.matched.some((record) => record.meta.notAuth)
  if (notAuth) {
    // ループする可能性があるため、エラーハンドリングを用いた自動遷移は利用しない
    try {
      await useMedicalInstitutionsStore().fetchMedicalInstitutionList(true)
    } catch (e) {
      console.error(e)
    }
    next()
    return
  }

  // 認証処理
  await useMedicalInstitutionsStore().fetchMedicalInstitutionList()
  // 権限チェック
  const isAdmin = to.matched.some((record) => record.meta[role.ADMIN])
  if (isAdmin) {
    if (useMedicalInstitutionsStore().isAdmin()) {
      next()
      return
    } else {
      next('/forbidden')
      return
    }
  }

  const isGeneral = to.matched.some((record) => record.meta[role.GENERAL])
  if (isGeneral) {
    if (useMedicalInstitutionsStore().isGeneral()) {
      next()
      return
    } else {
      next('/forbidden')
      return
    }
  }
  const isRead = to.matched.some((record) => record.meta[role.READ])
  if (isRead) {
    if (useMedicalInstitutionsStore().isRead()) {
      next()
      return
    } else {
      next('/forbidden')
      return
    }
  }

  throw Error('expected role meta tag')
})

export default router
