import { http, HttpResponse, type PathParams } from 'msw'

import {
  hospitalizationOrderDocumentSets,
  hospitalizationOrderItems,
  hospitalizationOrders
} from './baseData'

import type {
  MiHospitalizationOrderDocumentSetItem,
  MiHospitalizationOrderDocumentSetListResponse,
  MiHospitalizationOrderItem,
  MiHospitalizationOrderListResponse,
  MiHospitalizationOrderResponse
} from '@/api/generate/apiSchema'

const baseUrl = import.meta.env.VITE_API_HOSPITALIZATION_API_BASE_URL
const medicalInstitutionV1Url = `${baseUrl}/api/medical-institutions/:medicalInstitutionId/v1`

const extractMedicalInstitutionId = (params: PathParams): string => {
  const medicalInstitutionId = params['medicalInstitutionId']
  if (typeof medicalInstitutionId == 'string') {
    return medicalInstitutionId
  }

  throw 'expect only one medicalInstitutionId'
}

export const hospitalizationApi = [
  http.get(`${medicalInstitutionV1Url}/hospitalization-orders`, ({ params }) => {
    const response: MiHospitalizationOrderListResponse = {
      list: hospitalizationOrders[extractMedicalInstitutionId(params)].map(
        (hospitalizationOrder) => {
          const item: MiHospitalizationOrderItem = {
            id: hospitalizationOrder.id,
            patientNo: hospitalizationOrder.patientNo,
            familyName: hospitalizationOrder.familyName,
            givenName: hospitalizationOrder.givenName,
            gender: hospitalizationOrder.gender,
            hospitalizationDate: hospitalizationOrder.hospitalizationDate,
            hospitalizationOrderStatus: hospitalizationOrder.hospitalizationOrderStatus,
            useAppStatus: hospitalizationOrder.useAppStatus,
            documentSetStatus: hospitalizationOrder.documentSetStatus
          }
          return item
        }
      )
    }
    return HttpResponse.json(response)
  }),
  http.get(
    `${medicalInstitutionV1Url}/hospitalization-orders/:hospitalizationOrderId`,
    ({ params }) => {
      const hospitalizationOrderId = params['hospitalizationOrderId']

      const hospitalizationOrder = hospitalizationOrderItems[
        extractMedicalInstitutionId(params)
      ].filter((hospitalization) => {
        return hospitalization.id === hospitalizationOrderId
      })[0]
      const response: MiHospitalizationOrderResponse = {
        id: hospitalizationOrder.id,
        patientId: hospitalizationOrder.patientId,
        representatives: hospitalizationOrder.representatives,
        hospitalizationOrderStatus: hospitalizationOrder.hospitalizationOrderStatus,
        hospitalDepartment: hospitalizationOrder.hospitalDepartment,
        hospitalRoom: hospitalizationOrder.hospitalRoom,
        hospitalizationDate: hospitalizationOrder.hospitalizationDate,
        ward: hospitalizationOrder.ward,
        dischargeDate: hospitalizationOrder.dischargeDate,
        createdAt: hospitalizationOrder.createdAt,
        updatedAt: hospitalizationOrder.updatedAt
      }
      return HttpResponse.json(response)
    }
  ),
  http.get(
    `${medicalInstitutionV1Url}/hospitalization-orders/:hospitalizationOrderId/document-sets`,
    ({ params }) => {
      const hospitalizationOrderId = params['hospitalizationOrderId']
      const response: MiHospitalizationOrderDocumentSetListResponse = {
        list: hospitalizationOrderDocumentSets[extractMedicalInstitutionId(params)]
          .filter((documentSet) => {
            return documentSet.hospitalizationOrderDocumentSetContentId == hospitalizationOrderId
          })
          .map((hospitalizationOrderDocumentSet) => {
            const item: MiHospitalizationOrderDocumentSetItem = {
              hospitalizationOrderDocumentSetId: hospitalizationOrderDocumentSet.documentSetId,
              hospitalizationOrderDocumentSetContentId:
                hospitalizationOrderDocumentSet.hospitalizationOrderDocumentSetContentId,
              documentSetId: hospitalizationOrderDocumentSet.documentSetId,
              documentSetTitle: hospitalizationOrderDocumentSet.documentSetTitle,
              contentId: hospitalizationOrderDocumentSet.contentId,
              contentRevisionId: hospitalizationOrderDocumentSet.contentRevisionId,
              contentTitle: hospitalizationOrderDocumentSet.contentTitle,
              contentType: hospitalizationOrderDocumentSet.ContentType,
              contentStatusCode: hospitalizationOrderDocumentSet.contentStatusCode,
              updatedAt: hospitalizationOrderDocumentSet.updatedAt
            }
            return item
          })
      }
      return HttpResponse.json(response)
    }
  )
]
