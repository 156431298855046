<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useConstants } from '@/composables/useConstants'
import FormContentRegistration from '@/features/FormContentRegistration/index.vue'
import UrlContentRegistration from '@/features/UrlContentRegistration/index.vue'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const CONTENT_TYPE = useConstants().CONSTANTS.CONTENT_TYPE
const displayNameStore = useDisplayNameStore()

const route = useRoute()
const contentType = route.query.type

onMounted(async () => {
  switch (contentType) {
    case CONTENT_TYPE.FORM:
      // フォームコンテンツ新規作成画面
      displayNameStore.updateDisplayName({
        breadcrumbs: [
          {
            title: t('pages.documentSets.documentSets'),
            href: '/document-sets',
            disable: false
          },
          {
            title: t('pages.documentSets.contents'),
            href: '/contents',
            disable: false
          },
          {
            title: t('pages.documentSets.new.form'),
            disable: true
          }
        ]
      })
      break
    case CONTENT_TYPE.URL:
      // URLコンテンツ新規作成画面
      displayNameStore.updateDisplayName({
        breadcrumbs: [
          {
            title: t('pages.documentSets.documentSets'),
            href: '/document-sets',
            disable: false
          },
          {
            title: t('pages.documentSets.contents'),
            href: '/contents',
            disable: false
          },
          {
            title: t('pages.documentSets.new.url'),
            disable: true
          }
        ]
      })
      break
    default:
      router.push('/not_found')
  }
})
</script>

<template>
  <v-container class="container">
    <FormContentRegistration v-if="contentType == CONTENT_TYPE.FORM" />
    <UrlContentRegistration v-if="contentType == CONTENT_TYPE.URL" />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
