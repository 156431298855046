<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { features } from '@/common/i18n/ja/features'
import BaseDialog from '@/components/Base/Dialog/index.vue'
import Label from '@/components/Base/Label/index.vue'

type Props = {
  /**
   * ダイアログの開閉
   */
  value: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false
})

type Emits = {
  (e: 'input', value: boolean): void
  (e: 'confirm', bindingToken: string): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()

const title = t('features.Card.appUsage.button.idInput')
const body = t('features.Card.appUsage.button.idModal.body')
const registerBtn = t('features.Card.appUsage.button.agentModal.title')
const cancelBtn = t('attributes.cancel')

const _bindingToken = ref<string>('')
const bindingToken = computed({
  get: () => _bindingToken.value,
  set: (value: string) => {
    const replacedValues = value.replace(/\s|-/g, '').match(/.{1,4}/g)
    if (replacedValues == null) {
      _bindingToken.value = ''
    } else {
      _bindingToken.value = replacedValues.slice(0, 4).join('-')
      if (replacedValues.length > 4) {
        _bindingToken.value += replacedValues.slice(4).join('')
      }
    }
  }
})

const onClose = (value: boolean) => {
  bindingToken.value = ''
  emit('input', value)
}

const onConfirm = (id: string) => {
  emit('confirm', id)
  bindingToken.value = ''
}
</script>
<template>
  <div>
    <base-dialog
      :value="props.value"
      :title="title"
      :confirmBtnText="registerBtn"
      :confirmBtnVisible="true"
      :cancelBtnText="cancelBtn"
      :cancelBtnDefault="'right'"
      :persistent="true"
      @input="onClose"
      @confirm="onConfirm(bindingToken)"
    >
      <template #contents>
        <v-form @submit.prevent="onConfirm(bindingToken)">
          <v-row justify="start">
            <v-col>
              <div class="py-5">
                <label>{{ body }}</label>
              </div>
              <div>
                <Label
                  id="bindingToken"
                  :labelText="features.Card.appUsage.button.idModal.fieldTitle"
                  :isRequired="true"
                ></Label>
                <v-text-field v-model="bindingToken" rows="1"></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>
<style lang="scss" scoped></style>
