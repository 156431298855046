<script setup lang="ts">
import DocumentSetRegistration from '@features/DocumentSetRegistration/index.vue'
import { useI18n } from 'vue-i18n'

import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()
displayNameStore.updateDisplayName({
  breadcrumbs: [
    {
      title: t('pages.documentSets.documentSets'),
      href: '/document-sets',
      disable: false
    },
    {
      title: t('pages.documentSets.new.list'),
      disable: true
    }
  ]
})
</script>
<template>
  <v-container class="container">
    <DocumentSetRegistration />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
