<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { VDataTable } from 'vuetify/lib/components/index.mjs'

import SortTableItem from './SortTableItem.vue'

import type { Header, Item } from './types'

import { getToken } from '@/components/Typography/token'

/**
 * ソートして表示可能なテーブルコンポーネント
 * ソートは外部からソートされる前提となる
 * ページネーションはスクロールのみとなる
 */

type Props = {
  headers: Header[] // keyがactionは使用しているため注意
  items: Item[]
  loading?: boolean
  viewAction?: boolean // >ボタンを表示する場合 true
  noDataText: string // データがない場合に表示されるテキスト
  height?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  viewAction: false,
  height: '100%'
})

const dataTable = ref<InstanceType<typeof VDataTable>>()

onMounted(() => {
  // スクロールページネーションの設定
  if (dataTable.value == undefined) {
    return
  }

  const tableElement = dataTable.value.$el.children[0]
  tableElement.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  // スクロールページネーションの解除
  if (dataTable.value == undefined) {
    return
  }

  const tableElement = dataTable.value.$el.children[0]
  tableElement.removeEventListener('scroll', onScroll)
})

export type SortItem = { key?: string; order?: 'ASC' | 'DESC' }

const emit = defineEmits<{
  (e: 'itemClick', item: Item): void
  (e: 'requestNextItem', item: unknown): void
  (e: 'update:sortBy', item: SortItem): void
}>()

// スクロールが画面下部に達した場合は、requestNextItemイベントを発火し、次のデータを要求する
const onScroll = (event: Event) => {
  const tableElement = event.target as HTMLElement
  if (
    tableElement.scrollTop + tableElement.clientHeight >= tableElement.scrollHeight &&
    !props.loading
  ) {
    emit('requestNextItem', items.value[items.value.length - 1])
  }
}

/**
 * アクション用のヘッダーを追加する場合は、追加した上で返す
 */
const headers = computed(() => {
  if (props.viewAction) {
    return props.headers.concat({
      key: 'action',
      title: '',
      minWidth: '',
      width: ''
    })
  } else {
    return props.headers
  }
})
/**
 * アクション用のアイテムを追加する場合は、追加した上で返す
 */
const items = computed<Item[]>(() => {
  if (props.viewAction) {
    return props.items.map((item) => {
      return {
        ...item,
        action: ''
      }
    })
  } else {
    return props.items
  }
})

/**
 * ソートテーブルに付与するクラスを計算する
 * @param column カラム
 * @param isSorted ソートが選択されている場合true
 */
const sortTableHeaderClass = (column: Header, isSorted: boolean) => {
  const classList = []
  if (column.align) {
    classList.push(`sort-table-header-column--${column.align}`)
  }

  if (isSorted) {
    classList.push('sort-table-header-column--sorted')
  }
  return classList
}

/**
 * ソート不可なテーブルに付与するクラスを計算する
 * @param column カラム
 * @param isSorted ソートが選択されている場合true
 */
const notSortTableHeaderClass = (column: Header) => {
  const classList = []
  if (column.align) {
    classList.push(`sort-table-header-column--${column.align}`)
  }

  return classList
}

/**
 * テーブルのスクロールをリセットする。
 * アイテムを検索し直す際やソートする際などに利用する。
 * 外部で発火するものとなるため、Exposeする。
 */
const resetScroll = () => {
  if (dataTable.value == undefined) {
    return
  }
  const tableElement = dataTable.value.$el.children[0]
  tableElement.scrollTop = 0
}

defineExpose({
  resetScroll
})

const sortBy = (value: SortItem[]) => {
  emit('update:sortBy', { key: value[0]?.key, order: value[0]?.order })
}

const dataTableTokenStyle = getToken('BODY_M')
</script>

<template>
  <v-data-table
    ref="dataTable"
    class="elevation-1 sort-table"
    :style="dataTableTokenStyle"
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    fixed-header
    :height="height"
    width="100%"
    hide-default-footer
    :items-per-page="-1"
    density="compact"
    :no-data-text="noDataText"
    @update:sortBy="sortBy"
    :disable-sort="true"
  >
    <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
      <tr>
        <template v-for="column in columns" :key="column.key">
          <th
            v-if="column.sortable && column.key != 'action'"
            class="sort-table-header-column"
            :class="sortTableHeaderClass(column as Header, isSorted(column))"
            @click="() => toggleSort(column)"
            :style="`min-width: ${column.minWidth}; width: ${column.width};`"
          >
            <span class="sort-table-header-item">{{ column.title }}</span>
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="16"></v-icon>
            </template>
            <v-icon
              v-else
              class="material-icons v-icon notranslate v-theme--digihosTheme v-icon--size-default"
              size="16"
              icon="unfold_more"
            />
          </th>
          <th
            class="not-sort-table-header-column"
            :class="notSortTableHeaderClass(column as Header)"
            v-else-if="column.key != 'action'"
            :style="`min-width: ${column.minWidth}; width: ${column.width};`"
          >
            <span class="sort-table-header-item">{{ column.title }}</span>
          </th>
          <th v-else class="sort-table-header-column sort-table-header-column--action" />
        </template>
      </tr>
    </template>
    <template v-slot:item="{ item }">
      <tr class="sort-table-body" @click="$emit('itemClick', item)">
        <SortTableItem
          v-for="header in headers"
          :key="header.key"
          :header="header"
          :itemValue="item[header.key]"
        >
        </SortTableItem>
      </tr>
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
.sort-table-header-column {
  cursor: pointer;
  padding: 8px !important;
  background-color: #f2f2f2 !important;
  &:hover {
    background-color: #e5e5e5 !important;
  }
  &--sorted {
    background-color: #d1d4d6 !important;
    &:hover {
      background-color: #d1d4d6 !important;
    }
  }
  &--action {
    width: 36px;
    height: 36px !important;
    background-color: #f2f2f2 !important;
    &:hover {
      background-color: #f2f2f2 !important;
    }
  }
  &--center {
    text-align: center !important;
  }
}
.not-sort-table-header-column {
  padding: 8px !important;
  background-color: #f2f2f2 !important;
  &--center {
    text-align: center !important;
  }
}
.v-table--density-compact {
  --v-table-header-height: 36px;
}

.sort-table-header-item {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.sort-table-body {
  cursor: pointer;
  transition-duration: 0.28s;
  transition-property: box-shadow, opacity, height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
