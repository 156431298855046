import { createVuetify, type ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, md } from 'vuetify/iconsets/md'
import { en, ja } from 'vuetify/locale'

import 'vuetify/styles'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'material-symbols/outlined.css'
import { VuetifyColorToken } from '.'

const digihosTheme: ThemeDefinition = {
  colors: {
    ...VuetifyColorToken
  }
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md
    }
  },
  locale: {
    locale: 'ja',
    fallback: 'en',
    messages: { ja, en }
  },
  theme: {
    defaultTheme: 'digihosTheme',
    themes: {
      digihosTheme
    }
  }
})

export default vuetify
