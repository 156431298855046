<script setup lang="ts">
import Button from '@components/Base/Button/index.vue'
import MedicalWorkerListSearch from '@features/MedicalWorkerList/MedicalWorkerListSerch/index.vue'
import MedicalWorkerListTable from '@features/MedicalWorkerList/MedicalWorkerListTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MiMedicalWorkerItem } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useMedicalWorkersStore } from '@/stores/medicalWorkers'

const medicalWorkersStore = useMedicalWorkersStore()
const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const isLoading = ref(true)
const searchName = ref<string>('')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalWorkerListTable = ref<InstanceType<typeof MedicalWorkerListTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (medicalWorkerListTable.value == undefined) {
    return
  }

  medicalWorkerListTable.value.resetScroll()
}

watch([searchName, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  medicalWorkersStore
    .fetchMedicalWorkerList(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      searchName.value,
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch medical worker list')
      }
    })
})

onMounted(async () => {
  const response = await medicalWorkersStore.fetchMedicalWorkerList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    searchName.value,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch medical worker list')
  }
})

onBeforeMount(() => {
  medicalWorkersStore.clearMedicalWorkerList()
})

const requestNextItem = (item: MiMedicalWorkerItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextMedicalWorkerList(item)
}

const fetchNextMedicalWorkerList = async (item: MiMedicalWorkerItem) => {
  const response = await medicalWorkersStore.fetchNextMedicalWorkerList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    item.id,
    searchName.value,
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}

const transitionNewUser = () => {
  router.push('/medical-workers/new')
}
</script>

<template>
  <div class="medical-worker-list rounded-lg">
    <div class="head">
      <MedicalWorkerListSearch v-model="searchName" />
      <Button
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        color="primary"
        :loading="false"
        :content="t('attributes.newCreate')"
        elevated="text"
        min-height="48px"
        icon="add"
        @click="transitionNewUser"
      ></Button>
    </div>
    <div class="medical-worker-list-body">
      <MedicalWorkerListTable
        ref="medicalWorkerListTable"
        :isLoading="isLoading"
        @requestNextItem="requestNextItem"
        @update:sort-by="sortBy"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.medical-worker-list {
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 652px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.medical-worker-list-body {
  height: calc(100% - 62px);
}

.head {
  display: flex;
  justify-content: space-between;
}
</style>
