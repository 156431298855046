<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import MenuButton from './MenuButton.vue'
import QuestionsContainer from './QuestionsContainer.vue'
import SectionFormOption from './SectionFormOption.vue'

import BodyText from '@/components/Base/BodyText/index.vue'
import {
  type FormContentInput,
  FormOptionType,
  type SectionContainer,
  SectionType,
  menuButtons
} from '@/features/FormContentForm/type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * セクションインデックス番号
   */
  sectionIndex: number
  /**
   * オプション要素数
   */
  sectionLength: number
  /**
   * セクションオブジェクト
   */
  section: SectionContainer
  /**
   * フォーム親オブジェクト
   */
  form: FormContentInput
}>()
const section = reactive(props.section)

const emit = defineEmits<{
  (e: 'addFormOption', optionType: FormOptionType | SectionType, sectionIndex: number): void
  (e: 'copySection', sectionIndex: number): void
  (e: 'deleteSection', sectionIndex: number): void
  (e: 'moveUpSection', sectionIndex: number): void
  (e: 'moveDownSection', sectionIndex: number): void
  (e: 'copyOption', sectionIndex: number, optionIndex: number): void
  (e: 'deleteOption', sectionIndex: number, optionIndex: number): void
  (e: 'moveUpOption', sectionIndex: number, optionIndex: number): void
  (e: 'moveDownOption', sectionIndex: number, optionIndex: number): void
}>()

// フォームオプションの追加
const addFormOption = (optionType: FormOptionType | SectionType, sectionIndex: number) => {
  emit('addFormOption', optionType, sectionIndex)
}

// セクションのコピー
const copySection = () => {
  emit('copySection', props.sectionIndex)
}

// セクションの削除
const deleteSection = () => {
  emit('deleteSection', props.sectionIndex)
}

// セクションの上移動
const moveUpSection = () => {
  emit('moveUpSection', props.sectionIndex)
}

// セクションの下移動
const moveDownSection = () => {
  emit('moveDownSection', props.sectionIndex)
}

// オプションのコピー
const copyOption = (sectionIndex: number, optionIndex: number) => {
  emit('copyOption', sectionIndex, optionIndex)
}

// オプションの削除
const deleteOption = (sectionIndex: number, optionIndex: number) => {
  emit('deleteOption', sectionIndex, optionIndex)
}

// オプションの上移動
const moveUpOption = (sectionIndex: number, optionIndex: number) => {
  emit('moveUpOption', sectionIndex, optionIndex)
}

// オプションの下移動
const moveDownOption = (sectionIndex: number, optionIndex: number) => {
  emit('moveDownOption', sectionIndex, optionIndex)
}

// セクションの上限数管理
const maxSectionLimit = 50

// セクション番号のプリント
const printSectionIndex = computed(() => {
  return `${t('features.FormContents.new.input.sectionIndexPrefix')}${props.sectionIndex + 1}`
})
</script>

<template>
  <div class="section-container">
    <div class="section-container-header">
      <div class="section-container-header-row section-container-header-row--title">
        <!-- セクションN番 -->
        <div class="section-container--header-col section-title-label">
          <BodyText
            :text="printSectionIndex"
            typographyType="h3"
            color="theme-DEFAULT-surface-brand-primary-value"
            bodyStyle="TITLE_M"
          ></BodyText>
        </div>

        <div class="section-container-header-col">
          <!-- セクションコピーボタン -->
          <v-btn
            class="section-container-header-col--btn copy-btn text-primary"
            label="option"
            :v-show="true"
            valiant="plain"
            rounded="lg"
            tabindex="-1"
            :disabled="props.sectionLength >= maxSectionLimit"
            flat
            @click="copySection"
          >
            <v-icon class="section-container-header-col--icon" icon="content_copy" size="20" />
          </v-btn>
          <!-- セクション削除ボタン -->
          <v-btn
            class="section-container-header-col--btn delete-btn text-primary"
            label="option"
            :v-show="true"
            valiant="plain"
            rounded="lg"
            tabindex="-1"
            :disabled="false"
            flat
            @click="deleteSection"
          >
            <v-icon class="section-container-header-col--icon" icon="delete_outline" />
          </v-btn>
          <!-- セクション上移動ボタン -->
          <v-btn
            class="section-container-header-col--btn move-up-btn text-primary"
            label="option"
            :v-show="true"
            valiant="plain"
            rounded="lg"
            tabindex="-1"
            :disabled="props.sectionIndex === 0"
            flat
            @click="moveUpSection"
          >
            <v-icon class="section-container-header-col--icon" icon="arrow_upward" />
          </v-btn>
          <!-- セクション下移動ボタン -->
          <v-btn
            class="section-container-header-col--btn move-down-btn text-primary"
            label="option"
            :v-show="true"
            valiant="plain"
            rounded="lg"
            tabindex="-1"
            :disabled="props.sectionIndex >= props.sectionLength - 1"
            flat
            @click="moveDownSection"
          >
            <v-icon class="section-container-header-col--icon" icon="arrow_downward" />
          </v-btn>
        </div>
      </div>

      <!-- セクションの中のセクション名項目 -->
      <div class="section-container-row">
        <SectionFormOption
          :sectionIndex="props.sectionIndex"
          :section="section"
        ></SectionFormOption>
      </div>
    </div>

    <div class="section-container-body">
      <!-- 各種質問項目 -->
      <QuestionsContainer
        v-for="(option, optionIndex) in section.options"
        :key="option.id"
        :section-index="props.sectionIndex"
        :option-index="optionIndex"
        :option="option"
        :form="form"
        :option-length="section.options.length"
        @copy-option="copyOption"
        @delete-option="deleteOption"
        @move-up-option="moveUpOption"
        @move-down-option="moveDownOption"
      ></QuestionsContainer>

      <div class="">
        <!-- // 新規追加ボタン -->
        <MenuButton
          :menuButtons="menuButtons"
          :sectionIndex="props.sectionIndex"
          @click="addFormOption"
        ></MenuButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-container {
  padding: 16px 16px 0px 16px;
}

.section-container-header {
  width: 100%;
  margin-bottom: 6px; // セクション名入力が下に漏れるため
}

.section-container-header-row {
  width: 100%;
  &--title {
    height: 36px;
    display: flex;
    justify-content: space-between;
  }
  margin-bottom: 16px;
}

.section-container-header-col {
  &--title {
    padding: 6px 0px;
  }
  &--btn {
    min-width: 36px;
    padding: 0px;
  }
  &--icon {
    margin: 8px;
    font-size: 20px;
  }
}

.section-container-body {
  width: 100%;
}

.section-title-label {
  align-self: center;
}
</style>
