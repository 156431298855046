import { http, HttpResponse, type PathParams } from 'msw'

import { medicalInstitutions, medicalWorkers, patientDetailList, patientList } from './baseData'

import type {
  MiMedicalWorkerListResponse,
  MiMedicalWorkerResponse,
  MiPatientListResponse,
  MiPatientResponse,
  MwMedicalInstitutionListResponse
} from '@/api/generate/apiSchema'

const baseUrl = import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
const medicalInstitutionV1Url = `${baseUrl}/api/medical-institutions/:medicalInstitutionId/v1`
const medicalWorkerV1Url = `${baseUrl}/api/medical-worker/v1`

const extractMedicalInstitutionId = (params: PathParams): string => {
  const medicalInstitutionId = params['medicalInstitutionId']
  if (typeof medicalInstitutionId == 'string') {
    return medicalInstitutionId
  }

  throw 'expect only one medicalInstitutionId'
}

export const medicalInstitutionApi = [
  http.get(`${medicalInstitutionV1Url}/medical-workers`, ({ params }) => {
    const response: MiMedicalWorkerListResponse = {
      list: medicalWorkers[extractMedicalInstitutionId(params)].map((medicalWorker) => {
        return {
          id: medicalWorker.id,
          familyName: medicalWorker.familyName,
          givenName: medicalWorker.givenName,
          phoneticFamilyName: medicalWorker.phoneticFamilyName,
          phoneticGivenName: medicalWorker.phoneticGivenName,
          email: medicalWorker.email,
          roleUrn: medicalWorker.roleUrn,
          createdAt: medicalWorker.createdAt,
          updatedAt: medicalWorker.updatedAt
        }
      })
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalInstitutionV1Url}/medical-workers/:medicalWorkerId`, ({ params }) => {
    const extractMedicalWorkerId = (params: PathParams): string => {
      const medicalWorkerId = params['medicalWorkerId']
      if (typeof medicalWorkerId == 'string') {
        return medicalWorkerId
      }

      throw 'expect only one medicalWorkerId'
    }

    const medicalInstitutionId = extractMedicalInstitutionId(params)
    const medicalWorkerId = extractMedicalWorkerId(params)
    const medicalWorker = medicalWorkers[medicalInstitutionId].find(
      (mw) => mw.id === medicalWorkerId
    )

    if (!medicalWorker) {
      return new HttpResponse('Not found', {
        status: 404
      })
    }

    const response: MiMedicalWorkerResponse = {
      id: medicalWorker.id,
      familyName: medicalWorker.familyName,
      givenName: medicalWorker.givenName,
      phoneticFamilyName: medicalWorker.phoneticFamilyName,
      phoneticGivenName: medicalWorker.phoneticGivenName,
      email: medicalWorker.email,
      roleUrn: medicalWorker.roleUrn,
      createdAt: medicalWorker.createdAt,
      updatedAt: medicalWorker.updatedAt
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalWorkerV1Url}/medical-institutions`, () => {
    const response: MwMedicalInstitutionListResponse = {
      list: medicalInstitutions.map((medicalInstitution) => {
        return {
          id: medicalInstitution.id,
          name: medicalInstitution.name,
          medicalWorkerId: medicalWorkers[medicalInstitution.id][0].id,
          roleUrn: medicalWorkers[medicalInstitution.id][0].roleUrn,
          familyName: medicalWorkers[medicalInstitution.id][0].familyName,
          givenName: medicalWorkers[medicalInstitution.id][0].givenName,
          email: medicalWorkers[medicalInstitution.id][0].email
        }
      })
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalInstitutionV1Url}/patients`, () => {
    const response: MiPatientListResponse = {
      list: patientList.map((patientList) => {
        return {
          id: patientList.id,
          patientNo: patientList.patientNo,
          familyName: patientList.familyName,
          givenName: patientList.givenName,
          gender: patientList.gender,
          birthDate: patientList.birthDate,
          createdAt: patientList.createdAt,
          updatedAt: patientList.updatedAt,
          hospitalizationOrderStatus: patientList.hospitalizationOrderStatus
        }
      })
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalInstitutionV1Url}/patients/:patientId`, ({ params }) => {
    const patientId = params['patientId']
    const patient = patientDetailList.filter((_patient) => {
      return _patient.id === patientId
    })[0]
    const response: MiPatientResponse = {
      id: patient.id,
      patientNo: patient.patientNo,
      familyName: patient.familyName,
      givenName: patient.givenName,
      phoneticFamilyName: patient.phoneticFamilyName,
      phoneticGivenName: patient.phoneticGivenName,
      gender: patient.gender,
      birthDate: patient.birthDate,
      createdAt: patient.createdAt,
      updatedAt: patient.updatedAt
    }
    return HttpResponse.json(response)
  })
]
