<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import PatientRegistration from '@/features/PatientRegistration/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()

onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.patients.list'),
        href: '/patients',
        disable: false
      },
      {
        title: t('pages.patients.new'),
        disable: true
      }
    ]
  })
})
</script>

<template>
  <v-container class="container">
    <!-- 入力フォーム -->
    <PatientRegistration />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
