<script setup lang="ts">
import SearchUserName from './SearchUserName.vue'

const model = defineModel<string>({ required: true })
</script>

<template>
  <div class="hospitalization-order-list-search">
    <SearchUserName v-model="model" />
  </div>
</template>
