<script setup lang="ts">
import { usePatientsStore } from '@stores/patients'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MiPatientItem } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/ScrollSortTable/types'
import useChip from '@/composables/useChip'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'

defineProps<{
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiPatientItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const patientsStore = usePatientsStore()
const { getHospitalizationOrderStatus } = useChip()
const { getFormattedDate } = useDateFormatter()

const headers: Header[] = [
  {
    title: t('features.patient.List.header.patientNo'),
    key: 'patientNo',
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    title: t('features.patient.List.header.name'),
    key: 'name',
    width: 'auto',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    title: t('features.patient.List.header.gender'),
    key: 'gender',
    width: '60px',
    minWidth: '60px'
  },
  {
    title: t('features.patient.List.header.birthDate'),
    key: 'birthDate',
    width: '103px',
    minWidth: '103px'
  },
  {
    title: t('features.patient.List.header.hospitalizationOrderStatus'),
    key: 'hospitalizationOrderStatus',
    width: '110px',
    minWidth: '110px',
    align: 'center'
  }
]

const items = computed(() => {
  const patientList = patientsStore.getPatientList()
  return patientList.value.map((patient: MiPatientItem) => {
    const status = patient.hospitalizationOrderStatus
      ? getHospitalizationOrderStatus(patient.hospitalizationOrderStatus)
      : ''
    return {
      id: patient.id,
      patientNo: patient.patientNo,
      name: patient.familyName + ' ' + patient.givenName,
      gender: t(`attributes.gender.${patient.gender}`),
      birthDate: getFormattedDate(new Date(patient.birthDate)),
      hospitalizationOrderStatus: status
        ? {
            ...status,
            label: t(status.label)
          }
        : ''
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}

const transitionDetailPatient = (item: Item) => {
  router.push(`/patients/${item.id}`)
}
</script>

<template>
  <ScrollSortTable
    class="patient-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    @itemClick="transitionDetailPatient"
    :noDataText="t('attributes.noDataText')"
    @requestNextItem="$emit('requestNextItem', $event as MiPatientItem)"
    @update:sortBy="sortBy"
  />
</template>

<style lang="scss" scoped>
.patient-list-table {
  max-height: 100%;
}
</style>
