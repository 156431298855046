<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { SearchHospitalizationOrderStatusCode } from '../type'

import SimpleSelect from '@/components/Base/SimpleSelect/index.vue'

const { t } = useI18n()

const model = defineModel<string>()

const items: {
  title: string
  value: SearchHospitalizationOrderStatusCode
}[] = [
  {
    title: t('attributes.all'),
    value: 'all'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.preHospital'),
    value: 'pre_hospital'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.hospital'),
    value: 'hospital'
  },
  {
    title: t('attributes.hospitalizationOrderStatus.discharged'),
    value: 'discharged'
  }
]
</script>

<template>
  <div style="min-width: 120px">
    <SimpleSelect
      v-model="model"
      :label="t('features.hospitalizationOrders.List.search.hospitalizationOrderStatus')"
      :items="items"
      hide-details
    />
  </div>
</template>
