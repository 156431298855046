<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { type MiHospitalizationOrderItem } from '@/api/generate/apiSchema'
import {
  isDocumentSetStatusCode,
  isHospitalizationOrderStatusCode,
  isUseAppStatusCode,
  type Order
} from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/ScrollSortTable/types'
import useChip from '@/composables/useChip'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'
import { useHospitalizationOrdersStore } from '@/stores/hospitalizationOrders'

defineProps<{
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiHospitalizationOrderItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const hospitalizationOrdersStore = useHospitalizationOrdersStore()
const { getFormattedDate } = useDateFormatter()

const { getAppStatus, getHospitalizationOrderStatus, getDocumentSetStatus } = useChip()

const headers: Header[] = [
  {
    title: t('features.hospitalizationOrders.List.header.patientNo'),
    key: 'patientNo',
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    title: t('features.hospitalizationOrders.List.header.patientName'),
    key: 'name',
    width: 'auto',
    minWidth: '100px',
    maxWidth: '100px'
  },
  {
    title: t('features.hospitalizationOrders.List.header.gender'),
    key: 'gender',
    width: '60px',
    minWidth: '60px'
  },
  {
    title: t('features.hospitalizationOrders.List.header.hospitalizationDate'),
    key: 'hospitalizationDate',
    width: '103px',
    minWidth: '103px'
  },
  {
    title: t('features.hospitalizationOrders.List.header.useAppStatus'),
    key: 'useAppStatus',
    align: 'center',
    width: '100px',
    minWidth: '100px',
    sortable: false
  },
  {
    title: t('features.hospitalizationOrders.List.header.hospitalizationOrderStatus'),
    key: 'hospitalizationOrderStatus',
    align: 'center',
    width: '80px',
    minWidth: '80px',
    sortable: false
  },
  {
    title: t('features.hospitalizationOrders.List.header.documentSetStatus'),
    key: 'documentSetStatus',
    align: 'center',
    width: '100px',
    minWidth: '100px',
    sortable: false
  }
]

const items = computed(() => {
  const hospitalizationOrderList = hospitalizationOrdersStore.getHospitalizationOrderList()
  return hospitalizationOrderList.value.map((hospitalizationOrder) => {
    if (
      !isUseAppStatusCode(hospitalizationOrder.useAppStatus) ||
      !isHospitalizationOrderStatusCode(hospitalizationOrder.hospitalizationOrderStatus) ||
      !isDocumentSetStatusCode(hospitalizationOrder.documentSetStatus)
    ) {
      throw Error('unexpected hospitalizationOrderList')
    }

    const useAppStatus = getAppStatus(hospitalizationOrder.useAppStatus)
    const hospitalizationOrderStatus = getHospitalizationOrderStatus(
      hospitalizationOrder.hospitalizationOrderStatus
    )
    const documentSetStatus = getDocumentSetStatus(hospitalizationOrder.documentSetStatus)
    if (!(useAppStatus && hospitalizationOrderStatus && documentSetStatus)) {
      throw Error('unexpected hospitalizationOrderList')
    }

    return {
      id: hospitalizationOrder.id,
      patientNo: hospitalizationOrder.patientNo,
      name: `${hospitalizationOrder.familyName} ${hospitalizationOrder.givenName}`,
      gender: t(`attributes.gender.${hospitalizationOrder.gender}`),
      hospitalizationDate:
        typeof hospitalizationOrder.hospitalizationDate === 'string'
          ? getFormattedDate(new Date(hospitalizationOrder.hospitalizationDate))
          : '',
      useAppStatus: {
        ...useAppStatus,
        label: t(useAppStatus.label)
      },
      hospitalizationOrderStatus: {
        ...hospitalizationOrderStatus,
        label: t(hospitalizationOrderStatus.label)
      },
      documentSetStatus: {
        ...documentSetStatus,
        label: t(documentSetStatus.label)
      }
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}

const transitionDetailHospitalizationOrder = (item: Item) => {
  router.push(`/hospitalization-orders/${item.id}`)
}
</script>

<template>
  <ScrollSortTable
    class="hospitalization-order-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    @itemClick="transitionDetailHospitalizationOrder"
    :noDataText="t('attributes.noDataText')"
    @requestNextItem="$emit('requestNextItem', $event as MiHospitalizationOrderItem)"
    @update:sortBy="sortBy"
  />
</template>

<style lang="scss" scoped>
.hospitalization-order-list-table {
  max-height: 100%;
}
</style>
