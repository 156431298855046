import type { ConstantsType } from '@/composables/type'

/**
 * 共通定数
 * @returns CONSTANTS
 */
export const useConstants = () => {
  const CONSTANTS: ConstantsType = {
    /**
     * 医療機関ユーザーロール
     */
    ROLE: {
      /**
       * 閲覧
       */
      READ: 'urn:optim:digihos:medical_institution_read',
      /**
       * 一般
       */
      GENERAL: 'urn:optim:digihos:medical_institution_general',
      /**
       * 管理
       */
      ADMIN: 'urn:optim:digihos:medical_institution_admin'
    },

    /**
     * 性別
     */
    GENDER: {
      /**
       * 男性
       */
      MALE: 1,
      /**
       * 女性
       */
      FEMALE: 2
    },

    /**
     * コンテンツタイプ
     */
    CONTENT_TYPE: {
      /**
       * URL
       */
      URL: 'url',
      /**
       * 回答フォーム
       */
      FORM: 'form'
    },

    /**
     * 書類提出状況
     */
    CONTENT_STATUS_CODE: {
      NOT_SUBMITTED: 'not_submitted',
      SUBMITTED: 'submitted',
      CONFIRMED: 'confirmed'
    },

    /**
     * 入院状況
     */
    HOSPITALIZATION_ORDER_STATUS: {
      PRE_HOSPITAL: 'pre_hospital',
      HOSPITAL: 'hospital',
      DISCHARGED: 'discharged'
    }
  }
  return {
    CONSTANTS
  }
}
