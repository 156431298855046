<script setup lang="ts">
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

import CheckBoxFormOption from './CheckBoxFormOption.vue'
import ChoicesFormOption from './ChoicesFormOption.vue'
import DateFormOption from './DateFormOption.vue'
import InputSetFormOption from './InputSetFormOption.vue'
import QuantitativeFormOption from './QuantitativeFormOption.vue'
import TextFormOption from './TextFormOption.vue'
import { getOptionTypeName } from '../TemplateFormFunctions'

import BodyText from '@/components/Base/BodyText/index.vue'
import {
  FormOptionType,
  type FormContentInput,
  type FormOption
} from '@/features/FormContentForm/type'

const props = defineProps<{
  /**
   * セクションインデックス番号
   */
  sectionIndex: number
  /**
   * セクション要素数
   */
  optionLength: number
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォームオプション
   */
  option: FormOption
  /**
   * フォーム親オブジェクト
   */
  form: FormContentInput
}>()

const option = reactive(props.option)

const emit = defineEmits<{
  (e: 'copyOption', sectionIndex: number, optionIndex: number): void
  (e: 'deleteOption', sectionIndex: number, optionIndex: number): void
  (e: 'moveUpOption', sectionIndex: number, optionIndex: number): void
  (e: 'moveDownOption', sectionIndex: number, optionIndex: number): void
}>()

// オプションのコピー
const copyOption = () => {
  emit('copyOption', props.sectionIndex, props.optionIndex)
}

// オプションの削除
const deleteOption = () => {
  emit('deleteOption', props.sectionIndex, props.optionIndex)
}

// オプションの上移動
const moveUpOption = () => {
  emit('moveUpOption', props.sectionIndex, props.optionIndex)
}

// オプションの下移動
const moveDownOption = () => {
  emit('moveDownOption', props.sectionIndex, props.optionIndex)
}

// オプションの上限数管理
const maxOptionLimit = 100

// 回答番号のプリント
const printQuestionNumber = computed(() => {
  const optionTypeName = t(getOptionTypeName(option.type))
  const typeNameWithPrefix = optionTypeName ? ` - ${optionTypeName}` : ''
  return `${t('features.FormContents.new.input.questionIndexPrefix')}${props.optionIndex + 1}${typeNameWithPrefix}`
})
</script>

<template>
  <div class="question-container">
    <div class="question-container-header">
      <div class="question-container-header-col">
        <!-- 回答N番 -->
        <body-text
          class="question-container-header-col--title"
          :text="printQuestionNumber"
          typography-type="h4"
          color="theme-DEFAULT-surface-brand-primary-value"
          body-style="TITLE_M"
        ></body-text>
      </div>
      <div class="question-container-header-col">
        <!-- コピーボタン -->
        <v-btn
          class="question-container-header-col--btn copy-btn text-primary"
          label="option"
          text=""
          :v-show="true"
          valiant="plain"
          rounded="lg"
          tabindex="-1"
          :disabled="props.optionLength >= maxOptionLimit"
          flat
          @click="copyOption"
        >
          <v-icon class="question-container-header-col--icon" icon="content_copy" />
        </v-btn>
        <!-- 削除ボタン -->
        <v-btn
          class="question-container-header-col--btn delete-btn text-primary"
          label="option"
          text=""
          :v-show="true"
          valiant="plain"
          rounded="lg"
          tabindex="-1"
          :disabled="false"
          flat
          @click="deleteOption"
        >
          <v-icon class="question-container-header-col--icon" icon="delete_outline" />
        </v-btn>
        <!-- 上移動 -->
        <v-btn
          class="question-container-header-col--btn move-up-btn text-primary"
          label="option"
          text=""
          :v-show="true"
          valiant="plain"
          rounded="lg"
          tabindex="-1"
          :disabled="props.optionIndex === 0"
          flat
          @click="moveUpOption"
        >
          <v-icon class="question-container-header-col--icon" icon="arrow_upward" />
        </v-btn>
        <!-- 下移動 -->
        <v-btn
          class="question-container-header-col--btn move-down-btn text-primary"
          label="option"
          text=""
          :v-show="true"
          valiant="plain"
          rounded="lg"
          tabindex="-1"
          :disabled="props.optionIndex >= optionLength - 1"
          flat
          @click="moveDownOption"
        >
          <v-icon class="question-container-header-col--icon" icon="arrow_downward" />
        </v-btn>
      </div>
    </div>
    <div>
      <!-- 各種質問項目 -->
      <!-- < text:テキスト | choice:選択肢 | check_box:チェックボックス | quantitative:数値 | date:日付 | input_set:入力セット > -->
      <TextFormOption
        v-if="FormOptionType.Text === option.type"
        :option-index="props.optionIndex"
        :option="option"
      ></TextFormOption>
      <ChoicesFormOption
        v-if="FormOptionType.Choice === option.type"
        :section-index="props.sectionIndex"
        :option-index="props.optionIndex"
        :form="form"
        :option="option"
      ></ChoicesFormOption>
      <CheckBoxFormOption
        v-if="FormOptionType.CheckBox === option.type"
        :option-index="props.optionIndex"
        :option="option"
      ></CheckBoxFormOption>
      <QuantitativeFormOption
        v-if="FormOptionType.Quantitative === option.type"
        :option-index="props.optionIndex"
        :option="option"
      ></QuantitativeFormOption>
      <DateFormOption
        v-if="FormOptionType.Date === option.type"
        :option-index="props.optionIndex"
        :option="option"
      ></DateFormOption>
      <InputSetFormOption
        v-if="FormOptionType.InputSet === option.type"
        :section-index="props.sectionIndex"
        :option-index="props.optionIndex"
        :form="form"
        :option="option"
      ></InputSetFormOption>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.question-container {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border: 2px solid rgb(var(--v-theme-primary)) !important;
  border-radius: 16px;
}
.question-container-header {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.question-container-header-col {
  &--title {
    padding: 6px 0px;
  }
  &--btn {
    min-width: 36px;
    padding: 0px;
  }
  &--icon {
    margin: 8px;
    font-size: 20px;
  }
}
:deep(.v-btn:disabled) {
  color: rgb(var(--surface-surface-transparent)) !important;
  opacity: var(--v-disabled-opacity) !important;
}
:deep(.v-btn:disabled .v-btn__overlay) {
  display: none;
}
</style>
