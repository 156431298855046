<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import BasePropertyField from '@components/Base/PropertyField/index.vue'
import { toRef, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { PatientDetailInput } from './type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import useBirthDate from '@/composables/useBirthDate'
import useFormattedName from '@/composables/useFormattedName'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { getFormattedBirthDate } = useBirthDate()
const { getFormattedNameAndPhoneticName } = useFormattedName()

const { t } = useI18n()

type Props = {
  patientDetail: PatientDetailInput
  patientId: string
}
const props = defineProps<Props>()

// 氏名を表示フォーマットに変更
const formattedName = computed(() => {
  return getFormattedNameAndPhoneticName(
    props.patientDetail.familyName,
    props.patientDetail.givenName,
    props.patientDetail.phoneticFamilyName,
    props.patientDetail.phoneticGivenName
  )
})

// 生年月日を表示フォーマットに変更
const formattedBirthDate = getFormattedBirthDate(props.patientDetail.birthDate)

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 患者一覧画面へ遷移
const transitionList = () => {
  router.push('/patients')
}

// 患者編集画面へ遷移
const transitionEdit = () => {
  router.push(`/patients/${props.patientId}/edit`)
}

// 患者ユーザー削除実行
const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    // 患者削除処理実行
    await medicalInstitutionsApiClient().api.destroyPatientByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.patientId,
      { optimId: '' }
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.patients.list') })
    )
    done()
    transitionList()
  } catch (error: unknown) {
    failure()
    if (axiosErrorGuard.isBadRequest(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.patients.list') })
      )
      return
    }
    throw error
  }
}

// モーダル開閉管理
const isOpenDeleteDialog = toRef<boolean>(false)

// 削除確認ダイアログを開く
const openDeleteConfirmDialog = () => {
  isOpenDeleteDialog.value = true
}
</script>

<template>
  <div class="patient-detail">
    <div class="patient-detail-header" v-if="medicalInstitutionsStore.isGeneral()">
      <BaseButton
        v-if="!patientDetail.isSynchronized"
        type="icon-link"
        variant="outlined"
        :content="t('attributes.edit')"
        icon="mode_edit_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="transitionEdit()"
      >
      </BaseButton>
      <BaseButton
        type="icon-link"
        variant="outlined"
        :content="t('attributes.delete')"
        icon="delete_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="openDeleteConfirmDialog"
      >
      </BaseButton>
    </div>

    <div class="patient-detail-body rounded-lg">
      <div class="row">
        <BasePropertyField
          :key-content="t('features.patient.PatientDetail.item.patientNo')"
          :value-content="props.patientDetail.patientNo"
          :value-max-width="200"
          :value-min-width="200"
          class="property-margin-right"
        />
        <BasePropertyField
          :key-content="t('features.patient.PatientDetail.item.name')"
          :value-content="formattedName"
          :value-max-width="600"
          :value-min-width="600"
          class="property-margin-right"
        />
      </div>
      <div class="row">
        <BasePropertyField
          :key-content="t('features.patient.PatientDetail.item.birthDate')"
          :value-content="formattedBirthDate"
          :value-max-width="200"
          :value-min-width="200"
          class="property-margin-right"
        />
        <BasePropertyField
          :key-content="t('features.patient.PatientDetail.item.gender')"
          :value-content="t(`attributes.gender.${props.patientDetail.gender}`)"
          :value-max-width="100"
          :value-min-width="100"
          class="property-margin-right"
        />
      </div>
    </div>

    <!-- 削除確認ダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenDeleteDialog"
      :title="t('attributes.deleteDialog.title', { resource: t('pages.patients.list') })"
      :content="t('attributes.deleteDialog.message', { resource: t('pages.patients.list') })"
      :cancelBtnText="t('attributes.cancel')"
      :confirmBtnText="t('attributes.delete')"
      :confirmBtnColor="'object-caution'"
      :size="'medium'"
      :confirmBtnVisible="true"
      @input="isOpenDeleteDialog = $event"
      @confirm="confirmDestroy"
    />
  </div>
</template>

<style lang="scss" scoped>
.patient-detail-header {
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.patient-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 616px;
  padding: 16px;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-wrap: break-word;
}

.property-margin-right {
  margin-right: 2px;
}
</style>
