<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { MiContentResponse } from '@/api/generate/apiSchema'
import type { FormContentInput } from '@/features/FormContentForm/type'
import type { UrlContentInput } from '@/features/UrlContentEdit/UrlContentEditForm/type'

import { documentManagementApiClient } from '@/api/apiClient'
import { useConstants } from '@/composables/useConstants'
import FormContentEdit from '@/features/FormContentEdit/index.vue'
import UrlContentEdit from '@/features/UrlContentEdit/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const CONTENT_TYPE = useConstants().CONSTANTS.CONTENT_TYPE
const displayNameStore = useDisplayNameStore()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const route = useRoute()
const contentId = route.params.contentId as string
const urlContentInput = ref<UrlContentInput>()
const formContentInput = ref<FormContentInput>()

onMounted(async () => {
  await fetchContent()
})

const fetchContent = async () => {
  const response = await documentManagementApiClient().api.contentByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    contentId,
    { optimId: '' }
  )

  // displayNameとパンくずリストと初期値
  switch (response.data.contentType) {
    case CONTENT_TYPE.FORM:
      fetchFormContent(response.data)
      displayNameStore.updateDisplayName({
        breadcrumbs: [
          {
            title: t('pages.documentSets.documentSets'),
            href: '/document-sets',
            disable: false
          },
          {
            title: t('pages.documentSets.contents'),
            href: '/contents',
            disable: false
          },
          {
            title: response.data.title,
            href: `/contents/${contentId}`,
            disable: false
          },
          {
            title: t('pages.documentSets.update.form'),
            disable: true
          }
        ]
      })
      break
    case CONTENT_TYPE.URL:
      fetchUrlContent(response.data)
      displayNameStore.updateDisplayName({
        breadcrumbs: [
          {
            title: t('pages.documentSets.documentSets'),
            href: '/document-sets',
            disable: false
          },
          {
            title: t('pages.documentSets.contents'),
            href: '/contents',
            disable: false
          },
          {
            title: response.data.title,
            href: `/contents/${contentId}`,
            disable: false
          },
          {
            title: t('pages.documentSets.update.url'),
            disable: true
          }
        ]
      })
      break
    default:
      throw new Error('Invalid contentType')
  }
}

const fetchFormContent = async (content: MiContentResponse) => {
  const revision = (
    await documentManagementApiClient().api.latestFormContentRevisionByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      contentId,
      { optimId: '' }
    )
  ).data
  formContentInput.value = {
    title: content.title,
    description: content.description,
    contentJson: JSON.parse(revision.contentJson),
    tag: content.tag
  }
}

const fetchUrlContent = async (content: MiContentResponse) => {
  const revision = (
    await documentManagementApiClient().api.latestUrlContentRevisionByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      contentId,
      { optimId: '' }
    )
  ).data
  urlContentInput.value = {
    title: content.title,
    description: content.description,
    url: revision.url,
    tag: content.tag
  }
}
</script>

<template>
  <v-container class="container">
    <FormContentEdit
      v-if="formContentInput != undefined"
      :content-id="contentId"
      :contentInput="formContentInput"
    />
    <UrlContentEdit
      v-if="urlContentInput != undefined"
      :content-id="contentId"
      :contentInput="urlContentInput"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
