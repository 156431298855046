<script setup lang="ts">
import { toRef, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useSnackbarStore } from '../../stores/snackbar'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiUpdatePatientRequest } from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import PatientEditForm from '@/features/PatientEdit/PatientEditForm/index.vue'
import { type PatientEditFormInput } from '@/features/PatientEdit/type'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

type Props = {
  patientId: string
  form: PatientEditFormInput
}

const isLoading = ref<boolean>(false)

const props = defineProps<Props>()
const patientId = props.patientId
const form = reactive({ ...props.form })

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 患者ユーザー編集
const edit = async () => {
  isLoading.value = true
  if (form.gender === undefined) {
    isLoading.value = false
    toggleValidationDialog()
    return
  }

  // リクエスト情報を作成
  const data: MiUpdatePatientRequest = {
    patientNo: form.patientNo,
    familyName: form.familyName,
    givenName: form.givenName,
    phoneticFamilyName: form.phoneticFamilyName,
    phoneticGivenName: form.phoneticGivenName,
    gender: form.gender,
    birthDate: form.birthDate
  }

  try {
    await medicalInstitutionsApiClient().api.updatePatientByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      patientId,
      { optimId: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', { resource: t('pages.patients.list') })
    )
    transitionDetail()
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// 患者詳細画面へ遷移
const transitionDetail = () => {
  router.push(`/patients/${patientId}`)
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- 入力フォーム -->
  <PatientEditForm
    v-model:patientNo="form.patientNo"
    v-model:familyName="form.familyName"
    v-model:givenName="form.givenName"
    v-model:phoneticFamilyName="form.phoneticFamilyName"
    v-model:phoneticGivenName="form.phoneticGivenName"
    v-model:gender="form.gender"
    v-model:birthDate="form.birthDate"
    :disabled="isLoading"
    @submit="edit()"
    @cancel="transitionDetail()"
  />

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog()"
  />
</template>

<style></style>
