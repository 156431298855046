// APIに関連するEnum系のみ本ファイルに定義する

export const UseAppStatusCode = ['used', 'not_used'] as const
export type UseAppStatusCode = (typeof UseAppStatusCode)[number]
export const isUseAppStatusCode = (statusCode: string): statusCode is UseAppStatusCode => {
  return UseAppStatusCode.some((value) => value === statusCode)
}

export const HospitalizationOrderStatusCode = ['pre_hospital', 'hospital', 'discharged'] as const
export type HospitalizationOrderStatusCode = (typeof HospitalizationOrderStatusCode)[number]
export const isHospitalizationOrderStatusCode = (
  statusCode: string
): statusCode is HospitalizationOrderStatusCode => {
  return HospitalizationOrderStatusCode.some((value) => value === statusCode)
}

export const DocumentSetStatusCode = [
  'not_sent',
  'not_submitted',
  'partial_submitted',
  'submitted',
  'confirmed'
] as const
export type DocumentSetStatusCode = (typeof DocumentSetStatusCode)[number]
export const isDocumentSetStatusCode = (
  statusCode: string
): statusCode is DocumentSetStatusCode => {
  return DocumentSetStatusCode.some((value) => value === statusCode)
}

export const SentDocumentSetContentStatusCode = ['not_submitted', 'submitted', 'confirmed'] as const
export type SentDocumentSetContentStatusCode = (typeof SentDocumentSetContentStatusCode)[number]
export const isSentDocumentSetContentStatusCode = (
  statusCode: string
): statusCode is SentDocumentSetContentStatusCode => {
  return SentDocumentSetContentStatusCode.some((value) => value === statusCode)
}

export const DocumentSetContentType = ['url', 'form'] as const
export type DocumentSetContentType = (typeof DocumentSetContentType)[number]
export const isDocumentSetContentType = (
  contentType: string
): contentType is DocumentSetContentType => {
  return DocumentSetContentType.some((value) => value == contentType)
}

export type Order = 'ASC' | 'DESC'
