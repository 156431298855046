import { isAxiosError } from 'axios'

import { Api } from '@/api/generate/apiSchema'
import router from '@/router'

export type ErrorOption = {
  allowNotFound: boolean
}

// APIクライアントの基本設定
const baseApiClient = () => {
  return new Api({
    // セッションタイムアウト時間
    timeout: import.meta.env.VITE_API_REQUEST_TIMEOUT,
    // ヘッダー情報
    headers: {
      'Content-Type': 'application/json'
    },
    // cookieをHTTPヘッダに付与
    withCredentials: true
  })
}

const noHandlingBaseApiClient = new Api({
  // セッションタイムアウト時間
  timeout: import.meta.env.VITE_API_REQUEST_TIMEOUT,
  // ヘッダー情報
  headers: {
    'Content-Type': 'application/json'
  },
  // cookieをHTTPヘッダに付与
  withCredentials: true
})

const instanceInterceptors = (
  Client: Api<unknown>,
  errorOption: ErrorOption | undefined = undefined
) => {
  Client.instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          // 認証の失敗
          case 401:
            // ログイン処理へ遷移
            if (error.response.data.error_code === 'CM0002') {
              if (
                error.config!.baseURL === import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
              ) {
                // 医療機関APIのログイン状態を元にログイン画面を表示する
                window.location.href = `${error.config!.baseURL}/gateway/login?isAutoLogin=false`
              } else {
                window.location.href = `${error.config!.baseURL}/gateway/login?isAutoLogin=true`
              }
              return
            }

            break
          // 認可の失敗
          case 403:
            router.push('/forbidden')
            return
          // 存在しない
          case 404:
            if (!errorOption?.allowNotFound) {
              router.push('/not_found')
            }
            throw error
          case 500:
          case 501:
          case 502:
          case 503:
          case 504:
          case 505:
          case 506:
          case 507:
          case 508:
          case 509:
          case 510:
          case 511:
            router.push('/server_error')
            return
          default:
        }
      }
      throw error
    }
  )
}

// 医療機関API用クライアント
export const medicalInstitutionsApiClient = (
  errorOption: ErrorOption | undefined = undefined,
  noHandling: boolean = false
) => {
  if (noHandling) {
    noHandlingBaseApiClient.instance.defaults.baseURL =
      import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
    return noHandlingBaseApiClient
  }
  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_MEDICAL_INSTITUTION_API_BASE_URL
  instanceInterceptors(apiClient, errorOption)
  return apiClient
}

// 書類管理API用クライアント
export const documentManagementApiClient = (
  errorOption: ErrorOption | undefined = undefined,
  noHandling: boolean = false
) => {
  if (noHandling) {
    noHandlingBaseApiClient.instance.defaults.baseURL =
      import.meta.env.VITE_API_DOCUMENT_MANAGEMENT_API_BASE_URL
    return noHandlingBaseApiClient
  }

  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_DOCUMENT_MANAGEMENT_API_BASE_URL
  instanceInterceptors(apiClient, errorOption)
  return apiClient
}

// 入院オーダーAPI用クライアント
export const hospitalizationOrderApiClient = (
  errorOption: ErrorOption | undefined = undefined,
  noHandling: boolean = false
) => {
  if (noHandling) {
    noHandlingBaseApiClient.instance.defaults.baseURL =
      import.meta.env.VITE_API_HOSPITALIZATION_API_BASE_URL
    return noHandlingBaseApiClient
  }

  const apiClient = baseApiClient()
  apiClient.instance.defaults.baseURL = import.meta.env.VITE_API_HOSPITALIZATION_API_BASE_URL
  instanceInterceptors(apiClient, errorOption)
  return apiClient
}
