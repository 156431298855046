<script setup lang="ts">
import { ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Item } from '@/components/Table/ScrollSortTable/types'
import type {
  HospitalizationOrderItem,
  PatientDetailItem
} from '@/features/HospitalizationOrderRegistration/type'

import BaseButton from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import PropertyField from '@/components/Base/PropertyField/index.vue'
import PatientDialog from '@/features/HospitalizationOrderRegistration/HospitalizationOrderRegistrationForm/PatientDialog/index.vue'

const { t } = useI18n()

const hospitalizationOrder = defineModel<HospitalizationOrderItem>('hospitalizationOrder', {
  required: true
})
// 選択された患者情報の表示
const patient = defineModel<PatientDetailItem>('patient', { required: true })
const patientName = toRef<string>(t('features.hospitalizationOrders.registration.patient.name'))
const gender = toRef<string>(t('features.hospitalizationOrders.registration.patient.gender'))

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

const submit = () => {
  emit('submit')
}

const cancel = () => {
  emit('cancel')
}
const isLoading = ref(false)

const itemClick = (item: Item) => {
  toggleDialog()
  patient.value.id = item.id

  if (
    typeof item.patientNo != 'string' ||
    typeof item.name != 'string' ||
    typeof item.birthDate != 'string' ||
    typeof item.gender != 'string'
  ) {
    throw Error('typed error')
  }
  patient.value.patientNo = item.patientNo
  patientName.value = item.name
  patient.value.birthDate = item.birthDate
  gender.value = item.gender
}

const isOpenDialog = toRef<boolean>(false)

const toggleDialog = () => {
  isOpenDialog.value = !isOpenDialog.value
}
</script>
<template>
  <!-- 入力フォーム -->
  <v-form>
    <div class="hospitalization-register rounded-lg">
      <div class="paragraph-text-field">
        <div>
          <Label
            id="hospitalizationDate"
            class="hospitalization-label"
            :labelText="t('features.hospitalizationOrders.registration.hospitalizationDate')"
            direction="left"
            tokenType="TITLE_L"
          ></Label>
          <v-text-field
            class="mt-1"
            type="date"
            v-model="hospitalizationOrder.hospitalizationDate"
            :rules="[]"
            hide-details
          ></v-text-field>
        </div>

        <div>
          <Label
            id="dischargeDate"
            :labelText="t('features.hospitalizationOrders.registration.dischargeDate')"
            direction="left"
            tokenType="TITLE_L"
          ></Label>
          <v-text-field
            class="mt-1"
            type="date"
            v-model="hospitalizationOrder.dischargeDate"
            :rules="[]"
            hide-details
          ></v-text-field>
        </div>
      </div>

      <div class="paragraph-text-field">
        <div>
          <Label
            id="department"
            :labelText="t('features.hospitalizationOrders.registration.department')"
            direction="left"
            tokenType="TITLE_L"
          ></Label>
          <v-text-field
            class="mt-1"
            v-model="hospitalizationOrder.hospitalDepartment"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="t('features.hospitalizationOrders.registration.department')"
            persistent-hint
            counter="100"
          ></v-text-field>
        </div>
        <div>
          <Label
            id="ward"
            :labelText="t('features.hospitalizationOrders.registration.ward')"
            direction="left"
            tokenType="TITLE_L"
          ></Label>
          <v-text-field
            class="mt-1"
            v-model="hospitalizationOrder.ward"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="t('features.hospitalizationOrders.registration.ward')"
            persistent-hint
            counter="100"
          ></v-text-field>
        </div>
      </div>

      <div>
        <Label
          id="room"
          :labelText="t('features.hospitalizationOrders.registration.room')"
          direction="left"
          tokenType="TITLE_L"
        ></Label>
        <v-text-field
          class="mt-1"
          v-model="hospitalizationOrder.hospitalRoom"
          :hint="t('validations.maxLength', { max: 100 })"
          :placeholder="t('features.hospitalizationOrders.registration.room')"
          persistent-hint
          counter="100"
        ></v-text-field>
      </div>

      <div>
        <div class="patient-select">
          <Label
            id="patientInfo"
            :labelText="t('features.hospitalizationOrders.registration.patient.information')"
            :isRequired="true"
            direction="left"
            tokenType="TITLE_L"
          ></Label>
          <BaseButton
            class="ml-4"
            type="icon-link"
            color="primary"
            :content="t('features.hospitalizationOrders.registration.button.select')"
            size="small"
            tokenType="LABEL_M"
            elevated="text"
            icon="add"
            @click="toggleDialog"
          ></BaseButton>
        </div>
        <div class="mt-1 patient-selected-fields">
          <div class="patient-selected-field-row rounded-lg">
            <PropertyField
              :keyContent="t('features.hospitalizationOrders.registration.patient.number')"
              :valueContent="patient.patientNo"
              class="patient-field"
              :placeholder="t('features.hospitalizationOrders.registration.patient.number')"
            />
            <PropertyField
              :keyContent="t('features.hospitalizationOrders.registration.patient.name')"
              :valueContent="patientName"
              class="patient-field"
              :placeholder="t('features.hospitalizationOrders.registration.patient.name')"
            />
          </div>
          <div class="patient-selected-field-row rounded-lg">
            <PropertyField
              :keyContent="t('features.hospitalizationOrders.registration.patient.birthDate')"
              :valueContent="patient.birthDate"
              class="patient-field"
              :placeholder="t('features.hospitalizationOrders.registration.patient.birthDate')"
            />
            <PropertyField
              :keyContent="t('features.hospitalizationOrders.registration.patient.gender')"
              :valueContent="gender"
              class="patient-field"
              :placeholder="t('features.hospitalizationOrders.registration.patient.gender')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-buttons">
      <BaseButton
        type="button"
        :disabled="isLoading"
        :loading="isLoading"
        :content="t('attributes.cancel')"
        variant="outlined"
        min-width="8rem"
        @click="cancel"
      />
      <BaseButton
        type="button"
        color="primary"
        :disabled="isLoading"
        :loading="isLoading"
        :content="t('attributes.register')"
        min-width="8rem"
        @click="submit"
      />
    </div>
  </v-form>

  <!-- 患者情報選択ダイアログ -->
  <PatientDialog :value="isOpenDialog" @input="toggleDialog" @itemClick="itemClick" />
</template>

<style lang="scss" scoped>
.hospitalization-register {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
}

.paragraph-text-field {
  display: flex;
  gap: 8px;
}

.paragraph-text-field > * {
  flex: 1 1;
}

.patient-select {
  display: flex;
  align-items: center;
}

.patient-selected-field-row {
  display: flex;
  gap: 2px;
}

.patient-selected-field-row > * {
  flex: 1 1;
}

.patient-field {
  max-width: calc(50% - 1px);
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.patient-selected-fields {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
