import AsyncLock from 'async-lock'
import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { documentManagementApiClient } from '@/api/apiClient'
import { type MiDocumentSetListResponse } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'

const limit = 50

interface SearchConditions {
  medicalInstitutionId?: string
  title?: string
  orderBy?: string
  order?: string
}

export const useDocumentSetsStore = defineStore('documentSets', () => {
  const lock = new AsyncLock({ timeout: 2000 })
  const searchConditions = ref<SearchConditions>({})
  const currentProcess = ref<string | undefined>()
  const searchedLastDocumentSetId = ref<string | undefined>()
  const documentSetList = ref<MiDocumentSetListResponse['list']>([])
  const getDocumentSetList = () => documentSetList

  /**
   * 検索結果を保存する
   * 結果は排他制御し、全て同一の呼び出しで更新されることを保証する
   */
  const setSearchResult = async (
    medicalInstitutionId: string,
    _searchedLastDocumentSetId: string | undefined,
    _documentSetList: MiDocumentSetListResponse['list'],
    title: string | undefined,
    orderBy: string | undefined,
    order: string | undefined
  ) => {
    await lock.acquire('setSearchResult', () => {
      searchedLastDocumentSetId.value = _searchedLastDocumentSetId
      documentSetList.value = _documentSetList
      searchConditions.value = {
        medicalInstitutionId,
        title,
        orderBy,
        order
      }
    })
  }

  const fetchDocumentSetList = async (
    medicalInstitutionId: string,
    title?: string,
    orderBy?: string,
    order?: string
  ): Promise<FetchApiResponse<MiDocumentSetListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    try {
      const response = await documentManagementApiClient().api.documentSetsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          title,
          limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(medicalInstitutionId, undefined, response.data.list, title, orderBy, order)

      return {
        success: true,
        content: documentSetList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const fetchNextDocumentSetList = async (
    medicalInstitutionId: string,
    lastDocumentSetId: string,
    title: string = '',
    orderBy?: string,
    order?: Order
  ): Promise<FetchApiResponse<MiDocumentSetListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    // 重複した検索
    if (searchedLastDocumentSetId.value == lastDocumentSetId) {
      return {
        success: false,
        status: 400
      }
    }
    // 検索キーが一致するか確認
    if (
      searchConditions.value.medicalInstitutionId != medicalInstitutionId ||
      searchConditions.value.title != title ||
      searchConditions.value.orderBy != orderBy ||
      searchConditions.value.order != order
    ) {
      // 一致しない場合は、追加検索ではなく初期化を行う
      return fetchDocumentSetList(medicalInstitutionId, title, orderBy, order)
    }

    // 取得処理
    try {
      const response = await documentManagementApiClient().api.documentSetsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          lastDocumentSetId,
          title,
          limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        lastDocumentSetId,
        [...documentSetList.value, ...response.data.list],
        title,
        orderBy,
        order
      )

      return {
        success: true,
        content: documentSetList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const clearDocumentSetList = () => {
    documentSetList.value = []
    searchedLastDocumentSetId.value = undefined
    searchConditions.value = {}
    currentProcess.value = undefined
  }

  return {
    getDocumentSetList,
    fetchDocumentSetList,
    fetchNextDocumentSetList,
    clearDocumentSetList
  }
})
