<script setup lang="ts">
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

import DEFAULT_USER_PROF_IMG from '@/assets/icons/user-prof.svg'

type Props = {
  /**
   * ユーザー名
   */
  name: string
  /**
   * メールアドレス
   */
  email: string
  /**
   * 会社名
   */
  corporationName: string
  /**
   * ログアウト処理
   */
  logout?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  email: '',
  corporationName: '',
  logout: undefined
})

const { name, email, corporationName } = toRefs(props)

const { t } = useI18n()
</script>

<template>
  <v-menu transition="slide-x-reverse-transition" :close-on-content-click="false" location="bottom">
    <template #activator="{ props: namedSlotProps }">
      <v-btn variant="text" icon v-bind="namedSlotProps">
        <v-avatar :size="48">
          <v-img :src="DEFAULT_USER_PROF_IMG" />
        </v-avatar>
      </v-btn>
    </template>
    <v-card elevation="10" class="mx-auto" max-width="400" variant="outlined">
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-title>{{ name }}</v-list-item-title>
          <v-list-item-subtitle class="mt-1">
            {{ email }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-divider />
        <v-list-item>{{ corporationName }}</v-list-item>
        <v-list-item @click="logout">
          <span> {{ t('attributes.logout') }}</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
