// エラーメッセージを指定する

export const errors = {
  hospitalization: {
    documentSetContentConfirmError: '書類の確定処理に失敗しました',
    documentSetContentUpdateError:
      '書類の保存に失敗しました。\n入力内容に誤りがないかご確認ください。'
  },
  sentDocumentSetContent: {
    editUpdateError: '編集の保存に失敗しました。\n入力内容に誤りがないかご確認ください。'
  }
}
