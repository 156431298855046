<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import HospitalizationOrderList from '@/features/HospitalizationOrderList/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()

displayNameStore.updateDisplayName({
  breadcrumbs: [
    {
      title: t('pages.hospitalizationOrders.list'),
      disable: true
    }
  ]
})
</script>

<template>
  <v-container class="container">
    <HospitalizationOrderList />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 64px);
  padding: 0px 16px 16px 16px;
}
</style>
