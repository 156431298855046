<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import type { TextOption } from '@/features/FormContentForm/type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォームオプション
   */
  option: TextOption
}>()

const option = reactive(props.option)

// 複数行用のテキストフィールド
const isMultiLine = computed<boolean>(() => {
  return option.isMultiLine
})

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}

// スタイル用
const bodyStyle = computed(() => getTypography('BODY_S'))
</script>

<template>
  <div class="text-form-option">
    <div class="content">
      <div>
        <!-- 項目名 -->
        <v-text-field
          v-model="option.name"
          class="required-field"
          hide-details="auto"
          counter="100"
          :placeholder="t('features.FormContents.new.placeholder.fieldName')"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
          :rules="[required(option.name, t('features.FormContents.new.placeholder.fieldName'))]"
        ></v-text-field>
      </div>

      <!-- 複数行 or 一行 -->
      <div v-if="isMultiLine">
        <!-- 複数行入力 -->
        <v-textarea
          v-model="option.description"
          :placeholder="t('features.FormContents.new.placeholder.description')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
        ></v-textarea>
      </div>
      <div v-else>
        <!-- 一行入力 -->
        <v-text-field
          v-model="option.description"
          :placeholder="t('features.FormContents.new.placeholder.description')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
        ></v-text-field>
      </div>
    </div>
    <v-divider class="divider"></v-divider>
    <div class="bottom">
      <!-- 複数行入力スイッチ -->
      <v-switch
        v-model="option.isMultiLine"
        class="switch"
        :style="bodyStyle"
        :label="t('features.FormContents.new.textForm.multiLineAnswer')"
        color="primary"
        baseColor="theme-DEFAULT-surface-tertiary-value"
        inset
        hide-details
        flat
      />
      <!-- 回答必須スイッチ -->
      <v-switch
        v-model="option.isRequired"
        class="switch"
        :style="bodyStyle"
        :label="t('features.FormContents.new.input.required')"
        color="primary"
        baseColor="theme-DEFAULT-surface-tertiary-value"
        hide-details
        inset
        flat
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* #app > div > div > main > div > form > div > form > div.v-sheet.v-theme--digihosTheme.rounded-lg.mb-8.py-4.px-3 > div > div.v-row.justify-start.px-4 > div.v-container.v-locale--is-ltr.container-outline.pb-0 > div:nth-child(2) > div > div > div.v-input__control > div > div.v-field__outline */
:deep(.required-field :before) {
  border-width: 0 0 2px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.bottom {
  display: flex;
  justify-content: end;
  gap: 16px;
  height: 36px;
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-input--density-default {
  --v-input-control-height: 36px;
}
</style>

<style lang="scss">
.text-form-option {
  .bottom {
    .switch {
      .v-selection-control--density-default {
        --v-selection-control-size: 36px !important;
      }
    }
  }
}
</style>
