<script setup lang="ts">
import Button from '@components/Base/Button/index.vue'
import DocumentSetListSearch from '@features/DocumentSetList/DocumentSetListSearch/index.vue'
import DocumentSetListTable from '@features/DocumentSetList/DocumentSetListTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiDocumentSetItem } from '@/api/generate/apiSchema'

import { type Order } from '@/api/types'
import { getToken } from '@/components/Typography/token'
import router from '@/router'
import { useDocumentSetsStore } from '@/stores/documentSets'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const documentSetsStore = useDocumentSetsStore()

const isLoading = ref(true)
const searchDocumentSetTitle = ref<string>('')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const documentSetListTable = ref<InstanceType<typeof DocumentSetListTable>>()
const resetScroll = () => {
  if (documentSetListTable.value == undefined) {
    return
  }

  documentSetListTable.value.resetScroll()
}

watch([searchDocumentSetTitle, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  documentSetsStore
    .fetchDocumentSetList(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      searchDocumentSetTitle.value,
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch medical worker list')
      }
    })
})

onMounted(async () => {
  const response = await documentSetsStore.fetchDocumentSetList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    searchDocumentSetTitle.value,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch medical worker list')
  }
})

onBeforeMount(() => {
  documentSetsStore.clearDocumentSetList()
})

const requestNextItem = (item: MiDocumentSetItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextDocumentSetList(item)
}

const fetchNextDocumentSetList = async (item: MiDocumentSetItem) => {
  const response = await documentSetsStore.fetchNextDocumentSetList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    item.id,
    searchDocumentSetTitle.value,
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}

const transitionNewDocumentSet = () => {
  router.push('/document-sets/new')
}

const tokenStyleBodyL = getToken('BODY_L')
const tokenStyleLabelL = getToken('LABEL_L')
</script>

<template>
  <div class="document-set-list">
    <div class="tabs">
      <div class="tab active-tab">
        <p :style="tokenStyleLabelL">{{ t('pages.documentSets.documentSets') }}</p>
      </div>
      <div class="tab not-active-tab">
        <p :style="tokenStyleBodyL" @click="router.push('/contents')">
          {{ t('pages.documentSets.contents') }}
        </p>
      </div>
    </div>
    <div class="document-set-list-content">
      <div class="document-set-list-header">
        <div>
          <DocumentSetListSearch v-model:searchDocumentSetTitle="searchDocumentSetTitle" />
        </div>
        <div>
          <Button
            v-if="medicalInstitutionsStore.isAdmin()"
            type="icon-link"
            :content="t('attributes.newCreate')"
            icon="add"
            color="primary"
            :loading="false"
            elevated="text"
            min-height="48px"
            @click="transitionNewDocumentSet"
          >
          </Button>
        </div>
      </div>
      <div class="document-set-list-body">
        <DocumentSetListTable
          ref="documentSetsListTable"
          :isLoading="isLoading"
          @requestNextItem="requestNextItem"
          @update:sort-by="sortBy"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.document-set-list {
  min-width: 520px;
  height: 100%;
}

.document-set-list-content {
  background-color: white;
  min-height: 616px;
  height: calc(100% - 43.6px);
  border-radius: 0 8px 8px 8px;
  padding: 16px;
}

.document-set-list-body {
  height: calc(100% - 62px);
}

.tabs {
  display: flex;
  margin-left: 0.3px;
  text-align: center;
}
.tab {
  padding: 9px 16px;
  background-color: white;
}
.not-active-tab {
  cursor: pointer;
}
.active-tab {
  border-bottom: 2px solid #004386;
}
.active-tab > p {
  color: #004386;
}

.document-set-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
