<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiContentItem } from '@/api/generate/apiSchema'

import { type Order } from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/ScrollSortTable/types'
import useContentTypeFormatter from '@/composables/useContentTypeFormatter'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'
import { useContentsStore } from '@/stores/contents'

defineProps<{
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiContentItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const contentsStore = useContentsStore()
const { getFormattedDateTime } = useDateFormatter()
const { transContentType } = useContentTypeFormatter()

const headers: Header[] = [
  {
    title: t('features.contentSet.List.header.title'),
    key: 'title',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.contentSet.List.header.contentType'),
    key: 'contentType',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.contentSet.List.header.updatedAt'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed(() => {
  const contentList = contentsStore.getContentList()
  return contentList.value.map((content: MiContentItem) => {
    return {
      id: content.id,
      title: content.title,
      contentType: transContentType(content.contentType),
      createdAt: content.createdAt,
      updatedAt: getFormattedDateTime(new Date(content.updatedAt))
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}
</script>

<template>
  <ScrollSortTable
    class="content-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    @itemClick="(item: Item) => router.push(`/contents/${item.id}`)"
    :noDataText="t('attributes.noDataText')"
    @requestNextItem="$emit('requestNextItem', $event as MiContentItem)"
    @update:sortBy="sortBy"
    :viewAction="true"
  />
</template>

<style scoped>
.content-list-table {
  max-height: 100%;
}
</style>
