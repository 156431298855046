import { default as nucleusColors } from '@optim-design-system/tokens/colors.json'
import { default as typography } from '@optim-design-system/tokens/typography.json'

import type { NucleusColorTokenType } from './types'

type NestedObject = {
  [key: string]: NestedObject | string
}

/**
 * ネストされたオブジェクトをフラット化する関数
 *
 * @param {NestedObject} obj - フラット化するオブジェクト
 *
 * @param {string} parentKey - 親キー
 *
 * @param {Record<string, string>} result - 結果オブジェクト
 *
 * @returns {Record<string, string>} フラット化されたオブジェクト
 */
function flattenAndExcludeValue(
  obj: NestedObject,
  parentKey: string = '',
  result: Record<string, string> = {}
): Record<string, string> {
  for (const key in obj) {
    // Construct the new key
    const newKey = parentKey ? `${parentKey}-${key}` : key

    if (
      typeof obj[`${key}`] === 'object' &&
      obj[`${key}`] !== null &&
      !Array.isArray(obj[`${key}`])
    ) {
      // If the key is 'value', skip it
      if (key === 'value') continue

      // Recurse into the nested object
      flattenAndExcludeValue(obj[`${key}`] as NestedObject, newKey, result)
    } else {
      // Add the key-value pair to the result object
      result[`${newKey}`] = obj[`${key}`] as string
    }
  }
  return result
}
// valueを除外してフラット化した関数を実行
const NucleusColorsFlat: Record<NucleusColorTokenType, string> =
  flattenAndExcludeValue(nucleusColors)

export { typography, NucleusColorsFlat }
