<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiHospitalizationOrderResponse } from '@/api/generate/apiSchema'

import BaseChip from '@/components/Base/Chip/index.vue'
import PropertyField from '@/components/Base/PropertyField/index.vue'
import useChip from '@/composables/useChip'

const { t } = useI18n()

type Props = {
  // 入院詳細
  hospitalizationOrderDetail: MiHospitalizationOrderResponse
}
const props = defineProps<Props>()

const titleStyle = getTypography('TITLE_M')

const hospitalizationOrderStatus = ref('')
const chipColor = ref('')

// 入院ステータス
const { getHospitalizationOrderStatus } = useChip()

const hospitalizationOrderStatusItem = getHospitalizationOrderStatus(
  props.hospitalizationOrderDetail.hospitalizationOrderStatus
)

if (hospitalizationOrderStatusItem) {
  hospitalizationOrderStatus.value = t(hospitalizationOrderStatusItem.label)
  chipColor.value = hospitalizationOrderStatusItem.color
}

// 日付のフォーマット変換
const formattedHospitalizationDate =
  typeof props.hospitalizationOrderDetail.hospitalizationDate === 'string'
    ? props.hospitalizationOrderDetail.hospitalizationDate.replace(/-/g, '/')
    : ''
const formattedDischargeDate =
  typeof props.hospitalizationOrderDetail.dischargeDate === 'string'
    ? props.hospitalizationOrderDetail.dischargeDate.replace(/-/g, '/')
    : ''
</script>
<template>
  <div class="hospitalization-detail">
    <div :style="titleStyle">
      {{ t('features.DetailHospitalizationOrder.hospitalization.hospitalizationDetail') }}
    </div>
    <div class="chip-and-field">
      <PropertyField
        :key-content="
          t('features.DetailHospitalizationOrder.hospitalization.hospitalizationStatus')
        "
        :value-content="''"
      >
        <BaseChip
          :label="hospitalizationOrderStatus"
          :color="chipColor"
          elevation="2"
          class="chip-place"
        />
      </PropertyField>
    </div>
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.hospitalization.hospitalizationDate')"
        :value-content="formattedHospitalizationDate"
      />
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.hospitalization.dischargeDate')"
        :value-content="formattedDischargeDate"
      />
    </div>
    <div class="row">
      <PropertyField
        :key-content="
          t('features.DetailHospitalizationOrder.hospitalization.hospitalizationDepartment')
        "
        :value-content="props.hospitalizationOrderDetail.hospitalDepartment"
      />
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.hospitalization.hospitalizationWard')"
        :value-content="props.hospitalizationOrderDetail.ward"
      />
    </div>
    <PropertyField
      :key-content="t('features.DetailHospitalizationOrder.hospitalization.hospitalizationRoom')"
      :value-content="props.hospitalizationOrderDetail.hospitalRoom"
    />
  </div>
</template>
<style lang="scss" scoped>
.hospitalization-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chip-and-field {
  max-width: calc(50% - 5px);
}

.chip-place {
  justify-content: center;
}

.row {
  display: flex;
  gap: 5px;
}

.row > * {
  flex: 1 1;
}
</style>
