<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import SearchTextField from '@/components/Base/SearchTextField/index.vue'

const { t } = useI18n()

const model = defineModel<string>()
</script>

<template>
  <div style="min-width: 240px">
    <SearchTextField v-model="model" :label="t('features.contentSet.List.search.title')" />
  </div>
</template>
