import { documentManagementApi } from './api/documentManamenetApi'
import { hospitalizationApi } from './api/hospitalizationApi'
import { medicalInstitutionApi } from './api/medicalInstitutionApi'
import { userApi } from './api/userApi'

export const handlers = [
  ...documentManagementApi,
  ...medicalInstitutionApi,
  ...userApi,
  ...hospitalizationApi
]
