<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { medicalInstitutionsApiClient } from '@/api/apiClient'

const versions = ref<object>()
const medicalInstitutionFrontend = ref('')

onMounted(async () => {
  medicalInstitutionFrontend.value = import.meta.env.VITE_APP_VERSION
  const response = await medicalInstitutionsApiClient({ allowNotFound: true }).instance.get(
    '/gateway/api/version'
  )
  versions.value = response.data.versions
})
</script>
<template>
  <div class="container">
    <h1>versions</h1>
    <div v-for="(value, key) in versions" :key="key">{{ key }}: {{ value }}</div>
    <div>medical-institution-frontend: {{ medicalInstitutionFrontend }}</div>
  </div>
</template>
<style lang="css" scoped>
.container {
  padding: 16px;
  text-align: center;
}
</style>
