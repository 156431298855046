<script setup lang="ts">
import { ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import { documentManagementApiClient } from '@/api/apiClient'
import Text from '@/components/Base/BodyText/index.vue'
import BaseButton from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import DocumentSetDetailTable from '@/features/DocumentSetDetail/DocumentSetDetailTable/index.vue'
import { type DocumentSetDetailInput } from '@/features/DocumentSetDetail/type'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const isLoading = ref(false)

type Props = {
  documentSet: DocumentSetDetailInput
  documentSetId: string
}

const props = defineProps<Props>()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// コンテンツ削除
const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    toggleDeleteConfirmDialog()

    await documentManagementApiClient().api.destroyDocumentSetByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.documentSetId,
      { optimId: '' }
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.documentSets.documentSets') })
    )
    done()
    transitionList()
  } catch (error: unknown) {
    failure()
    if (axiosErrorGuard.isBadRequest(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.documentSets.documentSets') })
      )
      return
    }

    throw error
  }
}

const transitionList = () => {
  router.push('/document-sets')
}

// モーダル開閉管理
const deleteConfirmDialog = toRef<boolean>(false)

// ダイアログを開閉
const toggleDeleteConfirmDialog = () => {
  deleteConfirmDialog.value = !deleteConfirmDialog.value
}
</script>

<template>
  <div class="document-set-detail-header">
    <BaseButton
      v-if="medicalInstitutionsStore.isAdmin()"
      type="icon-link"
      variant="outlined"
      :content="t('attributes.edit')"
      icon="mode_edit_outline"
      color="primitive-white-DEFAULT-value"
      tokenType="LABEL_M"
      size="small"
      @click="() => router.push(`/document-sets/${props.documentSetId}/edit`)"
    />
    <BaseButton
      v-if="medicalInstitutionsStore.isAdmin()"
      type="icon-link"
      variant="outlined"
      :content="t('attributes.delete')"
      icon="delete_outline"
      color="primitive-white-DEFAULT-value"
      tokenType="LABEL_M"
      size="small"
      @click="() => toggleDeleteConfirmDialog()"
    />
  </div>

  <div class="rounded-lg document-set-detail-container">
    <div class="cols">
      <Label :label-text="t('features.documentSets.detail.title')"></Label>
      <Text :text="documentSet.title" typography-type="p"></Text>
    </div>
    <div class="cols">
      <Label :label-text="t('features.documentSets.detail.description')"></Label>
      <Text class="document-set-description" :text="documentSet.description" typography-type="p"></Text>
    </div>
    <div>
      <DocumentSetDetailTable
        ref="documentSetDetailTable"
        :contentList="documentSet.contentList"
        :is-loading="isLoading"
      />
    </div>

    <OnlyTextContentDialog
      :value="deleteConfirmDialog"
      :title="t('features.documentSets.detail.alert.title')"
      :content="t('features.documentSets.detail.alert.content')"
      :cancelBtnText="t('attributes.cancel')"
      :confirmBtnText="t('attributes.delete')"
      :confirmBtnColor="'object-caution'"
      :size="'medium'"
      :confirmBtnVisible="true"
      @input="toggleDeleteConfirmDialog"
      @confirm="confirmDestroy"
    />
  </div>
</template>

<style lang="scss" scoped>
.document-set-detail-container {
  background-color: var(--surface-surface-primary, #ffffff);
  padding: 16px;
  min-height: 616px;
  min-width: 520px;
}
.document-set-detail-header {
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}
.document-set-description {
  white-space: pre-wrap;
}
.cols {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
</style>
