import AsyncLock from 'async-lock'
import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import { type MiHospitalizationOrderListResponse } from '@/api/generate/apiSchema'
import {
  DocumentSetStatusCode,
  HospitalizationOrderStatusCode,
  UseAppStatusCode,
  type Order
} from '@/api/types'

const limit = 50

interface SearchConditions {
  medicalInstitutionId?: string
  patientNo?: string
  name?: string
  useAppStatus?: string
  documentSetStatus?: string
  hospitalizationOrderStatus?: string
  orderBy?: string
  order?: string
}

export const useHospitalizationOrdersStore = defineStore('hospitalizationOrders', () => {
  const lock = new AsyncLock({ timeout: 2000 })
  const searchConditions = ref<SearchConditions>({})
  const currentProcess = ref<string | undefined>()
  const searchedLastHospitalizationOrderId = ref<string | undefined>()
  const hospitalizationOrderList = ref<MiHospitalizationOrderListResponse['list']>([])
  const getHospitalizationOrderList = () => hospitalizationOrderList

  /**
   * 検索結果を保存する
   * 結果は排他制御し、全て同一の呼び出しで更新されることを保証する
   */
  const setSearchResult = async (
    medicalInstitutionId: string,
    _searchedLastHospitalizationOrderId: string | undefined,
    _hospitalizationOrderList: MiHospitalizationOrderListResponse['list'],
    patientNo: string | undefined,
    name: string | undefined,
    useAppStatus: string | undefined,
    documentSetStatus: string | undefined,
    hospitalizationOrderStatus: string | undefined,
    orderBy: string | undefined,
    order: string | undefined
  ) => {
    await lock.acquire('setSearchResult', () => {
      searchedLastHospitalizationOrderId.value = _searchedLastHospitalizationOrderId
      hospitalizationOrderList.value = _hospitalizationOrderList
      searchConditions.value = {
        medicalInstitutionId,
        patientNo,
        name,
        useAppStatus,
        documentSetStatus,
        hospitalizationOrderStatus,
        orderBy,
        order
      }
    })
  }

  const fetchHospitalizationOrderList = async (
    medicalInstitutionId: string,
    patientNo?: string,
    name?: string,
    useAppStatus?: UseAppStatusCode,
    hospitalizationOrderStatus?: HospitalizationOrderStatusCode,
    documentSetStatus?: DocumentSetStatusCode,
    orderBy?: string,
    order?: string
  ): Promise<FetchApiResponse<MiHospitalizationOrderListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    try {
      const response =
        await hospitalizationOrderApiClient().api.hospitalizationOrdersByMedicalInstitution(
          medicalInstitutionId,
          {
            optimId: '',
            patientNo,
            name,
            useAppStatus,
            documentSetStatus,
            hospitalizationOrderStatus,
            limit,
            orderBy,
            order
          }
        )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        undefined,
        response.data.list,
        patientNo,
        name,
        useAppStatus,
        documentSetStatus,
        hospitalizationOrderStatus,
        orderBy,
        order
      )

      return {
        success: true,
        content: hospitalizationOrderList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const fetchNextHospitalizationOrderList = async (
    medicalInstitutionId: string,
    lastHospitalizationOrderId: string,
    patientNo: string = '',
    name: string = '',
    useAppStatus?: UseAppStatusCode,
    hospitalizationOrderStatus?: HospitalizationOrderStatusCode,
    documentSetStatus?: DocumentSetStatusCode,
    orderBy?: string,
    order?: Order
  ): Promise<FetchApiResponse<MiHospitalizationOrderListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    // 重複した検索
    if (searchedLastHospitalizationOrderId.value == lastHospitalizationOrderId) {
      return {
        success: false,
        status: 400
      }
    }
    // 検索キーが一致するか確認
    if (
      searchConditions.value.medicalInstitutionId != medicalInstitutionId ||
      searchConditions.value.patientNo != patientNo ||
      searchConditions.value.name != name ||
      searchConditions.value.useAppStatus != useAppStatus ||
      searchConditions.value.documentSetStatus != documentSetStatus ||
      searchConditions.value.hospitalizationOrderStatus != hospitalizationOrderStatus ||
      searchConditions.value.orderBy != orderBy ||
      searchConditions.value.order != order
    ) {
      // 一致しない場合は、追加検索ではなく初期化を行う
      return fetchHospitalizationOrderList(
        medicalInstitutionId,
        patientNo,
        name,
        useAppStatus,
        hospitalizationOrderStatus,
        documentSetStatus,
        orderBy,
        order
      )
    }

    // 取得処理
    try {
      const response =
        await hospitalizationOrderApiClient().api.hospitalizationOrdersByMedicalInstitution(
          medicalInstitutionId,
          {
            optimId: '',
            lastHospitalizationOrderId,
            patientNo,
            name,
            useAppStatus,
            documentSetStatus,
            hospitalizationOrderStatus,
            limit,
            orderBy,
            order
          }
        )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        lastHospitalizationOrderId,
        [...hospitalizationOrderList.value, ...response.data.list],
        patientNo,
        name,
        useAppStatus,
        documentSetStatus,
        hospitalizationOrderStatus,
        orderBy,
        order
      )

      return {
        success: true,
        content: hospitalizationOrderList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const clearHospitalizationOrderList = () => {
    hospitalizationOrderList.value = []
    searchedLastHospitalizationOrderId.value = undefined
    searchConditions.value = {}
    currentProcess.value = undefined
  }

  return {
    getHospitalizationOrderList,
    fetchHospitalizationOrderList,
    fetchNextHospitalizationOrderList,
    clearHospitalizationOrderList
  }
})
