<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

import type { MenuButton, FormOptionType, SectionType } from '../type'

import Button from '@/components/Base/Button/index.vue'

const showButtons = ref<boolean>(false)

const openToggle = (): void => {
  showButtons.value = !showButtons.value
}

const props = defineProps<{
  /**
   * メニューボタン一覧
   */
  menuButtons: MenuButton[]
  /**
   * セクション項番
   */
  sectionIndex: number
}>()

const emit = defineEmits<{
  (e: 'click', type: FormOptionType | SectionType, sectionIndex: number): void
}>()

const handleClick = (type: FormOptionType | SectionType): void => {
  emit('click', type, props.sectionIndex)
  openToggle()
}
</script>

<template>
  <!-- 開閉ボタン -->
  <div v-if="!showButtons">
    <Button
      :content="showButtons ? '' : t('attributes.newCreate')"
      color="primary"
      icon="add"
      iconDirection="left"
      size="medium"
      type="icon-link"
      @click="openToggle"
    ></Button>
  </div>

  <div v-else class="expandable-btn v-btn--variant-elevated">
    <v-btn variant="flat" class="transparent-btn" @click="openToggle">
      <v-icon class="transparent-btn--icon" icon="add" />
    </v-btn>
    <!-- メニューコンテンツ -->
    <TransitionGroup name="expand" class="expanded-group" tag="div">
      <v-btn
        v-for="(btn, index) in props.menuButtons"
        :key="index"
        :label="btn.type"
        :text="btn.name"
        v-show="showButtons"
        valiant="outlined"
        class="expanded-group-btn"
        rounded="lg"
        flat
        @click="handleClick(btn.type)"
      >
        <v-icon class="expanded-group-btn--icon" :icon="btn.icon" />
        {{ t(btn.name) }}
      </v-btn>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
.expandable-btn {
  padding: 6px 8px 6px 0px;
  background-color: rgb(var(--v-theme-primary));
  border-radius: 16px;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}
.expanded-group {
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
}
.expand-enter-active {
  transition: all 0.1s ease;
  opacity: all 0.2s ease;
}
.expand-enter-to {
  opacity: 0;
}
.expand-enter-active {
  transform: translateX(-50%);
  opacity: 0;
}
.expand-leave-to {
  transform: translateX(-20%);
  opacity: 0;
}
.transparent-btn {
  background: transparent;
  border: none;
  color: inherit;
  height: 40px;
  padding: 0px;
  margin: 0px 4px 0px 12px;
  min-width: 24px;
  &--icon {
    font-size: 24px;
    color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  }
}
.expanded-group-btn {
  border: 1px solid #d1d4d6;
  padding: 0 6px;
  max-width: calc(100% / 7);
  min-width: fit-content;
  height: 34px;
  &--icon {
    font-size: 20px;
    margin-right: 4px;
  }
}
</style>
