<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import MedicalWorkerRegistration from '@/features/MedicalWorkerRegistration/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

// 初期化

const displayNameStore = useDisplayNameStore()
onMounted(() => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.medicalWorkers.list'),
        href: '/medical-workers',
        disable: false
      },
      {
        title: t('pages.medicalWorkers.new'),
        disable: true
      }
    ]
  })
})
</script>
<template>
  <!-- 医療機関ユーザー登録 -->
  <v-container class="container">
    <!-- 入力フォーム -->
    <MedicalWorkerRegistration />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
