<script setup lang="ts">
import OnlyTextContentDialog from '@components/Dialog/OnlyTextContentDialog/index.vue'
import HospitalizationOrderForm from '@features/HospitalizationOrderEdit/HospitalizationOrderEditForm/index.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { HospitalizationOrderItem, PatientDetailItem } from './type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import { type MiUpdateHospitalizationOrderRequest } from '@/api/generate/apiSchema'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()

const props = defineProps<{
  hospitalizationOrderId: string
}>()

const hospitalizationOrder = defineModel<HospitalizationOrderItem>('hospitalizationOrder', {
  required: true
})
const patient = defineModel<PatientDetailItem>('patient', { required: true })

const isLoading = ref<boolean>(false)

const update = async () => {
  isLoading.value = true
  try {
    // リクエスト情報
    const data: MiUpdateHospitalizationOrderRequest = {
      patientId: patient.value.id,
      hospitalizationOrderStatus: hospitalizationOrder.value.hospitalizationOrderStatus,
      hospitalDepartment: hospitalizationOrder.value.hospitalDepartment,
      ward: hospitalizationOrder.value.ward,
      hospitalRoom: hospitalizationOrder.value.hospitalRoom,
      hospitalizationDate: hospitalizationOrder.value.hospitalizationDate,
      dischargeDate: hospitalizationOrder.value.dischargeDate
    }
    const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
    const query = {
      optimId: ''
    }
    const response =
      await hospitalizationOrderApiClient().api.updateHospitalizationOrdersByMedicalInstitution(
        medicalInstitutionId,
        props.hospitalizationOrderId,
        query,
        data
      )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', {
        resource: t('features.hospitalizationOrders.Edit.hospitalizationOrder')
      })
    )
    router.push(`/hospitalization-orders/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }
    throw error
  }
}

// モーダル開閉管理
const isOpenValidationDialog = ref<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>
<template>
  <HospitalizationOrderForm
    v-model:hospitalization-order="hospitalizationOrder"
    v-model:patient="patient"
    v-model:is-loading="isLoading"
    @submit="update"
    @cancel="
      () => {
        router.push(`/hospitalization-orders/${props.hospitalizationOrderId}`)
      }
    "
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'large'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog()"
  />
</template>

<style lang="scss" scoped>
.hospitalization-register {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
}

.paragraph-text-field {
  display: flex;
  gap: 8px;
}

.paragraph-text-field > * {
  flex: 1 1;
}

.patient-select {
  display: flex;
  align-items: center;
}

.patient-selected-field-row {
  display: flex;
  gap: 2px;
}

.patient-selected-field-row > * {
  flex: 1 1;
}

.patient-field {
  max-width: calc(50% - 1px);
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.patient-selected-fields {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
