<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import PatientDetail from '@/features/PatientDetail/index.vue'
import { type PatientDetailInput } from '@/features/PatientDetail/type'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const route = useRoute()
const patientId = typeof route.params.patientId == 'string' ? route.params.patientId : ''

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const patientDetail = ref<PatientDetailInput | undefined>()

const displayNameStore = useDisplayNameStore()
onMounted(async () => {
  await detail()
})

// 患者詳細
const detail = async () => {
  const response = await medicalInstitutionsApiClient().api.patientByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    patientId,
    { optimId: '' }
  )

  patientDetail.value = {
    patientNo: response.data.patientNo,
    familyName: response.data.familyName,
    givenName: response.data.givenName,
    phoneticFamilyName: response.data.phoneticFamilyName,
    phoneticGivenName: response.data.phoneticGivenName,
    gender: response.data.gender,
    birthDate: response.data.birthDate,
    isSynchronized: response.data.clientId != null
  }
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.patients.list'),
        href: '/patients',
        disable: false
      },
      {
        title: `${patientDetail.value.familyName}` + `${patientDetail.value.givenName}`,
        disable: true
      }
    ]
  })
}
</script>

<template>
  <v-container class="container">
    <!-- 患者詳細 -->
    <PatientDetail
      v-if="patientDetail != undefined"
      :patientDetail="patientDetail"
      :patientId="patientId"
    >
    </PatientDetail>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
