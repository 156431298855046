<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import Button from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'

const { t } = useI18n()

type Props = {
  disabled: boolean
}

defineProps<Props>()

const title = defineModel('title', { required: true })
const description = defineModel('description', { required: true })
const url = defineModel('url', { required: true })
const tag = defineModel('tag', { required: true })

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

const submit = () => {
  emit('submit')
}

const cancel = () => {
  emit('cancel')
}

// 検索タグ (1つのみ)
const input = ref<string>('')

const addOrUpdateTag = () => {
  if (input.value) {
    tag.value = input.value
    input.value = ''
  }
}

const removeToken = () => {
  tag.value = undefined
}

const tokenStyle = getTypography('LABEL_S')
</script>

<template>
  <v-form>
    <div class="rounded-lg url-content-edit">
      <div>
        <Label
          forHtml="content_title"
          :labelText="t('features.contentSet.RegistrationForm.input.title')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="content_title"
          class="mt-1"
          v-model="title"
          counter="30"
          :hint="t('validations.maxLength', { max: 30 })"
          :placeholder="t('features.contentSet.RegistrationForm.placeholder.title')"
          persistent-hint
          :rules="[]"
        ></v-text-field>
      </div>
      <div>
        <Label
          forHtml="content_message"
          :labelText="t('features.contentSet.RegistrationForm.input.description')"
        ></Label>
        <v-textarea
          id="content_message"
          class="mt-1"
          :placeholder="t('features.contentSet.RegistrationForm.placeholder.description')"
          :hint="t('validations.maxLength', { max: 1000 })"
          persistent-hint
          v-model="description"
          counter="1000"
        ></v-textarea>
      </div>
      <div>
        <Label
          forHtml="content_title"
          :labelText="t('features.contentSet.RegistrationForm.input.url')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="content_url"
          class="pt-1"
          v-model="url"
          :hint="t('validations.maxLength', { max: 1000 })"
          :placeholder="t('features.contentSet.RegistrationForm.placeholder.url')"
          persistent-hint
          counter="1000"
          :rules="[]"
        ></v-text-field>
      </div>
      <!-- 検索タグ -->
      <div>
        <Label
          forHtml="search_tag"
          :labelText="t('features.FormContents.new.input.searchTag')"
        ></Label>
        <div>
          <v-chip
            v-if="tag"
            :style="tokenStyle"
            class="mt-2 mb-4 tag"
            closable
            variant="outlined"
            close
            @click:close="removeToken"
          >
            <span :style="tokenStyle">
              {{ tag }}
            </span>
          </v-chip>

          <v-textField
            v-model="input"
            @keyup.enter="addOrUpdateTag"
            persistent-hint
            counter="30"
            :hint="t('validations.maxLength', { max: 30 })"
            :placeholder="t('features.FormContents.new.input.searchTag')"
          />
        </div>
      </div>
    </div>

    <div class="form-buttons">
      <Button
        type="button"
        color="primitive-white-DEFAULT-value"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.cancel')"
        elevated="text"
        min-width="8rem"
        @click="cancel"
        variant="outlined"
      ></Button>
      <Button
        type="button"
        color="primary"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.save')"
        min-width="8rem"
        @click="submit"
      ></Button>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.url-content-edit {
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.tag {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 4px;
}
</style>
