<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiHospitalizationOrderRepresentativeItem } from '@/api/generate/apiSchema'

import { isDocumentSetStatusCode, type DocumentSetStatusCode } from '@/api/types'
import BaseChip from '@/components/Base/Chip/index.vue'
import PropertyField from '@/components/Base/PropertyField/index.vue'
import useChip from '@/composables/useChip'
import useFormattedName from '@/composables/useFormattedName'
import { type PatientDetailInput } from '@/features/SentDocumentSetContentDetail/type'

const { getFormattedNameAndPhoneticName } = useFormattedName()
const { getFormattedNameAndRelationship } = useFormattedName()
const { getDocumentSetStatus } = useChip()

const { t } = useI18n()

type Props = {
  // 入院患者詳細
  patientDetail: PatientDetailInput
  representatives: MiHospitalizationOrderRepresentativeItem[]
  documentStatusCode: DocumentSetStatusCode | string

  updateDate: string
}
const props = defineProps<Props>()

// 患者氏名を表示フォーマットに変更
const patientName = computed(() => {
  return getFormattedNameAndPhoneticName(
    props.patientDetail.familyName,
    props.patientDetail.givenName,
    props.patientDetail.phoneticFamilyName,
    props.patientDetail.phoneticGivenName
  )
})

// 代理者氏名を表示フォーマットに変更
const representativeName = computed(() => {
  const name = ref<string[]>([])
  props.representatives.forEach((value) => {
    name.value.push(
      getFormattedNameAndRelationship(value.familyName, value.givenName, value.relationshipCode)
    )
  })
  return name.value.join(' ')
})

// 書類提出状態の文言
const getChipValue = computed(() => (documentSetStatus: string) => {
  if (isDocumentSetStatusCode(documentSetStatus)) {
    const chipValue = getDocumentSetStatus(documentSetStatus)
    return {
      ...chipValue,
      label: chipValue?.label ? t(chipValue.label) : ''
    }
  }
  return {
    type: 'chip',
    label: documentSetStatus,
    color: 'primitive'
  }
})
</script>

<template>
  <div class="patient-property-field">
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.no')"
        :value-content="patientDetail.patientNo"
      />
    </div>
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.name')"
        :value-content="patientName"
      />
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.representativeName')"
        :value-content="representativeName"
      />
    </div>
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.documentStatus')"
        value-content=""
      >
        <BaseChip
          :label="getChipValue(documentStatusCode).label"
          :color="getChipValue(documentStatusCode).color"
        >
        </BaseChip>
      </PropertyField>
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.updateDate')"
        :value-content="updateDate"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.patient-property-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.row > * {
  flex: 1 1;
}
</style>
