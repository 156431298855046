<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'

type Props = {
  title: string
  description: string
}
defineProps<Props>()

const titleStyle = getTypography('TITLE_M')
const descriptionStyle = getTypography('BODY_M')
</script>

<template>
  <div class="error">
    <div class="title" :style="titleStyle">
      {{ title }}
    </div>
    <div class="description" :style="descriptionStyle">
      {{ description }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  vertical-align: middle;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
}
.title {
  text-align: center;
  padding: 10px;
}

.description {
  text-align: center;
}
</style>
