<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import Button from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import { useConstants } from '@/composables/useConstants'

const { t } = useI18n()
const { CONSTANTS } = useConstants()

type Props = {
  disabled: boolean
}

defineProps<Props>()

const familyName = defineModel('familyName')
const givenName = defineModel('givenName')
const phoneticFamilyName = defineModel('phoneticFamilyName')
const phoneticGivenName = defineModel('phoneticGivenName')
const email = defineModel('email')
const role = defineModel('role')

const roleSelectItems = [
  { value: CONSTANTS.ROLE.ADMIN, title: t('attributes.role.admin') },
  { value: CONSTANTS.ROLE.GENERAL, title: t('attributes.role.general') },
  { value: CONSTANTS.ROLE.READ, title: t('attributes.role.read') }
]

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

// アクションを伝達
const submit = () => {
  emit('submit')
}
const cancel = () => {
  emit('cancel')
}
</script>

<template>
  <v-form>
    <div class="medical-worker-edit rounded-lg">
      <div class="row">
        <Label
          :token-type="'TITLE_M'"
          forHtml="edit-medical-worker-family-name"
          :labelText="t('features.medicalWorker.MedicalWorkerRegistrationForm.input.name')"
          :isRequired="true"
          direction="left"
        ></Label>
        <div class="medical-worker-name mt-1">
          <v-text-field
            id="edit-medical-worker-family-name"
            v-model="familyName"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="
              t('features.medicalWorker.MedicalWorkerRegistrationForm.placeholder.familyName')
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>

          <v-text-field
            id="edit-medical-worker-given-name"
            v-model="givenName"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="
              t('features.medicalWorker.MedicalWorkerRegistrationForm.placeholder.givenName')
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>
        </div>
      </div>

      <div class="row">
        <Label
          :token-type="'TITLE_M'"
          forHtml="edit-medical-worker-phonetic-family-name"
          :labelText="t('features.medicalWorker.MedicalWorkerRegistrationForm.input.phoneticName')"
          :isRequired="true"
          direction="left"
        ></Label>
        <div class="medical-worker-name mt-1">
          <v-text-field
            id="edit-medical-worker-phonetic-family-name"
            v-model="phoneticFamilyName"
            :hint="t('validations.maxLengthAndPhonetic', { max: 100 })"
            :placeholder="
              t(
                'features.medicalWorker.MedicalWorkerRegistrationForm.placeholder.phoneticFamilyName'
              )
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>

          <v-text-field
            :token-type="'TITLE_M'"
            id="edit-medical-worker-phonetic-given-name"
            v-model="phoneticGivenName"
            :hint="t('validations.maxLengthAndPhonetic', { max: 100 })"
            :placeholder="
              t(
                'features.medicalWorker.MedicalWorkerRegistrationForm.placeholder.phoneticGivenName'
              )
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>
        </div>
      </div>

      <div class="row">
        <Label
          :token-type="'TITLE_M'"
          forHtml="edit-medical-worker-mail-address"
          :labelText="t('features.medicalWorker.MedicalWorkerRegistrationForm.input.email')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="edit-medical-worker-mail-address"
          class="mt-1"
          v-model="email"
          :placeholder="t('features.medicalWorker.MedicalWorkerRegistrationForm.placeholder.email')"
          hide-details
          :rules="[]"
        ></v-text-field>
      </div>

      <div class="row">
        <Label
          :token-type="'TITLE_M'"
          forHtml="edit-medical-worker-role"
          :labelText="t('features.medicalWorker.MedicalWorkerRegistrationForm.input.role')"
          :isRequired="true"
        ></Label>
        <v-select
          id="edit-medical-worker-role"
          class="mt-1"
          v-model="role"
          :items="roleSelectItems"
          hide-details
        ></v-select>
      </div>
    </div>

    <div class="form-buttons">
      <Button
        type="button"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.cancel')"
        variant="outlined"
        min-width="8rem"
        @click="cancel"
      ></Button>
      <Button
        type="button"
        color="primary"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.save')"
        min-width="8rem"
        @click="submit"
      ></Button>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.validation-register-text {
  color: var(--object-object-hint, #a3a9ad);
  font-size: 14px;
  padding: 4px;
}

.medical-worker-edit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
}

.medical-worker-name {
  display: flex;
  gap: 8px;
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
