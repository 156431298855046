<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiDocumentSetItem } from '@/api/generate/apiSchema'

import { type Order } from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import { type Header, type Item } from '@/components/Table/ScrollSortTable/types'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'
import { useDocumentSetsStore } from '@/stores/documentSets'

defineProps<{
  isLoading: boolean
}>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiDocumentSetItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const documentSetsStore = useDocumentSetsStore()
const { getFormattedDateTime } = useDateFormatter()

const headers: Header[] = [
  {
    title: t('features.documentSets.header.title'),
    key: 'title',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.documentSets.header.contentSize'),
    key: 'contentSize',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.documentSets.header.updatedAt'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed(() => {
  const documentSetList = documentSetsStore.getDocumentSetList()
  return documentSetList.value.map((documentSet: MiDocumentSetItem) => {
    return {
      id: documentSet.id,
      title: documentSet.title,
      contentSize: documentSet.contentSize.toString(),
      updatedAt: getFormattedDateTime(new Date(documentSet.updatedAt))
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}

const transitionDetailDocumentSet = (item: Item) => {
  const detailPath = `/document-sets/${item.id}`
  router.push(detailPath)
}
</script>

<template>
  <ScrollSortTable
    class="document-set-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    :loading="isLoading"
    @itemClick="transitionDetailDocumentSet"
    :noDataText="t('attributes.noDataText')"
    @requestNextItem="$emit('requestNextItem', $event as MiDocumentSetItem)"
    @update:sortBy="sortBy"
    :viewAction="true"
  />
</template>

<style scoped>
.document-set-list-table {
  max-height: 100%;
}
</style>
