<script setup lang="ts">
import FormContentDetail from '@features/SentDocumentSetContentDetail/FormContentDetail.vue'
import UrlContentDetail from '@features/SentDocumentSetContentDetail/UrlContentDetail.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { PatientDetailInput } from '@/features/PatientDetail/type'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import {
  type MiHospitalizationOrderDocumentSetItem,
  type MiHospitalizationOrderResponse
} from '@/api/generate/apiSchema'
import { useConstants } from '@/composables/useConstants'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderDetailStore } from '@/stores/hospitalizationOrderDetail'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()
const displayNameStore = useDisplayNameStore()
const hospitalizationOrderDetailStore = useHospitalizationOrderDetailStore()

const route = useRoute()
const CONTENT_TYPE = useConstants().CONSTANTS.CONTENT_TYPE

// 入院オーダーID
const hospitalizationOrderId =
  typeof route.params.hospitalizationOrderId == 'string' ? route.params.hospitalizationOrderId : ''

// 入院オーダー書類セットコンテンツID
const hospitalizationOrderDocumentSetContentId =
  typeof route.params.hospitalizationOrderDocumentSetContentId == 'string'
    ? route.params.hospitalizationOrderDocumentSetContentId
    : ''

// 医療機関ID
const medicalInstitutionId = useMedicalInstitutionsStore().currentMedicalInstitution().id

// 入院オーダー書類セットアイテム
const hospitalizationOrderDocumentSetItem = ref<MiHospitalizationOrderDocumentSetItem>()

// 入院オーダー詳細
const hospitalizationOrderDetail = ref<MiHospitalizationOrderResponse>()

// 患者ID
const patientId = ref<string>()

// 患者詳細情報
const patientDetail = ref<PatientDetailInput>()

// 入院オーダー書類セットアイテム
const fetchHospitalizationOrderDocumentSetItem = async () => {
  const response =
    await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByMedicalInstitution(
      medicalInstitutionId,
      hospitalizationOrderId,
      { optimId: '' }
    )
  if (response !== undefined) {
    response.data.list.forEach((content: MiHospitalizationOrderDocumentSetItem) => {
      if (
        content.hospitalizationOrderDocumentSetContentId ===
        hospitalizationOrderDocumentSetContentId
      ) {
        hospitalizationOrderDocumentSetItem.value = { ...content }
      }
    })
  }
}

// 入院オーダー取得
const fetchHospitalizationOrderDetail = async (): Promise<void> => {
  const response = await hospitalizationOrderDetailStore.fetchHospitalizationOrderDetail(
    medicalInstitutionId,
    hospitalizationOrderId
  )
  if (response.success) {
    hospitalizationOrderDetail.value = response.content
  }
}

// 患者詳細情報を取得
const fetchPatientDetail = async (): Promise<void> => {
  const OrderDetail = hospitalizationOrderDetail.value

  // ID未取得なら患者IDを取得を試みる
  if (!OrderDetail?.patientId) {
    await fetchHospitalizationOrderDetail()
  }

  // 取得できない場合はリクエストに失敗しているためエラー処理
  if (!OrderDetail?.patientId) {
    throw Error('Failed to get PatientId')
  }

  patientId.value = OrderDetail.patientId

  // 患者詳細情報を取得
  const response = await hospitalizationOrderDetailStore.fetchPatientDetail(
    medicalInstitutionId,
    patientId.value
  )
  if (response.success) {
    patientDetail.value = response.content
  }
}

const setDisplayName = () => {
  if (hospitalizationOrderDocumentSetItem.value) {
    const pageTitleBreadcrumbs = t(`pages.hospitalizationOrders.submissionDetail`, {
      resource: hospitalizationOrderDocumentSetItem.value.contentTitle
    })

    displayNameStore.updateDisplayName({
      breadcrumbs: [
        {
          title: t('pages.hospitalizationOrders.list'),
          href: '/hospitalization-orders',
          disable: false
        },
        {
          title: t('pages.hospitalizationOrders.detail'),
          href: `/hospitalization-orders/${hospitalizationOrderId}`,
          disable: false
        },
        {
          title: t('pages.hospitalizationOrders.sendDocumentSets'),
          href: `/hospitalization-orders/${hospitalizationOrderId}/contents`,
          disable: false
        },
        {
          title: pageTitleBreadcrumbs,
          disable: true
        }
      ]
    })
  }
}

// データ取得&初期化
onMounted(async () => {
  await Promise.all([fetchHospitalizationOrderDocumentSetItem(), fetchHospitalizationOrderDetail()])
  await fetchPatientDetail()
  setDisplayName()
})

// 画面リロード
const onReload = () => {
  router.go(0)
}
</script>

<template>
  <v-container class="container">
    <div v-if="hospitalizationOrderDocumentSetItem !== undefined">
      <!-- フォームコンテンツ詳細 -->
      <div v-if="CONTENT_TYPE.FORM === hospitalizationOrderDocumentSetItem.contentType">
        <div v-if="hospitalizationOrderDetail !== undefined && patientDetail !== undefined">
          <FormContentDetail
            :hospitalization-order-detail="hospitalizationOrderDetail"
            :patient-detail="patientDetail"
            :medical-institution-id="medicalInstitutionId"
            :hospitalization-order-document-set-content-id="
              hospitalizationOrderDocumentSetContentId
            "
            :hospitalizationOrderDocumentSetItem="hospitalizationOrderDocumentSetItem"
            @on-reload="onReload"
          ></FormContentDetail>
        </div>
      </div>
      <!-- URLコンテンツ詳細 -->
      <div v-if="CONTENT_TYPE.URL === hospitalizationOrderDocumentSetItem.contentType">
        <div v-if="hospitalizationOrderDetail !== undefined && patientDetail !== undefined">
          <UrlContentDetail
            :hospitalization-order-detail="hospitalizationOrderDetail"
            :patient-detail="patientDetail"
            :medical-institution-id="medicalInstitutionId"
            :hospitalization-order-document-set-content-id="
              hospitalizationOrderDocumentSetContentId
            "
            :hospitalizationOrderDocumentSetItem="hospitalizationOrderDocumentSetItem"
          ></UrlContentDetail>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
