import { useI18n } from 'vue-i18n'

export default function useContentTypeFormatter() {
  const { t } = useI18n()

  const transContentType = (contentType: string) => {
    switch (contentType.toUpperCase()) {
      case 'URL':
        return 'URL'
      case 'FORM':
        return t('features.contentSet.form')
      default:
        return contentType
    }
  }

  return {
    transContentType
  }
}
