<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { defineProps, toRefs } from 'vue'

import type { TypographyTokenType } from '@/components/Typography/types'

type Props = {
  /**
   * フォームラベル
   */
  forHtml?: string
  /**
   * ラベル
   */
  labelText?: string
  /**
   * 必須かどうか
   */
  isRequired?: boolean
  /**
   * ステータスの表示位置
   */
  direction?: 'left' | 'right'
  /**
   * 既定の文章スタイル
   */
  tokenType?: TypographyTokenType
}
const props = withDefaults(defineProps<Props>(), {
  formLabel: 'label',
  labelText: 'text',
  isRequired: false,
  direction: 'left',
  tokenType: 'TITLE_M'
})

const { tokenType } = toRefs(props)
const tokenStyle = getTypography(tokenType.value)
</script>

<template>
  <div>
    <span v-if="isRequired && direction === 'left'" :style="tokenStyle" class="required-mark"
      >*</span
    >
    <label :for="forHtml" :style="tokenStyle">{{ labelText }}</label>
    <span v-if="isRequired && direction === 'right'" :style="tokenStyle" class="required-mark"
      >*</span
    >
  </div>
</template>

<style scoped>
.required-mark {
  color: rgb(var(--v-theme-object-caution));
  margin-left: 4px;
}
.label-text {
  color: var(--on-surface-on-surface-primary, #192833);
}
</style>
