import { format } from 'date-fns'

export default function () {
  const getFormattedDate = (date: Date): string => {
    return format(date, 'yyyy/MM/dd')
  }

  const getFormattedDateTime = (date: Date): string => {
    return format(date, 'yyyy/MM/dd HH:mm')
  }

  return {
    getFormattedDate,
    getFormattedDateTime
  }
}
