<script setup lang="ts">
import {
  getTypography,
  type NucleusColorTokenType,
  type NucleusTypographyTokenType
} from '@optim-design-system/src'
import { defineProps, computed } from 'vue'
import { useTheme } from 'vuetify'

import type { TypographyType } from './type'

type Props = {
  /**
   * テキスト
   */
  text: string
  /**
   * 文章スタイル h1～h6 | p
   */
  typographyType: TypographyType
  /**
   * テキストカラー
   */
  color?: NucleusColorTokenType | string
  /**
   * ボディスタイル
   */
  bodyStyle?: NucleusTypographyTokenType
}

const props = withDefaults(defineProps<Props>(), {
  text: 'テキスト',
  typographyType: 'p',
  color: '',
  bodyStyle: 'BODY_M'
})

const bodyStyle = computed(() => {
  return getTypography(props.bodyStyle)
})

const colorStyle = computed(() => {
  const thmeColors = useTheme().current.value.colors
  return { color: thmeColors[props.color] }
})
</script>

<template>
  <component :is="typographyType" :style="[colorStyle, bodyStyle]">{{ text }}</component>
</template>

<style scoped>
/** 文章スタイル */
.typography-h1 {
  line-height: 1;
  letter-spacing: -0.015625em;
}
.typography-h2 {
  line-height: 1;
  letter-spacing: -0.0083333333em;
}
.typography-h3 {
  line-height: 1.05;
  letter-spacing: normal;
}
.typography-h4 {
  line-height: 1.175;
  letter-spacing: 0.0073529412em;
}
.typography-h5 {
  line-height: 1.333;
  line-height: normal;
}
.typography-h6 {
  line-height: 1.6;
  letter-spacing: 0.0125em;
}
.typography-p {
  line-height: 1.5;
  letter-spacing: 0.03125em;
}
</style>
