import { http, HttpResponse, type PathParams } from 'msw'

import {
  contents,
  documentSets,
  documentSetList,
  urlContentRevisions,
  formContentRevisions
} from './baseData'

import type {
  MiContentItem,
  MiContentListResponse,
  MiContentResponse,
  MiUrlContentRevisionResponse,
  MiDocumentSetItem,
  MiDocumentSetListResponse,
  MiDocumentSetResponse,
  MiFormContentRevisionResponse
} from '@/api/generate/apiSchema'

const baseUrl = import.meta.env.VITE_API_DOCUMENT_MANAGEMENT_API_BASE_URL
const medicalInstitutionV1Url = `${baseUrl}/api/medical-institutions/:medicalInstitutionId/v1`

const extractMedicalInstitutionId = (params: PathParams): string => {
  const medicalInstitutionId = params['medicalInstitutionId']
  if (typeof medicalInstitutionId == 'string') {
    return medicalInstitutionId
  }

  throw 'expect only one medicalInstitutionId'
}

export const documentManagementApi = [
  http.get(`${medicalInstitutionV1Url}/contents`, ({ params }) => {
    const response: MiContentListResponse = {
      list: contents[extractMedicalInstitutionId(params)].map((content) => {
        const item: MiContentItem = {
          id: content.id,
          title: content.title,
          contentType: content.contentType as MiContentItem['contentType'],
          createdAt: content.createdAt,
          updatedAt: content.updatedAt
        }
        return item
      })
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalInstitutionV1Url}/contents/:contentId`, ({ params }) => {
    const contentId = params['contentId']
    const content = contents[extractMedicalInstitutionId(params)].filter((content) => {
      return content.id == contentId
    })[0]
    const response: MiContentResponse = {
      id: content.id,
      title: content.title,
      description: content.description,
      contentType: content.contentType,
      createdAt: content.createdAt,
      updatedAt: content.updatedAt
    }
    return HttpResponse.json(response)
  }),
  http.get(
    `${medicalInstitutionV1Url}/contents/:contentId/url-content-revisions/latest`,
    ({ params }) => {
      const contentId = params['contentId']
      const content = urlContentRevisions.filter((content) => {
        return content.id == contentId
      })[0]
      const response: MiUrlContentRevisionResponse = {
        id: content.id,
        url: content.url,
        createdAt: content.createdAt
      }
      return HttpResponse.json(response)
    }
  ),
  http.get(
    `${medicalInstitutionV1Url}/contents/:contentId/form-content-revisions/latest`,
    ({ params }) => {
      const contentId = params['contentId']
      const contentRevision = formContentRevisions.filter((content) => {
        return content.id == contentId
      })[0]
      const response: MiFormContentRevisionResponse = {
        id: contentRevision.id,
        contentJson: contentRevision.contentJson,
        createdAt: contentRevision.createdAt
      }
      return HttpResponse.json(response)
    }
  ),
  http.get(`${medicalInstitutionV1Url}/document-sets`, ({ params }) => {
    const response: MiDocumentSetListResponse = {
      list: documentSets[extractMedicalInstitutionId(params)].map((documentSet) => {
        const item: MiDocumentSetItem = {
          id: documentSet.id,
          title: documentSet.setName,
          contentSize: documentSet.documentNo,
          createdAt: documentSet.createdAt,
          updatedAt: documentSet.updatedAt
        }
        return item
      })
    }
    return HttpResponse.json(response)
  }),
  http.get(`${medicalInstitutionV1Url}/document-sets/:documentId`, ({ params }) => {
    const documentId = params['documentId']
    const documentSet = documentSetList.filter((documentSetList) => {
      return documentSetList.id == documentId
    })[0]
    const response: MiDocumentSetResponse = {
      id: documentSet.id,
      title: documentSet.title,
      description: documentSet.description,
      contents: documentSet.contents,
      createAt: documentSet.createAt,
      updatedAt: documentSet.updatedAt
    }
    return HttpResponse.json(response)
  })
]
