<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DateOption } from '@/features/FormContentForm/type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォームオプション
   */
  option: DateOption
}>()
const option = reactive(props.option)

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}

// スタイル用
const bodyStyle = computed(() => getTypography('BODY_S'))
</script>
<template>
  <div class="date-form-option">
    <div class="content">
      <!-- 項目名 -->
      <v-text-field
        v-model="option.name"
        class="required-field"
        :placeholder="t('features.FormContents.new.placeholder.fieldName')"
        counter="100"
        :hint="t('validations.maxLength', { max: 100 })"
        persistent-hint
        :rules="[required(option.name, t('features.FormContents.new.placeholder.fieldName'))]"
      ></v-text-field>

      <div>
        <!-- 説明 -->
        <v-text-field
          v-model="option.description"
          :placeholder="t('features.FormContents.new.placeholder.description')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
        ></v-text-field>
      </div>
    </div>

    <v-divider class="divider"></v-divider>

    <div class="bottom">
      <!-- 回答必須スイッチ -->
      <v-switch
        v-model="option.isRequired"
        :style="bodyStyle"
        :label="t('features.FormContents.new.input.required')"
        color="primary"
        baseColor="theme-DEFAULT-surface-tertiary-value"
        hide-details
        inset
        flat
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.required-field :before) {
  border-width: 0 0 3px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.bottom {
  display: flex;
  justify-content: end;
  gap: 16px;
  height: 36px;
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-input--density-default {
  --v-input-control-height: 36px;
}
</style>

<style lang="scss">
.date-form-option {
  .bottom {
    .switch {
      .v-selection-control--density-default {
        --v-selection-control-size: 36px !important;
      }
    }
  }
}
</style>
