<script setup lang="ts">
import HospitalizationOrderEdit from '@features/HospitalizationOrderEdit/index.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type {
  HospitalizationOrderItem,
  PatientDetailItem
} from '@/features/HospitalizationOrderEdit/type.ts'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()
const route = useRoute()
const hospitalizationOrderId =
  typeof route.params.hospitalizationOrderId == 'string' ? route.params.hospitalizationOrderId : ''

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
const HospitalizationOrderDetail = ref<HospitalizationOrderItem>()
onMounted(async () => {
  // 入院オーダー情報取得
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.hospitalizationOrders.list'),
        href: '/hospitalization-orders',
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.detail'),
        href: `/hospitalization-orders/${hospitalizationOrderId}`,
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.edit'),
        disable: true
      }
    ]
  })
  await fetchHospitalizationOrderDetail()
  await fetchPatientDetail()
})

// 入院詳細
const fetchHospitalizationOrderDetail = async () => {
  const response =
    await medicalInstitutionsApiClient().api.hospitalizationOrderByMedicalInstitution(
      medicalInstitutionId,
      hospitalizationOrderId,
      { optimId: '' }
    )

  // 入院オーダーが同期によって作成されている場合、404へ遷移させる
  if (response.data.clientHospitalizationOrderId != null) {
    router.replace('/not_found')
  }

  HospitalizationOrderDetail.value = {
    id: response.data.id,
    patientId: response.data.patientId,
    hospitalizationOrderStatus: response.data.hospitalizationOrderStatus,
    hospitalizationDate: response.data.hospitalizationDate,
    dischargeDate: response.data.dischargeDate,
    hospitalDepartment: response.data.hospitalDepartment,
    ward: response.data.ward,
    hospitalRoom: response.data.hospitalRoom
  }
}

// 患者詳細
const patientDetail = ref<PatientDetailItem>()
const patientId = ref('')

const fetchPatientDetail = async () => {
  patientId.value =
    HospitalizationOrderDetail.value?.patientId != undefined
      ? HospitalizationOrderDetail.value.patientId
      : ''
  const response = await medicalInstitutionsApiClient().api.patientByMedicalInstitution(
    medicalInstitutionId,
    patientId.value,
    { optimId: '' }
  )
  patientDetail.value = {
    id: response.data.id,
    patientNo: response.data.patientNo,
    familyName: response.data.familyName,
    givenName: response.data.givenName,
    phoneticFamilyName: response.data.phoneticFamilyName,
    phoneticGivenName: response.data.phoneticGivenName,
    gender: response.data.gender,
    birthDate: response.data.birthDate
  }
}
</script>
<template>
  <v-container class="container">
    <!-- 入力フォーム -->
    <HospitalizationOrderEdit
      v-if="HospitalizationOrderDetail != undefined && patientDetail != undefined"
      v-model:patient="patientDetail"
      v-model:hospitalizationOrder="HospitalizationOrderDetail"
      :hospitalizationOrderId="hospitalizationOrderId"
    />
  </v-container>
</template>
<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
