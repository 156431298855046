<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'

import type { VuetifyColorTokenType } from '@/common/VuetifyTheme/types'

type Props = {
  /**
   * variant
   */
  variant?: 'elevated' | 'flat' | 'outlined' | 'text' | 'plain'
  /**
   * ボタンの色
   */
  color?: VuetifyColorTokenType
  /**
   * disableにするかどうか
   */
  disabled?: boolean
  /**
   * ローディング中かどうか
   */
  loading?: boolean
  /**
   * ボタンの中身
   */
  content: string
  /**
   * ボタンの種類
   */
  type?: 'button' | 'icon-link'
  /**
   * ボタンのアイコン
   */
  icon?: string
  /**
   * アイコンの表示位置
   */
  iconDirection?: 'left' | 'right'
  size?: 'small' | 'medium'
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primitive-white-DEFAULT-value',
  disabled: false,
  loading: false,
  content: 'ボタン',
  type: 'button',
  icon: '',
  iconDirection: 'left',
  size: 'medium',
  variant: 'elevated'
})

type Emits = {
  (e: 'click', value: MouseEvent): void
}

const emit = defineEmits<Emits>()

const onClick = (e: MouseEvent) => {
  emit('click', e)
}

const tokenStyle = getTypography(props.size == 'small' ? 'LABEL_M' : 'LABEL_L')
</script>

<template>
  <v-btn
    v-if="type === 'button'"
    v-bind="$attrs"
    :variant="variant"
    :color="color"
    :loading="loading"
    :disabled="disabled"
    :class="`base-button-${size}`"
    @click="onClick"
  >
    <span :style="tokenStyle">
      {{ content }}
    </span>
  </v-btn>
  <v-btn
    v-else-if="type === 'icon-link'"
    v-bind="$attrs"
    :color="color"
    :loading="loading"
    :disabled="disabled"
    :class="`base-button-${size}`"
    :variant="variant"
    @click="onClick"
  >
    <v-icon
      v-if="iconDirection === 'left'"
      class="mr-1"
      :style="size == 'small' ? 'font-size: 20px' : 'font-size: 24px'"
    >
      {{ icon }}
    </v-icon>
    <span :style="tokenStyle">
      {{ content }}
    </span>
    <v-icon
      v-if="iconDirection === 'right'"
      class="mr-1"
      :style="size == 'small' ? 'font-size: 20px' : 'font-size: 24px'"
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.base-button-medium {
  padding: 12px;
  border-radius: 10px;
  height: 48px !important;
}

.base-button-small {
  padding: 7.5px;
  border-radius: 10px;
  height: 36px !important;
}

.v-btn--variant-outlined {
  border-color: #8f99a3;
  opacity: 1;
}

.text-primitive-white-DEFAULT-value {
  background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value)) !important;
  color: #192833 !important;
}

.bg-surface-disable {
  color: rgba(var(--v-theme-on-surface), 0.26) !important;
}

.base-button-medium {
  .v-btn__overlay {
    opacity: 0 !important;
  }
}

.base-button-small {
  .v-btn__overlay {
    opacity: 0 !important;
  }
}
</style>
