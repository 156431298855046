<script setup lang="ts">
import { reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import UrlContentRegistrationForm from './UrlContentRegistrationForm/index.vue'

import type { UrlContentRegistration } from './type'
import type { MiContentRequest } from '@/api/generate/apiSchema'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const axiosErrorGuard = useAxiosErrorGuard()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const isLoading = ref<boolean>(false)

const form = reactive<UrlContentRegistration>({
  title: '',
  description: '',
  url: '',
  tag: undefined
})

const createUrlContent = async () => {
  isLoading.value = true
  const data: MiContentRequest = {
    title: form.title,
    description: form.description,
    urlContentRevision: {
      url: form.url
    },
    tag: form.tag
  }

  try {
    const response = await medicalInstitutionsApiClient().api.createContentByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      { optimId: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('pages.documentSets.contents') })
    )
    router.push(`/contents/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

const transitionContentList = () => {
  router.push('/contents')
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)
// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <UrlContentRegistrationForm
    v-model:title="form.title"
    v-model:description="form.description"
    v-model:url="form.url"
    v-model:tag="form.tag"
    :disabled="isLoading"
    @submit="createUrlContent()"
    @cancel="transitionContentList()"
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style></style>
