<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import PatientEdit from '@/features/PatientEdit/index.vue'
import { type PatientEditFormInput } from '@/features/PatientEdit/type'
import router from '@/router'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const route = useRoute()
const patientId = typeof route.params.patientId == 'string' ? route.params.patientId : ''

// ログイン情報
const medicalInstitutionsStore = useMedicalInstitutionsStore()

const form = ref<PatientEditFormInput | undefined>()

const displayNameStore = useDisplayNameStore()

onMounted(async () => {
  await detail()
})

// 患者詳細
const detail = async () => {
  const response = await medicalInstitutionsApiClient().api.patientByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    patientId,
    { optimId: '' }
  )

  // 患者が同期によって作成されている場合、404へ遷移させる
  if (response.data.clientId != null) {
    router.replace('/not_found')
  }

  form.value = {
    patientNo: response.data.patientNo,
    familyName: response.data.familyName,
    givenName: response.data.givenName,
    phoneticFamilyName: response.data.phoneticFamilyName,
    phoneticGivenName: response.data.phoneticGivenName,
    gender: response.data.gender,
    birthDate: response.data.birthDate
  }

  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.patients.list'),
        href: '/patients',
        disable: false
      },
      {
        title: `${form.value.familyName}` + ' ' + `${form.value.givenName}`,
        href: `/patients/${patientId}`,
        disable: false
      },
      {
        title: t('pages.patients.edit'),
        disable: true
      }
    ]
  })
}
</script>

<template>
  <v-container class="container">
    <!-- 入力フォーム -->
    <PatientEdit v-if="form != undefined" :form="form" :patientId="patientId"></PatientEdit>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
