<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import HospitalizationDetail from './HospitalizationDetail/index.vue'
import PatientIntelligence from './PatientIntelligence/index.vue'

import type { Item } from './Card/DocumentStatus/type'
import type { MiHospitalizationOrderResponse } from '@/api/generate/apiSchema'
import type { PatientDetailInput } from '@/features/PatientDetail/type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import BaseButton from '@/components/Base/Button/index.vue'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import AppUsage from '@/features/HospitalizationOrderDetail/Card/AppUsage/index.vue'
import DocumentStatus from '@/features/HospitalizationOrderDetail/Card/DocumentStatus/index.vue'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

type Props = {
  /**
   * 患者情報
   */
  patientDetail: PatientDetailInput
  /**
   * 入院オーダー
   */
  personalHospitalizationDetail: MiHospitalizationOrderResponse
  /**
   * アプリ利用状況
   */
  isUseAppStatus: boolean
  /**
   * 入院オーダーId
   */
  hospitalizationOrderId: string
  /**
   * 患者とアプリユーザの紐付け
   */
  userPatientId: string | undefined
  /**
   * ローディング
   */
  loading: boolean
  /**
   * 書類提出状態ステータス
   */
  documentStatus: Item
}
const props = defineProps<Props>()

type Emits = {
  (e: 'bind'): void
}

const emit = defineEmits<Emits>()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const edit = () => {
  router.push(`/hospitalization-orders/${props.personalHospitalizationDetail.id}/edit`)
}

const isOpenDeleteDialog = ref(false)

const openDeleteConfirmDialog = () => {
  isOpenDeleteDialog.value = !isOpenDeleteDialog.value
}

const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    // 入院オーダー削除実行
    await medicalInstitutionsApiClient().api.destroyHospitalizationOrderByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.personalHospitalizationDetail.id,
      { optimId: '' }
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.hospitalizationOrders.list') })
    )
    done()
    router.push('/hospitalization-orders')
  } catch (error: unknown) {
    failure()
    if (useAxiosErrorGuard().isBadRequest(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.hospitalizationOrders.list') })
      )
      return
    }
    throw error
  }
}

const bind = () => {
  emit('bind')
}
</script>
<template>
  <div class="hospitalization-order-detail-header" v-if="medicalInstitutionsStore.isGeneral()">
    <BaseButton
      v-if="personalHospitalizationDetail.clientId == undefined"
      type="icon-link"
      variant="outlined"
      :content="t('attributes.edit')"
      icon="mode_edit_outline"
      color="primitive-white-DEFAULT-value"
      tokenType="LABEL_M"
      size="small"
      @click="edit"
    />
    <BaseButton
      type="icon-link"
      variant="outlined"
      :content="t('attributes.delete')"
      icon="delete_outline"
      color="primitive-white-DEFAULT-value"
      tokenType="LABEL_M"
      size="small"
      @click="openDeleteConfirmDialog"
    />
  </div>
  <div class="hospitalization-order-detail rounded-lg">
    <PatientIntelligence
      class="patient-intelligence"
      :patient-detail="patientDetail"
      :representatives="personalHospitalizationDetail.representatives"
    />
    <div class="patient-card">
      <AppUsage
        :hospitalization-id="personalHospitalizationDetail.id"
        :patientId="personalHospitalizationDetail.patientId"
        :patient-detail="patientDetail"
        :representatives="personalHospitalizationDetail.representatives"
        :user-patient-id="userPatientId"
        :loading="loading"
        @bind="bind"
      />
      <DocumentStatus
        :isUseAppStatus="isUseAppStatus"
        :hospitalizationOrderId="hospitalizationOrderId"
        :documentStatus="documentStatus"
        :loading="loading"
      />
    </div>
    <div class="detail-intelligence">
      <HospitalizationDetail :hospitalization-order-detail="personalHospitalizationDetail" />
    </div>
  </div>

  <!-- 入院オーダー削除確認ダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenDeleteDialog"
    :title="t('attributes.deleteDialog.title', { resource: t('pages.hospitalizationOrders.list') })"
    :content="
      t('attributes.deleteDialog.message', { resource: t('pages.hospitalizationOrders.list') })
    "
    :cancelBtnText="t('attributes.cancel')"
    :confirmBtnText="t('attributes.delete')"
    :confirmBtnColor="'object-caution'"
    :size="'medium'"
    :confirmBtnVisible="true"
    @input="isOpenDeleteDialog = !isOpenDeleteDialog"
    @confirm="confirmDestroy"
  />
</template>
<style lang="scss" scoped>
.hospitalization-order-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
}

.hospitalization-order-detail-header {
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.patient-detail-field {
  display: flex;
  gap: 5px;
}

.account-icon {
  flex-basis: 1;
}

.patient-intelligence {
  flex-grow: 3;
}

.patient-card {
  display: flex;
  gap: 16px;
}

.detail-intelligence {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
