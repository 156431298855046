<script setup lang="ts">
import { computed, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import type { QuantitativeOption } from './type'

import BaseBadge from '@/components/Base/Badge/index.vue'
import BaseLabel from '@/components/Base/Label/index.vue'
import Typography from '@/components/Typography/Typography.vue'

const { t } = useI18n()

// props初期化
const props = withDefaults(
  defineProps<{
    sectionIndex: number
    content: QuantitativeOption
    /**
     * コンポーネント識別のため（主に入力セットで利用）
     */
    alternativeId?: string
  }>(),
  {}
)
const content = reactive<QuantitativeOption>(props.content)

// IDを取得（alternativeIdが優先）
const getId = computed(() => {
  return props.alternativeId ?? content.id
})

// ラベルID発行
const labelId = computed(() => {
  return `${props.sectionIndex}_${getId.value}`
})

// emit定義
const emit = defineEmits<{
  (e: 'onChange', value: number | null): void
}>()

const onChange = () => {
  if (typeof content.value == "string" && content.value == "") {
    content.value = null;
  }
  emit('onChange', content.value)
}

// 入力セットでのpropsの変更を検知
watchEffect(() => {
  Object.assign(content, props.content)
})
</script>

<template>
  <div>
    <div>
      <!-- 項目名 -->
      <BaseLabel :label-text="content.name" token-type="LABEL_L" class="label-wrapper"></BaseLabel>
      <div v-show="content.isRequired" class="badge-wrapper">
        <BaseBadge
          :label="t('attributes.required')"
          background-color="primitive-red-accent-40-value"
        ></BaseBadge>
      </div>
    </div>
    <div>
      <label :for="labelId" class="content-description">{{ content.description }}</label>
      <div class="unit-input-field-wrapper">
        <v-text-field
          class="unit-input-field input-field"
          :id="labelId"
          type="number"
          v-model="content.value"
          variant="outlined"
          persistent-hint
          :hint="t('features.hospitalizationDocument.FormContentSubmission.pleaseEnterNumber')"
          @input="onChange"
        ></v-text-field>
        <!-- 単位 -->
        <Typography tag="span" token-type="BODY_L">{{ content.unit }}</Typography>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// ラベルラッパー要素
.label-wrapper {
  display: inline-block;
}
// バッジラッパー要素
.badge-wrapper {
  display: inline-block;
  height: 1.2lh;
  vertical-align: middle;
  :deep(.badge) {
    display: inline-block;
    height: 1.2lh;
    vertical-align: middle;
  }
}
// コンテンツ説明文
.content-description {
  display: inline;
}
.input-field {
  padding: 4px 0;
}
// 入力フィールドと単位を横並びにするボックス
.unit-input-field-wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
}
// 単位数値入力フィールド
.unit-input-field {
  width: 80%;
  min-width: 70%;
}
</style>
