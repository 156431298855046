<script setup lang="ts">
import { toRefs, computed } from 'vue'

import MedicalWorkerMenu from '@/features/AppTools/MedicalWorkerMenu/index.vue'

type Props = {
  /**
   * ユーザー名
   */
  name: string
  /**
   * メールアドレス
   */
  email: string
  /**
   * 会社名
   */
  corporationName: string
  /**
   * ログアウト処理
   */
  logout: () => void
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  email: '',
  corporationName: ''
})

const { name, email, corporationName } = toRefs(props)

const menu = computed<Omit<Props, 'logout'>>(() => {
  return {
    name: name.value,
    email: email.value,
    corporationName: corporationName.value
  }
})
</script>

<template>
  <div>
    <MedicalWorkerMenu
      :name="menu.name"
      :email="menu.email"
      :corporation-name="menu.corporationName"
      :logout="logout"
    />
  </div>
</template>
