<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import PatientDialogContent from './PatientDialogContent.vue'

import type { Item } from '@/components/Table/ScrollSortTable/types'

import BaseDialog from '@/components/Base/Dialog/index.vue'

type Props = {
  /**
   * dialogの開閉
   */
  value: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false
})

type Emits = {
  (e: 'input', value: boolean): void
  (e: 'itemClick', item: Item): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()

const onClose = (value: boolean) => {
  emit('input', value)
}

const itemClick = (item: Item) => {
  emit('itemClick', item)
}
</script>
<template>
  <BaseDialog
    :value="props.value"
    :title="t('features.hospitalizationOrders.Edit.search.patient')"
    :cancelBtnText="t('attributes.cancel')"
    size="large"
    cancelBtnDefault="right"
    @input="onClose"
  >
    <template #contents>
      <PatientDialogContent @itemClick="itemClick" />
    </template>
  </BaseDialog>
</template>
