<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { SearchDocumentSetStatusCode } from '../type'

import SimpleSelect from '@/components/Base/SimpleSelect/index.vue'

const { t } = useI18n()

const model = defineModel<string>()

const items: { title: string; value: SearchDocumentSetStatusCode }[] = [
  {
    title: t('attributes.all'),
    value: 'all'
  },
  {
    title: t('attributes.documentSetStatus.notSent'),
    value: 'not_sent'
  },
  {
    title: t('attributes.documentSetStatus.notSubmitted'),
    value: 'not_submitted'
  },
  {
    title: t('attributes.documentSetStatus.partialSubmitted'),
    value: 'partial_submitted'
  },
  {
    title: t('attributes.documentSetStatus.submitted'),
    value: 'submitted'
  },
  {
    title: t('attributes.documentSetStatus.confirmed'),
    value: 'confirmed'
  }
]
</script>

<template>
  <div style="min-width: 140px">
    <SimpleSelect
      v-model="model"
      :label="t('features.hospitalizationOrders.List.search.documentSetStatus')"
      :items="items"
      hide-details
    />
  </div>
</template>
