<script setup lang="ts">
import SentDocumentSetContentListTable from '@features/SentDocumentSetContentList/SentDocumentSetContentListTable/index.vue'
import { onMounted, ref, watch } from 'vue'

import type { MiHospitalizationOrderDocumentSetItem } from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

export type SentDocumentSetContentList = {
  id: string
  documentSetTitle: string
  contentTitle: string
  contentStatusCode: 'not_submitted' | 'submitted' | 'confirmed'
  updatedAt: string
}

type Props = {
  hospitalizationOrderId: string
}

const isLoading = ref<boolean>(false)
const defaultDocumentSetList = ref<MiHospitalizationOrderDocumentSetItem[]>([])
const documentSetList = ref<MiHospitalizationOrderDocumentSetItem[]>([])
const props = defineProps<Props>()
const hospitalizationOrderId = ref<string>(props.hospitalizationOrderId)
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const sentDocumentSetContentListTable = ref<InstanceType<typeof SentDocumentSetContentListTable>>()

const statusOrder: Record<SentDocumentSetContentList['contentStatusCode'], number> = {
  not_submitted: 1,
  submitted: 2,
  confirmed: 3
}

const sortDocuments = (
  data: MiHospitalizationOrderDocumentSetItem[],
  orderBy: string,
  order: Order
) => {
  const sortedData = [...data]
  if (orderBy === 'contentStatusCode') {
    sortedData.sort(
      (a, b) =>
        statusOrder[a.contentStatusCode as keyof typeof statusOrder] -
        statusOrder[b.contentStatusCode as keyof typeof statusOrder]
    )
  }

  if (orderBy === 'documentSetTitle' || orderBy === 'contentTitle' || orderBy === 'contentType') {
    sortedData.sort((a, b) =>
      a[orderBy as keyof MiHospitalizationOrderDocumentSetItem].localeCompare(
        b[orderBy as keyof MiHospitalizationOrderDocumentSetItem],
        'ja'
      )
    )
  } else if (orderBy === 'updatedAt') {
    sortedData.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
  }

  return order.toUpperCase() == 'DESC' ? sortedData.reverse() : sortedData
}

watch([orderBy, order], () => {
  documentSetList.value =
    order.value && orderBy.value
      ? sortDocuments(defaultDocumentSetList.value, orderBy.value, order.value)
      : defaultDocumentSetList.value
})

onMounted(async () => {
  isLoading.value = true
  const response =
    await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      hospitalizationOrderId.value,
      { optimId: '' }
    )
  defaultDocumentSetList.value = response.data.list
    .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime())
    .reverse()
  documentSetList.value = defaultDocumentSetList.value
  isLoading.value = false
})

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}
</script>
<template>
  <div class="sent-document-sets-list">
    <div>
      <SentDocumentSetContentListTable
        :isLoading="isLoading"
        :hospitalizationOrderId="hospitalizationOrderId"
        ref="sentDocumentSetContentListTable"
        v-model:document-set-list="documentSetList"
        @update:sort-by="sortBy"
      />
    </div>
  </div>
</template>

<style>
.sent-document-sets-list {
  min-width: 520px;
  background-color: white;
  min-height: 640px;
  border-radius: 0 8px 8px 8px;
  padding: 16px;
}
</style>
