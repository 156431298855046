<script setup lang="ts">
import DocumentSetSelectDialog from '@features/SendDocumentSets/SendDocumentSetsForm/DocumentSetSelectList/DocumentSetSelectDialog/index.vue'
import DocumentSetSelectSearch from '@features/SendDocumentSets/SendDocumentSetsForm/DocumentSetSelectList/DocumentSetSelectSearch/index.vue'
import DocumentSetSelectTable from '@features/SendDocumentSets/SendDocumentSetsForm/DocumentSetSelectList/DocumentSetSelectTable/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiDocumentSetItem } from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'

import { useDocumentSetsStore } from '@/stores/documentSets'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const selectDocumentSetStore = useDocumentSetsStore()
const medicalInstitutionsStore = useMedicalInstitutionsStore()
const { t } = useI18n()

defineProps<{
  isOpenDocumentSetSelectDialog: boolean
  hospitalizationOrderId: string
}>()

const emit = defineEmits<{
  (e: 'toggleDocumentSetSelectDialog'): void
}>()

const toggleDocumentSetSelectDialog = () => {
  emit('toggleDocumentSetSelectDialog')
}

// 登録済み書類セットと選択中の書類セットの状態を管理
const registeredDocumentSets = defineModel<MiDocumentSetItem[]>('registeredDocumentSets', {
  required: true
})
const selectDocumentSets = ref<string[]>([])
const changeRegisteredDocumentSets = () => {
  const documentSetList = selectDocumentSetStore.getDocumentSetList()

  // 新規に追加する要素を抽出
  const newItemsToAdd = documentSetList.value.filter(
    (documentSet: MiDocumentSetItem) =>
      selectDocumentSets.value.includes(documentSet.id) &&
      !registeredDocumentSets.value.some(
        (registeredDocumentSet) => registeredDocumentSet.id === documentSet.id
      )
  )

  // registeredDocumentSetsから削除する要素のidを抽出
  const itemsToRemoveIds = registeredDocumentSets.value
    .filter((registeredDocumentSet) => !selectDocumentSets.value.includes(registeredDocumentSet.id))
    .map((documentSet) => documentSet.id)

  registeredDocumentSets.value = [
    ...registeredDocumentSets.value.filter((item) => !itemsToRemoveIds.includes(item.id)),
    ...newItemsToAdd
  ]
}

const selectLimit = 10
const isOverSelect = ref<boolean>(false)

const isLoading = ref(true)
const searchTitle = ref<string>('')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
const selectContentsListTable = ref<InstanceType<typeof DocumentSetSelectTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (selectContentsListTable.value == undefined) {
    return
  }

  selectContentsListTable.value.resetScroll()
}

watch([searchTitle, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  selectDocumentSetStore
    .fetchDocumentSetList(medicalInstitutionId, searchTitle.value, orderBy.value, order.value)
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch documentSet list')
      }
    })
})

watch(selectDocumentSets, () => {
  selectDocumentSets.value.length > selectLimit
    ? (isOverSelect.value = true)
    : (isOverSelect.value = false)
})

onMounted(async () => {
  const response = await selectDocumentSetStore.fetchDocumentSetList(
    medicalInstitutionId,
    searchTitle.value,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch documentSet list')
  }

  selectDocumentSets.value = registeredDocumentSets.value.map((documentSet) => documentSet.id)
})

onBeforeMount(() => {
  selectDocumentSetStore.clearDocumentSetList()
})

const requestNextItem = (item: MiDocumentSetItem) => {
  isLoading.value = true
  fetchNextDocumentSetList(item)
}

const fetchNextDocumentSetList = async (item: MiDocumentSetItem) => {
  const response = await selectDocumentSetStore.fetchNextDocumentSetList(
    medicalInstitutionId,
    item.id,
    searchTitle.value,
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}
</script>

<template>
  <DocumentSetSelectDialog
    :value="isOpenDocumentSetSelectDialog"
    :title="t('features.SendDocumentSets.Form.DocumentSetSelectList.Dialog.title')"
    :isOverSelect="isOverSelect"
    @input="toggleDocumentSetSelectDialog"
    @confirm="changeRegisteredDocumentSets"
  >
    <template v-slot:documentSet>
      <div class="container">
        <div class="head">
          <DocumentSetSelectSearch v-model:search-title="searchTitle" />
        </div>
        <DocumentSetSelectTable
          v-model:select-items="selectDocumentSets"
          ref="selectContentsListTable"
          :isLoading="isLoading"
          :hospitalizationOrderId="hospitalizationOrderId"
          @requestNextItem="
            () => {
              const documentSetList = selectDocumentSetStore.getDocumentSetList()
              requestNextItem(documentSetList.value.slice(-1)[0])
            }
          "
          @update:sort-by="sortBy"
        />
      </div>
    </template>
  </DocumentSetSelectDialog>
</template>

<style lang="scss" scoped>
.container {
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.head {
  width: 240px;
}
</style>
