import AsyncLock from 'async-lock'
import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { documentManagementApiClient } from '@/api/apiClient'
import { type MiContentListResponse } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'

const limit = 50

interface SearchConditions {
  medicalInstitutionId?: string
  title?: string
  tag?: string
  orderBy?: string
  order?: string
}

export const useContentsStore = defineStore('contents', () => {
  const lock = new AsyncLock({ timeout: 2000 })
  const searchConditions = ref<SearchConditions>({})
  const currentProcess = ref<string | undefined>()
  const searchedLastContentId = ref<string | undefined>()
  const contentList = ref<MiContentListResponse['list']>([])
  const getContentList = () => contentList

  /**
   * 検索結果を保存する
   * 結果は排他制御し、全て同一の呼び出しで更新されることを保証する
   */
  const setSearchResult = async (
    medicalInstitutionId: string,
    _searchedLastContentId: string | undefined,
    _contentList: MiContentListResponse['list'],
    title: string | undefined,
    tag: string | undefined,
    orderBy: string | undefined,
    order: string | undefined
  ) => {
    await lock.acquire('setSearchResult', () => {
      searchedLastContentId.value = _searchedLastContentId
      contentList.value = _contentList
      searchConditions.value = {
        medicalInstitutionId,
        title,
        tag,
        orderBy,
        order
      }
    })
  }

  const fetchContentList = async (
    medicalInstitutionId: string,
    title?: string,
    tag?: string,
    orderBy?: string,
    order?: string
  ): Promise<FetchApiResponse<MiContentListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    try {
      const response = await documentManagementApiClient().api.contentsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          title,
          tag,
          limit: limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        undefined,
        response.data.list,
        title,
        tag,
        orderBy,
        order
      )

      return {
        success: true,
        content: contentList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const fetchNextContentList = async (
    medicalInstitutionId: string,
    lastContentId: string,
    title: string = '',
    tag?: string,
    orderBy?: string,
    order?: Order
  ): Promise<FetchApiResponse<MiContentListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    // 重複した検索
    if (searchedLastContentId.value == lastContentId) {
      return {
        success: false,
        status: 400
      }
    }
    // 検索キーが一致するか確認
    if (
      searchConditions.value.medicalInstitutionId != medicalInstitutionId ||
      searchConditions.value.orderBy != orderBy ||
      searchConditions.value.order != order
    ) {
      // 一致しない場合は、追加検索ではなく初期化を行う
      return fetchContentList(medicalInstitutionId, title, orderBy, order)
    }

    // 取得処理
    try {
      const response = await documentManagementApiClient().api.contentsByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          lastContentId: lastContentId,
          title,
          tag,
          limit: limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        lastContentId,
        [...contentList.value, ...response.data.list],
        title,
        tag,
        orderBy,
        order
      )

      return {
        success: true,
        content: contentList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const clearContentList = () => {
    contentList.value = []
    searchedLastContentId.value = undefined
    searchConditions.value = {}
    currentProcess.value = undefined
  }

  return {
    getContentList,
    fetchContentList,
    fetchNextContentList,
    clearContentList
  }
})
