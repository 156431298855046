<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type {
  SearchDocumentSetStatusCode,
  SearchHospitalizationOrderStatusCode,
  SearchUseAppStatusCode
} from './type'

import { type MiHospitalizationOrderItem } from '@/api/generate/apiSchema'
import {
  DocumentSetStatusCode,
  HospitalizationOrderStatusCode,
  UseAppStatusCode,
  type Order
} from '@/api/types'
import HospitalizationOrderListSearch from '@/features/HospitalizationOrderList/HospitalizationOrderListSearch/index.vue'
import HospitalizationOrderListTable from '@/features/HospitalizationOrderList/HospitalizationOrderListTable/index.vue'
import router from '@/router'
import { useHospitalizationOrdersStore } from '@/stores/hospitalizationOrders'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const hospitalizationOrdersStore = useHospitalizationOrdersStore()
const { t } = useI18n()

const isLoading = ref(true)
const searchPatientNo = ref<string>('')
const searchPatientName = ref<string>('')
const searchUseAppStatus = ref<SearchUseAppStatusCode>('all')
const searchHospitalizationOrderStatus = ref<SearchHospitalizationOrderStatusCode>('all')
const searchDocumentSetStatus = ref<SearchDocumentSetStatusCode>('all')
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const hospitalizationOrderListTable = ref<InstanceType<typeof HospitalizationOrderListTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (hospitalizationOrderListTable.value == undefined) {
    return
  }

  hospitalizationOrderListTable.value.resetScroll()
}

const getSearchUseAppStatus = (): UseAppStatusCode | undefined => {
  if (searchUseAppStatus.value == 'all') {
    return undefined
  } else {
    return searchUseAppStatus.value
  }
}

const getSearchHospitalizationOrderStatus = (): HospitalizationOrderStatusCode | undefined => {
  if (searchHospitalizationOrderStatus.value == 'all') {
    return undefined
  } else {
    return searchHospitalizationOrderStatus.value
  }
}

const getSearchDocumentSetStatus = (): DocumentSetStatusCode | undefined => {
  if (searchDocumentSetStatus.value == 'all') {
    return undefined
  } else {
    return searchDocumentSetStatus.value
  }
}

watch(
  [
    searchPatientNo,
    searchPatientName,
    searchUseAppStatus,
    searchHospitalizationOrderStatus,
    searchDocumentSetStatus,
    orderBy,
    order
  ],
  () => {
    // 前回の検索を実行中でも検索を新規開始する
    isLoading.value = true
    hospitalizationOrdersStore
      .fetchHospitalizationOrderList(
        medicalInstitutionsStore.currentMedicalInstitution().id,
        searchPatientNo.value,
        searchPatientName.value,
        getSearchUseAppStatus(),
        getSearchHospitalizationOrderStatus(),
        getSearchDocumentSetStatus(),
        orderBy.value,
        order.value
      )
      .then((response) => {
        if (response.success || response.cancel != true) {
          isLoading.value = false
        }

        if (response.success) {
          resetScroll()
        } else {
          throw Error('fetch hospitalization order list')
        }
      })
  }
)

onMounted(async () => {
  const response = await hospitalizationOrdersStore.fetchHospitalizationOrderList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    searchPatientNo.value,
    searchPatientName.value,
    getSearchUseAppStatus(),
    getSearchHospitalizationOrderStatus(),
    getSearchDocumentSetStatus(),
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch hospitalization order list')
  }
})

onBeforeMount(() => {
  hospitalizationOrdersStore.clearHospitalizationOrderList()
})

const requestNextItem = (item: MiHospitalizationOrderItem) => {
  // isLoadingは同期的に処理し、追加のデータを要求する
  isLoading.value = true
  fetchNextHospitalizationOrderList(item)
}

const fetchNextHospitalizationOrderList = async (item: MiHospitalizationOrderItem) => {
  const response = await hospitalizationOrdersStore.fetchNextHospitalizationOrderList(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    item.id,
    searchPatientNo.value,
    searchPatientName.value,
    getSearchUseAppStatus(),
    getSearchHospitalizationOrderStatus(),
    getSearchDocumentSetStatus(),
    orderBy.value,
    order.value
  )
  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}

const transitionNewHospitalizationOrder = () => {
  router.push('/hospitalization-orders/new')
}
</script>

<template>
  <div class="hospitalization-order-list rounded-lg">
    <div class="hospitalization-order-list-header">
      <HospitalizationOrderListSearch
        v-model:searchPatientNo="searchPatientNo"
        v-model:searchPatientName="searchPatientName"
        v-model:searchUseAppStatus="searchUseAppStatus"
        v-model:searchHospitalizationOrderStatus="searchHospitalizationOrderStatus"
        v-model:searchDocumentSetStatus="searchDocumentSetStatus"
      />

      <BaseButton
        class="new-button"
        v-if="medicalInstitutionsStore.isGeneral()"
        type="icon-link"
        color="primary"
        :loading="false"
        :content="t('attributes.newCreate')"
        elevated="text"
        min-height="48px"
        icon="add"
        @click="transitionNewHospitalizationOrder()"
      ></BaseButton>
    </div>
    <div class="hospitalization-order-list-body">
      <HospitalizationOrderListTable
        ref="hospitalizationOrderListTable"
        :isLoading="isLoading"
        @requestNextItem="requestNextItem"
        @update:sort-by="sortBy"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hospitalization-order-list {
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 652px;
  min-width: 520px;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hospitalization-order-list-header {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: start;
  gap: 8px;
}

.hospitalization-order-list-header > * {
  flex: 1 1;
}

.hospitalization-order-list-body {
  @media (0px <= width < 768px) {
    height: calc(100% - 168px);
  }
  @media (768px <= width < 1280px) {
    height: calc(100% - 112px);
  }
  @media (1280px <= width) {
    height: calc(100% - 62px);
  }
}

.new-button {
  @media (0px <= width < 768px) {
    min-width: 100%;
  }
  @media (768px <= width) {
    min-width: 122.25px;
    max-width: 122.25px;
  }
}
</style>
