<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import type { CheckBoxOption } from '../type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォームオプション
   */
  option: CheckBoxOption
}>()
const option = reactive(props.option)

// フォーム作成時に一つ作成する
onMounted(() => {
  if (option.selects.length <= 0) {
    option.selects.push('')
  }
})

// 選択肢の上限数を管理
const addMaxLimit = 20
const addChoice = () => {
  if (option.selects.length < addMaxLimit) {
    option.selects.push('')
  }
}

// 選択肢を削除
const removeChoice = (index: number) => {
  option.selects.splice(index, 1)
}

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}

// スタイル用
const labelStyle = computed(() => getTypography('LABEL_M'))
const bodyStyle = computed(() => getTypography('BODY_S'))
</script>

<template>
  <div class="check-box-form-option">
    <div class="content">
      <!-- 項目名 -->
      <div>
        <v-text-field
          v-model="option.name"
          class="required-field"
          :placeholder="t('features.FormContents.new.placeholder.fieldName')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
          :rules="[required(option.name, t('features.FormContents.new.placeholder.fieldName'))]"
        ></v-text-field>
      </div>

      <!-- 説明 -->
      <div>
        <v-text-field
          v-model="option.description"
          :placeholder="t('features.FormContents.new.placeholder.description')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
        ></v-text-field>
      </div>

      <div class="check-box-field" v-for="(item, index) in option.selects" :key="index">
        <!-- 選択肢 -->
        <v-text-field
          v-model="option.selects[index]"
          class="required-field"
          :placeholder="t('features.FormContents.new.checkBox.choice')"
          prepend-icon="check_box_outline_blank"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          :rules="[
            required(option.selects[index], t('features.FormContents.new.placeholder.choice'))
          ]"
        ></v-text-field>

        <!-- 選択肢削除ボタン -->
        <v-btn
          class="close-button text-blue-grey-lighten-3"
          valiant="flat"
          tabindex="-1"
          flat
          icon="close"
          @click="removeChoice(index)"
        ></v-btn>
      </div>

      <div>
        <!-- 選択肢を追加するボタン -->
        <v-btn
          class="option-btn text-primary"
          :style="labelStyle"
          label="option"
          valiant="plain"
          rounded="lg"
          :disabled="option.selects.length >= addMaxLimit"
          flat
          @click="addChoice"
        >
          <template v-slot:prepend>
            <v-icon icon="add" size="20"> </v-icon>
          </template>
          {{ t('features.FormContents.new.checkBox.addChoice') }}
        </v-btn>
      </div>
    </div>
    <v-divider class="divider"></v-divider>

    <div class="bottom">
      <!-- 回答必須スイッチ -->
      <v-switch
        v-model="option.isRequired"
        :style="bodyStyle"
        :label="t('features.FormContents.new.input.required')"
        color="primary"
        baseColor="theme-DEFAULT-surface-tertiary-value"
        hide-details
        inset
        flat
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.no-under-gutters .v-input__details) {
  display: none;
}
.close-button {
  background-color: transparent;
}
.field-gap:not(:first-child) {
  margin-top: 20px;
}
:deep(.v-btn:disabled) {
  color: rgb(var(--surface-surface-transparent)) !important;
  opacity: var(--v-disabled-opacity) !important;
}
:deep(.v-btn:disabled .v-btn__overlay) {
  display: none;
}
:deep(.required-field :before) {
  border-width: 0 0 3px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.bottom {
  display: flex;
  justify-content: end;
  gap: 16px;
  height: 36px;
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-input--density-default {
  --v-input-control-height: 36px;
}

.check-box-field {
  display: flex;
}

.option-btn {
  height: 24px;
  min-width: auto !important;
  padding: 0 4px;
}
</style>

<style lang="scss">
.check-box-form-option {
  .bottom {
    .switch {
      .v-selection-control--density-default {
        --v-selection-control-size: 36px !important;
      }
    }
  }
}
</style>
