<script setup lang="ts">
import TableItem from '@components/Table/DragAndDropTable/TableItem.vue'
import { computed, onMounted, onUpdated, ref } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { VDataTable } from 'vuetify/lib/components/index.mjs'

import type { Header, Item } from '@components/Table/DragAndDropTable/types'

import { getToken } from '@/components/Typography/token'

/**
 * ソートして表示可能なテーブルコンポーネント
 * ソートは外部からソートされる前提となる
 * ページネーションはスクロールのみとなる
 */

type Props = {
  headers: Header[] // keyがactionは使用しているため注意
  modelValue: Item[]
  loading?: boolean
  noDataText: string // データがない場合に表示されるテキスト
  height?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  height: '100%'
})

const dataTable = ref<InstanceType<typeof VDataTable>>()

const emit = defineEmits<{
  (e: 'itemClick', item: Item): void
  (e: 'update:modelValue', items: Item[]): void
}>()

/**
 * アクション用のヘッダーを追加する場合は、追加した上で返す
 */
const headers = computed(() => {
  return props.headers
})

const draggable = (items: Item[]) => {
  emit('update:modelValue', items)
}

const dataTableTokenStyle = getToken('BODY_M')

// 削除した tbody を保持する変数
let removedTbody: Element | null = null
let isTbodyRemoved = false // フラグ変数

// tbody 内のすべての td をチェックして、「表示するコンテンツはありません」というテキストが含まれているか確認
const checkRemoveTbody = (tbody: Element): boolean => {
  const textContent = Array.from(tbody.querySelectorAll('tr > td'))
    .map((td) => (td as HTMLElement).innerText?.trim())
    .join(' ')
  return textContent === props.noDataText
}

const removeTbody = () => {
  const tbodyList = document.querySelectorAll('.v-table__wrapper > table > tbody')
  if (tbodyList.length === 0) return

  for (const tbody of tbodyList) {
    if (checkRemoveTbody(tbody)) {
      if (!isTbodyRemoved) {
        removedTbody = tbody
        isTbodyRemoved = true
      }
      tbody.remove()
      break
    }
  }
}

const restoreTbody = () => {
  const table = document.querySelector('.v-table__wrapper > table')
  if (table && removedTbody) {
    table.appendChild(removedTbody)
  }
}

onMounted(() => {
  if (props.modelValue.length != 0) {
    removeTbody()
  }
})

onUpdated(() => {
  if (props.modelValue.length != 0) {
    removeTbody()
  }
  if (props.modelValue.length == 0) {
    restoreTbody() // modelValueが空の場合は復元
  }
})

const onChoose = () => {
  setTimeout(() => {
    document.body.classList.add('grabbing')
  })
}
const onUnChoose = () => {
  document.body.classList.remove('grabbing')
}
</script>

<template>
  <v-data-table
    ref="dataTable"
    class="elevation-1 sort-table"
    :style="dataTableTokenStyle"
    :headers="headers"
    :loading="loading"
    disable-pagination
    fixed-header
    :height="height"
    width="100%"
    hide-default-footer
    :items-per-page="-1"
    density="compact"
    :no-data-text="noDataText"
    :disable-sort="true"
  >
    <template v-slot:headers="{ columns }">
      <tr>
        <th class="drag-handler-header"></th>
        <template v-for="column in columns" :key="column.key">
          <th
            class="table-header-column"
            :style="`min-width: ${column.minWidth}; width: ${column.width};`"
          >
            <span class="table-header-item">{{ column.title }}</span>
          </th>
        </template>
      </tr>
    </template>
    <template #tbody>
      <VueDraggable
        :handle="'.drag-handler-column'"
        :forceFallback="true"
        :model-value="modelValue"
        @update:modelValue="draggable"
        tag="tbody"
        :key="modelValue.map((item: Item) => item.id).join('-')"
        @choose="onChoose"
        @unchoose="onUnChoose"
      >
        <tr v-for="(item, index) in modelValue" :key="index" class="dragger-table-body">
          <td class="drag-handler-column">
            <v-icon class="drag-handle-icon" icon="drag_handle" size="20"></v-icon>
          </td>
          <TableItem
            v-for="header in headers"
            :key="header.key"
            :header="header"
            :itemValue="item[header.key]"
          >
          </TableItem>
        </tr>
      </VueDraggable>
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
.drag-handler-header {
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: #f2f2f2 !important;
  padding: 8px !important;
}
.drag-handler-column {
  cursor: grab;
  padding: 8px !important;
  max-width: 36px;
  min-width: 36px;
  width: 36px !important;
}
.table-header-column {
  padding: 8px !important;
  background-color: #f2f2f2 !important;
  &--center {
    text-align: center !important;
  }
}
.v-table--density-compact {
  --v-table-header-height: 36px;
}

.table-header-item {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}
.drag-handle-icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.sort-table-body {
  cursor: pointer;
  transition-duration: 0.28s;
  transition-property: box-shadow, opacity, height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
