//  APIのレスポンスのような、画面に表示する要素を指定するファイル

export const attributes = {
  role: {
    admin: '管理者',
    general: '一般',
    read: '閲覧'
  },
  login: 'ログイン',
  logout: 'ログアウト',
  register: '登録',
  confirm: '確定',
  save: '保存',
  create: '作成',
  send: '送信',
  edit: '編集',
  delete: '削除',
  newCreate: '新規作成',
  newAdd: '新規追加',
  cancel: 'キャンセル',
  close: '閉じる',
  required: '必須',
  comma: '、',
  successCreated: '{resource}の登録に成功しました',
  successUpdated: '{resource}の更新に成功しました',
  successDeleted: '{resource}の削除に成功しました',
  failureDelete: '{resource}の削除に失敗しました',
  failureFetch: '{resource}の取得に失敗しました',
  deleteDialog: {
    title: '{resource}削除',
    message: '{resource}を削除します。\n※この動作は取り消せません。'
  },
  applyDialog: {
    title: '{resource}確定'
  },
  select: '選択',
  successRegistration: '{resource}の登録に成功しました',
  all: 'すべて',
  useAppStatus: {
    used: '登録済',
    notUsed: '未登録'
  },
  appUsers: {
    notUsed: '未登録',
    patient: '本人',
    representative: '代理人',
    patientAndRepresentative: '本人/代理人',
    user: 'ユーザー',
    namePrefix: '・'
  },
  hospitalizationOrderStatus: {
    preHospital: '入院前',
    hospital: '入院中',
    discharged: '退院済'
  },
  documentSetStatus: {
    notSent: '未送付',
    notSubmitted: '未提出',
    partialSubmitted: '一部提出',
    submitted: '提出済',
    confirmed: '確定済'
  },
  hospitalizationStatus: {
    beforeHospitalization: '入院前',
    nowHospitalization: '入院中',
    afterHospitalization: '退院済'
  },
  gender: {
    1: '男性',
    2: '女性'
  },
  noDataText: 'データが存在しません。',
  noDataHyphen: '-',
  formatTemplate: {
    required: '{resource} は必須項目です',
    fullAge: ' (満{resource}歳)',
    phoneticName: ' ({resource})'
  },
  relationshipType: {
    wife: '妻',
    husband: '夫',
    biological_mother: '実母',
    biological_father: '実父',
    mother_in_law: '義母',
    father_in_law: '義父',
    eldest_son: '長男',
    eldest_daughter: '長女',
    second_son: '次男',
    second_daughter: '次女',
    third_son: '三男',
    third_daughter: '三女',
    fourth_son: '四男',
    fourth_daughter: '四女',
    fifth_son: '五男',
    fifth_daughter: '五女',
    older_brother: '兄',
    older_sister: '姉',
    younger_brother: '弟',
    younger_sister: '妹',
    grand_father: '祖父',
    grand_mother: '祖母',
    grand_son: '孫息子',
    grand_daughter: '孫娘',
    uncle: '叔父',
    aunt: '叔母',
    nephew: '甥',
    niece: '姪',
    other: 'その他'
  }
}
