<script setup lang="ts">
import { computed, ref, toRef, withDefaults } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiHospitalizationOrderRepresentativeItem } from '@/api/generate/apiSchema'
import type { PatientDetailInput } from '@/features/PatientDetail/type'

import Card from '@/components/Base/Card/index.vue'
import useFormattedName from '@/composables/useFormattedName'
import IdInputDialog from '@/features/HospitalizationOrderDetail/Card/AppUsage/IdInputDialog/index.vue'
import QrCodeScanner from '@/features/HospitalizationOrderDetail/Card/AppUsage/QrCodeScanner/index.vue'
import RepresentativeDialog from '@/features/HospitalizationOrderDetail/Card/AppUsage/RepresentativeDialog/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

type Props = {
  /**
   * ボタンの色分岐
   */
  outlined?: boolean
  /**
   * ボタンのdisabled
   */
  disabled?: boolean
  /**
   * 入院オーダーID
   */
  hospitalizationId: string
  /**
   * 患者ID
   */
  patientId: string
  /**
   * 患者情報
   */
  patientDetail: PatientDetailInput
  /**
   * 代理人
   */
  representatives: MiHospitalizationOrderRepresentativeItem[]
  /**
   * 患者とアプリユーザの紐付け
   */
  userPatientId: string | undefined
  /**
   * ローディング
   */
  loading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  outlined: true,
  disabled: true,
  hospitalizationId: '',
  patientId: ''
})

type Emits = {
  (e: 'bind'): void
}

const emit = defineEmits<Emits>()

const formattedName = useFormattedName()

const title = t('features.Card.appUsage.title')
const firstBtnText = t('features.Card.appUsage.button.QrCodeScanner')
const secondBtnText = t('features.Card.appUsage.button.idInput')

const patientName = computed(() => {
  return formattedName.getFormattedName(
    props.patientDetail.familyName,
    props.patientDetail.givenName
  )
})

const representativesName = computed(() => {
  const nameList = props.representatives.map((representative) => {
    return `${formattedName.getFormattedName(representative.familyName, representative.givenName)}（${t(`attributes.relationshipType.${representative.relationshipCode}`)}）`
  })
  return nameList
})

const label = computed(() => {
  if (props.userPatientId == undefined && representativesName.value.length == 0) {
    return t('attributes.appUsers.notUsed')
  } else if (props.userPatientId != undefined && representativesName.value.length != 0) {
    return t('attributes.appUsers.patientAndRepresentative')
  } else if (props.userPatientId != undefined) {
    return t('attributes.appUsers.patient')
  } else {
    return t('attributes.appUsers.representative')
  }
})

const chipColor = computed(() => {
  if (props.userPatientId == undefined && representativesName.value.length == 0) {
    return 'error'
  } else {
    return 'primary'
  }
})

const isQrCodeScanner = ref(false)
const toQrCodeScanner = () => {
  isQrCodeScanner.value = !isQrCodeScanner.value
}

const isIdDialog = toRef<boolean>(false)

const idDialog = () => {
  isIdDialog.value = !isIdDialog.value
}

const isRepresentativeDialog = toRef<boolean>(false)
const bindingToken = ref('')

const openRepresentativeDialog = (id: string) => {
  bindingToken.value = id
  isRepresentativeDialog.value = !isRepresentativeDialog.value

  isIdDialog.value = false
  if (isQrCodeScanner.value) {
    isQrCodeScanner.value = false
    displayName()
  }
}

const closeRepresentativeDialog = () => {
  isRepresentativeDialog.value = !isRepresentativeDialog.value
  bindingToken.value = ''
}

const bind = () => {
  isRepresentativeDialog.value = !isRepresentativeDialog.value
  emit('bind')
}

const cancelQrScanner = () => {
  isQrCodeScanner.value = !isQrCodeScanner.value
  displayName()
}

const displayNameStore = useDisplayNameStore()
const displayName = () => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.hospitalizationOrders.list'),
        href: '/hospitalization-orders',
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.detail'),
        disable: true
      }
    ]
  })
}
</script>
<template>
  <div v-if="!loading">
    <Card
      :title="title"
      :label="label"
      :showChip="true"
      :chipColor="chipColor"
      :outlined="useMedicalInstitutionsStore().isGeneral()"
      :firstBtnText="firstBtnText"
      :secondBtnText="secondBtnText"
      :layout="'split-h'"
      @firstClick="toQrCodeScanner"
      @secondClick="idDialog"
      :firstBtnDisabled="!useMedicalInstitutionsStore().isGeneral()"
      :secondBtnDisabled="!useMedicalInstitutionsStore().isGeneral()"
    >
      <template #body>
        <div v-if="!userPatientId && representativesName.length === 0">
          <p>{{ t('features.Card.appUsage.body.notUsed') }}</p>
        </div>
        <div v-else>
          <p>{{ t('features.Card.appUsage.body.used') }}</p>
          <p v-if="userPatientId">
            {{ t('attributes.appUsers.namePrefix') }}{{ patientName
            }}{{ `（${t('attributes.appUsers.patient')}）` }}
          </p>
          <p v-for="(representativeName, index) in representativesName" :key="index">
            {{ t('attributes.appUsers.namePrefix') }}{{ representativeName }}
          </p>
        </div>
      </template>
    </Card>
  </div>
  <IdInputDialog :value="isIdDialog" @input="idDialog" @confirm="openRepresentativeDialog" />
  <RepresentativeDialog
    :value="isRepresentativeDialog"
    :bindingToken="bindingToken"
    :hospitalizationId="props.hospitalizationId"
    :patientId="props.patientId"
    @close="closeRepresentativeDialog"
    @bind="bind"
  />
  <QrCodeScanner
    v-if="isQrCodeScanner"
    @cancel="cancelQrScanner"
    @confirm="openRepresentativeDialog"
  />
</template>
<style lang="scss"></style>
