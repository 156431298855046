import { computed, ref, shallowRef, type Ref } from 'vue'

/**
 * @param state ディレイを行って変更したい値のRef or computed
 * @param delayTime ディレイを行うms
 * @returns value: リアルタイムに値が変更される。:model-valueに設定する, setValue: セッター。@inputに設定する
 */
export const useLazyChange = <T>(
  state: Ref<T> | ReturnType<typeof computed<T>>,
  delayTime: number = 500
) => {
  const timeoutId = ref<NodeJS.Timeout | string | number | undefined>()
  const value = shallowRef<T>(state.value)

  const changeState = () => {
    state.value = value.value
  }

  const setValue = (v: T) => {
    clearTimeout(timeoutId.value)
    value.value = v
    timeoutId.value = setTimeout(changeState, delayTime)
  }

  return {
    /** Value at which changes are reflected in real time */
    value,
    /** setter */
    setValue
  }
}
