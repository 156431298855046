<script setup lang="ts">
import Text from '@components/Base/BodyText/index.vue'
import Label from '@components/Base/Label/index.vue'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import PatientIntelligence from './PatientIntelligence/index.vue'

import type { UrlContentDetail } from './type'
import type { PatientDetailInput } from '../PatientDetail/type'
import type {
  MiHospitalizationOrderDocumentSetContentResponse,
  MiHospitalizationOrderDocumentSetItem,
  MiHospitalizationOrderResponse,
  MiUrlContentRevisionResponse
} from '@/api/generate/apiSchema'

import { documentManagementApiClient, hospitalizationOrderApiClient } from '@/api/apiClient'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'

const axiosErrorGuard = useAxiosErrorGuard()
const { t } = useI18n()

// 初期化
const props = withDefaults(
  defineProps<{
    hospitalizationOrderDetail: MiHospitalizationOrderResponse
    patientDetail: PatientDetailInput
    medicalInstitutionId: string
    hospitalizationOrderDocumentSetContentId: string
    hospitalizationOrderDocumentSetItem: MiHospitalizationOrderDocumentSetItem
  }>(),
  {}
)

// 入院オーダー書類セットコンテンツ
const documentSetContent = ref<MiHospitalizationOrderDocumentSetContentResponse>()

// URLコンテンツ詳細
const urlRevision = ref<MiUrlContentRevisionResponse>()

// 入院オーダー書類セットコンテンツを取得
const fetchDocumentSetContent = async () => {
  const response = await hospitalizationOrderApiClient({ allowNotFound: true })
    .api.hospitalizationOrderDocumentSetContentByMedicalInstitution(
      props.medicalInstitutionId,
      props.hospitalizationOrderDetail.id,
      props.hospitalizationOrderDocumentSetItem.hospitalizationOrderDocumentSetId,
      props.hospitalizationOrderDocumentSetItem.hospitalizationOrderDocumentSetContentId,
      { optimId: '' }
    )
    .catch((error) => {
      if (axiosErrorGuard.isNotFound(error)) {
        return undefined
      }
      throw error
    })

  documentSetContent.value = response?.data
}

// URLコンテンツのリビジョンを取得
const fetchUrlContent = async () => {
  const response = await documentManagementApiClient({
    allowNotFound: false
  }).api.urlContentRevisionByMedicalInstitution(
    props.medicalInstitutionId,
    props.hospitalizationOrderDocumentSetItem.contentId,
    props.hospitalizationOrderDocumentSetItem.contentRevisionId,
    { optimId: '' }
  )

  urlRevision.value = response.data
}

// URLコンテンツを取得
const urlContentDetail = computed((): UrlContentDetail => {
  return {
    id: urlRevision.value?.id,
    revisionId: documentSetContent.value?.contentRevisionId,
    title: documentSetContent.value?.title,
    description: documentSetContent.value?.description,
    url: urlRevision.value?.url,
    createdAt: urlRevision.value?.createdAt
  } as UrlContentDetail
})

// 書類提出状況
const documentStatus = computed<string>(() => {
  // URLコンテンツは提出ステータスを持たないため固定値
  return t('attributes.noDataHyphen')
})

// 更新日時の取得
const updateDateTime = computed<string>(() => {
  // URLコンテンツは更新日時を持たないため固定値
  return t('attributes.noDataHyphen')
})

// ドキュメントステータスCSSスタイル
const documentStatusStyle = computed<Record<string, string>>(() => {
  return {
    backgroundColor: 'inherit !important',
    color: 'inherit !important'
  }
})

onMounted(async () => {
  await Promise.all([fetchDocumentSetContent(), fetchUrlContent()])
})
</script>

<template>
  <!-- 右上アクションボタン群 -->
  <div class="hospitalization-order-detail-header"></div>

  <!-- 患者基本情報 -->
  <div class="hospitalization-order-detail rounded-lg">
    <PatientIntelligence
      class="patient-intelligence"
      :patient-detail="patientDetail"
      :representatives="hospitalizationOrderDetail.representatives"
      :document-status-code="documentStatus"
      :update-date="updateDateTime"
      :document-status-styles="documentStatusStyle"
    />
  </div>

  <!-- URLコンテンツ-->
  <div v-if="urlContentDetail !== undefined">
    <div class="rounded-lg url-content-detail-body">
      <div class="row">
        <Label for-html="content_title" :labelText="t('features.contentSet.Detail.input.title')" />
        <Text class="mt-1" :text="urlContentDetail.title" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.description')"
        />
        <Text class="url-content-description" :text="urlContentDetail.description" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label for-html="content_url" :labelText="t('features.contentSet.Detail.input.url')" />
        <a :href="urlContentDetail.url" target="_blank">
          <Text class="mt-1" :text="urlContentDetail.url" :typographyType="'p'" />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hospitalization-order-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
}

.hospitalization-order-detail-header {
  margin: 20px 0 0 0;
}

.row {
  word-wrap: break-word;
}

.patient-intelligence {
  flex-grow: 3;
  :deep(.property-field) {
    .value {
      min-width: 260px;
    }
  }
}

.url-content-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  padding: 20px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1019607843);
}

.url-content-description {
  white-space: pre-wrap;
  margin-top: 4px;
}
</style>
