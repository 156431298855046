<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { v4 as uuidV4 } from 'uuid'
import { defineProps, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import MenuButton from './FormOption/MenuButton.vue'
import SectionContainer from './FormOption/SectionContainer.vue'
import {
  createDefaultBranch,
  createInitFormOption,
  createInitSectionContainer
} from './TemplateFormFunctions'

import Label from '@/components/Base/Label/index.vue'
import {
  type FormContentInput,
  menuButtons,
  FormOptionType,
  SectionType,
  type FormOption,
  type SectionContainer as SectionContainerType,
  type Choice
} from '@/features/FormContentForm/type'

const { t } = useI18n()

// 入力フォーム
const props = defineProps<{ form: FormContentInput }>()
const form = reactive(props.form)

// フォームオプションとセクションの作成
const addFormOption = (optionType: FormOptionType | SectionType, sectionIndex: number): void => {
  // 最初のセクション作成
  const firstAddFormOption = form.contentJson === null || form.contentJson.length === 0
  if (firstAddFormOption) {
    form.contentJson = []
    form.contentJson.push(createInitSectionContainer(SectionType.Section))

    // フォームオプションは必ずセクションに内包される
    if (SectionType.Section !== optionType) {
      const formOption = createInitFormOption(optionType)
      if (formOption) {
        form.contentJson[sectionIndex].options.push(formOption)
      }
    }
    return
  }

  // 二個目以降のセクション作成
  if (SectionType.Section === optionType) {
    form.contentJson.push(createInitSectionContainer(optionType))
    return
  }

  // 二個目以降のフォームオプション作成
  const formOption = createInitFormOption(optionType)
  if (formOption) {
    form.contentJson[sectionIndex].options.push(formOption)
  }
}

// セクションコピー
// @Note: オプションコピー時の選択肢と入力セットについて、オプションコピーでは登録解除されないが、セクションコピー時は登録解除する
const copySection = (sectionIndex: number) => {
  const newSection = {
    id: uuidV4(),
    name: t('features.FormContents.new.input.copySectionInitValue'),
    options: form.contentJson[sectionIndex].options.map((option: FormOption) => {
      if (option.type === FormOptionType.Choice) {
        // IDと配列を初期化してコピー
        return {
          ...JSON.parse(JSON.stringify(option)),
          id: uuidV4(),
          choices: []
        }
      } else if (option.type === FormOptionType.InputSet) {
        // IDと配列を初期化してコピー
        return {
          ...JSON.parse(JSON.stringify(option)),
          id: uuidV4(),
          optionIds: []
        }
      } else {
        // IDを初期化してコピー
        return {
          ...JSON.parse(JSON.stringify(option)),
          id: uuidV4()
        }
      }
    })
  }
  // コピー元の直後にコピー
  form.contentJson.splice(sectionIndex + 1, 0, newSection)
}

// セクション削除
const deleteSection = (sectionIndex: number) => {
  const deleteIdSet: Set<string> = new Set(
    form.contentJson[sectionIndex].options.map((option: FormOption) => option.id)
  )

  // 削除対象セクション以外のセクション内を探索
  form.contentJson.forEach((section: SectionContainerType) => {
    // 自セクションならチェックする必要がないのでスキップ
    if (section.id === form.contentJson[sectionIndex].id) {
      return
    }
    section.options.forEach((option: FormOption) => {
      // 選択肢の分岐先に削除対象IDが含まれていた場合は初期化
      if (FormOptionType.Choice === option.type) {
        option.choices.forEach((choice: Choice) => {
          if (choice.branch.type === 'option') {
            if (deleteIdSet.has(choice.branch.id)) {
              choice.branch = createDefaultBranch()
            }
          }
        })
        // 入力セットに削除対象IDが含まれていた場合は該当項目を削除
      } else if (FormOptionType.InputSet === option.type) {
        option.optionIds.filter((id: string) => !deleteIdSet.has(id))
      }
    })
  })
  // 自セクションを削除
  form.contentJson.splice(sectionIndex, 1)
}

// セクションの上移動
const moveUpSection = (sectionIndex: number) => {
  if (sectionIndex > 0) {
    // フォームの襦袢を入れ替え
    ;[form.contentJson[sectionIndex], form.contentJson[sectionIndex - 1]] = [
      form.contentJson[sectionIndex - 1],
      form.contentJson[sectionIndex]
    ]
  }
}

// セクションの下移動
const moveDownSection = (sectionIndex: number) => {
  if (sectionIndex < form.contentJson.length - 1) {
    ;[form.contentJson[sectionIndex], form.contentJson[sectionIndex + 1]] = [
      form.contentJson[sectionIndex + 1],
      form.contentJson[sectionIndex]
    ]
  }
}

// オプションのコピー
const copyOption = (sectionIndex: number, optionIndex: number) => {
  const newOption = {
    ...JSON.parse(JSON.stringify(form.contentJson[sectionIndex].options[optionIndex])),
    id: uuidV4()
  } as FormOption
  // コピー元の直後にコピー
  form.contentJson[sectionIndex].options.splice(optionIndex + 1, 0, newOption)
}

// オプションの削除
const deleteOption = (sectionIndex: number, optionIndex: number) => {
  const deleteId = form.contentJson[sectionIndex].options[optionIndex].id
  // 自フォームオプションIDを登録しているフォームオプションの登録を解除
  form.contentJson.forEach((section: SectionContainerType) => {
    section.options.forEach((option: FormOption) => {
      // 選択肢の分岐先に削除対象IDが含まれていた場合は初期化
      if (FormOptionType.Choice === option.type) {
        option.choices.forEach((choice: Choice) => {
          if (choice.branch.type === 'option') {
            if (deleteId === choice.branch.id) {
              choice.branch = createDefaultBranch()
            }
          }
        })
        // 入力セットに削除対象IDが含まれていた場合は該当項目を削除
      } else if (FormOptionType.InputSet === option.type) {
        option.optionIds.filter((id: string) => deleteId !== id)
      }
    })
  })
  // 自フォームオプションを削除
  form.contentJson[sectionIndex].options.splice(optionIndex, 1)
}

// オプションの上移動
const moveUpOption = (sectionIndex: number, optionIndex: number) => {
  if (optionIndex > 0) {
    ;[
      form.contentJson[sectionIndex].options[optionIndex],
      form.contentJson[sectionIndex].options[optionIndex - 1]
    ] = [
      form.contentJson[sectionIndex].options[optionIndex - 1],
      form.contentJson[sectionIndex].options[optionIndex]
    ]
  }
}

// オプションの下移動
const moveDownOption = (sectionIndex: number, optionIndex: number) => {
  if (optionIndex < form.contentJson[sectionIndex].options.length - 1) {
    ;[
      form.contentJson[sectionIndex].options[optionIndex],
      form.contentJson[sectionIndex].options[optionIndex + 1]
    ] = [
      form.contentJson[sectionIndex].options[optionIndex + 1],
      form.contentJson[sectionIndex].options[optionIndex]
    ]
  }
}

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}
// 検索タグ (1つのみ)
const input = ref<string>('')

const addOrUpdateTag = () => {
  if (input.value) {
    form.tag = input.value
    input.value = ''
  }
}

const removeToken = () => {
  form.tag = undefined
}

const tokenStyle = getTypography('LABEL_S')
</script>

<template>
  <v-form>
    <div class="rounded-lg new-form-registration">
      <!-- // タイトル -->
      <div class="content-title">
        <Label
          forHtml="content_title"
          :labelText="t('features.FormContents.new.input.title')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="content_title"
          class="mt-1 required-field"
          v-model="form.title"
          counter="30"
          :hint="t('validations.maxLength', { max: 30 })"
          :placeholder="t('features.FormContents.new.placeholder.title')"
          persistent-hint
          :rules="[required(form.title, t('features.FormContents.new.placeholder.title'))]"
        ></v-text-field>
      </div>

      <!-- // 内容説明 -->
      <div>
        <Label
          forHtml="content_description"
          :labelText="t('features.FormContents.new.input.contentDescription')"
        ></Label>
        <v-textarea
          id="content_description"
          class="mt-1"
          v-model="form.description"
          counter="1000"
          :hint="t('validations.maxLength', { max: 1000 })"
          persistent-hint
          :placeholder="t('features.FormContents.new.placeholder.contentDescription')"
        ></v-textarea>
      </div>

      <!-- 検索タグ -->
      <div>
        <Label
          forHtml="search_tag"
          :labelText="t('features.FormContents.new.input.searchTag')"
        ></Label>
        <div>
          <v-chip
            v-if="form.tag"
            :style="tokenStyle"
            class="mt-2 mb-4 tag"
            closable
            variant="outlined"
            close
            @click:close="removeToken"
          >
            <span :style="tokenStyle">
              {{ form.tag }}
            </span>
          </v-chip>

          <v-textField
            v-model="input"
            @keyup.enter="addOrUpdateTag"
            persistent-hint
            counter="30"
            :hint="t('validations.maxLength', { max: 30 })"
            :placeholder="t('features.FormContents.new.input.searchTag')"
          />
        </div>
      </div>

      <div>
        <!-- //回答内容 -->
        <Label
          forHtml="content_title"
          :labelText="t('features.FormContents.new.input.questions')"
          :isRequired="true"
          direction="left"
        ></Label>

        <!-- セクション -->
        <SectionContainer
          v-for="(section, index) in form.contentJson"
          :key="section.id"
          :sectionIndex="index"
          :section="section"
          :section-length="form.contentJson.length"
          :form="form"
          @add-form-option="addFormOption"
          @copy-section="copySection"
          @delete-section="deleteSection"
          @move-up-section="moveUpSection"
          @move-down-section="moveDownSection"
          @copy-option="copyOption"
          @delete-option="deleteOption"
          @move-up-option="moveUpOption"
          @move-down-option="moveDownOption"
        ></SectionContainer>

        <!-- ページを開いた直後はセクションがないので強制的にメニューボタンを表示 -->
        <MenuButton
          class="mt-1"
          v-if="form.contentJson.length == 0"
          :menuButtons="menuButtons"
          :sectionIndex="0"
          @click="addFormOption"
        ></MenuButton>
      </div>
    </div>
  </v-form>
</template>

<style scoped lang="scss">
.new-form-registration {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--surface-surface-primary, #ffffff);
  padding: 16px;
  min-width: 520px;
}
.content-title-label {
  margin-bottom: 4px;
}
:deep(.v-btn:disabled) {
  color: rgb(var(--surface-surface-transparent)) !important;
  opacity: var(--v-disabled-opacity) !important;
}
:deep(.v-btn:disabled .v-btn__overlay) {
  display: none;
}
:deep(.required-field :before) {
  border-width: 0 0 3px;
}

.tag {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 4px;
}
</style>
