<script setup lang="ts">
import { ref } from 'vue'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import MedicalWorkerEdit from '@/features/MedicalWorkerEdit/index.vue'
import { type MedicalWorkerEditFormInput } from '@/features/MedicalWorkerEdit/type'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const displayNameStore = useDisplayNameStore()

// パスからmedicalWorkerIdを抽出
const route = useRoute()
const medicalWorkerId =
  typeof route.params.medicalWorkerId == 'string' ? route.params.medicalWorkerId : ''

const form = ref<MedicalWorkerEditFormInput | undefined>()

onMounted(async () => {
  await fetchMedicalWorker()
})

// 医療機関ユーザー取得
const fetchMedicalWorker = async () => {
  const response = await medicalInstitutionsApiClient().api.medicalWorkerByMedicalInstitution(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    medicalWorkerId,
    { optimId: '' }
  )
  form.value = {
    familyName: response.data.familyName,
    givenName: response.data.givenName,
    phoneticFamilyName: response.data.phoneticFamilyName,
    phoneticGivenName: response.data.phoneticGivenName,
    email: response.data.email,
    role: response.data.roleUrn
  }
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.medicalWorkers.list'),
        href: '/medical-workers',
        disable: false
      },
      {
        title: `${form.value.familyName}` + ' ' + `${form.value.givenName}`,
        href: `/medical-workers/${medicalWorkerId}`,
        disable: false
      },
      {
        title: t('pages.medicalWorkers.edit'),
        disable: true
      }
    ]
  })
}
</script>
<template>
  <!-- 医療機関ユーザー編集 -->
  <v-container class="container">
    <!-- 入力フォーム -->
    <MedicalWorkerEdit
      v-if="form != undefined"
      :form="form"
      :medicalWorkerId="medicalWorkerId"
    ></MedicalWorkerEdit>
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
