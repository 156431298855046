<script setup lang="ts">
import { type DetectedBarcode } from 'barcode-detector/pure'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { QrcodeStream } from 'vue-qrcode-reader'

import BaseButton from '@/components/Base/Button/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()

displayNameStore.updateDisplayName({
  breadcrumbs: [
    {
      title: t('features.DetailHospitalizationOrder.scanQrCode'),
      disable: true
    }
  ]
})

type Emits = {
  (e: 'cancel'): void
  (e: 'confirm', bindingToken: string): void
}
const emit = defineEmits<Emits>()

const bindingToken = ref('')
const onDetect = (detectedCodes: DetectedBarcode[]) => {
  bindingToken.value = detectedCodes.map((code) => code.rawValue).join(',')
  emit('confirm', bindingToken.value)
}

const isLoading = ref(true)
const onCameraOn = () => {
  isLoading.value = false
}
</script>

<template>
  <div>
    <div class="fullscreen">
      <BaseButton
        class="close-button"
        variant="text"
        content=""
        type="icon-link"
        icon="close"
        size="small"
        @click="
          () => {
            emit('cancel')
          }
        "
      />
      <qrcode-stream @detect="onDetect" @camera-on="onCameraOn">
        <div class="overlay">
          <div class="scan-region">
            <div class="region-border region-border-left-top"></div>
            <div class="region-border region-border-left-bottom"></div>
            <div class="region-border region-border-right-top"></div>
            <div class="region-border region-border-right-bottom"></div>
          </div>
        </div>
        <div v-if="isLoading" class="camera-loading"></div>
      </qrcode-stream>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fullscreen {
  position: fixed;
  z-index: 1;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-region {
  width: 250px;
  height: 250px;
  position: relative;
}

.scan-region::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
}

.region-border {
  position: absolute;
  width: 30%;
  height: 30%;
}

.region-border-left-top {
  border-top: 5px solid rgba(var(--v-theme-primary));
  border-left: 5px solid rgba(var(--v-theme-primary));
  top: -4px;
  left: -4px;
}

.region-border-left-bottom {
  border-bottom: 5px solid rgba(var(--v-theme-primary));
  border-left: 5px solid rgba(var(--v-theme-primary));
  bottom: -4px;
  left: -4px;
}

.region-border-right-top {
  border-top: 5px solid rgba(var(--v-theme-primary));
  border-right: 5px solid rgba(var(--v-theme-primary));
  top: -4px;
  right: -4px;
}

.region-border-right-bottom {
  border-bottom: 5px solid rgba(var(--v-theme-primary));
  border-right: 5px solid rgba(var(--v-theme-primary));
  bottom: -4px;
  right: -4px;
}

.close-button {
  position: absolute;
  z-index: 2;
  top: 20px;
  right: 20px;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
}

.camera-loading {
  z-index: 5;
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}
</style>
