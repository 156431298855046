<script setup lang="ts">
import { toRefs } from 'vue'

import type { BreadcrumbsType } from './types'

import router from '@/router'

type Props = {
  /**
   * パンくずリスト
   */
  breadcrumbs: BreadcrumbsType[]
}

const props = withDefaults(defineProps<Props>(), {
  breadcrumbs: (): BreadcrumbsType[] => [],
  display: ''
})

const { breadcrumbs } = toRefs(props)

const navigateTo = (path: string) => {
  router.push(path)
}
</script>

<template>
  <v-toolbar-title>
    <!-- パンくずリストの表示 -->
    <v-breadcrumbs divider=">" class="app-bar-breadcrumbs" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          v-if="item.disable === false"
          class="breadcrumbs-item"
          @click="navigateTo(item.href)"
          >{{ item.title }}</v-breadcrumbs-item
        >
        <v-breadcrumbs-item v-if="item.disable === true" class="breadcrumbs-item">{{
          item.title
        }}</v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-toolbar-title>
</template>

<style>
.app-bar {
  .v-breadcrumbs {
    padding: 0;
    margin: 0;
  }
  .v-breadcrumbs-divider {
    font-size: 12px;
    color: #666666;
  }
}
</style>

<style scoped>
header {
  background: var(--background-background, #f2f6f9);
}
.breadcrumbs {
  padding-left: 0px;
  padding-bottom: 0px;
  font-weight: bold;
  background: var(--background-background, #f2f6f9);
}
.breadcrumbs-item {
  color: var(--object-object-link, #004386);
  font-weight: 400;
  font-size: 16px;
}
.breadcrumbs-item:hover {
  cursor: pointer;
}
.breadcrumbs-item:last-child {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.breadcrumbs-item:last-child:hover {
  cursor: default;
}

.v-toolbar-title {
  background: var(--background-background, #f2f6f9);
}
</style>
