import AsyncLock from 'async-lock'
import axios, { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

import { type FetchApiResponse } from './types'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiMedicalWorkerListResponse } from '@/api/generate/apiSchema'
import { type Order } from '@/api/types'

const limit = 50

interface SearchConditions {
  medicalInstitutionId?: string
  userName?: string
  orderBy?: string
  order?: string
}

export const useMedicalWorkersStore = defineStore('medicalWorkers', () => {
  const lock = new AsyncLock({ timeout: 2000 })
  const searchConditions = ref<SearchConditions>({})
  const currentProcess = ref<string | undefined>()
  const searchedLastMedicalWorkerId = ref<string | undefined>()
  const medicalWorkerList = ref<MiMedicalWorkerListResponse['list']>([])
  const getMedicalWorkerList = () => medicalWorkerList

  /**
   * 検索結果を保存する
   * 結果は排他制御し、全て同一の呼び出しで更新されることを保証する
   */
  const setSearchResult = async (
    medicalInstitutionId: string,
    _searchedLastMedicalWorkerId: string | undefined,
    _medicalWorkerList: MiMedicalWorkerListResponse['list'],
    userName: string | undefined,
    orderBy: string | undefined,
    order: string | undefined
  ) => {
    await lock.acquire('setSearchResult', () => {
      searchedLastMedicalWorkerId.value = _searchedLastMedicalWorkerId
      medicalWorkerList.value = _medicalWorkerList
      searchConditions.value = {
        medicalInstitutionId,
        userName,
        orderBy,
        order
      }
    })
  }

  const fetchMedicalWorkerList = async (
    medicalInstitutionId: string,
    name?: string,
    orderBy?: string,
    order?: string
  ): Promise<FetchApiResponse<MiMedicalWorkerListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    try {
      const response = await medicalInstitutionsApiClient().api.medicalWorkersByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          name,
          lastMedicalWorkerId: undefined,
          limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(medicalInstitutionId, undefined, response.data.list, name, orderBy, order)

      return {
        success: true,
        content: medicalWorkerList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const fetchNextMedicalWorkerList = async (
    medicalInstitutionId: string,
    lastMedicalWorkerId: string,
    name: string = '',
    orderBy?: string,
    order?: Order
  ): Promise<FetchApiResponse<MiMedicalWorkerListResponse['list']>> => {
    // 前回検索中の場合はその処理をキャンセルさせる
    const processId = uuidv4()
    currentProcess.value = processId

    // 重複した検索
    if (searchedLastMedicalWorkerId.value == lastMedicalWorkerId) {
      return {
        success: false,
        status: 400
      }
    }
    // 検索キーが一致するか確認
    if (
      searchConditions.value.medicalInstitutionId != medicalInstitutionId ||
      searchConditions.value.orderBy != orderBy ||
      searchConditions.value.order != order
    ) {
      // 一致しない場合は、追加検索ではなく初期化を行う
      return fetchMedicalWorkerList(medicalInstitutionId, name, orderBy, order)
    }

    // 取得処理
    try {
      const response = await medicalInstitutionsApiClient().api.medicalWorkersByMedicalInstitution(
        medicalInstitutionId,
        {
          optimId: '',
          lastMedicalWorkerId,
          name,
          limit,
          orderBy,
          order
        }
      )

      // 既に別の処理が実行されているためキャンセル
      if (currentProcess.value != processId) {
        return {
          success: false,
          status: 400,
          cancel: true
        }
      }

      setSearchResult(
        medicalInstitutionId,
        lastMedicalWorkerId,
        [...medicalWorkerList.value, ...response.data.list],
        name,
        orderBy,
        order
      )

      return {
        success: true,
        content: medicalWorkerList.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }

      throw error
    }
  }

  const clearMedicalWorkerList = () => {
    medicalWorkerList.value = []
    searchedLastMedicalWorkerId.value = undefined
    searchConditions.value = {}
    currentProcess.value = undefined
  }

  return {
    getMedicalWorkerList,
    fetchMedicalWorkerList,
    fetchNextMedicalWorkerList,
    clearMedicalWorkerList
  }
})
