<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import type { MiHospitalizationOrderResponse } from '@/api/generate/apiSchema'
import type { Item } from '@/features/HospitalizationOrderDetail/Card/DocumentStatus/type'
import type { PatientDetailInput } from '@/features/PatientDetail/type'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import HospitalizationOrderDetail from '@/features/HospitalizationOrderDetail/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useHospitalizationOrderDetailStore } from '@/stores/hospitalizationOrderDetail'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const displayNameStore = useDisplayNameStore()
const hospitalizationOrderDetailStore = useHospitalizationOrderDetailStore()

const route = useRoute()
const hospitalizationOrderId =
  typeof route.params.hospitalizationOrderId == 'string' ? route.params.hospitalizationOrderId : ''

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
const userPatientId = ref<string>()
const loading = ref(true)
const isUseAppStatus = ref<boolean>(false)
const documentStatus = ref<Item>({
  documentSetStatus: {
    label: '',
    type: '',
    color: ''
  }
})
const allDataLoaded = ref<boolean>(false)

const fetchPatientUser = async (patientId: string) => {
  const response = await medicalInstitutionsApiClient().api.userPatientByMedicalInstitution(
    medicalInstitutionId,
    patientId,
    { optimId: '' }
  )
  userPatientId.value = response.data.userId
}

const checkUseAppStatus = () => {
  if (userPatientId.value != undefined || hospitalizationDetail.value?.representatives.length) {
    isUseAppStatus.value = true
  } else {
    isUseAppStatus.value = false
  }
  loading.value = false
}

// 患者詳細
const hospitalizationDetail = ref<MiHospitalizationOrderResponse>()
const patientDetail = ref<PatientDetailInput>()
const patientId = ref('')

onMounted(async () => {
  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.hospitalizationOrders.list'),
        href: '/hospitalization-orders',
        disable: false
      },
      {
        title: t('pages.hospitalizationOrders.detail'),
        disable: true
      }
    ]
  })
  await fetchPersonalHospitalizationOrder()
  await fetchPatientUser(hospitalizationDetail.value?.patientId ?? '')
  checkUseAppStatus()
})

const bind = async () => {
  await fetchPersonalHospitalizationOrder()
  await fetchPatientUser(hospitalizationDetail.value?.patientId ?? '')
  checkUseAppStatus()
}

const fetchPersonalHospitalizationOrder = async () => {
  // 入院オーダーの取得
  const hospitalizationResponse =
    await hospitalizationOrderDetailStore.fetchHospitalizationOrderDetail(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      hospitalizationOrderId
    )

  if (hospitalizationResponse.success) {
    hospitalizationDetail.value = hospitalizationResponse.content
    patientId.value = hospitalizationResponse.content.patientId ?? ''
  }

  // 患者情報の取得
  const patientResponse = await hospitalizationOrderDetailStore.fetchPatientDetail(
    medicalInstitutionsStore.currentMedicalInstitution().id,
    patientId.value
  )
  if (patientResponse.success) {
    patientDetail.value = patientResponse?.content
  }

  const documentSetStatus = await hospitalizationOrderDetailStore.fetchDocumentSetStatus(
    hospitalizationOrderId,
    medicalInstitutionId
  )
  if (documentSetStatus.success && documentSetStatus.content) {
    documentStatus.value = documentSetStatus.content
  }

  allDataLoaded.value = true
}
</script>
<template>
  <v-container class="container">
    <HospitalizationOrderDetail
      v-if="
        allDataLoaded &&
        hospitalizationDetail != undefined &&
        patientDetail != undefined &&
        isUseAppStatus != undefined &&
        hospitalizationOrderId != undefined &&
        documentStatus != undefined
      "
      :isUseAppStatus="isUseAppStatus"
      :patient-detail="patientDetail"
      :personal-hospitalization-detail="hospitalizationDetail"
      :hospitalizationOrderId="hospitalizationOrderId"
      :user-patient-id="userPatientId"
      :loading="loading"
      :documentStatus="documentStatus"
      @bind="bind"
    />
  </v-container>
</template>
<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
