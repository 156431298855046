<script setup lang="ts">
import Text from '@components/Base/BodyText/index.vue'
import BaseButton from '@components/Base/Button/index.vue'
import Label from '@components/Base/Label/index.vue'
import { getTypography } from '@optim-design-system/src'
import { computed, defineProps, onBeforeMount, reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FormContent } from './type'

import { documentManagementApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import FormContentSubmissionForm from '@/features/CommonForm/formContentSubmissionForm.vue'
import { type SectionContainer as SectionContainerType } from '@/features/CommonForm/type'
import router from '@/router'
import { useFormContentNavigationStore } from '@/stores/formContentNavigation'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()
const formContentNavigationStore = useFormContentNavigationStore()

const { t } = useI18n()

type Props = {
  formContent: FormContent
  contentId: string
}

const props = defineProps<Props>()
const contentId = ref(props.contentId)
const formContent = ref({ ...props.formContent })
const fetchedJson = reactive<SectionContainerType[]>(JSON.parse(formContent.value.contentJson))

const axiosErrorGuard = useAxiosErrorGuard()

onBeforeMount(() => {
  formContentNavigationStore.clearFormContentNavigation()
})

// 最終セクション番号
const lastSectionNumber = computed(() => {
  const sc = JSON.parse(JSON.stringify(fetchedJson)) as SectionContainerType[]
  return sc.length - 1
})

// 次のセクションが存在するか
const hasNextSection = computed((): boolean => currentSectionNumber.value < lastSectionNumber.value)

// 前のセクションが存在するか
const hasPrevSection = computed((): boolean => currentSectionNumber.value > 0)

// 現在のセクション番号を取得
const currentSectionNumber = computed((): number => {
  return formContentNavigationStore.section
})

// 前の質問へボタンハンドラー。ヘッダーコンポーネントに設定するコールバック関数を定義
const goBack = async (): Promise<void> => {
  if (hasPrevSection.value) {
    formContentNavigationStore.previousSection()
  }
}

// 次の質問へ | 確認画面へ
const goNext = async () => {
  if (hasNextSection.value) {
    // 次のセクションが残っている場合は次のセクション画面を表示
    formContentNavigationStore.nextSection()
  }
}

// アラートの削除ボタンがタップされた時の処理
const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    await documentManagementApiClient().api.destroyContentByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.contentId,
      { optimId: '' }
    )
    // コンテンツ覧画面へ遷移
    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.documentSets.contents') })
    )
    done()
    transitionList()
  } catch (error: unknown) {
    failure()
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.documentSets.contents') })
      )
      return
    }

    throw error
  }
}

// コンテンツ編集画面へ遷移
const transitionEdit = () => {
  router.push(`/contents/${contentId.value}/edit`)
}

const transitionList = () => {
  router.push('/contents')
}

// モーダル開閉管理
const isOpenDeleteDialog = toRef<boolean>(false)

// 削除確認ダイアログを開く
const openDeleteConfirmDialog = () => {
  isOpenDeleteDialog.value = true
}

const tokenStyle = getTypography('LABEL_S')
</script>

<template>
  <div class="url-content-detail">
    <div class="url-content-detail-header">
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('attributes.edit')"
        icon="mode_edit_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="transitionEdit"
      >
      </BaseButton>
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('attributes.delete')"
        icon="delete_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="openDeleteConfirmDialog"
      >
      </BaseButton>
    </div>
    <div class="rounded-lg url-content-detail-body">
      <div class="row">
        <Label for-html="content_title" :labelText="t('features.contentSet.Detail.input.title')" />
        <Text class="mt-1" :text="formContent.title" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.description')"
        />
        <Text class="form-content-description" :text="formContent.description" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.tag')"
        />
        <v-chip v-if="formContent.tag" :style="tokenStyle" class="mt-2 mb-4 tag" variant="outlined">
          <span :style="tokenStyle">
            {{ formContent.tag }}
          </span>
        </v-chip>
      </div>
    </div>

    <!-- 削除確認ダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenDeleteDialog"
      :title="t('features.contentSet.Detail.alert.formTitle')"
      :content="t('features.contentSet.Detail.alert.formContent')"
      :cancelBtnText="t('attributes.cancel')"
      :confirmBtnText="t('features.contentSet.Detail.alert.deleted')"
      :confirmBtnColor="'object-caution'"
      :size="'medium'"
      :confirmBtnVisible="true"
      @input="isOpenDeleteDialog = $event"
      @confirm="confirmDestroy"
    />
  </div>
  <div class="form-content-detail-header">
    <div class="form-content-detail-body" v-if="fetchedJson.length">
      <form class="form-detail">
        <BaseButton
          type="icon-link"
          variant="text"
          :content="t('features.hospitalizationDocument.FormContentSubmission.goBack')"
          icon="west"
          color="not-working-dark"
          tokenType="LABEL_M"
          size="small"
          @click="goBack"
        >
        </BaseButton>
        <FormContentSubmissionForm
          :fetched-content-json="fetchedJson"
          :current-section-number="currentSectionNumber"
        >
          <template v-if="hasNextSection" #next-button>
            <!-- 次へボタン -->
            <BaseButton
              class="transition-next-button"
              color="primary"
              :content="t('features.hospitalizationDocument.FormContentSubmission.goToNext')"
              type="button"
              variant="flat"
              @click="goNext"
            ></BaseButton>
          </template>
          <!-- 確認ボタン -->
          <template v-else #next-button>
            <BaseButton
              class="transition-next-button"
              color="primary"
              :content="t('features.hospitalizationDocument.FormContentSubmission.goToConfirm')"
              type="button"
              variant="flat"
              @click="goNext"
            ></BaseButton>
          </template>
        </FormContentSubmissionForm>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.url-content-detail {
  padding-bottom: 32px;
}
.url-content-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 200px;
  padding: 16px;
}
.url-content-detail-header {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-bottom: 8px;
}
.form-content-description {
  white-space: pre-wrap;
  margin-top: 4px;
}
.row {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-wrap: break-word;
}
.chip-and-field {
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-wrap: break-word;
  max-width: calc(50% - 5px);
}
.chip-place {
  justify-content: center;
}
.form-content-detail-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 400px;
  padding: 16px;
}
.form-content-detail-body {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}
.form-detail {
  border-radius: 10px;
  padding: 32px 16px 16px 16px;
  background: rgb(225, 222, 237);
}
.tag {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 4px;
}
// 次へボタン
.transition-next-button {
  background-color: #0017c1 !important;
  min-width: 92px;
  padding: 0 24px;
  font-size: 1.125rem;
  height: 52px;
  font-weight: 600;
  border-radius: 24px;
  padding: 14px 87px;
}
</style>
