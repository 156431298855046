// UUIDなどを統一して返すため、サンプル用のデータを管理するファイル

export const medicalInstitutions = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    name: 'オプティム医療機関',
    createdAt: '2024-07-10T05:39:30Z',
    updatedAt: '2024-07-10T05:39:30Z'
  }
]
export const medicalWorkers = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      familyName: 'オプティム',
      givenName: '管理1',
      phoneticFamilyName: 'オプティム',
      phoneticGivenName: 'カンリ',
      email: 'kanri.optim@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_admin',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000001',
      familyName: 'オプティム',
      givenName: '一般1',
      phoneticFamilyName: 'オプティム',
      phoneticGivenName: 'イッパン',
      email: 'ippan.optim@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_general',
      createdAt: '2024-07-17T05:39:30Z',
      updatedAt: '2024-07-17T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000002',
      familyName: 'オプティム',
      givenName: '閲覧1',
      phoneticFamilyName: 'オプティム',
      phoneticGivenName: 'エツラン',
      email: 'eturan.optim@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_read',
      createdAt: '2024-07-18T05:39:30Z',
      updatedAt: '2024-07-18T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000003',
      familyName: 'オプティム🕐' + '長'.repeat(100 - 6),
      givenName: '管理🕐' + '長'.repeat(100 - 3),
      phoneticFamilyName: 'オプティム' + 'ナ'.repeat(100 - 5),
      phoneticGivenName: 'カンリ' + 'ナ'.repeat(100 - 3),
      email: 'kanri.optim' + 'm'.repeat(255 - 23) + '@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_read',
      createdAt: '2024-07-18T05:39:30Z',
      updatedAt: '2024-07-18T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000004',
      familyName: 'オプティム',
      givenName: '管理2',
      phoneticFamilyName: 'オプティム',
      phoneticGivenName: 'カンリ',
      email: 'kanri.optim@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_admin',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000005',
      familyName: 'オプティム',
      givenName: '一般2',
      phoneticFamilyName: 'オプティム',
      phoneticGivenName: 'イッパン',
      email: 'ippan.optim@example.com',
      roleUrn: 'urn:optim:digihos:medical_institution_general',
      createdAt: '2024-07-17T05:39:30Z',
      updatedAt: '2024-07-17T05:39:30Z'
    }
  ]
}

export const formContentRevisions = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    description: 'hogehogehoge',
    contentJson:
      '[{"id":"4e749106-0af9-4a7c-8966-d0c8c86980af","name":"セクション1","options":[{"id":"f2d78504-01a3-4527-9397-a37bdc6d706c","name":"テキスト","type":"text","isRequired":false,"description":"","isMultiLine":false},{"id":"92cb546d-69f0-4d84-b059-353b03335eeb","name":"選択肢","type":"choice","choices":[{"name":"回答3へ","branch":{"id":"53936ade-64ba-48e0-a7f0-89727108a757","type":"option"}},{"name":"デフォルト","branch":{"type":"next"}},{"name":"フォーム末尾","branch":{"type":"last"}},{"name":"回答5へ","branch":{"id":"9374b5f6-eff5-46f3-a2fc-df90792f3224","type":"option"}}],"isRequired":false,"description":""},{"id":"53936ade-64ba-48e0-a7f0-89727108a757","name":"日付","type":"date","isRequired":false,"description":""},{"id":"3f13e5e4-d7ae-4e53-891f-1d48b5d93cdc","name":"数値","type":"quantitative","unit":"","isRequired":false,"description":""},{"id":"9374b5f6-eff5-46f3-a2fc-df90792f3224","name":"チェックボックス","type":"check_box","selects":["チェックボックス"],"isRequired":false,"description":""},{"id":"e03f39bc-d571-44d6-b26b-777306ba97ac","name":"入力セット","type":"input_set","optionIds":["3f13e5e4-d7ae-4e53-891f-1d48b5d93cdc","9374b5f6-eff5-46f3-a2fc-df90792f3224"]}]},{"id":"a9ce3249-691c-4134-88da-78c7e993a29a","name":"セクション2","options":[{"id":"3ae700a9-cd90-48ce-9a9b-239706690e6e","name":"複数行テキスト","type":"text","isRequired":true,"description":"説明が複数行あるテキストです。","isMultiLine":true}]}]',
    createdAt: '2024-07-16T05:39:30Z'
  }
]

export const urlContentRevisions = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    description: 'hogehogehoge',
    url: 'https://example.hoge',
    createdAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000001',
    description: 'hogehogehoge',
    url: 'https://example.hoge',
    createdAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000002',
    description: 'hogehogehoge',
    url: 'https://example.hoge',
    createdAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000003',
    description: 'hogehogehoge',
    url: 'https://example.hoge',
    createdAt: '2024-07-16T05:39:30Z'
  }
]

export const contents = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      title: 'Formコンテンツ1',
      description: '説明',
      contentType: 'form',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000001',
      title: 'Form1🕐' + '長'.repeat(30 - 5),
      description: '説明🕐' + '長'.repeat(1000 - 3),
      contentType: 'form',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000002',
      title: 'URLコンテンツ1',
      contentType: 'url',
      description: '説明',
      specificContentId: '00000000-ffff-0000-ffff-000000000000',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000003',
      title: 'URL🕐' + '長'.repeat(30 - 4),
      contentType: 'url',
      description: '説明',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000011',
      title: 'URL3🕐' + '長'.repeat(30 - 4),
      contentType: 'url',
      description: '説明',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    }
  ]
}

export const hospitalizationOrderDocumentSets = {
  [medicalInstitutions[0].id]: [
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000001',
      documentSetTitle: '入院手続き書類1',
      contentId: '00000000-ffff-0000-ffff-000000000000',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: 'title1',
      ContentType: 'url',
      contentStatusCode: '',
      updatedAt: '2024-06-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000002',
      documentSetTitle: '入院手続き書類2',
      contentId: '00000000-ffff-0000-ffff-000000000001',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: 'title1',
      ContentType: 'form',
      contentStatusCode: 'not_submitted',
      updatedAt: '2024-08-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000003',
      documentSetTitle: '入院手続き書類3',
      contentId: '00000000-ffff-0000-ffff-000000000002',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '問診票',
      ContentType: 'form',
      contentStatusCode: 'submitted',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000004',
      documentSetTitle: '入院手続き書類4',
      contentId: '00000000-ffff-0000-ffff-000000000003',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000005',
      documentSetTitle: '病院案内1',
      contentId: '00000000-ffff-0000-ffff-000000000004',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書1',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000006',
      documentSetTitle: '病院案内2',
      contentId: '00000000-ffff-0000-ffff-000000000005',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書2',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000007',
      documentSetTitle: '病院案内3',
      contentId: '00000000-ffff-0000-ffff-000000000006',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書3',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000008',
      documentSetTitle: '病院案内4',
      contentId: '00000000-ffff-0000-ffff-000000000007',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書4',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000009',
      documentSetTitle: '病院案内5',
      contentId: '00000000-ffff-0000-ffff-000000000008',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書5',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000010',
      documentSetTitle: '病院案内6',
      contentId: '00000000-ffff-0000-ffff-000000000009',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書6',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000011',
      documentSetTitle: '病院案内7',
      contentId: '00000000-ffff-0000-ffff-0000000000010',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書7',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-00000000000212',
      documentSetTitle: '病院案内8',
      contentId: '00000000-ffff-0000-ffff-000000000011',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書8',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000013',
      documentSetTitle: '病院案内9',
      contentId: '00000000-ffff-0000-ffff-0000000000012',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書9',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000014',
      documentSetTitle: '病院案内10',
      contentId: '00000000-ffff-0000-ffff-0000000000013',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書10',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000015',
      documentSetTitle: '病院案内11',
      contentId: '00000000-ffff-0000-ffff-0000000000014',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書11',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000016',
      documentSetTitle: '病院案内12',
      contentId: '00000000-ffff-0000-ffff-000000000015',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書12',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000017',
      documentSetTitle: '病院案内13',
      contentId: '00000000-ffff-0000-ffff-000000000016',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書13',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000018',
      documentSetTitle: '病院案内14',
      contentId: '00000000-ffff-0000-ffff-000000000017',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書14',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-0000000000019',
      documentSetTitle: '病院案内15',
      contentId: '00000000-ffff-0000-ffff-000000000018',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書15',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    },
    {
      hospitalizationOrderDocumentSetContentId: '00000000-ffff-0000-ffff-000000000000',
      documentSetId: '00000000-ffff-0000-ffff-000000000020',
      documentSetTitle: '病院案内16',
      contentId: '00000000-ffff-0000-ffff-000000000019',
      contentRevisionId: '00000000-ffff-0000-ffff-000000000000',
      contentTitle: '入院申込書16',
      ContentType: 'form',
      contentStatusCode: 'confirmed',
      updatedAt: '2024-07-16T05:39:30Z'
    }
  ]
}

export const documentSets = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      setName: '書類セット名001',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000001',
      setName: '書類セット名002',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000002',
      setName: '書類セット名003',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000003',
      setName: '書類セット名004',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000004',
      setName: '書類セット名005',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000005',
      setName: '書類セット名006',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000006',
      setName: '書類セット名007',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000007',
      setName: '書類セット名008',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000008',
      setName: '書類セット名009',
      documentNo: 0,
      createdAt: '2024-07-23T05:39:30Z',
      updatedAt: '2024-07-23T05:39:30Z'
    }
  ]
}

export const hospitalizationOrders = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      patientNo: '200',
      familyName: 'オプティム',
      givenName: '患者1',
      gender: 1,
      hospitalizationDate: '2000-04-01',
      useAppStatus: 'not_used',
      hospitalizationOrderStatus: 'pre_hospital',
      documentSetStatus: 'not_sent'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000001',
      patientNo: '201',
      familyName: 'オプティム',
      givenName: '患者2🕐',
      gender: 2,
      hospitalizationDate: '2000-04-01',
      representativeName: 'オプティム 代理人2🕐',
      useAppStatus: 'used',
      hospitalizationOrderStatus: 'hospital',
      documentSetStatus: 'not_submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000002',
      patientNo: '202',
      familyName: 'オプティム',
      givenName: '患者3',
      gender: 2,
      hospitalizationDate: '1990-04-09',
      representativeName: 'オプティム 代理人3',
      useAppStatus: 'used',
      hospitalizationOrderStatus: 'discharged',
      documentSetStatus: 'partial_submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000003',
      patientNo: '203',
      familyName: 'オプティム',
      givenName: '患者4',
      gender: 1,
      hospitalizationDate: '1990-12-17',
      representativeName: 'オプティム 代理人4',
      useAppStatus: 'not_used',
      hospitalizationOrderStatus: 'hospital',
      documentSetStatus: 'submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000004',
      patientNo: '2000000000000000',
      familyName: `オプティム${'長'.repeat(20)}`,
      givenName: '患者5',
      gender: 2,
      hospitalizationDate: '1990-12-17',
      representativeName: `オプティム${'長'.repeat(20)} 代理人5`,
      useAppStatus: 'not_used',
      hospitalizationOrderStatus: 'hospital',
      documentSetStatus: 'confirmed'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000005',
      patientNo: '205',
      familyName: 'オプティム',
      givenName: '患者6',
      gender: 1,
      hospitalizationDate: '1990-04-09',
      representativeName: 'オプティム 代理人6',
      useAppStatus: 'used',
      hospitalizationOrderStatus: 'hospital',
      documentSetStatus: 'partial_submitted'
    },
    {
      id: '00000000-ffff-0000-ffff-000000000006',
      patientNo: '206',
      familyName: 'オプティム',
      givenName: '患者7',
      gender: 2,
      hospitalizationDate: '1990-08-09',
      representativeName: 'オプティム 代理人7',
      useAppStatus: 'used',
      hospitalizationOrderStatus: 'hospital',
      documentSetStatus: 'submitted'
    }
  ]
}

export const hospitalizationOrderItems = {
  [medicalInstitutions[0].id]: [
    {
      id: '00000000-ffff-0000-ffff-000000000000',
      patientId: '00000000-ffff-0000-ffff-000000000001',
      representatives: [
        {
          id: '00000000-ffff-0000-ffff-000000000000',
          familyName: 'オプティム',
          givenName: '太郎',
          relationshipCode: '父'
        }
      ],
      hospitalizationOrderStatus: 'pre_hospital',
      hospitalDepartment: '皮膚科',
      ward: '1',
      hospitalRoom: '1',
      hospitalizationDate: '2024-07-16T05:39:30Z',
      dischargeDate: '2024-07-16T05:39:30Z',
      createdAt: '2024-07-16T05:39:30Z',
      updatedAt: '2024-07-16T05:39:30Z'
    }
  ]
}

export const documentSetList = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    title: '書類セット001',
    description:
      '説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。',
    createAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z',
    contents: [
      {
        id: '00000000-ffff-0000-ffff-000000000000',
        title: 'Formコンテンツ1',
        description: '説明',
        contentType: 'Form',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      },
      {
        id: '00000000-ffff-0000-ffff-000000000001',
        title: 'Form1🕐' + '長'.repeat(30 - 5),
        description: '説明🕐' + '長'.repeat(1000 - 3),
        contentType: 'Form',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      },
      {
        id: '00000000-ffff-0000-ffff-000000000002',
        title: 'URLコンテンツ1',
        contentType: 'Url',
        description: '説明',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      }
    ]
  },
  {
    id: '00000000-ffff-0000-ffff-000000000001',
    title: '書類セット002',
    description: '説明が入ります。説明が入ります。説明が入ります。',
    createAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z',
    contents: [
      {
        id: '00000000-ffff-0000-ffff-000000000000',
        title: 'Formコンテンツ1',
        description: '説明',
        contentType: 'Form',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      },
      {
        id: '00000000-ffff-0000-ffff-000000000001',
        title: 'Form1🕐' + '長'.repeat(30 - 5),
        description: '説明🕐' + '長'.repeat(1000 - 3),
        contentType: 'Form',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      },
      {
        id: '00000000-ffff-0000-ffff-000000000002',
        title: 'URLコンテンツ1',
        contentType: 'Url',
        description: '説明',
        createdAt: '2024-07-16T05:39:30Z',
        updatedAt: '2024-07-16T05:39:30Z'
      }
    ]
  }
]
export const patientList = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    patientNo: '200',
    familyName: 'オプティム',
    givenName: '太郎',
    gender: 1,
    birthDate: '1990-04-09',
    hospitalizationOrderStatus: undefined,
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000001',
    patientNo: '201',
    familyName: 'オプティム',
    givenName: '次郎',
    gender: 1,
    birthDate: '1990-04-09',
    hospitalizationOrderStatus: undefined,
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000002',
    patientNo: '202',
    familyName: 'オプティム',
    givenName: '花子',
    gender: 2,
    birthDate: '1990-04-09',
    hospitalizationOrderStatus: undefined,
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000003',
    patientNo: '203',
    familyName: 'オプティム',
    givenName: '三郎',
    gender: 1,
    birthDate: '1990-04-10',
    hospitalizationOrderStatus: undefined,
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000004',
    patientNo: '204',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000005',
    patientNo: '205',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000006',
    patientNo: '206',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000007',
    patientNo: '207',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000008',
    patientNo: '208',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000009',
    patientNo: '209',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-0000000010',
    patientNo: '210',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000011',
    patientNo: '211',
    familyName: 'オプティム',
    givenName: '太郎',
    hospitalizationOrderStatus: 'pre_hospital',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  }
]

export const patientDetailList = [
  {
    id: '00000000-ffff-0000-ffff-000000000000',
    patientNo: '200',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000001',
    patientNo: '201',
    familyName: 'オプティム',
    givenName: '次郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'ジロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000002',
    patientNo: '202',
    familyName: 'オプティム',
    givenName: '花子',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'ハナコ',
    gender: 2,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000003',
    patientNo: '203',
    familyName: 'オプティム',
    givenName: '三郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'サブロウ',
    gender: 1,
    birthDate: '1990-04-10',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000004',
    patientNo: '204',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000005',
    patientNo: '205',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000006',
    patientNo: '206',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000007',
    patientNo: '207',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000008',
    patientNo: '208',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000009',
    patientNo: '209',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-0000000010',
    patientNo: '210',
    familyName: 'オプティム',
    givenName: '太郎',
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    gender: 1,
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  },
  {
    id: '00000000-ffff-0000-ffff-000000000011',
    patientNo: '211',
    familyName: 'オプティム',
    givenName: '太郎',
    gender: 1,
    phoneticFamilyName: 'オプティム',
    phoneticGivenName: 'タロウ',
    birthDate: '1990-04-09',
    createdAt: '2024-07-16T05:39:30Z',
    updatedAt: '2024-07-16T05:39:30Z'
  }
]
