<script setup lang="ts">
import FormContentDetail from '@features/FormContentDetail/index.vue'
import UrlContentDetail from '@features/UrlContentDetail/index.vue'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { VContainer } from 'vuetify/components'

import type { MiFormContentRevisionItem, MiUrlContentRevisionItem } from '@/api/generate/apiSchema'

import { documentManagementApiClient } from '@/api/apiClient'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const displayNameStore = useDisplayNameStore()

const urlContent = ref<
  MiUrlContentRevisionItem & { title: string; description: string; tag?: string }
>()
const formContent = ref<
  MiFormContentRevisionItem & { title: string; description: string; tag?: string }
>()

const route = useRoute()
const contentId = typeof route.params.contentId == 'string' ? route.params.contentId : ''

onMounted(async () => {
  await fetchUrlContent()
})

// コンテンツ取得
const fetchUrlContent = async () => {
  const query = {
    optimId: ''
  }
  const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
  const contentResponse = (
    await documentManagementApiClient().api.contentByMedicalInstitution(
      medicalInstitutionId,
      contentId,
      query
    )
  ).data

  if (contentResponse.contentType === 'form') {
    const formContentResponse = (
      await documentManagementApiClient().api.latestFormContentRevisionByMedicalInstitution(
        medicalInstitutionId,
        contentId,
        query
      )
    ).data

    formContent.value = {
      title: contentResponse.title,
      description: contentResponse.description,
      contentJson: formContentResponse.contentJson,
      tag: contentResponse.tag
    }
  }

  if (contentResponse.contentType === 'url') {
    const urlContentResponse = (
      await documentManagementApiClient().api.latestUrlContentRevisionByMedicalInstitution(
        medicalInstitutionId,
        contentId,
        query
      )
    ).data

    urlContent.value = {
      title: contentResponse.title,
      description: contentResponse.description,
      url: urlContentResponse.url,
      tag: contentResponse.tag
    }
  }

  displayNameStore.updateDisplayName({
    breadcrumbs: [
      {
        title: t('pages.documentSets.documentSets'),
        href: '/document-sets',
        disable: false
      },
      {
        title: t('pages.documentSets.contents'),
        href: `/contents`,
        disable: false
      },
      {
        title: `${contentResponse.title}`,
        disable: true
      }
    ]
  })
}
</script>

<template>
  <v-container class="container">
    <UrlContentDetail
      v-if="urlContent != undefined"
      :urlContent="urlContent"
      :contentId="contentId"
    />
    <FormContentDetail
      v-if="formContent != undefined"
      :formContent="formContent"
      :contentId="contentId"
    />
  </v-container>
</template>

<style lang="scss" scoped>
.container {
  padding: 0px 16px 16px 16px;
}
</style>
