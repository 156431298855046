<script setup lang="ts">
import Text from '@components/Base/BodyText/index.vue'
import BaseButton from '@components/Base/Button/index.vue'
import Label from '@components/Base/Label/index.vue'
import { getTypography } from '@optim-design-system/src'
import { defineProps, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { UrlContent } from './type'

import { documentManagementApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const { t } = useI18n()

type Props = {
  urlContent: UrlContent
  contentId: string
}

const props = defineProps<Props>()
const contentId = ref(props.contentId)
const urlContent = ref({ ...props.urlContent })

const axiosErrorGuard = useAxiosErrorGuard()

// アラートの削除ボタンがタップされた時の処理
const confirmDestroy = async (done: () => void, failure: () => void) => {
  try {
    await documentManagementApiClient().api.destroyContentByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.contentId,
      { optimId: '' }
    )
    // コンテンツ覧画面へ遷移
    snackbarStore.setSuccessSnackbar(
      t('attributes.successDeleted', { resource: t('pages.documentSets.contents') })
    )
    done()
    transitionList()
  } catch (error: unknown) {
    failure()
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      snackbarStore.setErrorSnackbar(
        t('attributes.failureDelete', { resource: t('pages.documentSets.contents') })
      )
      return
    }

    throw error
  }
}

// コンテンツ編集画面へ遷移
const transitionEdit = () => {
  router.push(`/contents/${contentId.value}/edit`)
}

const transitionList = () => {
  router.push('/contents')
}

// モーダル開閉管理
const isOpenDeleteDialog = toRef<boolean>(false)

// 削除確認ダイアログを開く
const openDeleteConfirmDialog = () => {
  isOpenDeleteDialog.value = true
}

const tokenStyle = getTypography('LABEL_S')
</script>

<template>
  <div class="url-content-detail">
    <div class="url-content-detail-header">
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('attributes.edit')"
        icon="mode_edit_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="transitionEdit"
      >
      </BaseButton>
      <BaseButton
        v-if="medicalInstitutionsStore.isAdmin()"
        type="icon-link"
        variant="outlined"
        :content="t('attributes.delete')"
        icon="delete_outline"
        color="primitive-white-DEFAULT-value"
        tokenType="LABEL_M"
        size="small"
        @click="openDeleteConfirmDialog"
      >
      </BaseButton>
    </div>
    <div class="rounded-lg url-content-detail-body">
      <div class="row">
        <Label for-html="content_title" :labelText="t('features.contentSet.Detail.input.title')" />
        <Text class="mt-1" :text="urlContent.title" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.description')"
        />
        <Text class="url-content-description" :text="urlContent.description" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label for-html="content_url" :labelText="t('features.contentSet.Detail.input.url')" />
        <Text class="mt-1" :text="urlContent.url" :typographyType="'p'" />
      </div>
      <div class="row">
        <Label
          for-html="content_description"
          :labelText="t('features.contentSet.Detail.input.tag')"
        />
        <v-chip v-if="urlContent.tag" :style="tokenStyle" class="mt-2 mb-4 tag" variant="outlined">
          <span :style="tokenStyle">
            {{ urlContent.tag }}
          </span>
        </v-chip>
      </div>
    </div>

    <!-- 削除確認ダイアログ -->
    <OnlyTextContentDialog
      :value="isOpenDeleteDialog"
      :title="t('features.contentSet.Detail.alert.title')"
      :content="t('features.contentSet.Detail.alert.content')"
      :cancelBtnText="t('attributes.cancel')"
      :confirmBtnText="t('features.contentSet.Detail.alert.deleted')"
      :confirmBtnColor="'object-caution'"
      :size="'medium'"
      :confirmBtnVisible="true"
      @input="isOpenDeleteDialog = $event"
      @confirm="confirmDestroy"
    />
  </div>
</template>

<style lang="scss" scoped>
.url-content-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 616px;
  padding: 16px;
}
.url-content-detail-header {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-bottom: 8px;
}
.url-content-description {
  white-space: pre-wrap;
  margin-top: 4px;
}
.row {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-wrap: break-word;
}
.tag {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  box-shadow: 0px 1px 2px 0px #0000004d;
  border-radius: 4px;
}
</style>
