<script setup lang="ts">
import { reactive, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import UrlContentEditForm from './UrlContentEditForm/index.vue'

import type { UrlContentInput } from './UrlContentEditForm/type'

import { documentManagementApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

type Props = {
  contentInput: UrlContentInput
  contentId: string
}

const isLoading = ref<boolean>(false)

// コンテンツ情報取得
const props = defineProps<Props>()
const contentId = ref(props.contentId)
const contentInput = reactive({ ...props.contentInput })
const axiosErrorGuard = useAxiosErrorGuard()

// 更新処理
const update = async () => {
  isLoading.value = true

  try {
    await documentManagementApiClient().api.updateContentByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      contentId.value,
      { optimId: '' },
      {
        title: contentInput.title,
        description: contentInput.description,
        urlContentRevision: {
          url: contentInput.url
        },
        tag: contentInput.tag
      }
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', { resource: t('pages.documentSets.contents') })
    )
    transitionContentDetail()
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

const transitionContentDetail = () => {
  router.push(`/contents/${contentId.value}`)
}
// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)
// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <UrlContentEditForm
    v-model:title="contentInput.title"
    v-model:description="contentInput.description"
    v-model:url="contentInput.url"
    v-model:tag="contentInput.tag"
    :disabled="isLoading"
    @submit="update"
    @cancel="transitionContentDetail"
  />

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style></style>
