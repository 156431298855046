<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiHospitalizationOrderRepresentativeItem } from '@/api/generate/apiSchema'
import type { PatientDetailInput } from '@/features/PatientDetail/type'

import PropertyField from '@/components/Base/PropertyField/index.vue'
import useBirthDate from '@/composables/useBirthDate'
import useFormattedName from '@/composables/useFormattedName'

const { getFormattedNameAndPhoneticName } = useFormattedName()
const { getFormattedNameAndRelationship } = useFormattedName()
const { getFormattedBirthDate } = useBirthDate()

const { t } = useI18n()

type Props = {
  // 入院患者詳細
  patientDetail: PatientDetailInput
  representatives: MiHospitalizationOrderRepresentativeItem[]
}
const props = defineProps<Props>()

// 患者氏名を表示フォーマットに変更
const patientName = computed(() => {
  return getFormattedNameAndPhoneticName(
    props.patientDetail.familyName,
    props.patientDetail.givenName,
    props.patientDetail.phoneticFamilyName,
    props.patientDetail.phoneticGivenName
  )
})

// 生年月日を表示フォーマットに変更
const formattedBirthDate = getFormattedBirthDate(props.patientDetail.birthDate)

// 代理人氏名を表示フォーマットに変更
const representativeName = computed(() => {
  const name = ref<string[]>([])
  props.representatives.forEach((value) => {
    name.value.push(
      getFormattedNameAndRelationship(value.familyName, value.givenName, value.relationshipCode)
    )
  })
  return name.value.join(' ')
})
</script>
<template>
  <div class="patient-property-field">
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.no')"
        :value-content="props.patientDetail.patientNo"
      />
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.name')"
        :value-content="patientName"
      />
    </div>
    <div class="row">
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.birthDate')"
        :value-content="formattedBirthDate"
      />
      <PropertyField
        :key-content="t('features.DetailHospitalizationOrder.patientDetail.gender')"
        :value-content="t(`attributes.gender.${props.patientDetail.gender}`)"
      />
    </div>
    <PropertyField
      :key-content="t('features.DetailHospitalizationOrder.patientDetail.representativeName')"
      :value-content="representativeName"
    />
  </div>
</template>
<style lang="scss" scoped>
.patient-property-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.row {
  display: flex;
  gap: 5px;
}

.row > * {
  flex: 1 1;
}
</style>
