<script setup lang="ts">
import { computed } from 'vue'

import { getToken } from '@/components/Typography/token'
import { useLazyChange } from '@/composables/useLazyChange'

type Props = {
  label?: string
  modelValue: string
}

const props = defineProps<Props>()

const valueHook = useLazyChange(
  computed({
    get: () => props.modelValue,
    set: (value) => {
      emit('update:modelValue', value)
    }
  })
)

type Emits = {
  (e: 'update:modelValue', value: string): void
}

const emit = defineEmits<Emits>()

const tokenStyleBodyL = getToken('BODY_L')
</script>

<template>
  <v-text-field
    :style="tokenStyleBodyL"
    class="search-text-field"
    :model-value="valueHook.value"
    :label="label"
    variant="outlined"
    rounded
    @input="valueHook.setValue($event.target.value)"
    hide-details
  >
    <template v-slot:prepend-inner>
      <v-icon icon="search" size="20" />
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.search-text-field {
  --v-input-control-height: 48px !important;
  --v-input-padding-top: 12px !important;
  .v-field {
    --v-field-padding-bottom: 12px !important;
  }
}
</style>
