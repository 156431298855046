<script setup lang="ts">
import { toRef, ref } from 'vue'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { type MiUpdateMedicalWorkerRequest } from '@/api/generate/apiSchema'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import MedicalWorkerEditForm from '@/features/MedicalWorkerEdit/MedicalWorkerEditForm/index.vue'
import { type MedicalWorkerEditFormInput } from '@/features/MedicalWorkerEdit/type'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

type Props = {
  form: MedicalWorkerEditFormInput
  medicalWorkerId: string
}

const isLoading = ref<boolean>(false)

const props = defineProps<Props>()
const form = reactive({ ...props.form })
const medicalWorkerId = ref(props.medicalWorkerId)

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const snackbarStore = useSnackbarStore()

const axiosErrorGuard = useAxiosErrorGuard()

// 医療機関ユーザー更新
const update = async () => {
  isLoading.value = true
  const data: MiUpdateMedicalWorkerRequest = {
    familyName: form.familyName,
    givenName: form.givenName,
    phoneticFamilyName: form.phoneticFamilyName,
    phoneticGivenName: form.phoneticGivenName,
    email: form.email,
    roleUrn: form.role
  }

  try {
    await medicalInstitutionsApiClient().api.updateMedicalWorkerByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      medicalWorkerId.value,
      { optimId: '', optimIdToken: '' },
      data
    )

    snackbarStore.setSuccessSnackbar(
      t('attributes.successUpdated', { resource: t('pages.medicalWorkers.list') })
    )
    transitionDetail()
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

// 医療機関ユーザー詳細画面へ遷移
const transitionDetail = () => {
  router.push(`/medical-workers/${medicalWorkerId.value}`)
}

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- 入力フォーム -->
  <MedicalWorkerEditForm
    v-model:familyName="form.familyName"
    v-model:givenName="form.givenName"
    v-model:phoneticFamilyName="form.phoneticFamilyName"
    v-model:phoneticGivenName="form.phoneticGivenName"
    v-model:email="form.email"
    v-model:role="form.role"
    :disabled="isLoading"
    @submit="update()"
    @cancel="transitionDetail()"
  />
  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog()"
  />
</template>

<style></style>
