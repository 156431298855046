<script setup lang="ts">
// import process from 'process'
import BaseButton from '@components/Base/Button/index.vue'
import BaseSnackbar from '@components/Base/Snackbar/index.vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

import type { NavMenu } from './Navigation/types'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { AppBar, Navigation } from '@/components/Layout'
import AppTools from '@/features/AppTools/index.vue'
import { useDisplayNameStore } from '@/stores/displayName'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

const { t } = useI18n()

const medicalInstitutionsStore = useMedicalInstitutionsStore()
const { mobile } = useDisplay()

const readNavMenus: NavMenu[] = [
  {
    name: t('pages.hospitalizationOrders.list'),
    icon: 'assignment',
    to: '/hospitalization-orders'
  },
  {
    name: t('pages.patients.list'),
    icon: 'person',
    to: '/patients'
  },
  {
    name: t('pages.documentSets.documentSets'),
    icon: 'folder',
    to: '/document-sets'
  },
  {
    name: t('pages.medicalWorkers.list'),
    icon: 'groups',
    to: '/medical-workers'
  }
]

const navMenus = computed<NavMenu[]>(() => {
  const menus: NavMenu[] = []
  if (medicalInstitutionsStore.isLoading() || !medicalInstitutionsStore.isLogged()) {
    return menus
  }

  if (medicalInstitutionsStore.isRead()) {
    menus.push(...readNavMenus)
  }
  return menus
})

const drawer = ref<boolean>(false)
// ログインユーザアカウント情報取得
type userType = {
  name: string
  email: string
  appRole: string
  corporation: {
    name: string
  }
}

const findAccount = (): userType => {
  return {
    name: medicalInstitutionsStore.currentUserName(),
    email: medicalInstitutionsStore.currentMedicalInstitution().email,
    appRole: medicalInstitutionsStore.currentMedicalInstitution().roleUrn,
    corporation: {
      name: medicalInstitutionsStore.currentMedicalInstitution().name
    }
  }
}

const logout = async () => {
  // Gatewayを介して1APIのログアウトで全てログアウトされる
  await medicalInstitutionsApiClient().instance.post(
    '/gateway/logout',
    {},
    { withCredentials: true }
  )
  location.pathname = '/'
}

const login = () => {
  // router.pushではリファラが切り替わらないため、not_foundなどのエラーページにログイン後に戻る可能性がある
  location.pathname = '/'
}

const toggleNavigation = () => {
  drawer.value = !drawer.value
}

const store = useDisplayNameStore()
</script>

<template>
  <v-app class="base-layout">
    <!-- サイドバーナビゲーション -->
    <div>
      <navigation
        :model-value="true"
        :nav-menus="navMenus"
        :drawer="drawer"
        @click="toggleNavigation"
      />
    </div>
    <!-- ヘッダー -->
    <v-app-bar
      class="no-underline app-bar"
      :style="
        mobile ? 'padding-left: 48px;' : drawer ? 'padding-left: 48px;' : 'padding-left: 256px;'
      "
    >
      <!-- 画面情報の表示 -->
      <app-bar :breadcrumbs="store.displayName.breadcrumbs ?? []" />
      <!-- アカウント情報の表示 -->
      <div
        class="account-menu"
        v-if="!medicalInstitutionsStore.isLoading() && medicalInstitutionsStore.isLogged()"
      >
        <app-tools
          :name="findAccount().name"
          :email="findAccount().email"
          :corporation-name="findAccount().corporation.name"
          :logout="logout"
        />
      </div>
      <!-- ログインボタンの表示 -->
      <div v-else-if="!medicalInstitutionsStore.isLoading()" class="login-button">
        <BaseButton
          size="small"
          color="primary"
          :content="t('attributes.login')"
          @click="login"
          token-type="LABEL_M"
        />
      </div>
    </v-app-bar>

    <v-main class="app-main" :style="mobile ? 'padding-left: 48px;' : ''">
      <slot />
    </v-main>

    <BaseSnackbar />
  </v-app>
</template>

<style lang="scss" scoped>
.base-layout {
  &__app-bar {
    border-bottom: 1px solid #d8d8d8 !important;
  }
  background: var(--background-background, #f2f6f9);
  min-width: 600px;
}
// vuetifyの影枠を消す
.no-underline {
  box-shadow: none !important;
}
.v-toolbar__content {
  background: var(--background-background, #f2f6f9);
}

.app-bar {
  background: var(--background-background, #f2f6f9) !important;
  width: 100% !important;
  left: 0px !important;
}
.account-menu {
  padding: 8px;
}
.login-button {
  padding: 16px;
}
</style>
