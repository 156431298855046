import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSnackbarStore = defineStore('snackbar', () => {
  const isActive = ref<boolean>(false)
  const message = ref<string>()
  const color = ref<string>()
  let timeoutId: NodeJS.Timeout | undefined = undefined
  const delay = 4000

  const unsetSnackbar = () => {
    isActive.value = false
    message.value = undefined
    color.value = undefined
  }

  const setSuccessSnackbar = (_message: string) => {
    clearTimeout(timeoutId)
    unsetSnackbar()
    setTimeout(() => {
      message.value = _message
      isActive.value = true
      color.value = 'success'
      timeoutId = setTimeout(() => {
        isActive.value = false
      }, delay)
    }, 100)
  }

  const setErrorSnackbar = (_message: string) => {
    clearTimeout(timeoutId)
    unsetSnackbar()
    setTimeout(() => {
      message.value = _message
      isActive.value = true
      color.value = 'error'
      timeoutId = setTimeout(() => {
        isActive.value = true
      }, delay)
    }, 100)
  }

  return {
    unsetSnackbar,
    setSuccessSnackbar,
    setErrorSnackbar,
    isActive,
    message,
    color
  }
})
