<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

import { type SectionContainer } from '../type'

const { t } = useI18n()

// props 定義
const props = defineProps<{
  sectionIndex: number
  section: SectionContainer
}>()

const section: SectionContainer = reactive(props.section)

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}
</script>

<template>
  <!-- セクション名 -->
  <v-text-field
    v-model="section.name"
    class="required-field"
    :placeholder="t('features.FormContents.new.section.placeholder')"
    counter="100"
    :hint="t('validations.maxLength', { max: 100 })"
    persistent-hint
    :rules="[required(section.name, t('features.FormContents.new.section.placeholder'))]"
  ></v-text-field>
</template>

<style scoped>
:deep(.required-field :before) {
  border-width: 0 0 3px;
}
</style>
