<script setup lang="ts">
import { onBeforeMount, onMounted, ref, toRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import PatientSelectListTable from './PatientSelectListTable/index.vue'

import type { MiPatientItem } from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'
import type { Item } from '@/components/Table/ScrollSortTable/types'

import SearchTextField from '@/components/Base/SearchTextField/index.vue'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { usePatientsStore } from '@/stores/patients'

type Emits = {
  (e: 'itemClick', item: Item): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()

const searchPatientNo = toRef('')
const searchPatientName = toRef('')

const isLoading = ref(true)
const patientsStore = usePatientsStore()
const orderBy = ref<string | undefined>()
const order = ref<Order | undefined>()

const medicalInstitutionId = useMedicalInstitutionsStore().currentMedicalInstitution().id
const selectPatientListTable = ref<InstanceType<typeof PatientSelectListTable>>()
// データに変更があった場合は、スクロールをリセットする
const resetScroll = () => {
  if (selectPatientListTable.value == undefined) {
    return
  }
  selectPatientListTable.value.resetScroll()
}

watch([searchPatientNo, searchPatientName, orderBy, order], () => {
  // 前回の検索を実行中でも検索を新規開始する
  isLoading.value = true
  patientsStore
    .fetchPatientList(
      medicalInstitutionId,
      searchPatientNo.value,
      searchPatientName.value,
      undefined,
      orderBy.value,
      order.value
    )
    .then((response) => {
      if (response.success || response.cancel != true) {
        isLoading.value = false
      }

      if (response.success) {
        resetScroll()
      } else {
        throw Error('fetch patient list')
      }
    })
})

onMounted(async () => {
  const response = await patientsStore.fetchPatientList(medicalInstitutionId)

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }

  if (!response.success) {
    throw Error('fetch patient list')
  }
})

onBeforeMount(() => {
  patientsStore.clearPatientList()
})

const requestNextItem = (item: MiPatientItem) => {
  isLoading.value = true
  fetchNextPatientList(item)
}

const fetchNextPatientList = async (item: MiPatientItem) => {
  //item（現ページの最後の患者）以降のデータをfetch

  const response = await patientsStore.fetchNextPatientList(
    medicalInstitutionId,
    item.id,
    searchPatientNo.value,
    searchPatientName.value,
    undefined,
    orderBy.value,
    order.value
  )

  if (response.success || response.cancel != true) {
    isLoading.value = false
  }
}

const itemClick = (item: Item) => {
  emit('itemClick', item)
}

const sortBy = (_orderBy?: string, _order?: Order) => {
  orderBy.value = _orderBy
  order.value = _order
}
</script>
<template>
  <div>
    <div class="search-style">
      <div style="min-width: 180px">
        <SearchTextField
          :label="t('features.hospitalizationOrders.Edit.search.patientNo')"
          :modelValue="searchPatientNo"
          @update:model-value="(value) => (searchPatientNo = value)"
        />
      </div>
      <div style="min-width: 240px">
        <SearchTextField
          :label="t('features.hospitalizationOrders.Edit.search.patientName')"
          :modelValue="searchPatientName"
          @update:model-value="(value) => (searchPatientName = value)"
        />
      </div>
    </div>
    <PatientSelectListTable
      @itemClick="itemClick"
      @update:sortBy="sortBy"
      @requestNextItem="
        () => {
          const patientList = patientsStore.getPatientList()
          // 次のページの患者一覧表示（現ページの最後の患者）
          requestNextItem(patientList.value.slice(-1)[0])
        }
      "
    />
  </div>
</template>
<style lang="scss" scoped>
.search-style {
  display: flex;
  padding-bottom: 16px;
  gap: 8px;
}
</style>
