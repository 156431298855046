<script setup lang="ts">
import { ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DocumentSetType } from './DocumentSetRegistrationForm/type'
import type { MiFormDocumentSetRequest } from '@/api/generate/apiSchema'

import { medicalInstitutionsApiClient } from '@/api/apiClient'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useAxiosErrorGuard from '@/composables/useAxiosErrorGuard'
import DocumentSetRegistrationForm from '@/features/DocumentSetRegistration/DocumentSetRegistrationForm/index.vue'
import router from '@/router'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'
import { useSnackbarStore } from '@/stores/snackbar'

const { t } = useI18n()

const snackbarStore = useSnackbarStore()
const axiosErrorGuard = useAxiosErrorGuard()
const medicalInstitutionsStore = useMedicalInstitutionsStore()

const documentSetForm = ref<DocumentSetType>({
  title: '',
  description: '',
  contents: []
})

// フォーム新規登録
const create = async () => {
  isLoading.value = true
  // リクエスト情報を作成
  const data: MiFormDocumentSetRequest = {
    title: documentSetForm.value.title,
    description: documentSetForm.value.description ?? '',
    contentIds: documentSetForm.value.contents.map(({ id }) => id)
  }
  const medicalInstitutionId = medicalInstitutionsStore.currentMedicalInstitution().id
  const query = {
    optimId: '',
    optimIdToken: ''
  }

  try {
    const response = await medicalInstitutionsApiClient().api.createDocumentSetByMedicalInstitution(
      medicalInstitutionId,
      query,
      data
    )
    snackbarStore.setSuccessSnackbar(
      t('attributes.successCreated', { resource: t('pages.documentSets.documentSets') })
    )
    router.push(`/document-sets/${response.data.id}`)
  } catch (error: unknown) {
    isLoading.value = false
    if (axiosErrorGuard.isBadRequest(error) || axiosErrorGuard.isConflict(error)) {
      toggleValidationDialog()
      return
    }

    throw error
  }
}

const isLoading = ref<boolean>(false)

// モーダル開閉管理
const isOpenValidationDialog = toRef<boolean>(false)

// バリデーションモーダルを開閉
const toggleValidationDialog = () => {
  isOpenValidationDialog.value = !isOpenValidationDialog.value
}
</script>

<template>
  <!-- フォーム新規作成画面 -->
  <DocumentSetRegistrationForm
    v-model:documentSetRegistrationForm="documentSetForm"
    :form="documentSetForm"
    @submit="create"
    @cancel="() => router.push(`/document-sets/`)"
    :isLoading="isLoading"
  />

  <!-- バリデーションエラーダイアログ -->
  <OnlyTextContentDialog
    :value="isOpenValidationDialog"
    :content="t('validations.error')"
    :cancelBtnText="t('attributes.close')"
    :size="'small'"
    :confirmBtnVisible="false"
    @input="toggleValidationDialog"
  />
</template>

<style></style>
