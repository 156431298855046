<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { MiHospitalizationOrderDocumentSetItem } from '@/api/generate/apiSchema'
import type { Header, Item } from '@/components/Table/ScrollSortTable/types'

import { isSentDocumentSetContentStatusCode, type Order } from '@/api/types'
import { ScrollSortTable } from '@/components/Table'
import useChip from '@/composables/useChip'
import { useConstants } from '@/composables/useConstants'
import useContentTypeFormatter from '@/composables/useContentTypeFormatter'
import useDateFormatter from '@/composables/useDateFormatter'
import router from '@/router'

defineProps<{
  isLoading: boolean
  hospitalizationOrderId: string
}>()

const emit = defineEmits<{
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const documentSetList = defineModel<MiHospitalizationOrderDocumentSetItem[]>('documentSetList', {
  required: true
})

const { t } = useI18n()

const { getSentDocumentSetContentStatus } = useChip()
const { getFormattedDateTime } = useDateFormatter()
const { transContentType } = useContentTypeFormatter()
const { CONSTANTS } = useConstants()

const headers: Header[] = [
  {
    title: t('features.sentDocumentSetList.header.documentSetName'),
    key: 'documentSetTitle',
    width: 'auto',
    minWidth: '116px',
    maxWidth: '116px'
  },
  {
    title: t('features.sentDocumentSetList.header.contentName'),
    key: 'contentTitle',
    width: 'auto',
    minWidth: '116px',
    maxWidth: '116px'
  },
  {
    title: t('features.sentDocumentSetList.header.contentType'),
    key: 'contentType',
    width: '120px',
    minWidth: '116px'
  },
  {
    title: t('features.sentDocumentSetList.header.sendStatus'),
    key: 'contentStatusCode',
    align: 'center',
    width: '120px',
    minWidth: '116px'
  },
  {
    title: t('features.sentDocumentSetList.header.updatedAt'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px',
    maxWidth: '150px'
  }
]

const items = computed(() => {
  return documentSetList.value.map((documentSet) => {
    if (
      isSentDocumentSetContentStatusCode(documentSet.contentStatusCode) &&
      documentSet.contentType == CONSTANTS.CONTENT_TYPE.FORM
    ) {
      const documentSetStatus = getSentDocumentSetContentStatus(documentSet.contentStatusCode)
      return {
        id: documentSet.hospitalizationOrderDocumentSetContentId,
        documentSetTitle: documentSet.documentSetTitle,
        contentTitle: documentSet.contentTitle,
        contentType: transContentType(documentSet.contentType),
        contentStatusCode: {
          ...documentSetStatus,
          label: t(documentSetStatus.label)
        },
        updatedAt: getFormattedDateTime(new Date(documentSet.updatedAt))
      }
    }
    const documentSetStatus = ' - '

    return {
      id: documentSet.hospitalizationOrderDocumentSetContentId,
      documentSetTitle: documentSet.documentSetTitle,
      contentTitle: documentSet.contentTitle,
      contentType: transContentType(documentSet.contentType),
      contentStatusCode: documentSetStatus,
      updatedAt: getFormattedDateTime(new Date(documentSet.updatedAt))
    }
  })
})

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}
</script>

<template>
  <ScrollSortTable
    class="sent-document-set-list-table"
    ref="scrollSortTable"
    :loading="isLoading"
    :headers="headers"
    :items="items"
    :noDataText="t('attributes.noDataText')"
    @update:sortBy="sortBy"
    @itemClick="
      (item: Item) => {
        router.push(`/hospitalization-orders/${hospitalizationOrderId}/contents/${item.id}`)
      }
    "
  />
</template>

<style scoped>
.sent-document-set-list-table {
  max-height: 624px;
}
</style>
