import { v4 as uuidV4 } from 'uuid'

import type { MiFormContentRevisionItem } from '@/api/generate/apiSchema'

import {
  formOptionTypeName,
  FormOptionType,
  SectionType,
  type SectionContainer,
  type TextOption,
  type ChoiceOption,
  type CheckBoxOption,
  type QuantitativeOption,
  type DateOption,
  type InputSetOption,
  type FormContentInput,
  type FormOption,
  type Choice,
  type BranchType
} from '@/features/FormContentForm/type'

/**
 * typeからフォームオプション種別名称を取得する
 * @param {FormOptionType | SectionType } optionType タイプ種別名
 * @return {string} フォームオプションの種別名称
 */
export const getOptionTypeName = (optionType: FormOptionType | SectionType): string => {
  if (optionType in formOptionTypeName) {
    return formOptionTypeName[optionType].name
  }
  return ''
}

/**
 * デフォルト値のマッピング
 */
type FormOptionMapping = {
  [FormOptionType.Text]: TextOption
  [FormOptionType.Choice]: ChoiceOption
  [FormOptionType.CheckBox]: CheckBoxOption
  [FormOptionType.Quantitative]: QuantitativeOption
  [FormOptionType.Date]: DateOption
  [FormOptionType.InputSet]: InputSetOption
}

/**
 * フォームオプション 初期値作成メソッド
 */
export const createInitFormOption = (
  optionType: FormOptionType
): FormOptionMapping[FormOptionType] => {
  switch (optionType) {
    case FormOptionType.Text:
      return {
        id: uuidV4(),
        type: FormOptionType.Text,
        name: '',
        description: '',
        isRequired: false,
        isMultiLine: false
      }
    case FormOptionType.Choice:
      return {
        id: uuidV4(),
        type: FormOptionType.Choice,
        name: '',
        description: '',
        isRequired: false,
        choices: [{ name: '', branch: { type: 'default' } }]
      }
    case FormOptionType.CheckBox:
      return {
        id: uuidV4(),
        type: FormOptionType.CheckBox,
        name: '',
        description: '',
        isRequired: false,
        selects: []
      }
    case FormOptionType.Quantitative:
      return {
        id: uuidV4(),
        type: FormOptionType.Quantitative,
        name: '',
        description: '',
        isRequired: false,
        unit: ''
      }
    case FormOptionType.Date:
      return {
        id: uuidV4(),
        type: FormOptionType.Date,
        name: '',
        description: '',
        isRequired: false
      }
    case FormOptionType.InputSet:
      return {
        id: uuidV4(),
        type: FormOptionType.InputSet,
        name: '',
        optionIds: []
      }
    default:
      throw new Error(`undefined FormOptionType: ${optionType}`)
  }
}

/**
 * セクションコンテナの初期値作成メソッド
 */
export const createInitSectionContainer = (optionType: SectionType): SectionContainer => {
  if (SectionType.Section === optionType) {
    return {
      id: uuidV4(),
      name: '',
      options: []
    }
  }
  throw new Error(`undefined SectionType: ${optionType}`)
}

// Branchの初期値作成メソッド
export const createDefaultBranch = (): BranchType => {
  return { type: 'default' }
}

// Choiceの初期値作成メソッド
export const createDefaultChoice = (): Choice => {
  return { name: '', branch: createDefaultBranch() }
}

/**
 * フォームオプションIDからフォームオプションインデックス番号を逆引き
 * @param form フォームオブジェクト
 * @param targetOptionId 探したいフォームオプションのフォームオプションID
 * @param sectionIndex （任意）探索するセクションのインデックス番号
 * @returns 見つからない場合null、ある場合セクションインデックス番号とオプションインデックス番号を返却
 */
export const getDestinationOptionIndex = (
  form: FormContentInput | undefined,
  targetOptionId: string,
  sectionIndex?: number
): { sectionIndex: number; optionIndex: number } | null => {
  if (!targetOptionId) {
    return null
  }
  if (!form) {
    return null
  }
  // セクション内だけを探索したい場合
  if (sectionIndex) {
    const resultOptionIndex = form.contentJson[sectionIndex].options.findIndex(
      (option: FormOption) => {
        option.id === targetOptionId
      }
    )
    return { sectionIndex: sectionIndex, optionIndex: resultOptionIndex }
  }
  // セクション内を上から探索
  let result = null
  form.contentJson.forEach((section: SectionContainer, sectionIndex: number) => {
    section.options.forEach((option: FormOption, optionIndex: number) => {
      option.id === targetOptionId &&
        (result = { sectionIndex: sectionIndex, optionIndex: optionIndex })
    })
  })
  return result
}

// contentJsonをAPI送信直前に整形
export const tidyContentJson = (dirtyJson: SectionContainer[]): MiFormContentRevisionItem => {
  // テキストフォームオプションで単一行なら改行コードを削除
  dirtyJson.forEach((sc: SectionContainer) => {
    sc.options.forEach((fo: FormOption) => {
      fo.type === FormOptionType.Text &&
        !fo.isMultiLine &&
        fo.description.length &&
        (fo.description = fo.description.replace(/(\r\n|\n|\r)/g, ''))
    })
  })

  // 選択肢フォームオプションで分岐先がdefaultの場合にnextに置換
  dirtyJson.forEach((sc: SectionContainer) =>
    sc.options.forEach(
      (fo: FormOption) =>
        fo.type === FormOptionType.Choice &&
        fo.choices.forEach(
          (c: Choice) => c.branch.type === 'default' && (c.branch = { type: 'next' })
        )
    )
  )

  const tidyJson = JSON.stringify(dirtyJson)

  return { contentJson: tidyJson }
}
