<script setup lang="ts">
import PropertyField from '@components/Base/PropertyField/index.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import type { DisplayPatientInfo } from '../../type'

import useBirthDate from '@/composables/useBirthDate'
import useFormattedName from '@/composables/useFormattedName'
type Props = {
  patientInfo: DisplayPatientInfo
}

const props = defineProps<Props>()

const { t } = useI18n()
const { getFormattedBirthDate } = useBirthDate()
const { getFormattedNameAndPhoneticName } = useFormattedName()

const formattedName = computed(() => {
  return getFormattedNameAndPhoneticName(
    props.patientInfo.familyName,
    props.patientInfo.givenName,
    props.patientInfo.phoneticFamilyName,
    props.patientInfo.phoneticGivenName
  )
})

// 生年月日を表示フォーマットに変更
const formattedBirthDate = getFormattedBirthDate(props.patientInfo.birthDate)
</script>

<template>
  <div class="patient-detail-body rounded-lg">
    <div class="patient-field-row">
      <PropertyField
        :key-content="t('features.SendDocumentSets.PatientDisplay.patientNo')"
        :value-content="patientInfo.patientNo"
        class="patient-field"
      />
      <PropertyField
        :key-content="t('features.SendDocumentSets.PatientDisplay.name')"
        :value-content="formattedName"
        class="patient-field"
      />
    </div>
    <div class="patient-field-row">
      <PropertyField
        :key-content="t('features.SendDocumentSets.PatientDisplay.birthDate')"
        :value-content="formattedBirthDate"
        class="patient-field"
      />
      <PropertyField
        :key-content="t('features.SendDocumentSets.PatientDisplay.gender')"
        :value-content="t(`attributes.gender.${props.patientInfo.gender}`)"
        class="patient-field"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.patient-detail-body {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.patient-select {
  display: flex;
  align-items: center;
}

.patient-field-row {
  display: flex;
  gap: 2px;
}

.patient-field-row > * {
  flex: 1 1;
}

.patient-field {
  max-width: calc(50% - 1px);
}
</style>
