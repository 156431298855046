<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { VDataTable } from 'vuetify/lib/components/index.mjs'

import type { Header, Item } from './types'

import { getToken } from '@/components/Typography/token'

/**
 * ソートして表示可能なテーブルコンポーネント
 * ソートは外部からソートされる前提となる
 */

type Props = {
  headers: Header[] // keyがactionは使用しているため注意
  items: Item[]
  loading?: boolean
  viewAction?: boolean // >ボタンを表示する場合 true
  noDataText: string // データがない場合に表示されるテキスト
  height?: string
  isSelectedDisable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  viewAction: false,
  height: '100%',
  isSelectedDisable: false
})

const dataTable = ref<InstanceType<typeof VDataTable>>()

onMounted(() => {
  // スクロールページネーションの設定
  if (dataTable.value == undefined) {
    return
  }

  const tableElement = dataTable.value.$el.children[0]
  tableElement.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  // スクロールページネーションの解除
  if (dataTable.value == undefined) {
    return
  }

  const tableElement = dataTable.value.$el.children[0]
  tableElement.removeEventListener('scroll', onScroll)
})

export type SortItem = { key?: string; order?: 'ASC' | 'DESC' }

const emit = defineEmits<{
  (e: 'itemClick', item: Item): void
  (e: 'requestNextItem', item: unknown): void
  (e: 'update:sortBy', item: SortItem): void
  (e: 'update:selectedItems', data: unknown[]): void
}>()

const onScroll = (event: Event) => {
  const tableElement = event.target as HTMLElement
  if (
    tableElement.scrollTop + tableElement.clientHeight >= tableElement.scrollHeight &&
    !props.loading
  ) {
    emit('requestNextItem', items.value[items.value.length - 1])
  }
}

const selectableItems = defineModel('selectableItems')

/**
 * アクション用のヘッダーを追加する場合は、追加した上で返す
 */
const headers = computed(() => {
  if (props.viewAction) {
    return props.headers.concat({
      key: 'action',
      title: '',
      minWidth: '',
      width: ''
    })
  } else {
    return props.headers
  }
})
/**
 * アクション用のアイテムを追加する場合は、追加した上で返す
 */
const items = computed<Item[]>(() => {
  if (props.viewAction) {
    return props.items.map((item) => {
      return {
        ...item,
        action: ''
      }
    })
  } else {
    return props.items
  }
})

/**
 * ソートテーブルに付与するクラスを計算する
 * @param column カラム
 * @param isSorted ソートが選択されている場合true
 */
const sortTableHeaderClass = (column: Header, isSorted: boolean) => {
  const classList = []
  if (column.align) {
    classList.push(`check-sort-table-header-column--${column.align}`)
  }

  if (isSorted) {
    classList.push('check-sort-table-header-column--sorted')
  }
  return classList
}

/**
 * テーブルのスクロールをリセットする。
 * アイテムを検索し直す際やソートする際などに利用する。
 * 外部で発火するものとなるため、Exposeする。
 */
const resetScroll = () => {
  if (dataTable.value == undefined) {
    return
  }
  const tableElement = dataTable.value.$el.children[0]
  tableElement.scrollTop = 0
}

defineExpose({
  resetScroll
})

const sortBy = (value: SortItem[]) => {
  emit('update:sortBy', { key: value[0]?.key, order: value[0]?.order })
}

const dataTableTokenStyle = getToken('BODY_M')
</script>

<template>
  <v-data-table
    v-model="selectableItems"
    ref="dataTable"
    class="elevation-1 check-sort-table"
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    fixed-header
    :height="height"
    width="100%"
    hide-default-footer
    :items-per-page="-1"
    density="compact"
    :no-data-text="noDataText"
    @update:sortBy="sortBy"
    :disable-sort="true"
    show-select
    :select-strategy="isSelectedDisable ? 'single' : 'all'"
  >
    <template
      #headers="{
        columns,
        isSorted,
        getSortIcon,
        toggleSort,
        allSelected,
        selectAll,
        someSelected
      }"
    >
      <tr>
        <template v-for="column in columns" :key="column.key">
          <th
            v-if="column.key != 'action' && column.title != ''"
            class="check-sort-table-header-column"
            :class="sortTableHeaderClass(column as Header, isSorted(column))"
            @click="() => toggleSort(column)"
            :style="`min-width: ${column.minWidth}; width: ${column.width};`"
          >
            <span class="check-sort-table-header-item">{{ column.title }}</span>
            <template v-if="isSorted(column)">
              <v-icon :icon="getSortIcon(column)" size="16"></v-icon>
            </template>
            <v-icon
              v-else-if="column.title != ''"
              class="material-icons v-icon notranslate v-theme--digihosTheme v-icon--size-default"
              size="16"
              icon="unfold_more"
            />
          </th>
          <th
            v-else-if="column.title == ''"
            class="check-sort-table-header-column check-sort-table-header-column--checkbox"
          >
            <v-checkbox-btn
              class="check-sort-table-checkbox"
              :indeterminate="someSelected && !allSelected"
              :model-value="allSelected"
              color="primary"
              density="compact"
              @update:model-value="selectAll(!allSelected)"
            />
          </th>
          <th v-else class="check-sort-table-header-column--action" />
        </template>
      </tr>
    </template>
    <template v-if="!isSelectedDisable" #[`header.data-table-select`]="{}"> </template>

    <template v-slot:item="{ item, isSelected, toggleSelect, internalItem }">
      <tr class="check-sort-table-body">
        <td class="check-sort-table-body-column check-sort-table-body-column--checkbox">
          <v-checkbox-btn
            class="check-sort-table-checkbox"
            :model-value="isSelected(internalItem)"
            color="primary"
            density="compact"
            @update:model-value="toggleSelect(internalItem)"
          />
        </td>
        <td
          v-for="header in headers"
          :key="header.key"
          :header="header"
          class="check-sort-table-body-column"
          :style="`min-width: ${header.minWidth}; width: ${header.width}; max-width: ${header.maxWidth};`"
        >
          <div :style="dataTableTokenStyle" class="check-sort-table-body-column--text">
            {{ item[header.key] }}
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<style lang="scss" scoped>
.check-sort-table-header-column {
  cursor: pointer;
  padding: 8px !important;
  background-color: #f2f2f2 !important;
  &:hover {
    background-color: #e5e5e5 !important;
  }
  &--sorted {
    background-color: #d1d4d6 !important;
    &:hover {
      background-color: #d1d4d6 !important;
    }
  }
  &--center {
    text-align: center !important;
  }
  &--checkbox {
    width: 36px;
    background-color: #f2f2f2 !important;
    &:hover {
      background-color: #f2f2f2 !important;
    }
  }
}
.v-table--density-compact {
  --v-table-header-height: 36px;
}

.check-sort-table-header-item {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.check-sort-table-body-column {
  padding: 8px !important;
  &--text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--center {
    text-align: center;
  }
  &--checkbox {
    width: 36px;
  }
}

.check-sort-table-checkbox {
  width: 20px;
}
.v-selection-control--density-compact {
  --v-selection-control-size: 20px;
}
</style>
