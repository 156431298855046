import axios, { HttpStatusCode } from 'axios'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { FetchApiResponse } from './types'
import type {
  MiHospitalizationOrderDocumentSetItem,
  MiHospitalizationOrderResponse
} from '@/api/generate/apiSchema'
import type { Item } from '@/features/HospitalizationOrderDetail/Card/DocumentStatus/type'
import type { PatientDetailInput } from '@/features/PatientDetail/type'
import type { AxiosError } from 'axios'

import { hospitalizationOrderApiClient, medicalInstitutionsApiClient } from '@/api/apiClient'
import useChip from '@/composables/useChip'

export const useHospitalizationOrderDetailStore = defineStore('hospitalizationOrderDetail', () => {
  const { t } = useI18n()
  const hospitalizationOrderDetail = ref<MiHospitalizationOrderResponse>()
  const patientDetail = ref<PatientDetailInput>()
  const documentStatus = ref<Item>()

  const { getDocumentSetStatus } = useChip()

  // 入院オーダー詳細
  const fetchHospitalizationOrderDetail = async (
    medicalInstitutionId: string,
    hospitalizationOrderId: string
  ): Promise<FetchApiResponse<MiHospitalizationOrderResponse>> => {
    try {
      const response =
        await medicalInstitutionsApiClient().api.hospitalizationOrderByMedicalInstitution(
          medicalInstitutionId,
          hospitalizationOrderId,
          { optimId: '' }
        )
      hospitalizationOrderDetail.value = {
        id: response.data.id,
        clientId: response.data.clientId,
        clientHospitalizationOrderId: response.data.clientHospitalizationOrderId,
        patientId: response.data.patientId,
        representatives: response.data.representatives,
        hospitalizationOrderStatus: response.data.hospitalizationOrderStatus,
        hospitalizationDate: response.data.hospitalizationDate,
        dischargeDate: response.data.dischargeDate,
        hospitalDepartment: response.data.hospitalDepartment,
        ward: response.data.ward,
        hospitalRoom: response.data.hospitalRoom,
        createdAt: response.data.createdAt,
        updatedAt: response.data.updatedAt
      }
      return {
        success: true,
        content: hospitalizationOrderDetail.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }
      throw error
    }
  }

  // 患者詳細
  const fetchPatientDetail = async (
    medicalInstitutionId: string,
    patientId: string
  ): Promise<FetchApiResponse<PatientDetailInput>> => {
    try {
      const response = await medicalInstitutionsApiClient().api.patientByMedicalInstitution(
        medicalInstitutionId,
        patientId,
        { optimId: '' }
      )

      if (response === undefined) {
        return {
          success: false,
          status: HttpStatusCode.NotFound
        }
      }

      patientDetail.value = {
        patientNo: response.data.patientNo,
        familyName: response.data.familyName,
        givenName: response.data.givenName,
        phoneticFamilyName: response.data.phoneticFamilyName,
        phoneticGivenName: response.data.phoneticGivenName,
        gender: response.data.gender,
        birthDate: response.data.birthDate,
        isSynchronized: response.data.clientId != undefined
      }

      return {
        success: true,
        content: patientDetail.value
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        return {
          success: false,
          status: axiosError.response?.status
        }
      }
      throw error
    }
  }

  // 提出状況ステータス
  const fetchDocumentSetStatus = async (
    hospitalizationOrderId: string,
    medicalInstitutionId: string
  ) => {
    try {
      const response =
        await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByMedicalInstitution(
          medicalInstitutionId,
          hospitalizationOrderId,
          {
            optimId: ''
          }
        )
      const documentSets = response.data.list

      // URLコンテンツを排除
      const formContents = documentSets.filter((documentSet) => {
        return documentSet.contentType === 'form'
      })

      const statusKey = getStatusKey(documentSets, formContents)

      const documentSetStatus = getDocumentSetStatus(statusKey)
      if (!documentSetStatus) {
        throw Error('unexpected documentSetStatus')
      }

      documentStatus.value = {
        documentSetStatus: {
          ...documentSetStatus,
          label: t(documentSetStatus.label)
        }
      }

      return {
        success: true,
        content: documentStatus.value
      }
    } catch (error: unknown) {
      return {
        success: false,
        status: ''
      }
    }
  }

  return {
    fetchHospitalizationOrderDetail,
    fetchPatientDetail,
    fetchDocumentSetStatus
  }
})

const getStatusKey = (
  documentSets: MiHospitalizationOrderDocumentSetItem[],
  formContents: MiHospitalizationOrderDocumentSetItem[]
) => {
  if (documentSets.length === 0) {
    return 'not_sent'
  }

  if (formContents.length === 0) {
    return 'no_document_submit'
  }

  // ステータスの数を集計
  const statusCounts = formContents.reduce<{ [key: string]: number }>((accumulate, order) => {
    const statusCode = order.contentStatusCode
    accumulate[statusCode] = (accumulate[statusCode] || 0) + 1
    return accumulate
  }, {})

  // 提出済フォームコンテンツの数
  const submittedCount = statusCounts['submitted'] || 0
  // 未提出フォームコンテンツの数
  const notSubmittedCount = statusCounts['not_submitted'] || 0

  if (notSubmittedCount === formContents.length) {
    return 'not_submitted'
  } else if (notSubmittedCount > 0) {
    return 'partial_submitted'
  } else if (submittedCount > 0) {
    return 'submitted'
  } else {
    return 'confirmed'
  }
}
