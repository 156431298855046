<script setup lang="ts">
import BaseButton from '@components/Base/Button/index.vue'
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { VuetifyColorTokenType } from '@/common/VuetifyTheme/types'
import type { SectionContainer } from '@/features/CommonForm/type'

import BaseDialog from '@/components/Base/Dialog/index.vue'
import { OnlyTextContentDialog } from '@/components/Dialog'
import useDialog from '@/composables/useDialog'
import FormContentSubmissionForm from '@/features/CommonForm/formContentSubmissionForm.vue'
import { useHospitalizationOrderConfirmationStore } from '@/stores/hospitalizationOrderConfirmation'

const { t } = useI18n()
const hospitalizationOrderConfirmationStore = useHospitalizationOrderConfirmationStore()

const props = withDefaults(
  defineProps<{
    value: boolean
    fetchedContentJson: SectionContainer[]
    sectionNumber: number
    medicalInstitutionId: string
    hospitalizationOrderId: string
    hospitalizationOrderDocumentSetContentId: string
    isPermittedChangeAction: boolean
  }>(),
  {
    value: false,
    isPermittedChangeAction: false
  }
)

const contentJson = reactive<SectionContainer[]>(
  JSON.parse(JSON.stringify(props.fetchedContentJson))
)

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
}>()

const { isLoadingState, startLoading, stopLoading } = useDialog()

// 編集の有無をチェック
const isDirty = ref<boolean>(false)
let submitButtonColor = ref<VuetifyColorTokenType>('surface-disable')
watch(isDirty, () => {
  submitButtonColor.value = isDirty.value ? 'primary' : 'surface-disable'
})

// セクションタイトル
const sectionTitle = computed(() => {
  return t('pages.documentSets.ofEdit', {
    resource: `${t('features.FormContents.typeName.section')}${props.sectionNumber + 1}`
  })
})

// submit処理
const onConfirm = async () => {
  startLoading()
  await saveFormContent()
  stopLoading()
}

const onClose = () => {
  emit('close')
}

// 保存API呼び出し
const saveFormContent = async () => {
  // 権限チェック
  if (props.isPermittedChangeAction === false) {
    return
  }
  // 編集点が無い場合は保存しない
  if (isDirty.value === false) {
    return
  }

  // API送信
  try {
    const response =
      await hospitalizationOrderConfirmationStore.updateHospitalizationOrderSubmissionFormContent(
        props.medicalInstitutionId,
        props.hospitalizationOrderId,
        props.hospitalizationOrderDocumentSetContentId,
        { contentJson: JSON.stringify(contentJson) }
      )
    if (response.success) {
      emit('confirm')
    } else {
      toggleErrorDialog()
    }
  } catch (error) {
    console.error(error)
    toggleErrorDialog()
  }
}

// エラーモーダル開閉管理
const isOpenErrorDialog = ref<boolean>(false)
const toggleErrorDialog = () => {
  isOpenErrorDialog.value = !isOpenErrorDialog.value
}

// フォームコンテンツの編集を反映
const onEditFormContent = (sc: SectionContainer[]): void => {
  isDirty.value = true
  contentJson.splice(0, contentJson.length, ...sc)
}

// 画面終了時クリア
onUnmounted(() => {
  isDirty.value = false
  contentJson.splice(0, contentJson.length)
})
</script>

<template>
  <BaseDialog
    :value="props.value"
    :title="sectionTitle"
    :confirm-btn-text="t('attributes.select')"
    :confirmBtnVisible="true"
    cancel-btn-default="right"
    :cancel-btn-text="t('attributes.cancel')"
    size="large"
    minHeight="633px"
    persistent
    @input="onClose"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>

    <template #actions-right>
      <BaseButton
        type="button"
        :color="submitButtonColor"
        :disabled="!isDirty || !isPermittedChangeAction"
        :loading="isLoadingState.value"
        :content="t('attributes.save')"
        min-width="8rem"
        @click="onConfirm"
      />
    </template>
    <template #contents>
      <div class="scroll-view">
        <div class="form-content-detail-body" v-if="contentJson.length">
          <div class="form-detail">
            <!-- フォームコンテンツ -->
            <FormContentSubmissionForm
              :fetched-content-json="contentJson"
              :current-section-number="sectionNumber"
              @on-change="onEditFormContent"
            ></FormContentSubmissionForm>
          </div>
          <!-- エラーダイアログ -->
          <OnlyTextContentDialog
            :value="isOpenErrorDialog"
            :content="t('errors.sentDocumentSetContent.editUpdateError')"
            :cancelBtnText="t('attributes.close')"
            :size="'small'"
            :confirmBtnVisible="false"
            @input="toggleErrorDialog"
          />
        </div>
      </div>
      <hr />
    </template>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.scroll-view {
  max-height:  calc(100svb - 230px);
  padding: 0 0 12px 0;
  margin-bottom: 12px;
  overflow: auto;
  border: none;
}
.content-detail-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  min-height: 200px;
  padding: 16px;
}
.content-detail {
  padding: 32px 0;
}
:deep(.section-title-wrapper) {
  display: none;
}
</style>
