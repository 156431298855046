<script setup lang="ts">
import SearchDocumentSetStatus from './SearchDocumentSetStatus.vue'
import SearchHospitalizationOrderStatus from './SearchHospitalizationOrderStatus.vue'
import SearchPatientName from './SearchPatientName.vue'
import SearchPatientNo from './SearchPatientNo.vue'
import SearchUseAppStatus from './SearchUseAppStatus.vue'

const searchPatientNo = defineModel<string>('searchPatientNo', { required: true })
const searchPatientName = defineModel<string>('searchPatientName', { required: true })
const searchUseAppStatus = defineModel<string>('searchUseAppStatus', { required: true })
const searchHospitalizationOrderStatus = defineModel<string>('searchHospitalizationOrderStatus', {
  required: true
})
const searchDocumentSetStatus = defineModel<string>('searchDocumentSetStatus', { required: true })
</script>

<template>
  <div class="hospitalization-order-list-search">
    <SearchPatientNo class="" v-model="searchPatientNo" />
    <SearchPatientName class="" v-model="searchPatientName" />
    <SearchUseAppStatus class="" v-model="searchUseAppStatus" />
    <SearchHospitalizationOrderStatus class="" v-model="searchHospitalizationOrderStatus" />
    <SearchDocumentSetStatus class="" v-model="searchDocumentSetStatus" />
  </div>
</template>

<style lang="scss" scoped>
.hospitalization-order-list-search {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
