<script setup lang="ts">
import { computed, watch, ref } from 'vue'

import { getToken } from '@/components/Typography/token'

const tokenStyle = getToken('BODY_L')

type Props = {
  /**
   * キーのテキスト
   */
  keyContent: string
  /**
   * 値のテキスト
   */
  valueContent: string
  /**
   * プレースホルダーの初期値
   */
  placeholder?: string // placeholderをオプショナルにする
}

const props = defineProps<Props>()

const displayValue = ref(props.placeholder ? props.placeholder : props.valueContent)

const valueColor = ref(props.placeholder ? '#A3A9AD' : '#192833')

watch(
  () => props.valueContent,
  (newValue) => {
    if (newValue) {
      displayValue.value = newValue
      valueColor.value = '#192833'
    }
  }
)

const keyStyle = computed(() => {
  return {
    width: '120px',
    ...tokenStyle
  }
})

const valueStyle = computed(() => {
  return {
    width: 'auto',
    color: valueColor.value,
    ...tokenStyle
  }
})
</script>

<template>
  <div class="property-field">
    <div class="key" :style="keyStyle">{{ keyContent }}</div>
    <div class="value-wrapper">
      <slot>
        <div class="value" :style="valueStyle">{{ displayValue }}</div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.property-field {
  border-bottom: 1px solid #d1d4d6;
  display: flex;

  .key {
    flex-shrink: 0;
    padding: 8px;
    background-color: #e8e9eb;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .value-wrapper {
    flex-grow: 1;
    padding: 8px;
    background-color: rgb(var(--v-theme-primitive-white-DEFAULT-value));
    overflow: hidden;
  }
  .value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
