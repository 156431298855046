<script setup lang="ts">
import { getTypography } from '@optim-design-system/src'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import BaseButton from '@/components/Base/Button/index.vue'
import Dialog from '@/components/Base/Dialog/index.vue'
import useDialog from '@/composables/useDialog'
import { createDefaultChoice } from '@/features/FormContentForm/TemplateFormFunctions'
import {
  FormOptionType,
  type AvailableDestination,
  type BranchDefault,
  type BranchLast,
  type BranchNext,
  type BranchNextOption,
  type BranchTypeValue,
  type Choice,
  type ChoiceOption,
  type FormContentInput,
  type FormOption
} from '@/features/FormContentForm/type'

const { t } = useI18n()

const props = defineProps<{
  /**
   * セクションインデックス番号
   */
  sectionIndex: number
  /**
   * オプションインデックス番号
   */
  optionIndex: number
  /**
   * フォーム親オブジェクト
   */
  form: FormContentInput
  /**
   * フォームオプション
   */
  option: ChoiceOption
}>()
const option = reactive(props.option)

// 開いているダイアログのID
const dialogId = ref<number>(0)
// 親フォームオブジェクト
const form = computed<FormContentInput>(() => ({ ...props.form }))
// 選択した遷移先（ラジオグループ用のリアクティブ変数） 空文字|uuid|next|lastの文字列が入る
const selectIdOrType = ref<(string | BranchTypeValue)[]>([])
// ダイアログごとのラジオグループの選択値
const destinationSettings = reactive<Destination[]>([])

// 初期化
onMounted(() => {
  // 一つもフィールドがなければフィールドを一つ作成する
  if (option.choices.length <= 0) {
    // コンポーネント作成時に入力フォームを自動で一つ用意する
    option.choices.push(createDefaultChoice())
    destinationSettings.push(createDefaultDestintion())
  } else if (option.choices.length > 0) {
    // 自コンポーネントがコピーで作成されたとき分岐先設定を自動反映
    option.choices.forEach((choice: Choice) => {
      if (choice.branch.type === 'option') {
        destinationSettings.push(createDestination(choice.branch.id, true))
      } else if (choice.branch.type === 'last' || choice.branch.type === 'next') {
        destinationSettings.push(createDestination(choice.branch.type, true))
      } else {
        destinationSettings.push(createDestination('defualt', false))
      }
    })
  }
})

// 選択肢の上限数を管理
const addMaxLimit = 20
const addChoice = () => {
  if (option.choices.length < addMaxLimit) {
    option.choices.push(createDefaultChoice())
    destinationSettings.push(createDefaultDestintion())
    selectIdOrType.value.push('default')
  }
}

// 選択肢を削除
const removeChoice = (choiceIndex: number) => {
  option.choices.splice(choiceIndex, 1)
  destinationSettings.splice(choiceIndex, 1)
  selectIdOrType.value.splice(choiceIndex, 1)
}

// ダイアログの保存
const preSaveIdOrType = ref<(string | BranchTypeValue)[]>([])
const onSaveDialog = () => {
  isOpenDialog.value = !isOpenDialog.value
  selectIdOrType.value[dialogId.value] = preSaveIdOrType.value[dialogId.value]
  changeDialog()
}

// 選択可能候補一覧
const availableDestinationsList = computed<AvailableDestination[]>(() => {
  if (form.value) {
    // セクション内の全フォームオプションのIDを取得
    return (
      form.value.contentJson[props.sectionIndex].options
        // セクション内からインプットセットを除くフォームオプションのIDとインデックスを取得
        .map((option: FormOption, optionIndex: number) => ({
          id: option.id,
          index: optionIndex,
          type: option.type
        }))
        // 順番が自分より後の要素を抽出。インプットセットと選択肢を除外。
        .filter(
          (option: AvailableDestination & { type: string }) =>
            option.index > props.optionIndex &&
            option.type !== FormOptionType.Choice &&
            option.type !== FormOptionType.InputSet
        )
        // 抽出結果を型整形
        .map((option: AvailableDestination & { type: string }) => ({
          id: option.id,
          index: option.index
        }))
    )
  }
  return []
})

/**
 * フォームオプションIDから回答番号を逆引き
 * @param string フォームオプションのid
 * @return number 回答番号（インデックス+1）
 **/
const getAvailableQuestionNumber = (formOptionId: string): number | null => {
  const destination = availableDestinationsList.value.find(
    (ava: AvailableDestination) => ava.id === formOptionId
  )
  return destination ? destination.index + 1 : null
}

/**
 * ブランチの設定値と簡易作成メソッド
 * @param destinationIdOrType 分岐先フォームオプションのID
 * @param destinationOptionIndex 分岐先フォームオプションのインデックス番号
 * @return Destination 分岐先の宛先インスタンス
 */
type Destination = {
  destinationIdOrType: string | BranchTypeValue
  isModified: boolean
}
const createDestination = (
  destinationIdOrType: string,
  // destinationOptionIndex: number,
  isModified: boolean = false
): Destination => {
  return {
    destinationIdOrType: destinationIdOrType,
    // destinationOptionIndex: destinationOptionIndex,
    isModified: isModified
  }
}

// 選択肢の宛先初期値の作成メソッド
const createDefaultDestintion = (): Destination => {
  return {
    destinationIdOrType: 'default',
    isModified: false
  }
}

// ダイアログで分岐先を設定したときformにも反映させる
const changeDialog = () => {
  // next | last の場合typeをそのまま設定
  if (
    selectIdOrType.value[dialogId.value] === 'next' ||
    selectIdOrType.value[dialogId.value] === 'last'
  ) {
    // next|lastは分岐先が実質ないため分岐先フォームオプションインデックス番号に 0 を登録
    destinationSettings[dialogId.value] = createDestination(
      selectIdOrType.value[dialogId.value],
      true
    )
  } else if (selectIdOrType.value[dialogId.value] === 'default') {
    // defaultならidにdefaultを設定, isModifiedはfalse
    destinationSettings[dialogId.value] = createDestination(
      selectIdOrType.value[dialogId.value],
      false
    )
  } else {
    const destinationOptionIndex = getAvailableQuestionNumber(selectIdOrType.value[dialogId.value])
    // フォームオプションのインデックスが見つからない場合は初期化
    if (!destinationOptionIndex) {
      destinationSettings[dialogId.value] = createDefaultDestintion()
    } else {
      // optionの場合
      destinationSettings[dialogId.value] = createDestination(
        selectIdOrType.value[dialogId.value],
        true
      )
    }
  }

  // ダイアログで選択されたId|Typeに応じてBranchを設定
  destinationSettings.forEach((destination, idsIndex) => {
    if (destination.isModified) {
      switch (destination.destinationIdOrType) {
        case 'default':
          option.choices[idsIndex].branch = { type: 'default' } as BranchDefault
          break
        case 'next':
          option.choices[idsIndex].branch = { type: 'next' } as BranchNext
          break
        case 'last':
          option.choices[idsIndex].branch = { type: 'last' } as BranchLast
          break
        default:
          option.choices[idsIndex].branch = {
            type: 'option',
            id: destination.destinationIdOrType
          } as BranchNextOption
          break
      }
    }
  })
}

// 分岐先がすでに設定されているかチェック
const isSetGoToBranch = (choiceIndex: number) => {
  const isModified = destinationSettings[choiceIndex] && destinationSettings[choiceIndex].isModified
  return isModified
}

// 分岐論理名を取得
const getDestinationOptionName = (choiceIndex: number) => {
  if (option.choices[choiceIndex]) {
    if (destinationSettings[choiceIndex].isModified) {
      // ダイアログで設定されたid|typeに応じて論理名を取得
      switch (option.choices[choiceIndex].branch.type) {
        case 'default':
          // 通常は通らないがフェイルセーフのため定義
          return ''
        case 'next':
          return t('features.FormContents.new.choices.toNext')
        case 'last':
          return t('features.FormContents.new.choices.toLast')
        case 'option':
          // 回答番号を取得
          return (
            t('features.FormContents.new.input.questionIndexPrefix') +
            getAvailableQuestionNumber(destinationSettings[choiceIndex].destinationIdOrType)
          )
        default:
          // next | last | default | uuid 以外の場合
          throw new Error(`Unexpected branch type specified as argument`)
      }
    }
  }
}

// 選択肢の宛先登録を解除
const removeDestination = (choiceIndex: number) => {
  option.choices.splice(choiceIndex, 1, createDefaultChoice())
  destinationSettings.splice(choiceIndex, 1, createDefaultDestintion())
  selectIdOrType.value.splice(choiceIndex, 1, 'default')
  preSaveIdOrType.value.splice(choiceIndex, 1, 'default')
}

// SelectIdOrType.value配列の中身を最新に更新する。順番入れ替え時などに使用
const validDestination = () => {
  option.choices.forEach((choice: Choice, choiceIndex: number) => {
    if (choice.branch.type === 'option') {
      const isValid = getAvailableQuestionNumber(choice.branch.id)
      if (!isValid) {
        removeDestination(choiceIndex)
        option.choices.splice(choiceIndex, 1, createDefaultChoice())
      }
    }
    // 選択肢の登録が解除されたときの初期化処理
    if (choice.branch.type === 'default') {
      destinationSettings[choiceIndex] = createDefaultDestintion()
      selectIdOrType.value.splice(choiceIndex, 1, 'default')
    }
  })
}

// フォームオプションの順番入れ替え時に登録先の妥当性をチェック
watch(
  () => form.value.contentJson[props.sectionIndex].options,
  () => {
    validDestination()
  },
  { deep: true }
)

// ダイアログの開閉制御
const isOpenDialog = ref<boolean>(false)
const openDialog = (choiceIndex: number) => {
  isOpenDialog.value = true
  dialogId.value = choiceIndex
}
const closeDialog = () => {
  isOpenDialog.value = false
  preSaveIdOrType.value[dialogId.value] = 'default'
}

// 必須項目エラーメッセージ
const required = (value: string, errorMessage: string) => {
  return !!value || t('attributes.formatTemplate.required', { resource: errorMessage })
}

// ローディング管理用
const { isLoadingState } = useDialog()

// スタイル用
const labelStyle = computed(() => getTypography('LABEL_M'))
const bodyStyle = computed(() => getTypography('BODY_S'))
</script>

<template>
  <div class="choices-form-option">
    <div class="content">
      <div>
        <!-- 項目名 -->
        <v-text-field
          v-model="option.name"
          class="required-field"
          :placeholder="t('features.FormContents.new.placeholder.fieldName')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
          :rules="[required(option.name, t('features.FormContents.new.placeholder.fieldName'))]"
        ></v-text-field>
      </div>

      <div>
        <!-- 説明 -->
        <v-text-field
          v-model="option.description"
          :placeholder="t('features.FormContents.new.placeholder.description')"
          counter="100"
          :hint="t('validations.maxLength', { max: 100 })"
          persistent-hint
        ></v-text-field>
      </div>

      <div>
        <div v-for="(choice, choiceIndex) in option.choices" :key="choiceIndex">
          <div class="choice-field">
            <!-- 選択肢 -->
            <v-text-field
              v-model="choice.name"
              class="required-field"
              prepend-icon="radio_button_unchecked"
              :placeholder="t('features.FormContents.new.choices.choice')"
              :hint="t('validations.maxLength', { max: 100 })"
              :rules="[required(choice.name, t('features.FormContents.new.placeholder.choice'))]"
            ></v-text-field>

            <!-- 選択肢削除ボタン -->
            <v-btn
              class="text-blue-grey-lighten-3"
              valiant="flat"
              tabindex="-1"
              flat
              icon="close"
              @click="removeChoice(choiceIndex)"
            ></v-btn>
          </div>

          <div>
            <div v-if="isSetGoToBranch(choiceIndex)" cols="10" class="added-choice-field">
              <!-- 登録済み選択肢名 -->
              <span class="text-primary">{{ getDestinationOptionName(choiceIndex) }}</span>

              <!-- 登録済み選択肢の登録解除ボタン -->
              <v-btn
                class="option-btn text-blue-grey-lighten-3"
                :style="labelStyle"
                label="option"
                valiant="plain"
                flat
                @click="removeDestination(choiceIndex)"
              >
                <v-icon icon="close" size="20"> </v-icon>
              </v-btn>
            </div>

            <div v-else cols="10" class="add-choice-field">
              <!-- 選択肢登録用ダイアログ表示ボタン -->
              <v-btn
                class="option-btn text-primary"
                :style="labelStyle"
                label="option"
                valiant="plain"
                rounded="lg"
                :disabled="false"
                tabindex="-1"
                flat
                @click="openDialog(choiceIndex)"
              >
                <template v-slot:prepend>
                  <v-icon icon="add" size="20"> </v-icon>
                </template>
                {{ t('features.FormContents.new.choices.addBranch') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- 選択肢を追加するボタン -->
        <v-btn
          class="option-btn text-primary"
          :style="labelStyle"
          label="option"
          :v-show="true"
          valiant="plain"
          rounded="lg"
          :disabled="option.choices.length >= addMaxLimit"
          flat
          @click="addChoice"
        >
          <template v-slot:prepend>
            <v-icon icon="add" size="20"> </v-icon>
          </template>
          {{ t('features.FormContents.new.choices.addChoice') }}
        </v-btn>
      </div>
    </div>

    <v-divider class="divider"></v-divider>

    <div class="bottom">
      <!-- 回答必須スイッチ -->
      <v-switch
        v-model="option.isRequired"
        :style="bodyStyle"
        :label="t('features.FormContents.new.input.required')"
        color="primary"
        baseColor="theme-DEFAULT-surface-tertiary-value"
        hide-details
        inset
        flat
      />
    </div>

    <Dialog
      :value="isOpenDialog"
      :title="t('features.FormContents.new.choices.settingBranch')"
      titleColor="primary"
      :cancelBtnText="t('attributes.cancel')"
      cancel-btn-default="right"
      confirmBtnVisible
      @input="closeDialog"
      @confirm="onSaveDialog"
    >
      <template #contents>
        <p class="d-flex justify-center">
          {{ t('features.FormContents.new.choices.addBranchCaption') }}
        </p>
        <v-container>
          <v-row class="my-0 mt-1 py-0">
            <v-col cols="10" class="my-0 py-0 row">
              <!-- 選択肢一覧 -->
              <v-radio-group v-model="preSaveIdOrType[dialogId]">
                <!-- 次へ（固定値） -->
                <v-radio
                  :label="t('features.FormContents.new.choices.toNext')"
                  value="next"
                ></v-radio>
                <!-- 末尾へ（固定値） -->
                <v-radio
                  :label="t('features.FormContents.new.choices.toLast')"
                  value="last"
                ></v-radio>
                <!-- 自フォームオプションより下にある同セクション内の設問番号を取得 -->
                <v-radio
                  v-for="(option, itemIndex) in availableDestinationsList"
                  :key="itemIndex"
                  :value="option.id"
                  :label="`${t('features.FormContents.new.input.questionIndexPrefix')}${option.index + 1}`"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template #actions-right>
        <BaseButton
          color="primary"
          :content="t('attributes.save')"
          :loading="isLoadingState.value"
          variant="flat"
          min-width="7rem"
          @click="onSaveDialog"
        />
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.no-under-gutters .v-input__details) {
  display: none;
}
.background-transparent {
  background-color: transparent;
}
.field-gap:not(:first-child) {
  margin-top: 20px;
}
:deep(.v-btn:disabled) {
  color: rgb(var(--surface-surface-transparent)) !important;
  opacity: var(--v-disabled-opacity) !important;
}
:deep(.v-btn:disabled .v-btn__overlay) {
  display: none;
}
:deep(.required-field :before) {
  border-width: 0 0 3px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.bottom {
  display: flex;
  justify-content: end;
  gap: 16px;
  height: 36px;
}

.divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.v-input--density-default {
  --v-input-control-height: 36px;
}

.choice-field {
  display: flex;
}

.add-choice-field {
  display: flex;
  margin-top: -22px;
  justify-content: end;
  flex: 0 0 93.3333333333%;
  max-width: 93.3333333333%;
  padding: 4px 0;
}
.added-choice-field {
  display: flex;
  margin-top: -22px;
  justify-content: end;
  flex: 0 0 93.3333333333%;
  max-width: 93.3333333333%;
  padding: 4px 0;
}

.option-btn {
  height: 24px;
  min-width: auto !important;
  padding: 0 4px;
}
</style>

<style lang="scss">
.choice-form-option {
  .bottom {
    .switch {
      .v-selection-control--density-default {
        --v-selection-control-size: 36px !important;
      }
    }
  }
}
</style>
