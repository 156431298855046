<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type {
  MiDocumentSetItem,
  MiHospitalizationOrderDocumentSetListResponse
} from '@/api/generate/apiSchema'
import type { Order } from '@/api/types'
import type { Header } from '@/components/Table/CheckSortTable/types'

import { hospitalizationOrderApiClient } from '@/api/apiClient'
import { CheckSortTable, ScrollSortTable } from '@/components/Table'
import useDateFormatter from '@/composables/useDateFormatter'
import { useDocumentSetsStore } from '@/stores/documentSets'
import { useMedicalInstitutionsStore } from '@/stores/medicalInstitutions'

type Props = {
  isLoading: boolean
  hospitalizationOrderId: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'requestNextItem', item: MiDocumentSetItem): void
  (e: 'update:sortBy', orderBy?: string, order?: Order): void
}>()

const { t } = useI18n()
const selectDocumentSetsStore = useDocumentSetsStore()
const { getFormattedDateTime } = useDateFormatter()

const medicalInstitutionsStore = useMedicalInstitutionsStore()

const sentDocumentSetList = ref<MiHospitalizationOrderDocumentSetListResponse>({ list: [] })

const headers: Header[] = [
  {
    title: t('features.SendDocumentSets.Form.DocumentSetSelectList.Table.Header.title'),
    key: 'title',
    width: 'auto',
    minWidth: '182px',
    maxWidth: '182px'
  },
  {
    title: t('features.SendDocumentSets.Form.DocumentSetSelectList.Table.Header.contentSize'),
    key: 'contentSize',
    width: '120px',
    minWidth: '120px'
  },
  {
    title: t('features.SendDocumentSets.Form.DocumentSetSelectList.Table.Header.onUpdated'),
    key: 'updatedAt',
    width: '150px',
    minWidth: '150px'
  }
]

const items = computed(() => {
  const documentSetList = selectDocumentSetsStore.getDocumentSetList()
  const sentDocumentSetIds = sentDocumentSetList.value.list.map(
    (sentDocumentSet) => sentDocumentSet.documentSetId
  )

  return documentSetList.value
    .filter((documentSet: MiDocumentSetItem) => {
      // sentDocumentSetList に含まれていない書類セットを残す
      return !sentDocumentSetIds.includes(documentSet.id)
    })
    .map((documentSet: MiDocumentSetItem) => {
      return {
        id: documentSet.id,
        title: documentSet.title,
        contentSize: documentSet.contentSize,
        updatedAt: getFormattedDateTime(new Date(documentSet.updatedAt))
      }
    })
})

onMounted(async () => {
  const response =
    await hospitalizationOrderApiClient().api.hospitalizationOrderDocumentSetsByMedicalInstitution(
      medicalInstitutionsStore.currentMedicalInstitution().id,
      props.hospitalizationOrderId,
      { optimId: '' }
    )

  // response.data にアクセスして list プロパティを参照する
  sentDocumentSetList.value.list = response.data.list
})

const selectItems = defineModel<string[]>('selectItems')

const scrollSortTable = ref<InstanceType<typeof ScrollSortTable>>()
const resetScroll = () => {
  if (scrollSortTable.value == undefined) {
    return
  }

  scrollSortTable.value.resetScroll()
}

defineExpose({
  resetScroll
})

const sortBy = (item: { key?: string; order?: Order }) => {
  emit('update:sortBy', item.key, item.order)
}
</script>

<template>
  <CheckSortTable
    class="document-set-list-table"
    ref="scrollSortTable"
    :headers="headers"
    :items="items"
    v-model:selectableItems="selectItems"
    :loading="isLoading"
    :noDataText="t('features.SendDocumentSets.Form.Table.noContentData')"
    @requestNextItem="$emit('requestNextItem', $event as MiDocumentSetItem)"
    @update:sortBy="sortBy"
  />
</template>

<style scoped>
.document-set-list-table {
  min-width: 505px;
  max-height: 400px;
}
</style>
