<script setup lang="ts">
import SearchTitle from './SearchTitle.vue'

const searchTitle = defineModel<string>('searchTitle')
</script>

<template>
  <div>
    <SearchTitle class="mr-2" v-model="searchTitle" />
  </div>
</template>

<style lang="scss" scoped></style>
