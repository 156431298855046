<script setup lang="ts">
import { defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

import Button from '@/components/Base/Button/index.vue'
import Label from '@/components/Base/Label/index.vue'
import { useConstants } from '@/composables/useConstants'

const { t } = useI18n()

type Props = {
  disabled: boolean
}

defineProps<Props>()

const { CONSTANTS } = useConstants()

const patientNo = defineModel<string>('patientNo', { required: true })
const familyName = defineModel<string>('familyName', { required: true })
const givenName = defineModel<string>('givenName', { required: true })
const phoneticFamilyName = defineModel<string>('phoneticFamilyName', { required: true })
const phoneticGivenName = defineModel<string>('phoneticGivenName', { required: true })
const gender = defineModel<number | undefined>('gender', { required: true })
const birthDate = defineModel<string>('birthDate', { required: true })

const genderSelectItems = [
  { value: CONSTANTS.GENDER.MALE, title: t('attributes.gender.1') },
  { value: CONSTANTS.GENDER.FEMALE, title: t('attributes.gender.2') }
]

const emit = defineEmits<{
  (e: 'submit'): void
  (e: 'cancel'): void
}>()

// アクションを伝達
const submit = () => {
  emit('submit')
}

const cancel = () => {
  emit('cancel')
}
</script>

<template>
  <v-form>
    <div class="patient-registration rounded-lg">
      <div>
        <Label
          forHtml="new-patient-no-title"
          :labelText="t('features.patient.PatientRegistrationForm.input.patientNo')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="new-patient-id"
          class="mt-1"
          v-model="patientNo"
          :hint="t('validations.maxLength', { max: 100 })"
          :placeholder="t('features.patient.PatientRegistrationForm.placeholder.patientNo')"
          persistent-hint
          :rules="[]"
        ></v-text-field>
      </div>

      <div>
        <Label
          forHtml="new-patient-name-title"
          :labelText="t('features.patient.PatientRegistrationForm.input.name')"
          :isRequired="true"
          direction="left"
        ></Label>
        <div class="patient-name mt-1">
          <v-text-field
            id="new-patient-family-name"
            v-model="familyName"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="t('features.patient.PatientRegistrationForm.placeholder.familyName')"
            persistent-hint
            :rules="[]"
          ></v-text-field>
          <v-text-field
            id="new-patient-given-name"
            v-model="givenName"
            :hint="t('validations.maxLength', { max: 100 })"
            :placeholder="t('features.patient.PatientRegistrationForm.placeholder.givenName')"
            persistent-hint
            :rules="[]"
          ></v-text-field>
        </div>
      </div>

      <div>
        <Label
          forHtml="new-patient-phonetic-name-title"
          :labelText="t('features.patient.PatientRegistrationForm.input.phoneticName')"
          :isRequired="true"
          direction="left"
        ></Label>
        <div class="patient-name">
          <v-text-field
            id="new-patient-phonetic-family-name"
            v-model="phoneticFamilyName"
            :hint="t('validations.maxLengthAndPhonetic', { max: 100 })"
            :placeholder="
              t('features.patient.PatientRegistrationForm.placeholder.phoneticFamilyName')
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>

          <v-text-field
            id="new-patient-phonetic-given-name"
            v-model="phoneticGivenName"
            :hint="t('validations.maxLengthAndPhonetic', { max: 100 })"
            :placeholder="
              t('features.patient.PatientRegistrationForm.placeholder.phoneticGivenName')
            "
            persistent-hint
            :rules="[]"
          ></v-text-field>
        </div>
      </div>

      <div>
        <Label
          forHtml="new-patient-gender-title"
          :labelText="t('features.patient.PatientRegistrationForm.input.gender')"
          :isRequired="true"
        ></Label>
        <v-select
          id="new-patient-gender"
          class="mt-1"
          v-model="gender"
          :placeholder="t('features.patient.PatientRegistrationForm.placeholder.gender')"
          hide-details
          :items="genderSelectItems"
        ></v-select>
      </div>

      <div>
        <Label
          forHtml="new-patient-birth-date-title"
          :labelText="t('features.patient.PatientRegistrationForm.input.birthDate')"
          :isRequired="true"
          direction="left"
        ></Label>
        <v-text-field
          id="new-patient-birth-date"
          class="mt-1"
          type="date"
          v-model="birthDate"
          :rules="[]"
          hide-details
        ></v-text-field>
      </div>
    </div>

    <div class="form-buttons">
      <Button
        type="button"
        color="primitive-white-DEFAULT-value"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.cancel')"
        elevated="text"
        variant="outlined"
        min-width="8rem"
        @click="cancel()"
      ></Button>
      <Button
        type="button"
        color="primary"
        :disabled="disabled"
        :loading="disabled"
        :content="t('attributes.register')"
        min-width="8rem"
        @click="submit()"
      ></Button>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.patient-registration {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: rgb(var(--v-theme-primitive-white-DEFAULT-value));
  padding: 16px;
  min-width: 520px;
}

.patient-name {
  display: flex;
  padding-top: 4px;
  gap: 8px;
}

.form-buttons {
  min-width: 520px;
  margin-top: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;
}
</style>
